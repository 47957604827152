import { FC, useState, useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';

import { getCompanyEmployees } from 'services/company';
import Select, { ListItem } from 'components/Select/v2';
import useLoadClients from 'utils/hooks/useLoadClients';
import { VALIDATION_MESSAGES } from 'constants/common';
import CardContainer from '../CardContainer';
import EmployeesList from '../EmployeesList';
import { NoEmployees, StyledSelect } from './ClientDetails.styled';

const ClientDetails: FC = () => {
  const [employees, setEmployees] = useState<ListItem[]>([]);
  const companies: ListItem[] =
    useLoadClients()?.values?.map(({ name, id }) => ({
      value: id,
      label: name,
    })) || [];
  const { control, errors, watch } = useFormContext();

  const companyId = watch('companyId');

  useEffect(() => {
    if (companyId) {
      getCompanyEmployees(companyId).then((data) => {
        setEmployees(
          data.map(({ name, id }) => ({
            label: name,
            value: id,
          })),
        );
      });
    }
  }, [companyId]);

  return (
    <CardContainer title="Client Details">
      <Controller
        name="companyId"
        rules={{ required: { value: true, message: VALIDATION_MESSAGES.required } }}
        control={control}
        render={({ value, onChange }, { invalid }) => (
          <StyledSelect>
            <Select
              verticalLabel
              isRequired
              isError={invalid}
              label="Company Name"
              mb={16}
              withSearch
              value={value}
              placeholder="Select a company"
              itemList={companies}
              onChange={onChange}
              errorMessage={errors.companyId?.message}
            />
          </StyledSelect>
        )}
      />
      {employees?.length > 0 ? (
        <EmployeesList list={employees} />
      ) : (
        <NoEmployees>
          This company hasn&#39;t any employees. You can add employees directly there.
        </NoEmployees>
      )}
    </CardContainer>
  );
};
export default ClientDetails;
