import styled from '@emotion/styled';
import { Tooltip } from '../Task/Task.styled';

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px 16px 24px;
  background-color: ${({ theme }) => theme.colors.white[400]};
  border: 1px solid ${({ theme }) => theme.colors.gray[500]};
  border-left: 4px solid ${({ theme }) => theme.colors.blue[300]};
  border-radius: 4px;
  max-height: 56px;
  margin: 10px;

  & span:last-child {
    color: ${({ theme }) => theme.colors.gray[600]};
  }
  &:hover > div {
    visibility: visible;
  }
`;

export const TooltipSubtask = styled(Tooltip)`
  bottom: 66px;
`;

export const TaskName = styled.span`
  font-weight: 500;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.gray[950]};
  overflow: hidden;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
`;
