import { FilterType } from 'pages/ProjectListPage/types';

export const STATUSES = [
  'All statuses',
  'Initial',
  'Discussion',
  'Documentation',
  'In Dev',
  'Complete',
  'Cancelled',
  'On Hold',
];

export const NEW_PROJECT_STATUSES = [
  'Initial',
  'Discussion',
  'Documentation',
  'In Dev',
  'Complete',
  'Cancelled',
  'On Hold',
];

export const STATUSES_ESTIMATION = ['Draft', 'Ready', 'Sent to approve', 'Accepted', 'Rejected'];

export const SORTS = [
  {
    label: 'Name',
    value: 'name',
  },
  {
    label: 'Deadline',
    value: 'deadline',
  },
];

export const SHOWN_QUANTITY = 12;

export const LANGUAGES = ['ALL', 'RU', 'EN'];

export const PAGE_NEIGHBOR_QUANTITY = 3;

export const EMAIL_VALIDATION = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const URL_VALIDATION = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/;

export const EMAIL_VALIDATION_WITH_COMMA = /^([a-z][a-z0-9_.]+@([a-z0-9-]+\.)+[a-z]{2,6}(, )*)+$/;

export const ACCEPTED_FILES = {
  types: ['image/png', 'image/jpeg', 'image/bmp', 'image/svg+xml'],
  labels: 'PNG, JPG, BMP, SVG',
};

export const VALIDATION_MESSAGES = {
  required: 'This field is required',
  length: 'The field length is invalid',
};

export const CONTACTS = [
  {
    value: '',
    label: 'Contact type',
  },
  {
    iconName: 'email',
    value: 'email',
    label: 'Email',
  },
  {
    iconName: 'skype',
    value: 'skype',
    label: 'Skype',
  },
  {
    iconName: 'linkedin',
    value: 'linkedin',
    label: 'LinkedIn',
  },
  {
    iconName: 'instagram',
    value: 'instagram',
    label: 'Instagram',
  },
  {
    iconName: 'facebook',
    value: 'facebook',
    label: 'Facebook',
  },
  {
    iconName: 'zoom',
    value: 'zoom',
    label: 'Zoom',
  },
];

export const initialFilter: FilterType = {
  search: '',
  status: STATUSES[0],
  language: LANGUAGES[0],
  sort: SORTS[1].value,
  asc: false,
};
