import styled from '@emotion/styled';

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0 0 10px;
`;

export const Title = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.gray[600]};
`;

export const KanbanTitle = styled.span`
  font-size: 26px;
  margin-top: 8px;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StoriesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.gray[200]};
  background-color: ${({ theme }) => theme.colors.white[400]};
  margin-bottom: 14px;
`;

export const KanbanContainer = styled.div<{ isLast: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 16px 22px;
  border-bottom: ${({ isLast, theme }) => (isLast ? '0' : `1px solid ${theme.colors.gray[200]}`)};
`;

export const TaskTitle = styled.div<{ isActive: boolean }>`
  display: flex;
  padding: 10px 0 10px 10px;
  width: 100%;
  align-items: center;
  cursor: pointer;
  color: ${({ isActive, theme }) => (isActive ? theme.colors.blue[300] : theme.colors.gray[950])};

  :hover {
    color: ${({ theme }) => theme.colors.blue[300]};
  }
`;

export const TaskStatus = styled.div`
  width: max-content;
  height: max-content;
  padding: 0 4px;
  color: ${({ theme }) => theme.colors.green[400]};
  border: 1px solid ${({ theme }) => theme.colors.green[400]};
  margin: 0 8px 0 8px;
`;

export const StoryName = styled.span`
  font-size: 16px;
  font-weight: 500;
  margin-left: 10px;
`;

export const StoryDescr = styled.span`
  color: ${({ theme }) => theme.colors.gray[600]};
  margin-left: 10px;
`;

export const BoardContainer = styled.div`
  display: flex;
  flex-flow: wrap column;
  background-color: ${({ theme }) => theme.colors.white[400]};
`;
