import { nanoid } from 'nanoid';
import groupBy from 'lodash/groupBy';

import { Row, RiskType } from './types';

type AddNewCategoryArgs = {
  currentSectionIndex: number;
  data: Row[];
  technologiesList: string[];
};

export const handleAddNewCategory = ({
  data,
  technologiesList,
  currentSectionIndex,
}: AddNewCategoryArgs): Row[] => {
  const insertionIndex = data.findIndex((row) => row.sectionIndex === currentSectionIndex + 1);
  const dataBeforeInsertion = data.slice(0, insertionIndex);
  const dataAfterInsertion = data
    .slice(insertionIndex)
    .map((row) => ({ ...row, sectionIndex: row.sectionIndex + 1 }));
  const newCategory = {
    isSection: true,
    rowId: nanoid(),
    sectionId: nanoid(),
    sectionIndex: data[insertionIndex].sectionIndex,
    title: '',
    type: 'DEVELOPMENT',
  } as Row;
  const technologies = technologiesList.reduce((acc, cur) => ({ ...acc, [cur]: null }), {});
  const emptyRowOfNewSection = {
    ...technologies,
    rowId: nanoid(),
    isSection: false,
    title: '',
    type: 'DEVELOPMENT',
    riskLevel: 'NO_RISKS',
    sectionIndex: newCategory.sectionIndex,
    sectionId: newCategory.sectionId,
    rowIndex: 0,
    comment: null,
    sum: 0,
  } as Row;

  return [...dataBeforeInsertion, newCategory, emptyRowOfNewSection, ...dataAfterInsertion];
};

type AddNewLineArgs = {
  currentRowId: string;
  currentSectionIndex: number;
  data: Row[];
  technologiesList: string[];
};

export const handleAddNewLine = ({
  currentRowId,
  currentSectionIndex,
  data,
  technologiesList,
}: AddNewLineArgs): Row[] => {
  const section = data.filter((row) => row.sectionIndex === currentSectionIndex);
  const sectionRow = section.find((el) => el.isSection);

  if (sectionRow) {
    const insertionIndex = section.findIndex((el) => el.rowId === currentRowId);
    const dataBeforeInsertion = section.slice(0, insertionIndex + 1);
    const dataAfterInsertion = section
      .slice(insertionIndex + 1)
      .map(({ rowIndex = 0, ...rest }) => ({ ...rest, rowIndex: rowIndex + 1 }));

    const technologies = technologiesList.reduce((acc, cur) => ({ ...acc, [cur]: null }), {});
    const emptyRow = {
      ...technologies,
      rowId: nanoid(),
      riskLevel: 'NO_RISKS',
      isSection: false,
      title: '',
      type: sectionRow.type,
      sectionIndex: sectionRow.sectionIndex,
      sectionId: sectionRow.sectionId,
      rowIndex: insertionIndex,
      comment: null,
      sum: 0,
    } as Row;

    const updatedSection = [...dataBeforeInsertion, emptyRow, ...dataAfterInsertion];

    const sectionStartIndex = data.findIndex(
      (el) => el.sectionIndex === currentSectionIndex && el.isSection,
    );

    const before = data.slice(0, sectionStartIndex);
    const after = data.slice(sectionStartIndex + section.length);

    return [...before, ...updatedSection, ...after];
  }

  return data;
};

const handleSort = (current: Row, next: Row) => {
  if (current.isSection) {
    return 0;
  }

  const { rowIndex: currentIndex = -1 } = current;
  const { rowIndex: nextRowIndex = 0 } = next;

  return currentIndex < nextRowIndex ? -1 : 1;
};

type MoveArgs = {
  isSectionRow: boolean;
  currentRowIndex: number | undefined;
  currentSectionId: string;
  currentSectionIndex: number;
  data: Row[];
};

export const handleMoveUp = ({
  currentSectionId,
  currentRowIndex,
  isSectionRow,
  currentSectionIndex,
  data,
}: MoveArgs): Row[] => {
  const currentSection = data.filter((row) => row.sectionId === currentSectionId);

  if (isSectionRow) {
    const prevSection = data.filter((row) => row.sectionIndex === currentSectionIndex - 1);
    const updatedCurrentSection = currentSection.map((element) => ({
      ...element,
      sectionIndex: currentSectionIndex - 1,
    }));
    const updatedPrevSection = prevSection.map((element) => ({
      ...element,
      sectionIndex: currentSectionIndex,
    }));

    const filteredData = data.filter(
      (element) =>
        element.sectionIndex !== currentSectionIndex - 1 &&
        element.sectionIndex !== currentSectionIndex,
    );

    return Object.values(
      groupBy([...filteredData, ...updatedCurrentSection, ...updatedPrevSection], 'sectionIndex'),
    ).flat();
  }

  const updatedCurrentSection = currentSection
    .map((element) => {
      if (typeof currentRowIndex === 'number' && element.rowIndex === currentRowIndex - 1) {
        return { ...element, rowIndex: currentRowIndex };
      }
      if (typeof currentRowIndex === 'number' && element.rowIndex === currentRowIndex) {
        return { ...element, rowIndex: currentRowIndex - 1 };
      }

      return element;
    })
    .sort(handleSort);

  const filteredData = data.filter((element) => element.sectionIndex !== currentSectionIndex);

  return Object.values(groupBy([...filteredData, ...updatedCurrentSection], 'sectionIndex')).flat();
};

export const handleMoveDown = ({
  currentSectionId,
  currentRowIndex,
  isSectionRow,
  currentSectionIndex,
  data,
}: MoveArgs): Row[] => {
  const currentSection = data.filter((row) => row.sectionId === currentSectionId);

  if (isSectionRow) {
    const nextSection = data.filter((row) => row.sectionIndex === currentSectionIndex + 1);
    const updatedCurrentSection = currentSection.map((element) => ({
      ...element,
      sectionIndex: currentSectionIndex + 1,
    }));
    const updatedNextSection = nextSection.map((element) => ({
      ...element,
      sectionIndex: currentSectionIndex,
    }));

    const filteredData = data.filter(
      (element) =>
        element.sectionIndex !== currentSectionIndex + 1 &&
        element.sectionIndex !== currentSectionIndex,
    );

    return Object.values(
      groupBy([...filteredData, ...updatedNextSection, ...updatedCurrentSection], 'sectionIndex'),
    ).flat();
  }

  const updatedCurrentSection = currentSection
    .map((element) => {
      if (typeof currentRowIndex === 'number' && element.rowIndex === currentRowIndex + 1) {
        return { ...element, rowIndex: currentRowIndex };
      }
      if (typeof currentRowIndex === 'number' && element.rowIndex === currentRowIndex) {
        return { ...element, rowIndex: currentRowIndex + 1 };
      }

      return element;
    })
    .sort(handleSort);

  const filteredData = data.filter((element) => element.sectionIndex !== currentSectionIndex);

  return Object.values(groupBy([...filteredData, ...updatedCurrentSection], 'sectionIndex')).flat();
};

type DeleteElementArgs = {
  isSectionRow: boolean;
  currentRowId: string;
  currentRowIndex: number | undefined;
  currentSectionIndex: number;
  data: Row[];
};

export const handleDeleteElement = ({
  currentRowId,
  currentRowIndex,
  currentSectionIndex,
  isSectionRow,
  data,
}: DeleteElementArgs): Row[] => {
  if (isSectionRow) {
    return data
      .filter((element) => element.sectionIndex !== currentSectionIndex)
      .map((element) => {
        if (element.sectionIndex > currentSectionIndex) {
          return { ...element, sectionIndex: element.sectionIndex - 1 };
        }

        return element;
      });
  }

  return data
    .filter((element) => element.rowId !== currentRowId)
    .map((element) => {
      const { sectionIndex, rowIndex = 0 } = element;
      if (
        sectionIndex === currentSectionIndex &&
        typeof currentRowIndex === 'number' &&
        rowIndex > currentRowIndex
      ) {
        return { ...element, rowIndex: rowIndex - 1 };
      }

      return element;
    });
};

type SetRiskArgs = {
  riskLevel: RiskType;
  rowId: string;
  data: Row[];
};
export const handleSetRiskLevel = ({ riskLevel, rowId, data }: SetRiskArgs): Row[] =>
  data.map((element) => {
    if (element.rowId === rowId) {
      return { ...element, riskLevel };
    }

    return element;
  });
