import { FC } from 'react';

import Icon from 'components/Icon';
import { ChipsItemContainer, DeleteIconContainer } from './ChipsSelect.styled';

type Props = {
  title: string;
  isExisting: boolean;
  onAnimationEnd: () => void;
  onDeleteItem: (value: string) => void;
};

const ChipsItem: FC<Props> = ({ title, isExisting, onAnimationEnd, onDeleteItem }) => {
  const handleDeleteItem = () => {
    onDeleteItem(title);
  };

  return (
    <ChipsItemContainer isExisting={isExisting} onAnimationEnd={onAnimationEnd}>
      {title}
      <DeleteIconContainer onClick={handleDeleteItem}>
        <Icon name="cross" size={10} />
      </DeleteIconContainer>
    </ChipsItemContainer>
  );
};

export default ChipsItem;
