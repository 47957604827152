import styled from '@emotion/styled';

import checkedIcon from 'assets/images/checked.svg';

export const CheckboxContainer = styled.label<{ mb?: number }>`
  display: flex;
  cursor: pointer;
  align-items: center;
  margin-bottom: ${({ mb }) => `${mb}px`};
`;

export const Input = styled.input`
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  position: absolute;

  &:checked + .checkbox {
    border-color: ${({ theme }) => theme.colors.blue[700]};
    background-color: ${({ theme }) => theme.colors.blue[700]};

    &::before {
      content: '';
      position: absolute;
      background: url(${checkedIcon});
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: 12px;
      height: 12px;
      top: 1px;
      left: 1px;
    }
  }

  &:checked + .checkbox:hover {
    border-color: ${({ theme }) => theme.colors.blue[800]};
    background-color: ${({ theme }) => theme.colors.blue[800]};
  }

  &:focus + .checkbox {
    box-shadow: 0 0 0 3px ${({ theme }) => theme.colors.blue[250]};
  }
`;

export const StyledCheckbox = styled.span`
  display: block;
  border: 2px solid ${({ theme }) => theme.colors.gray[900]};
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.white[400]};
  width: 18px;
  height: 18px;
  position: relative;
  transition: background-color 0.3s, border-color 0.3s, box-shadow 0.3s;
`;

export const Label = styled.span`
  margin-left: 6px;
  font-size: 12px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.gray[500]};
`;
