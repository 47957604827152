import styled from '@emotion/styled';

export const Box = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const SelectBox = styled.div`
  flex-grow: 1;
`;

export const ButtonBox = styled.div`
  margin-top: 1%;
`;
