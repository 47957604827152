import { FC, useEffect, useState, MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { css } from '@emotion/react';

import { STATUSES_ESTIMATION } from 'constants/common';
import Icon from 'components/Icon';
import Button from 'components/Button';
import {
  deleteEstimation,
  getEstimation,
  updateStatus,
  updateEstimation,
  copyEstimation,
} from 'services/estimation';
import { Estimation, Performer as PerformerType } from 'services/types/Iteration';
import { OldEstimationData, Person } from 'services/types/EstimationData';
import Modal from 'components/Modal';
import StatusesSelect from '../StatusesSelect';
import EstimationForm from '../EstimationForm';
import {
  ButtonRound,
  ButtonsContainer,
  EstimationContainer,
  IterationContainer,
  LeftContainer,
  EstimationRightContainer,
  EstimationTitle,
  Description,
  TitleContainer,
  VersionTitle,
} from '../Iterations.styled';

const stylesModal = css`
  width: 560px;
`;

const stylesButton = css`
  width: 195px;
  padding: 14px 40px;
`;

const statusesList = STATUSES_ESTIMATION.map((item) => item.toUpperCase().replaceAll(' ', '_'));

type Props = {
  estimation: Estimation;
  performers: PerformerType[];
  onUpdateData: () => void;
  onCopyData: () => void;
};

const EstimationItem: FC<Props> = ({ estimation, performers, onUpdateData, onCopyData }) => {
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isOpenCopyModal, setIsOpenCopyModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const { name, technologies, status, id, version, description } = estimation;
  const { control, errors, handleSubmit, setValue } = useForm({
    shouldUnregister: false,
  });

  useEffect(() => {
    setValue('name', name);
    setValue('version', version);
    setValue('status', status);
    setValue('description', description || '');
    setValue(
      `technologies`,
      technologies.map((item) => item.name),
    );
    getEstimation(id.toString()).then((data) => {
      const persons = data.persons.map((item) => ({
        changeableName: item.changeableName,
        name: item.position.name,
      }));

      setValue(`persons`, persons);
    });
  }, [setValue, name, version, status, technologies, description, id]);

  const onOpenEditModal = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsOpenEditModal(true);
  };

  const onOpenCopyModal = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsOpenCopyModal(true);
  };

  const onCloseEditModal = () => {
    setIsOpenEditModal(false);
  };

  const onCloseCopyModal = () => {
    setIsOpenCopyModal(false);
  };

  const onToggleDeleteModal = () => {
    setIsOpenDeleteModal((prevState) => !prevState);
  };

  const onDeleteEstimation = () => {
    deleteEstimation(id.toString()).then(() => {
      onUpdateData();
      onToggleDeleteModal();
    });
  };

  const onSubmit = (data: OldEstimationData) => {
    const namesPersons = new Set(data.persons.map((item) => item.changeableName));
    const filteredPersons = performers.filter((item) => namesPersons.has(item.name));
    const persons = data.persons.reduce((previousValue, currentItem) => {
      filteredPersons.forEach((filterItem) => {
        if (currentItem.changeableName === filterItem.name) {
          previousValue.push({
            ...currentItem,
            id: filterItem.id,
            personId: filterItem.id,
            position: {
              id: filterItem.position.id,
              name: filterItem.position.name,
            },
          });
        }
      });

      return previousValue;
    }, [] as Person[]);
    const dataToSend = {
      ...data,
      persons,
      id,
    };
    updateEstimation(dataToSend).then(() => {
      onUpdateData();
      onCloseEditModal();
    });
  };

  const onCopy = (data: OldEstimationData) => {
    copyEstimation(id.toString(), data).then(() => {
      onCopyData();
      onCloseCopyModal();
    });
  };

  const handleChangeEstimation = handleSubmit(onSubmit);

  const handleCopyEstimation = handleSubmit(onCopy);

  return (
    <>
      <EstimationContainer>
        <IterationContainer>
          <LeftContainer>
            <TitleContainer>
              <EstimationTitle>
                <Link to={`/main/estimation/table/${id}`}>{name}</Link>
              </EstimationTitle>
              <Description>{description}</Description>
            </TitleContainer>
            <Link to={`/main/estimation/${id}`}>
              <Button label="Go to tasks" styleButton={stylesButton} />
            </Link>
          </LeftContainer>
          <EstimationRightContainer>
            <VersionTitle>Version {version}</VersionTitle>
            <Controller
              name="status"
              control={control}
              defaultValue={status}
              render={({ onChange, value }) => {
                const onChangeStatus = (newValue: string) => {
                  onChange(newValue);
                  const data = {
                    id,
                    status: newValue,
                  };
                  updateStatus(data);
                };

                return (
                  <StatusesSelect value={value} items={statusesList} onChange={onChangeStatus} />
                );
              }}
            />
            <ButtonsContainer>
              <ButtonRound type="button" onClick={onOpenCopyModal}>
                <Icon name="gallery" />
              </ButtonRound>
              <ButtonRound type="button" onClick={onOpenEditModal}>
                <Icon name="pencil" />
              </ButtonRound>
              <ButtonRound type="button" onClick={onToggleDeleteModal}>
                <Icon name="delete" />
              </ButtonRound>
            </ButtonsContainer>
          </EstimationRightContainer>
        </IterationContainer>
      </EstimationContainer>
      {isOpenCopyModal && (
        <Modal
          title="Copy Estimation"
          textCancel="Cancel"
          textConfirm="Save"
          styleTypeConfirm="primary"
          styleModal={stylesModal}
          text={
            <EstimationForm
              control={control}
              item={estimation}
              id={id}
              errors={errors}
              persons={performers}
            />
          }
          isOpen={isOpenCopyModal}
          onCancel={onCloseCopyModal}
          onClose={onCloseCopyModal}
          onConfirm={handleCopyEstimation}
        />
      )}
      {isOpenEditModal && (
        <Modal
          title="Edit Estimation"
          textCancel="Cancel"
          textConfirm="Save"
          styleTypeConfirm="primary"
          styleModal={stylesModal}
          text={
            <EstimationForm
              control={control}
              item={estimation}
              id={id}
              errors={errors}
              persons={performers}
            />
          }
          isOpen={isOpenEditModal}
          onCancel={onCloseEditModal}
          onClose={onCloseEditModal}
          onConfirm={handleChangeEstimation}
        />
      )}
      {isOpenDeleteModal && (
        <Modal
          title="Delete Estimation"
          textCancel="Cancel"
          textConfirm="Delete"
          styleTypeConfirm="warning"
          text={`Are you sure want to delete "${name}"?`}
          isOpen={isOpenDeleteModal}
          onCancel={onToggleDeleteModal}
          onClose={onToggleDeleteModal}
          onConfirm={onDeleteEstimation}
        />
      )}
    </>
  );
};

export default EstimationItem;
