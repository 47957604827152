import { createContext } from 'react';
import { IExcelData } from './DirectionTable/types';
import { IExcelEstimationData } from '../../components/EstimationTable/types';

type TablesContextType = {
  tablesContext: {
    estimationTable?: IExcelEstimationData | null;
    directionTable?: IExcelData | null;
  };
  setTablesContext: React.Dispatch<
    React.SetStateAction<{
      estimationTable?: IExcelEstimationData | null;
      directionTable?: IExcelData | null;
    }>
  >;
};

const TablesContext = createContext<TablesContextType>({
  tablesContext: {
    estimationTable: null,
    directionTable: null,
  },
  setTablesContext: () => {},
});

export default TablesContext;
