import styled from '@emotion/styled';

export const MarginBox = styled.div`
  margin-bottom: 30px;
`;

export const ButtonBox = styled(MarginBox)`
  display: flex;
  justify-content: flex-end;
`;

export const TableBox = styled.div<{ label?: string }>`
  position: relative;

  &.active::after {
    content: '${({ label = 'Drop files here' }) => label}';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(196, 196, 196, 0.4);
    color: #c4c4c4;
    font-size: 64px;
    line-height: 24px;
    font-weight: 700;
    border: 3px dashed rgba(132, 142, 153, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
