import { FC } from 'react';

import { StubStyled, StubTitleStyled } from './EmptyStub.styled';

type Props = {
  title: string;
};

const EmptyStub: FC<Props> = ({ title }) => (
  <StubStyled>
    <StubTitleStyled>{title}</StubTitleStyled>
  </StubStyled>
);

export default EmptyStub;
