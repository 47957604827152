import { FC, MouseEvent } from 'react';
import PaginatorWrapper from './Paginator.styled';
import PaginatorItem from './PaginatorItem.styled';

export type Props = {
  totalRecords: number;
  pageNeighbors?: number;
  select?: number;
  onClick?: (page: number) => void;
};

const PREV_PAGE = '<';
const NEXT_PAGE = '>';

const getPageRange = (
  totalRecords: number,
  pageNeighbors: number,
  select: number,
): [number, number] => {
  const totalVisiblePages = Math.min(2 * pageNeighbors + 1, totalRecords);
  let left = Math.max(1, select - pageNeighbors);
  let right = Math.min(totalRecords, select + pageNeighbors);

  const realTotalPages = right - left + 1;

  if (realTotalPages < totalVisiblePages) {
    const delta = totalVisiblePages - realTotalPages;
    if (select < left + pageNeighbors) {
      right = Math.min(totalRecords, right + delta);
    } else {
      left = Math.min(totalRecords, left - delta);
    }
  }

  return [left, right];
};

const getValidSelect = (select: number, totalRecords: number) => {
  let realSelect = select;
  if (select < 1) {
    realSelect = 1;
  } else if (select > totalRecords) {
    realSelect = totalRecords;
  }

  return realSelect;
};

const Paginator: FC<Props> = ({ totalRecords, pageNeighbors, select = 1, onClick = () => {} }) => {
  const realSelect = getValidSelect(select, totalRecords);

  let left = 1;
  let right = totalRecords;

  if (pageNeighbors !== undefined) {
    [left, right] = getPageRange(totalRecords, pageNeighbors, realSelect);
  }

  const pages = [];

  const onClickHandler = (e: MouseEvent) => {
    const { textContent, tagName } = e.target as HTMLElement;

    if (!textContent || tagName?.toLowerCase() !== 'li') {
      return;
    }

    let newPageNumber = 0;
    if (textContent === PREV_PAGE) {
      newPageNumber = realSelect - 1;
    } else if (textContent === NEXT_PAGE) {
      newPageNumber = realSelect + 1;
    } else {
      newPageNumber = +textContent;
    }

    newPageNumber = getValidSelect(newPageNumber, totalRecords);

    if (newPageNumber === realSelect) {
      return;
    }

    onClick(newPageNumber);
  };

  for (let i = left; i <= right; i += 1) {
    pages.push(
      <PaginatorItem selected={i === realSelect} key={i}>
        {i}
      </PaginatorItem>,
    );
  }

  return (
    <PaginatorWrapper onClick={onClickHandler}>
      <PaginatorItem disabled={realSelect === 1 || !pages.length}>{PREV_PAGE}</PaginatorItem>
      {pages}
      <PaginatorItem disabled={realSelect === totalRecords || !pages.length}>
        {NEXT_PAGE}
      </PaginatorItem>
    </PaginatorWrapper>
  );
};

export default Paginator;
