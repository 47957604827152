/* eslint-disable @typescript-eslint/no-non-null-assertion */
import AppSection from 'pages/EstimationDetailPage/Gantt/models/AppSection';
import AppStory from '../../../../models/AppStory';
import { Sizes, WidthAndOffset } from '../../types';

export const getSubstoriesCountBefore = (storyIndex: number, stories: AppStory[]): number =>
  stories
    .filter((story, index) => index < storyIndex && story.expanded)
    .reduce((acc, story) => acc + story.substories.length, 0);

export const getStoriesCountBefore = (sectionIndex: number, sections: AppSection[]): number =>
  sections
    .filter((section, index) => index < sectionIndex && section.expanded)
    .reduce((acc, section) => acc + section.stories.length, 0);

export const getTotalStoryWidthFromSizes = (sizes: Sizes[]): WidthAndOffset => {
  try {
    const minX = Math.min(...sizes.map((item) => item.x));
    const maxX = Math.max(...sizes.map((item) => item.x + item.width));

    return {
      offsetX: minX,
      width: maxX - minX,
    };
  } catch (e) {
    return {
      offsetX: 0,
      width: 0,
    };
  }
};

export const getTotalStoryWidthFromStory = (story: AppStory, pxInHour: number): WidthAndOffset => {
  try {
    const offsetX = story.offsetHours * pxInHour;
    const width = story.hours * pxInHour;

    return {
      offsetX,
      width,
    };
  } catch (e) {
    return {
      offsetX: 0,
      width: 0,
    };
  }
};
