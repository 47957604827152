import { useCallback } from 'react';
import AppSection from 'pages/EstimationDetailPage/Gantt/models/AppSection';
import { updateIfIdMatches } from '../../../utils/algorithms';
import { SetModel } from '../types';

const useUpdateSectionById = (
  setModel: SetModel,
): ((sectionId: number, changes?: Partial<AppSection>) => void) =>
  useCallback(
    (sectionId: number, changes?: Partial<AppSection>) => {
      setModel((model) => ({
        ...model,
        sections: model.sections.map(
          updateIfIdMatches(sectionId, (sectionToUpdate) => ({
            ...sectionToUpdate,
            ...changes,
          })),
        ),
      }));
    },
    [setModel],
  );

export default useUpdateSectionById;
