import { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { css } from '@emotion/react';

import { STATUSES } from 'constants/common';
import Modal from 'components/Modal';
import {
  deleteIteration,
  getIterationEstimations,
  updateIteration,
  updateStatus,
} from 'services/iteration';
import Icon from 'components/Icon';
import { ProjectIteration } from 'services/types/Project';
import { Estimation, UpdatedIteration } from 'services/types/Iteration';
import StatusesSelect from '../StatusesSelect';
import IterationForm from '../IterationForm';
import EstimationsList from '../EstimationsList';
import AddEstimation from '../AddEstimation/AddEstimation';
import {
  ButtonRound,
  ButtonsContainer,
  Container,
  Description,
  EmployeesContainer,
  IterationContainer,
  TitleContainer,
  Performer,
  PerformersContainer,
  PerformerName,
  Position,
  RightContainer,
  ShowMore,
  Slash,
  Subtitle,
  Title,
} from '../Iterations.styled';

const stylesModal = css`
  width: 560px;
`;

type Props = {
  iteration: ProjectIteration;
  isUpdate: boolean;
  onUpdateData: () => void;
};

const statusesList = STATUSES.slice(1).map((item) => item.toUpperCase().replaceAll(' ', '_'));

const IterationItem: FC<Props> = ({ iteration, isUpdate, onUpdateData }) => {
  const { name, description, performers, status, id, version } = iteration;
  const [estimations, setEstimations] = useState<Estimation[]>();
  const [isOpenPerformersInfo, setIsOpenPerformersInfo] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const { control, errors, handleSubmit, setValue } = useForm({
    shouldUnregister: false,
  });

  useEffect(() => {
    setValue('name', name);
    setValue('description', description);
    setValue(
      'performers',
      performers.length > 0
        ? performers.map((performer) => ({
            name: performer.name,
            position: performer.position,
          }))
        : [
            {
              name: '',
              position: {
                name: '',
              },
            },
          ],
    );
    setValue('status', status);
    setValue('version', version);
  }, [setValue, name, description, performers, status, version]);

  useEffect(() => {
    if (isUpdate) {
      getIterationEstimations(iteration.id).then((data) => {
        setEstimations(data);
      });
    }
    getIterationEstimations(iteration.id).then((data) => {
      setEstimations(data);
    });
  }, [iteration.id, setValue, isUpdate]);

  const onTogglePerformers = () => {
    setIsOpenPerformersInfo((prevState) => !prevState);
  };

  const onToggleEditModal = () => {
    setIsOpenEditModal((prevState) => !prevState);
  };

  const onToggleDeleteModal = () => {
    setIsOpenDeleteModal((prevState) => !prevState);
  };

  const onDeleteIteration = () => {
    deleteIteration(id).then(() => onToggleDeleteModal());
  };

  const onSubmit = (data: UpdatedIteration) => {
    const dataToSend = {
      ...data,
      id,
    };
    updateIteration(dataToSend).then(() => {
      onUpdateData();
      onToggleEditModal();
    });
  };

  const handleChangeIteration = handleSubmit(onSubmit);

  return (
    <>
      <form onSubmit={handleChangeIteration}>
        <Container>
          <IterationContainer>
            <TitleContainer>
              <Title>{name}</Title>
              <Description>{description}</Description>
            </TitleContainer>
            <RightContainer>
              <PerformersContainer>
                <Subtitle>Performers</Subtitle>
                <Performer>
                  {performers.map((performer, index) => (
                    <EmployeesContainer
                      key={performer.id}
                      hide={index > 1 && !isOpenPerformersInfo}
                    >
                      <PerformerName>{performer.name}</PerformerName>
                      <Slash>/</Slash>
                      <Position>{performer.position.name}</Position>
                    </EmployeesContainer>
                  ))}
                </Performer>
                {performers.length > 2 && (
                  <ShowMore onClick={onTogglePerformers}>
                    show {isOpenPerformersInfo ? 'less' : 'more'}
                  </ShowMore>
                )}
              </PerformersContainer>
              <Controller
                name="status"
                control={control}
                defaultValue={status}
                render={({ onChange, value }) => {
                  const onChangeStatus = (newValue: string) => {
                    onChange(newValue);
                    const data = {
                      id,
                      status: newValue,
                    };
                    updateStatus(data);
                  };

                  return (
                    <StatusesSelect value={value} items={statusesList} onChange={onChangeStatus} />
                  );
                }}
              />
              <ButtonsContainer>
                <ButtonRound type="button" onClick={onToggleEditModal}>
                  <Icon name="pencil" />
                </ButtonRound>
                <ButtonRound type="button" onClick={onToggleDeleteModal}>
                  <Icon name="delete" />
                </ButtonRound>
              </ButtonsContainer>
            </RightContainer>
          </IterationContainer>
        </Container>
      </form>
      <EstimationsList
        estimations={estimations}
        performers={performers}
        onUpdateData={onUpdateData}
      />
      <AddEstimation persons={performers} id={id} onUpdateData={onUpdateData} />
      {isOpenEditModal && (
        <Modal
          title="Edit Iteration"
          textCancel="Cancel"
          textConfirm="Save"
          styleTypeConfirm="primary"
          styleModal={stylesModal}
          text={
            <IterationForm
              control={control}
              errors={errors}
              item={iteration}
              onSubmit={handleChangeIteration}
              setValue={setValue}
            />
          }
          isOpen={isOpenEditModal}
          onCancel={onToggleEditModal}
          onClose={onToggleEditModal}
          onConfirm={handleChangeIteration}
        />
      )}
      {isOpenDeleteModal && (
        <Modal
          title="Delete Iteration"
          textCancel="Cancel"
          textConfirm="Delete"
          styleTypeConfirm="warning"
          text={`Are you sure want to delete "${name}"?`}
          isOpen={isOpenDeleteModal}
          onCancel={onToggleDeleteModal}
          onClose={onToggleDeleteModal}
          onConfirm={onDeleteIteration}
        />
      )}
    </>
  );
};

export default IterationItem;
