import { FC } from 'react';

import IconContainer from './Icon.styled';
import { ReactComponent as Plus } from './svg/plus.svg';
import { ReactComponent as Filter } from './svg/filter.svg';
import { ReactComponent as AngleUp } from './svg/angleUp.svg';
import { ReactComponent as AngleDown } from './svg/angleDown.svg';
import { ReactComponent as Warning } from './svg/warning.svg';
import { ReactComponent as Chat } from './svg/chat.svg';
import { ReactComponent as Delete } from './svg/delete.svg';
import { ReactComponent as ArrowLeft } from './svg/arrowLeft.svg';
import { ReactComponent as Cross } from './svg/cross.svg';
import { ReactComponent as Question } from './svg/question.svg';
import { ReactComponent as Picture } from './svg/picture.svg';
import { ReactComponent as Email } from './svg/email.svg';
import { ReactComponent as Pencil } from './svg/pencil.svg';
import { ReactComponent as Facebook } from './svg/facebook.svg';
import { ReactComponent as Instagram } from './svg/instagram.svg';
import { ReactComponent as Linkedin } from './svg/linkedin.svg';
import { ReactComponent as Phone } from './svg/phone.svg';
import { ReactComponent as Zoom } from './svg/zoom.svg';
import { ReactComponent as Skype } from './svg/skype.svg';
import { ReactComponent as Add } from './svg/add.svg';
import { ReactComponent as Gallery } from './svg/gallery.svg';
import { ReactComponent as FilledWaring } from './svg/filledWarning.svg';
import { ReactComponent as Search } from './svg/search.svg';
import { ReactComponent as Share } from './svg/share.svg';
import { ReactComponent as ArrowDown } from './svg/arrowDown.svg';
import { ReactComponent as Doc } from './svg/doc.svg';
import { ReactComponent as Docx } from './svg/docx.svg';
import { ReactComponent as Jpg } from './svg/jpg.svg';
import { ReactComponent as Pdf } from './svg/pdf.svg';
import { ReactComponent as Png } from './svg/png.svg';
import { ReactComponent as Pptx } from './svg/pptx.svg';
import { ReactComponent as Xls } from './svg/xls.svg';
import { ReactComponent as Xlsx } from './svg/xlsx.svg';
import { ReactComponent as Unknown } from './svg/unknown.svg';

interface Icons {
  plus: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  filter: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  angleUp: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  angleDown: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  warning: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  chat: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  delete: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  arrowLeft: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  cross: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  question: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  picture: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  email: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  pencil: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  facebook: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  instagram: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  linkedin: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  phone: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  zoom: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  skype: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  add: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  gallery: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  filledWarning: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  search: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  share: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  arrowDown: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  doc: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  docx: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  jpg: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  pdf: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  png: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  pptx: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  xls: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  xlsx: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  unknown: React.ComponentType<React.SVGProps<SVGSVGElement>>;
}

export const nameToComponentMap: Icons = {
  plus: Plus,
  filter: Filter,
  angleUp: AngleUp,
  angleDown: AngleDown,
  warning: Warning,
  chat: Chat,
  delete: Delete,
  arrowLeft: ArrowLeft,
  cross: Cross,
  question: Question,
  picture: Picture,
  email: Email,
  pencil: Pencil,
  facebook: Facebook,
  instagram: Instagram,
  linkedin: Linkedin,
  phone: Phone,
  zoom: Zoom,
  skype: Skype,
  add: Add,
  gallery: Gallery,
  filledWarning: FilledWaring,
  search: Search,
  share: Share,
  arrowDown: ArrowDown,
  doc: Doc,
  docx: Docx,
  jpg: Jpg,
  pdf: Pdf,
  png: Png,
  pptx: Pptx,
  xls: Xls,
  xlsx: Xlsx,
  unknown: Unknown,
};

export type IconName = keyof Icons;

type Props = {
  name: IconName;
  size?: number;
  color?: string;
  className?: string;
  onClick?: (event: React.MouseEvent) => void;
};

const Icon: FC<Props> = ({ name, size = 14, color, className, ...rest }) => {
  const Component = nameToComponentMap[name];

  return (
    <IconContainer className={className} color={color} size={size} {...rest}>
      <Component />
    </IconContainer>
  );
};

export default Icon;
