import { ReactNode, ReactChild, forwardRef } from 'react';
import { Box, Before, After, Content } from './MenuItem.styled';

export type Props = {
  children: ReactNode;
  after?: ReactChild;
  before?: ReactChild;
  onClick?: (payload?: string) => void;
  payload?: string;
};

const MenuItem = forwardRef<HTMLLIElement, Props>(
  ({ before, children, after, onClick, payload }, boxRef) => {
    const clickHandle = () => {
      onClick?.(payload);
    };

    return (
      <Box onClick={clickHandle} ref={boxRef}>
        {before && <Before>{before}</Before>}
        <Content>{children}</Content>
        {after && <After>{after}</After>}
      </Box>
    );
  },
);

export default MenuItem;
