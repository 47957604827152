import { FC } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { getCookie, isTokenExpired } from 'utils/auth';

type Props = {
  path: string;
};

const ProtectedRoute: FC<Props> = ({ path, ...rest }) => {
  const token = getCookie('jwtToken');
  const isExpired = isTokenExpired();

  if (token && !isExpired) {
    return <Route path={path} {...rest} exact />;
  }

  return <Redirect to="/" />;
};

export default ProtectedRoute;
