import styled from '@emotion/styled';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
  box-sizing: border-box;

  & > * {
    margin: 8px;
    display: flex;
  }
`;

export default Container;
