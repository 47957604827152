import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

type AnimationType = {
  name: string;
  styles: string;
  anim: number;
  toString: () => string;
};

export const close = (): AnimationType & string => keyframes`
  from {
    transform: translate(0, 0);

  }
  to {
    transform: translate(320px, 0);
  }
`;

export const open = (): AnimationType & string => keyframes`
  from {
    transform: translate(320px, 0);
  }
  to {
    transform: translate(0, 0);
  }
`;

export const TaskDetailContainer = styled.div<{ isOpen?: boolean }>`
  box-sizing: border-box;
  width: 305px;
  height: 100%;
  margin-top: 72px;
  padding: 22px;
  background-color: #faf9fa;
  border: 1px solid ${({ theme }) => theme.colors.gray[500]};
  box-shadow: -5px 0 15px ${({ theme }) => theme.colors.blue[50]};
  position: fixed;
  right: 0;
  top: 0;
  animation: ${({ isOpen }) => (isOpen ? open() : close())} 0.5s;
`;
export const ItemTitle = styled.p`
  font-size: 18px;
  font-weight: 500;
  margin: 10px 0 15px;
  min-height: 50px;
`;

export const CloseButton = styled.button`
  color: ${({ theme }) => theme.colors.gray[900]};
  width: 32px;
  height: 32px;
  padding: 8px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.gray[200]};
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[250]};
  }
`;

export const CloseIcon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  &:before,
  &:after {
    position: absolute;
    width: 20px;
    height: 2px;
    content: '';
    background-color: ${({ theme }) => theme.colors.gray[900]};
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const StatusTitle = styled.span`
  color: ${({ theme }) => theme.colors.gray[950]};
  font-size: 16px;
`;

export const Subtitle = styled.span`
  display: inline-block;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.gray[900]};
  min-width: 40%;
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0 10px;
`;

export const EstimateContainer = styled.div`
  margin: 15px 0 10px;
`;
