import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const UseActiveTab = (tab: string | number): (() => void) => {
  const { pathname } = useLocation();
  const history = useHistory();

  useEffect(() => {
    const search = new URLSearchParams();
    search.set('activeTab', encodeURIComponent(tab));
    history.replace(`${pathname}?${search}`);
  }, [history, pathname, tab]);

  return () => {};
};

export default UseActiveTab;
