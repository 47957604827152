import { sumBy } from 'lodash';
import { SubTaskType } from '../types';

const sumHoursSubtasks = (substories: SubTaskType[]): JSX.Element => {
  const sumValue = sumBy(substories, function sumLodash(o) {
    return o.hours;
  });

  return <span>{sumValue ? <>{sumValue} h</> : null}</span>;
};

export default sumHoursSubtasks;
