import { FC, useState, useEffect, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import Input from 'components/Input';
import Checkbox from 'components/Checkbox';
import logo from 'assets/images/Logo.svg';
import login from 'services/auth';
import { setCookie } from 'utils/auth';
import { VALIDATION_MESSAGES } from 'constants/common';
import {
  Container,
  Form,
  IconContainer,
  Row,
  TooltipContainer,
  TooltipLabel,
  Tooltip,
  Button,
  SubmitError,
} from './Login.styled';

type FormValues = {
  username: string;
  password: string;
  remember: boolean;
};

const LoginPage: FC = () => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const { control, errors, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      username: '',
      password: '',
      remember: false,
    },
  });

  const onSubmit = handleSubmit((data: FormValues) => {
    setIsSubmitting(true);
    setSubmitError(false);
    login(data)
      .then(({ token, expires }) => {
        setCookie('jwtToken', token);
        setCookie('jwtTokenExpDate', expires);
        history.push('/main/project-list');
      })
      .catch(() => {
        setSubmitError(true);
      })
      .finally(() => setIsSubmitting(false));
  });

  const handleToggleTooltip = () => {
    setShow((prevState) => !prevState);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const target = event.target as HTMLElement;
      if (ref.current && !ref.current.contains(target)) {
        setShow(false);
      }
    }

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <Container>
      <Form onSubmit={onSubmit}>
        <IconContainer>
          <img src={logo} alt="logo" />
        </IconContainer>
        {submitError && (
          <SubmitError>
            <span>Incorrect login or password</span>
          </SubmitError>
        )}
        <Controller
          name="username"
          control={control}
          rules={{ required: { value: true, message: VALIDATION_MESSAGES.required } }}
          render={({ name, value, onChange, onBlur }, { invalid }) => (
            <Input
              name={name}
              mb={16}
              label="Login"
              placeholder="Enter login"
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              isError={invalid}
              errorMessage={errors.username?.message}
              isRequired
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          rules={{
            required: { value: true, message: VALIDATION_MESSAGES.required },
            minLength: {
              value: '6',
              message: 'VALIDATION_FIELD_MIN_LENGTH',
            },
          }}
          render={({ name, value, onChange, onBlur }, { invalid }) => (
            <Input
              name={name}
              mb={16}
              type="password"
              label="Password"
              placeholder="Enter password"
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              isError={invalid}
              helpText="6 characters at least"
              errorMessage={errors.password?.message}
              isRequired
            />
          )}
        />
        <Row>
          <Controller
            name="remember"
            control={control}
            render={({ name, value, onChange }) => (
              <Checkbox name={name} label="Remember me" value={value} onChange={onChange} />
            )}
          />
          <TooltipContainer ref={ref}>
            <TooltipLabel onClick={handleToggleTooltip}>Forgot password?</TooltipLabel>
            <CSSTransition in={show} timeout={300} classNames="tooltip" unmountOnExit>
              <Tooltip>
                Please note that your password is common with all
                <br /> other corporate SENLA systems (confluence/JIRA/git etc.)
                <br /> To reset your password please contact with system administrator.
              </Tooltip>
            </CSSTransition>
          </TooltipContainer>
        </Row>
        <Button type="submit" disabled={isSubmitting}>
          Login
        </Button>
      </Form>
    </Container>
  );
};
export default LoginPage;
