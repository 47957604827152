import { FC, useState, useMemo, useEffect, MouseEvent } from 'react';
import { useForm } from 'react-hook-form';
import upperFirst from 'lodash/upperFirst';
import { nanoid } from 'nanoid';
import { css, useTheme } from '@emotion/react';
import { Link } from 'react-router-dom';

import Icon, { IconName, nameToComponentMap } from 'components/Icon';
import EmployeeForm from 'components/EmployeeForm';
import { Data, ProjectType } from 'components/EmployeeForm/types';
import Modal from 'components/Modal';
import { updateEmployee } from 'services/employee';
import {
  ButtonProject,
  ButtonRound,
  ContactBox,
  ContactsContainer,
  Email,
  EmailContainer,
  EmployeeWrap,
  IconsContainer,
  LeftContainer,
  MainContainer,
  Name,
  Position,
  ProjectBox,
  ProjectsContainer,
  ProjectsTitle,
  RightContainer,
  SecondContainer,
  Slash,
  TextBold,
  ExpandIconContainer,
} from '../EmployeesList/EmployeeList.styled';

const stylesModal = css`
  width: 560px;
`;

export type EmployeeData = Data & {
  id: number;
  canEdit: boolean;
};

type Props = {
  item: EmployeeData;
  projectList?: ProjectType[];
  isLast: boolean;
  onDeleteEmployee: (value: number) => void;
  toUpdateData: () => void;
};

const EmployeeItem: FC<Props> = ({ item, projectList, isLast, onDeleteEmployee, toUpdateData }) => {
  const { name, email, position, contacts, projects } = item;
  const { colors } = useTheme();
  const [isOpenInfo, setIsOpenInfo] = useState(false);
  const [isModalForm, setIsModalForm] = useState(false);
  const [isServerError, setIsServerError] = useState(false);
  const { control, errors, handleSubmit, setValue } = useForm({
    mode: 'onBlur',
    defaultValues: item,
  });

  useEffect(() => {
    setValue('name', item.name);
    setValue('email', item.email);
    setValue('position', item.position);
    setValue('contacts', item.contacts);
    setValue('projects', item.projects);
  });

  const handleDeleteEmployee = () => {
    onDeleteEmployee(item.id);
  };

  const contactList = useMemo(
    () =>
      contacts.map((contact) => ({
        id: nanoid(),
        type: contact.type,
        label: upperFirst(contact.type),
        value: contact.value,
      })),
    [contacts],
  );

  const onToggleInfo = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setIsOpenInfo((prevState) => !prevState);
  };

  const onToggleModal = () => {
    setIsModalForm((prevState) => !prevState);
  };

  const onSubmit = (data: Data) => {
    const dataToSend = {
      ...data,
      id: item.id,
      projects: data.projects
        .filter((project) => project.id !== 0 && project.name !== '')
        .map((p) => p.id),
    };

    updateEmployee(dataToSend)
      .then(() => toUpdateData())
      .then(() => onToggleModal())
      .catch(() => setIsServerError((prevState) => !prevState));
  };

  const handleChangeEmployee = handleSubmit(onSubmit);

  return (
    <>
      <EmployeeWrap isLast={isLast}>
        <MainContainer onClick={onToggleInfo}>
          <LeftContainer>
            <Name>{name}</Name>
            <Position>
              <Slash>/</Slash>
              <span>{position}</span>
            </Position>
          </LeftContainer>
          <RightContainer>
            <EmailContainer>
              <Icon name="email" />
              <Email>{email}</Email>
            </EmailContainer>
            <IconsContainer>
              <ButtonRound onClick={onToggleModal}>
                <Icon name="pencil" color={colors.blue[300]} />
              </ButtonRound>
              <ButtonRound onClick={handleDeleteEmployee}>
                <Icon name="delete" color={colors.blue[300]} />
              </ButtonRound>
              <ExpandIconContainer onClick={onToggleInfo}>
                <Icon name={isOpenInfo ? 'angleUp' : 'angleDown'} />
              </ExpandIconContainer>
            </IconsContainer>
          </RightContainer>
        </MainContainer>
        {isOpenInfo && (
          <SecondContainer>
            <LeftContainer>
              {contactList.map((contact) => (
                <ContactBox key={contact.id}>
                  <ContactsContainer>
                    <EmailContainer>
                      {Object.keys(nameToComponentMap).includes(contact.type) && (
                        <Icon name={contact.type as IconName} size={16} />
                      )}
                      <TextBold>{contact.label}</TextBold>
                    </EmailContainer>
                    <Email>{contact.value}</Email>
                  </ContactsContainer>
                </ContactBox>
              ))}
            </LeftContainer>
            <RightContainer>
              {projects[0].name !== '' && (
                <ProjectsContainer>
                  <ProjectsTitle>Employee projects</ProjectsTitle>
                  <ProjectBox>
                    {projects.map((project) => (
                      <ButtonProject key={project.id} type="button">
                        <Link to={`/main/project-list/detail/${project.id}`}>{project.name}</Link>
                      </ButtonProject>
                    ))}
                  </ProjectBox>
                </ProjectsContainer>
              )}
            </RightContainer>
          </SecondContainer>
        )}
      </EmployeeWrap>
      {isModalForm && (
        <Modal
          title="Edit Employee"
          textCancel="Cancel"
          textConfirm="Save"
          styleTypeConfirm="primary"
          styleModal={stylesModal}
          text={
            isServerError ? (
              'Something go wrong'
            ) : (
              <EmployeeForm
                control={control}
                errors={errors}
                item={item}
                projectList={projectList}
                onSubmit={handleChangeEmployee}
              />
            )
          }
          isOpen={isModalForm}
          onCancel={onToggleModal}
          onClose={onToggleModal}
          onConfirm={handleChangeEmployee}
        />
      )}
    </>
  );
};
export default EmployeeItem;
