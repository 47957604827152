import { Theme } from '@emotion/react';

const theme: Theme = {
  colors: {
    red: {
      100: '#eed7d7',
      150: '#f9cccc',
      200: '#f9cdcd',
      250: '#ed6868',
      300: '#d74d4d',
      400: '#eb5757',
      900: '#ff1744',
    },
    yellow: {
      200: '#fbefc9',
      400: '#f2c94c',
    },
    green: {
      200: '#bcdfcb',
      400: '#219653',
    },
    blue: {
      50: '#d2e5fa',
      100: '#aed0f7',
      200: '#ceeafe',
      250: '#8dcdff',
      300: '#1d7de8', // main
      350: '#3991f3',
      400: '#5bbafb',
      600: '#0044c6', // dark
      700: '#007ad9',
      800: '#005b9f',
    },
    gray: {
      50: '#eeeeef',
      100: '#cae7fa',
      200: '#d5dee9',
      250: '#e8e9ea',
      300: '#f4f4f5',
      350: '#dde1e6',
      400: '#eaeaea',
      450: '#e3e3e3',
      500: '#c6c7cb',
      550: '#565e65',
      600: '#848E99',
      700: '#e6eaf1',
      750: '#c8c8c8',
      800: '#b9bdc4',
      850: '#bdbdbd',
      900: '#8d9097',
      950: '#3b4256',
    },
    black: {
      100: '#333',
      400: '#1e2832',
      500: '#000',
      600: '#1f2041',
    },
    white: {
      300: '#fcfcfc',
      400: '#fff',
      500: '#fafafa',
      600: '#fdfcfd',
      700: '#faf9fa',
    },
    orange: {
      400: '#ffa500',
    },
  },
  fontSizes: {
    standard: '14px',
    small: '12px',
  },
};

export default theme;
