import { FC } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { Employee as EmployeeType } from 'services/types/Client';
import EmployeeItem from './EmployeeItem';

const Employees: FC = () => {
  const { control, setValue } = useFormContext();
  const { fields, remove } = useFieldArray({
    control,
    name: 'employees',
  });

  const handleDelete = (index: number) => {
    remove(index);
  };

  const handleAppend = (employees: EmployeeType[]) => {
    setValue('employees', employees);
  };

  return (
    <>
      {fields.map(({ id, name, email, position }, index) => {
        const isFirst = index > 0;
        const isLast = index === fields.length - 1 || (index === 0 && !fields[fields.length - 1]);

        return (
          <EmployeeItem
            key={id}
            index={index}
            onRemove={handleDelete}
            onAppend={handleAppend}
            name={name}
            email={email}
            position={position}
            isAdd={isLast}
            isRemove={isFirst}
          />
        );
      })}
    </>
  );
};

export default Employees;
