import { BoardType, DetailsType, SubTaskType, TaskType } from 'components/KanbanBoard/types';
import { TaskStatusType } from 'services/types/Dictionary';
import { StoriesType } from 'services/types/tasks';

const createDataKanban = (data: StoriesType, statusList: TaskStatusType[]): BoardType[] => {
  const getAllSubtaskStatusies = (subTasks: SubTaskType[]) =>
    subTasks.map((subTask) => subTask.taskStatus?.title);

  return statusList.map((status, id) => ({
    status,
    sectionType: data.sectionType.title,
    id: String(id),
    tasks: data.stories
      .map((task) => ({
        ...task,
        substories: task.substories.filter((subTask) => subTask.taskStatus?.title === status.name),
        allStatusSubtask: task.substories.length
          ? getAllSubtaskStatusies(task.substories)
          : task.taskStatus?.title || 'ToDo',
        taskStatus: task.taskStatus
          ? task.taskStatus
          : { id: statusList[0].id, title: statusList[0].name },
      }))
      .filter((task) => task.allStatusSubtask.includes(status.name)),
  }));
};
export const showDetails = (
  value: string,
  id: number,
  details: DetailsType,
  taskType: TaskType | SubTaskType,
  setDetails: React.Dispatch<React.SetStateAction<DetailsType>>,
  subTaskName?: string | null,
): void => {
  const cur = id.toString();
  // Сценарий 1: 'Открытие окна'

  if (!details.showDetails) {
    setDetails({
      showDetails: true,
      idItem: cur,
      prev: +cur,
      [value]: taskType,
      additionalInfo: subTaskName,
    });
  }
  // Сценарий 2: 'Закрытие окна'

  if (details.showDetails && details.prev === +cur) {
    setDetails({ showDetails: false, idItem: cur, prev: 0, [value]: taskType });
  }
  // Сценарий 3: 'Смена информации'
  if (details.showDetails && details.prev !== +cur) {
    setDetails({
      showDetails: true,
      idItem: cur,
      prev: +cur,
      [value]: taskType,
      additionalInfo: subTaskName,
    });
  }
};

export default createDataKanban;
