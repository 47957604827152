import { FC } from 'react';
import { ArrayField, Control, Controller, DeepMap, FieldError } from 'react-hook-form';
import { useTheme } from '@emotion/react';

import useLoadEmployees from 'utils/hooks/useLoadEmployees';
import useLoadEmployeePositions from 'utils/hooks/useLoadEmployeePositions';
import { Performer as PerformerType } from 'services/types/Iteration';
import Select, { ListItem } from 'components/Select/v2';
import Icon from 'components/Icon';
import { VALIDATION_MESSAGES } from 'constants/common';
import { DataToPost } from '../../types';
import { ButtonRound, InputContainer, PerformerContainer } from './IterationForm.styled';
import InputWithPrompt from './InputWithPrompt';

type Props = {
  control: Control;
  errors: DeepMap<DataToPost, FieldError>;
  index: number;
  item: Partial<ArrayField<PerformerType, 'uuid'>>;
  fields: Partial<ArrayField<PerformerType, 'uuid'>>[];
  onAddItem: (value: number) => void;
  onDeleteItem: (value: number) => void;
  setValue: (
    name: string,
    value: string,
    config?: Partial<{ shouldValidate: boolean; shouldDirty: boolean }> | undefined,
  ) => void;
};

const PerformerItem: FC<Props> = ({
  control,
  errors,
  item,
  fields,
  index,
  onAddItem,
  onDeleteItem,
  setValue,
}) => {
  const { colors } = useTheme();
  const positions = useLoadEmployeePositions();
  const employeesList: ListItem[] =
    useLoadEmployees()?.map(({ name }) => ({
      value: name,
      label: name,
    })) || [];

  const formattedPositions =
    positions?.map((tech) => ({
      label: tech.name,
      value: tech.id,
    })) || [];

  const handleAddItem = () => {
    onAddItem(index);
  };

  const handleRemoveItem = () => {
    onDeleteItem(index);
  };

  return (
    <InputContainer>
      <PerformerContainer>
        <Controller
          name={`performers[${index}].name`}
          defaultValue={item.name || ''}
          rules={{ required: { value: true, message: VALIDATION_MESSAGES.required } }}
          control={control}
          render={({ value, onChange }, { invalid }) => (
            <Select
              value={value}
              placeholder="Choose performer"
              itemList={employeesList}
              width={170}
              isError={invalid}
              errorMessage={errors.performers?.[index]?.name?.message}
              onChange={onChange}
              verticalLabel
              isRequired
              withSearch
            />
          )}
        />
        <Controller
          name={`performers[${index}].position.name`}
          control={control}
          defaultValue={item.position?.name || ''}
          rules={{ required: { value: true, message: VALIDATION_MESSAGES.required } }}
          render={({ onChange, value }, { invalid }) => (
            <InputWithPrompt
              placeholder="position"
              mb={5}
              list={formattedPositions}
              value={value}
              isError={invalid}
              errorMessage={errors.performers?.[index]?.position?.name?.message}
              onChange={onChange}
              setValue={setValue}
              index={index}
              isRequired
            />
          )}
        />
        <ButtonRound type="button" onClick={handleRemoveItem} empty={fields.length === 1}>
          <Icon name="delete" color={colors.blue[300]} />
        </ButtonRound>
        <ButtonRound type="button" onClick={handleAddItem}>
          <Icon name="plus" color={colors.blue[300]} />
        </ButtonRound>
      </PerformerContainer>
    </InputContainer>
  );
};

export default PerformerItem;
