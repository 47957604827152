import { FC } from 'react';
import { useFormContext, Controller } from 'react-hook-form';

import Input from 'components/Input';
import ChipsSelect from 'components/ChipsSelect';
import useLoadTechnologies from 'utils/hooks/useLoadTechnologies';
import { VALIDATION_MESSAGES } from 'constants/common';
import CardContainer from '../CardContainer';

const IterationDescription: FC = () => {
  const { control, errors } = useFormContext();
  const technologies = useLoadTechnologies();

  const formattedTechnologies =
    technologies?.map((item) => ({
      label: item.name,
      value: item.id,
    })) || [];

  return (
    <CardContainer title="First iteration description">
      <Controller
        control={control}
        name="iteration.name"
        rules={{ required: { value: true, message: VALIDATION_MESSAGES.required } }}
        render={({ value, onChange }, { invalid }) => (
          <Input
            value={value}
            isError={invalid}
            label="Project iteration name"
            placeholder="Iteration name"
            isRequired
            errorMessage={errors.iteration?.name?.message}
            mb={16}
            onChange={onChange}
          />
        )}
      />
      <Controller
        name="iteration.technologies"
        control={control}
        rules={{
          validate: {
            required: (value) => value.length > 0 || VALIDATION_MESSAGES.required,
          },
        }}
        render={({ value, onChange }, { invalid }) => (
          <ChipsSelect
            label="Technologies"
            placeholder="Technology"
            value={value}
            isRequired
            mb={16}
            isError={invalid}
            list={formattedTechnologies}
            errorMessage={errors.iteration?.technologies?.message}
            onChange={onChange}
          />
        )}
      />
    </CardContainer>
  );
};

export default IterationDescription;
