import React, { FC } from 'react';
import styled from '@emotion/styled';
import { loadEventAnimationDefaultCss } from '../Animations/loadEvent.animation';

export const StyledContextMenu = styled.div`
  position: fixed;

  min-width: 150px;
  max-width: 175px;
  border-radius: 4px;

  background-color: #ffffff;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.05), 0 10px 10px rgba(0, 0, 0, 0.02);

  ${loadEventAnimationDefaultCss};

  overflow: hidden;
`;

export interface ContextMenuProps {
  x: number;
  y: number;
}

export const ContextMenu: FC<ContextMenuProps> = ({ x, y, children }) => (
  <StyledContextMenu style={{ top: y, left: x }}>{children}</StyledContextMenu>
);

export const StyledContextMenuItem = styled.button`
  height: 28px;
  border: none;
  text-align: left;

  color: #212121;

  background-color: white;

  :hover {
    background-color: #eeeeee;
  }
`;
