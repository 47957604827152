import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: max-content;
  padding: 10px 15px;
  background-color: ${({ theme }) => theme.colors.white[500]};
`;

export const FirstBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0 16px;
`;

export const DateBox = styled.div`
  min-height: 67px;
  margin-bottom: 16px;
`;

export const PerformerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 14px;
`;

export const ButtonRound = styled.button<{ empty?: boolean }>`
  color: ${({ theme }) => theme.colors.gray[900]};
  width: 32px;
  height: 32px;
  padding: 8px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.gray[200]};
  background-color: transparent;
  cursor: pointer;
  display: flex;
  visibility: ${({ empty }) => (empty ? 'hidden' : 'visible')};

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[250]};
  }
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.gray[550]};
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 4px;
`;
