import styled from '@emotion/styled';
import { FlexRowNowrapSpaceBetweenCenter } from 'pages/EstimationDetailPage/Gantt/components/Flex/index';

export const StyledTopToolbar = styled(FlexRowNowrapSpaceBetweenCenter)`
  height: 40px;
  padding: 0 40px;
  box-sizing: border-box;
  width: 100%;
`;

export const ToolbarTitle = styled.h1``;
