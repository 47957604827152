import styled from '@emotion/styled';

type TabNameProps = {
  active: boolean;
};

const TabNameStyled = styled.button<TabNameProps>`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  padding: 8px 14px;
  box-sizing: border-box;
  display: block;
  color: ${({ active, theme }) => (active ? theme.colors.blue[300] : theme.colors.gray[600])};
  border: none;
  background-color: transparent;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: ${({ active, theme }) => (active ? theme.colors.blue[300] : 'transparent')};

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.blue[300]};
    cursor: pointer;
  }
`;

export default TabNameStyled;
