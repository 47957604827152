import { FC, useState } from 'react';
import Icon from 'components/Icon';
import { StoriesType } from 'services/types/tasks';
import KanbanBoard from 'components/KanbanBoard';
import { TaskStatusType } from 'services/types/Dictionary';
import { KanbanContainer, StoryName, StoryDescr, TaskTitle } from './styled/KanbanBoard.styled';
import createDataKanban from './utils';

type Props = {
  data: StoriesType;
  statusList: TaskStatusType[];
  index: number;
};

const Story: FC<Props> = ({ data, index, statusList }) => {
  const [showBoards, setShowBoard] = useState(false);

  const onShow = () => {
    setShowBoard(!showBoards);
  };

  const dataKanbanBoard = createDataKanban(data, statusList);

  return (
    <>
      <KanbanContainer isLast={data.stories.length === index}>
        <TaskTitle isActive={showBoards} onClick={onShow}>
          {showBoards ? <Icon name="angleDown" /> : <Icon name="angleUp" />}
          <StoryName>{data.title}</StoryName>
          <StoryDescr>{data.stories.length} hours</StoryDescr>
        </TaskTitle>
        <KanbanBoard data={dataKanbanBoard} showBoards={showBoards} />
      </KanbanContainer>
    </>
  );
};

export default Story;
