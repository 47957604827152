import { useMemo } from 'react';
import { AppScaleEnum } from 'pages/EstimationDetailPage/Gantt/models/AppScaleEnum';
import { MONTH_COLUMN_SIZE, TIMELINE_COLUMN_SIZE, WEEK_COLUMN_SIZE } from '../consts';

const hoursToPX = (scale: AppScaleEnum): number => {
  switch (scale) {
    case AppScaleEnum.Days: {
      return TIMELINE_COLUMN_SIZE / 8;
    }
    case AppScaleEnum.Weeks: {
      return WEEK_COLUMN_SIZE / (8 * 5);
    }
    case AppScaleEnum.Months: {
      return MONTH_COLUMN_SIZE / (8 * 21);
    }
    default:
      return TIMELINE_COLUMN_SIZE / 8;
  }
};

const useHourPixels = (scaleKey: AppScaleEnum): number =>
  useMemo(() => hoursToPX(scaleKey), [scaleKey]);

export default useHourPixels;
