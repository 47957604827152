import { FC, useEffect } from 'react';

import Menu from './Menu';
import { MenuContainer } from './ContextMenu.styled';
import { useActions, useContextMenuRow } from '../context';

type Props = {
  xPos: string;
  yPos: string;
  showMenu: boolean;
};

const ContextMenu: FC<Props> = ({ yPos, xPos, showMenu }) => {
  const row = useContextMenuRow();
  const { setContextMenuRow } = useActions();

  useEffect(
    () => () => {
      setContextMenuRow(null);
    },
    [setContextMenuRow],
  );

  return showMenu && row ? (
    <MenuContainer xPos={xPos} yPos={yPos}>
      <Menu data={row} />
    </MenuContainer>
  ) : null;
};

export default ContextMenu;
