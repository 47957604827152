import { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { css, useTheme } from '@emotion/react';
import { useHistory, useParams } from 'react-router-dom';
import capitalize from 'lodash/capitalize';

import Button from 'components/Button';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import { getFile, postFile } from 'services/file';
import { getDetails, updateProject, deleteProject } from 'services/project';
import ProjectDetails from './ProjectDetails';
import ClientDetails from './ClientDetails';
import {
  Box,
  Container,
  InfoContainer,
  InfoText,
  ProjectDetailsContainer,
  RequiredMark,
  DeleteContainer,
  FooterContainer,
  WarningContainer,
  WarningText,
  Warning,
} from './Details.styled';

const stylesButton = css`
  margin-right: 20px;
`;

type FormValues = {
  projectName: string;
  projectOwner: string;
  projectStatus: string;
  language: string;
  deadline: string;
  description: string;
  projectLogo: File[];
  companyId: number;
  employees: { value: number | string }[];
  iteration: { name: string; technologies: string[] };
};

const defaultValue = {
  companyId: null,
  projectName: '',
  projectOwner: null,
  projectStatus: null,
  language: 'RU',
  deadline: '',
  description: '',
  projectLogo: [],
  employees: [{ value: -1 }],
};

const projectListPath = '/main/project-list';

const Details: FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const methods = useForm({
    defaultValues: defaultValue,
    shouldUnregister: false,
  });
  const { setValue, handleSubmit } = methods;
  const { colors } = useTheme();
  const [name, setName] = useState<string>();
  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    getDetails(id).then((data) => {
      setName(data.name);
      setValue('projectName', data.name);
      setValue('projectOwner', data.projectOwner);
      setValue('language', data.language);
      setValue('projectStatus', capitalize(data.status));
      setValue('deadline', new Date(data.deadline));
      setValue('description', data.description);
      setValue('companyId', data.company.id);
      setValue(
        'employees',
        data.employees.map((employee) => ({
          value: employee.id,
        })),
      );
      getFile(data.logo).then((file) => {
        setValue('projectLogo', [file]);
      });
    });
  }, [id, setValue]);

  const onSubmit = handleSubmit(
    ({
      deadline,
      description,
      employees,
      language,
      projectName,
      projectOwner,
      projectStatus,
      projectLogo,
    }: FormValues) => {
      const data = {
        id,
        description,
        projectOwner,
        deadline: Date.parse(deadline),
        employees: employees?.map(({ value }) => value) || [],
        language: language.toUpperCase(),
        name: projectName,
        status: projectStatus?.toUpperCase(),
      };
      updateProject(data)
        .then(() => {
          if (projectLogo[0]) {
            const formData = new FormData();
            formData.append('file', projectLogo[0]);

            postFile(formData, {
              fileSubType: 'AVATAR',
              fileType: 'IMAGE',
              ownerId: id,
              ownerType: 'PROJECT',
            });
          }
        })
        .then(() => {
          history.push(projectListPath);
        });
    },
  );

  const onToggleModal = () => {
    setIsOpenModal((prevState) => !prevState);
  };

  const onDeleteCompany = () => {
    deleteProject(id).then(() => history.push(projectListPath));
  };

  const onCancel = () => {
    history.push(projectListPath);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <Container>
          <InfoContainer>
            <Icon name="filledWarning" className="icon" color={colors.gray[550]} />
            <InfoText>
              All fields marked with <RequiredMark>*</RequiredMark> are required
            </InfoText>
          </InfoContainer>
          <Box>
            <ProjectDetailsContainer>
              <ProjectDetails />
            </ProjectDetailsContainer>
            <ClientDetails />
          </Box>
          <FooterContainer>
            <div>
              <Button label="Save" type="submit" styleType="primary" styleButton={stylesButton} />
              <Button label="Cancel" onClick={onCancel} />
            </div>
            <DeleteContainer>
              <WarningContainer>
                <Warning>Warning</Warning>
                <WarningText>Это приведёт к удалению компании и всех ее проектов.</WarningText>
              </WarningContainer>
              <Button label="Delete" styleType="warning" onClick={onToggleModal} />
            </DeleteContainer>
          </FooterContainer>
        </Container>
      </form>
      {isOpenModal && (
        <Modal
          title="Delete project"
          textCancel="Cancel"
          textConfirm="Delete"
          styleTypeConfirm="warning"
          text={`Are you sure you want to delete project "${name}"?`}
          isOpen={isOpenModal}
          onCancel={onToggleModal}
          onClose={onToggleModal}
          onConfirm={onDeleteCompany}
        />
      )}
    </FormProvider>
  );
};

export default Details;
