import { FC, ComponentPropsWithoutRef } from 'react';
import { SerializedStyles } from '@emotion/react';

import {
  Container,
  InputStyled,
  RequiredMark,
  ErrorMessage,
  ErrorContainer,
  Box,
  LabelContainer,
  HelpText,
} from './Input.styled';

export type Props = {
  label?: string;
  helpText?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  isError?: boolean;
  errorMessage?: string;
  inputStyles?: SerializedStyles;
  mb?: number;
} & ComponentPropsWithoutRef<'input'>;

const Input: FC<Props> = ({
  label,
  helpText,
  isRequired = false,
  isDisabled,
  isError = false,
  errorMessage,
  inputStyles,
  mb = 0,
  ...rest
}) => (
  <Container inputStyles={inputStyles} mb={mb}>
    <Box isError={isError}>
      <LabelContainer>
        <span>
          {label}
          {label && isRequired && <RequiredMark>*</RequiredMark>}
        </span>
        {helpText && <HelpText>{helpText}</HelpText>}
      </LabelContainer>
      <InputStyled disabled={isDisabled} isError={isError} {...rest} />
    </Box>
    <ErrorContainer>{isError && <ErrorMessage>{errorMessage}</ErrorMessage>}</ErrorContainer>
  </Container>
);

export default Input;
