import { configureStore, combineReducers } from '@reduxjs/toolkit';

import userReducer from './user';
import listsReducer from './lists';
import ganttReducer from './gantt';
import estimationReducer from './estimation';

export const rootReducer = combineReducers({
  user: userReducer,
  lists: listsReducer,
  gantt: ganttReducer,
  estimation: estimationReducer,
});

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export default store;
