import { FC, useEffect } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { Contact } from 'services/types/Client';
import Select from 'components/Select/v2';
import Input from 'components/Input';
import Icon from 'components/Icon';
import { CONTACTS } from 'constants/common';
import { BorderStyled, NoticeStyled, ContactStyled } from './styled/ContactItem.styled';
import Button from './styled/Button.styled';

type Props = {
  nestIndex: number;
  index: number;
  type: string;
  defaultValue: string;
  isRemove: boolean;
  isAdd: boolean;
  onRemove: (value: number) => void;
  onAppend: (value: Contact) => void;
};

const ContactItem: FC<Props> = ({
  nestIndex,
  index,
  onAppend,
  onRemove,
  type,
  defaultValue,
  isRemove,
  isAdd,
}) => {
  const { control, errors, setError, clearErrors } = useFormContext();
  const realType: string | undefined = useWatch({
    control,
    name: `employees[${nestIndex}].contacts[${index}].type`,
  });
  const realValue: string | undefined = useWatch({
    control,
    name: `employees[${nestIndex}].contacts[${index}].value`,
  });

  const handleRemove = () => {
    onRemove(index);
  };

  const handleAppend = () => {
    onAppend({
      type: '',
      value: '',
    });
  };

  useEffect(() => {
    if ((realValue && !realType) || (!realValue && realType)) {
      setError(`${nestIndex}.contacts[${index}]`, { message: 'contact Error' });
    } else {
      clearErrors(`${nestIndex}.contacts[${index}]`);
    }
  }, [realType, realValue, clearErrors, index, nestIndex, setError]);

  useEffect(() => () => clearErrors(`${nestIndex}.contacts[${index}]`), [
    index,
    nestIndex,
    clearErrors,
  ]);

  let isChanged = false;
  if (errors?.[nestIndex]?.contacts?.[index]) {
    isChanged = true;
  }

  return (
    <ContactStyled>
      <BorderStyled isWarning={isChanged}>
        <Controller
          control={control}
          name={`employees[${nestIndex}].contacts[${index}].type`}
          defaultValue={type}
          render={({ onChange, value }) => (
            <Select width={140} itemList={CONTACTS} value={value} onChange={onChange} />
          )}
        />
        <Controller
          control={control}
          name={`employees[${nestIndex}].contacts[${index}].value`}
          defaultValue={defaultValue}
          render={({ onChange, value }) => <Input width={212} value={value} onChange={onChange} />}
        />
      </BorderStyled>
      {isRemove && (
        <Button type="button" onClick={handleRemove}>
          <Icon name="delete" className="icon" />
        </Button>
      )}
      {isAdd && (
        <Button type="button" onClick={handleAppend}>
          <Icon name="plus" size={14} className="icon" />
        </Button>
      )}
      <NoticeStyled isWarning={isChanged}>FILL BOTH INPUTS</NoticeStyled>
    </ContactStyled>
  );
};

export default ContactItem;
