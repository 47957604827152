import { SubTaskType } from 'components/KanbanBoard/types';
import { protectedGet, protectedPut } from './protectedAxios';

import { StoriesType } from './types/tasks';

const TASKS_URL = '/api/tasks';

const getTasksUrl = (id: number) => `${TASKS_URL}/${id}`;

const updateTaskUrl = (id: number) => `${TASKS_URL}/task/${id}/status`;

const updateSubTaskUrl = (id: number) => `${TASKS_URL}/subtask/${id}/status`;

const getTasks = (id: number): Promise<StoriesType[]> => protectedGet(getTasksUrl(id));

export const updateTaskStatus = (id: number, statusId: number): Promise<unknown> =>
  protectedPut(updateTaskUrl(id), { id: statusId });

export const updateSubTaskStatus = (id: number, statusId: number): Promise<unknown> =>
  protectedPut(updateSubTaskUrl(id), { id: statusId });

export const updateGroupSubTasksStatus = async (
  subTasks: SubTaskType[],
  statusId: number,
): Promise<void> => {
  await Promise.all(subTasks.map((subtask) => updateSubTaskStatus(subtask.id, statusId)));
};

export default getTasks;
