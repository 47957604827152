import { FC } from 'react';

import {
  Table,
  HeaderRow,
  HeaderCell,
  BodyRow,
  BodyCell,
} from '../../pages/EstimationPage/styled/Table.styled';

const DATA = [
  {
    id: 1,
    title: 'Белый',
    describe: 'No risks',
    className: 'risks-table no-risks',
  },
  {
    id: 2,
    title: 'Жёлтый',
    describe: 'SENLA risks',
    className: 'risks-table senla-risks',
  },
  {
    id: 3,
    title: 'Красный',
    describe: 'Client side risks',
    className: 'risks-table client-risks',
  },
  {
    id: 4,
    title: 'Зеленый',
    describe: 'Extra or Additional Functionality from SENLA side',
    className: 'risks-table extra-risks',
  },
];

const RisksTable: FC = () => (
  <Table id="risksPDF">
    <thead>
      <HeaderRow>
        <HeaderCell colSpan={3}>Риски</HeaderCell>
      </HeaderRow>
    </thead>
    <tbody>
      {DATA.map((item) => (
        <BodyRow key={item.id} className={item.className}>
          <BodyCell width={80}>{item.id}</BodyCell>
          <BodyCell width={180}>{item.title}</BodyCell>
          <BodyCell>{item.describe}</BodyCell>
        </BodyRow>
      ))}
    </tbody>
  </Table>
);

export default RisksTable;
