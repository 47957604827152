import styled from '@emotion/styled';

export const StatusBoardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 3vh;
  margin-bottom: 20px;
`;

export const StatusTitle = styled.span`
  color: ${({ theme }) => theme.colors.gray[950]};
  font-size: 16px;
  padding: 20px 0 10px 20px;
`;

export const StatusBoardLine = styled.span`
  width: 100%;
  height: 5px;
  background-color: ${({ theme }) => theme.colors.gray[400]};
`;
