import { FC } from 'react';

import { Box, Item } from './EditMenu.styled';

type ItemProps = {
  data: string;
  onClick: (role: string) => void;
};

const MenuItem: FC<ItemProps> = ({ data, onClick }) => {
  const handleClick = () => {
    onClick(data);
  };

  return (
    <Item onClick={handleClick} className={data.toLocaleLowerCase()}>
      {data.toLocaleLowerCase()}
    </Item>
  );
};

export type Props = {
  items: { [key: string]: string };
  onClick: (role: string) => void;
};

const Menu: FC<Props> = ({ items, onClick }) => (
  <Box>
    {Object.entries(items).map(([key, value]) => (
      <MenuItem key={key} data={value} onClick={onClick} />
    ))}
  </Box>
);

export default Menu;
