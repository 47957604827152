import { FC } from 'react';
import { Control, DeepMap, FieldError, useFieldArray } from 'react-hook-form';

import PerformerItem from './PerformerItem';
import { Label } from './IterationForm.styled';
import { DataToPost } from '../../types';

type Props = {
  control: Control;
  errors: DeepMap<DataToPost, FieldError>;
  setValue: (
    name: string,
    value: string,
    config?: Partial<{ shouldValidate: boolean; shouldDirty: boolean }> | undefined,
  ) => void;
};

const PerformersList: FC<Props> = ({ control, errors, setValue }) => {
  const { fields, remove, insert } = useFieldArray({
    control,
    name: 'performers',
    keyName: 'uuid',
  });

  const handleAddItem = (index: number) => {
    insert(index + 1, {
      name: '',
      position: {
        id: -1,
        name: '',
      },
    });
  };

  const handleRemoveItem = (index: number) => {
    remove(index);
  };

  return (
    <>
      <Label>Involved specialists</Label>
      {fields.map((item, index) => (
        <PerformerItem
          key={item.uuid}
          index={index}
          fields={fields}
          item={item}
          control={control}
          errors={errors}
          onAddItem={handleAddItem}
          onDeleteItem={handleRemoveItem}
          setValue={setValue}
        />
      ))}
    </>
  );
};
export default PerformersList;
