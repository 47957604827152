import styled from '@emotion/styled';

export const BaseContainer = styled.div`
  width: 1920px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 24px;
`;

export const CenterContainer = styled.div`
  width: 1245px;
  margin: 0 auto;
`;
