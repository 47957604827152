import { ChangeEvent, FC, useState, memo, useEffect, useRef } from 'react';
import styled from '@emotion/styled';

import { useDevelopmentSectionTimeSum } from '../context';
import { EstimationSectionType } from '../types';

const Container = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  cursor: text;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  input[type='number'] {
    appearance: textfield;
  }
`;

const Input = styled.input`
  border: 1px solid transparent;
  line-height: 20px;
  padding: 5px 0 5px 5px;
  width: 100%;
`;

const getConvertedValue = (event: ChangeEvent<HTMLInputElement>) => {
  const { value: newValue } = event.target;
  const convertedValue = Number(newValue);

  const canChange = (convertedValue >= 0 && Number.isInteger(convertedValue)) || newValue === '';

  return {
    canChange,
    convertedValue,
  };
};

type ShowInputProps = {
  onBlur: () => void;
  canEdit?: boolean;
};

const useShowInput = ({ onBlur, canEdit }: ShowInputProps) => {
  const [isActive, setIsActive] = useState(false);

  const handleShowInput = () => {
    if (canEdit) return;
    setIsActive(true);
  };

  const handleHideInput = () => {
    setIsActive(false);
    onBlur();
  };

  return {
    isActive,
    handleShowInput,
    handleHideInput,
  };
};

type Props = {
  value: null | number | string;
  type: EstimationSectionType;
  readOnly?: boolean;
  onChange: (value: number) => void;
  onBlur: () => void;
  canEdit?: boolean;
};

const NumberField: FC<Props> = ({ value, readOnly = false, type, onChange, onBlur, canEdit }) => {
  const developmentSectionTimeSum = useDevelopmentSectionTimeSum();
  const ref = useRef<HTMLInputElement>(null);
  const { isActive, handleHideInput, handleShowInput } = useShowInput({
    onBlur,
  });

  useEffect(() => {
    if (isActive) {
      ref.current?.focus();
    }
  }, [isActive]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { canChange, convertedValue } = getConvertedValue(event);

    if (canChange) {
      onChange(convertedValue);
    }
  };

  const handleChangeManagementValue = (event: ChangeEvent<HTMLInputElement>) => {
    const { canChange, convertedValue } = getConvertedValue(event);

    if (canChange) {
      const percent = developmentSectionTimeSum === 0 ? 0 : 100 / developmentSectionTimeSum;
      onChange(convertedValue * percent);
    }
  };

  return (
    <Container onClick={handleShowInput}>
      {isActive && !readOnly && !canEdit ? (
        <Input
          ref={ref}
          type="number"
          min="0"
          step="1"
          value={value || ''}
          onChange={type === 'MANAGEMENT' ? handleChangeManagementValue : handleChange}
          onBlur={handleHideInput}
        />
      ) : (
        <span>{value || ''}</span>
      )}
    </Container>
  );
};

export default memo(NumberField);
