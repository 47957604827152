import styled from '@emotion/styled';

type Props = {
  rotateLeft?: boolean;
};

export default styled.div<Props>`
  cursor: pointer;
  ${({ rotateLeft }) =>
    rotateLeft &&
    `
    transform: rotate(-90deg);
  `}
  ${({ rotateLeft }) =>
    !rotateLeft &&
    `
    transform: rotate(90deg);
  `}
`;
