import styled from '@emotion/styled';

export const Box = styled.ul`
  list-style: none;
  position: absolute;
  margin: 0;
  padding: 0;
  right: -40px;
  top: 40px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.gray[200]};
  background: ${({ theme }) => theme.colors.white[400]};
  overflow: hidden;
  width: 190px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;

  &.menu-enter {
    opacity: 0;
    transform: translateY(10px);
  }

  &.menu-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms, transform 300ms;
  }

  &.menu-exit {
    opacity: 1;
    transform: translateY(0);
  }

  &.menu-exit-active {
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 400ms, transform 400ms;
  }
`;

export const Item = styled.li`
  width: 100%;
  padding: 15px 22px 15px 63px;
  position: relative;
  cursor: pointer;
  text-transform: lowercase;

  &::before {
    content: '';
    display: block;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    position: absolute;
    top: 14px;
    left: 22px;
  }

  &.basic::before {
    background-color: ${({ theme }) => theme.colors.green[400]};
  }

  &.admin::before {
    background-color: ${({ theme }) => theme.colors.yellow[400]};
  }

  &.super::before {
    background-color: ${({ theme }) => theme.colors.red[400]};
  }

  &:hover {
    background: ${({ theme }) => theme.colors.gray[400]};
  }

  &::first-letter {
    text-transform: uppercase;
  }
`;
