import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import FileUploader from 'components/FileUploader';
import { ACCEPTED_FILES } from 'constants/common';

const File: FC = () => {
  const { control } = useFormContext();

  return (
    <Controller
      name="logo"
      control={control}
      render={({ value, onChange }) => (
        <FileUploader
          accept={ACCEPTED_FILES.types}
          acceptText={ACCEPTED_FILES.labels}
          files={value}
          onChange={onChange}
        />
      )}
    />
  );
};

export default File;
