import styled from '@emotion/styled';

export const PopoverBox = styled.div`
  position: relative;
  z-index: 10;
`;

export const PositionedBox = styled.div<{ x: number; y: number }>`
  position: absolute;
  left: ${({ x }) => `${x}px`};
  top: ${({ y }) => `${y}px`};
`;
