import eachWeekendOfInterval from 'date-fns/eachWeekendOfInterval';
import { KonvaEventObject } from 'konva/types/Node';
import {
  add,
  compareAsc,
  compareDesc,
  differenceInBusinessDays,
  differenceInDays,
  parseISO,
} from 'date-fns';
import { MainModel } from '../../models/MainModel';
import { DatePeriod, MainModelWithSizes, AdaptedStory } from './types';
import AppSection from '../../models/AppSection';
import {
  LEFT_PANEL_WIDTH_PX,
  MONTH_COLUMN_SIZE,
  SUBSTORY_HEIGHT_PX,
  TIMELINE_COLUMN_SIZE,
  WEEK_COLUMN_SIZE,
} from './consts';
import { GanttGetRequestResponse } from '../../http/controllers/gantt-controller/gantt-get.request';
import AppStory from '../../models/AppStory';
import { CALENDAR_DAY_WIDTH } from './components/TimeLine/consts';

export const getAllStories = (model: MainModelWithSizes): AppStory[] => {
  const { sections } = model;
  const allStories: AppStory[] = [];
  sections.forEach((section) => {
    const { stories } = section;
    stories.forEach((story) => {
      allStories.push(story);
    });
  });

  return allStories;
};

export const getDefaultDatePeriod = (): DatePeriod => {
  const minStart = new Date(new Date().setHours(0, 0, 0, 0));
  const minEnd = add(new Date(new Date().setHours(0, 0, 0, 0)), { days: 30 });

  return {
    start: minStart,
    end: minEnd,
  };
};

export const getDatePeriod = (model: MainModelWithSizes, startdate: Date): DatePeriod => {
  const TIMELINE_GAP = 2;
  const minEnd = add(startdate, { days: 30 });
  const allStories = getAllStories(model);
  const sortedStoriesByEnd = [...allStories].sort((a, b) => compareDesc(a?.endDate, b?.endDate));
  const modelEnd = add(sortedStoriesByEnd[0]?.endDate, { days: TIMELINE_GAP });

  if (modelEnd === undefined) {
    return {
      start: startdate,
      end: minEnd,
    };
  }

  return {
    start: startdate,
    end: compareAsc(modelEnd, minEnd) ? modelEnd : minEnd,
  };
};

export const getLeftPanelHeightPx = (model: MainModel): number => {
  const storiesCount = model.sections.reduce(
    (acc, section) => acc + (section.expanded ? section.stories.length : 0),
    0,
  );

  return (
    model.sections.length * SUBSTORY_HEIGHT_PX +
    storiesCount * SUBSTORY_HEIGHT_PX +
    SUBSTORY_HEIGHT_PX
  );
};

export const getCanvasWidth = (
  datePeriod: DatePeriod,
  numericScale: number,
  columnSize: number,
): number =>
  Math.abs(differenceInDays(datePeriod.start, datePeriod.end)) *
  CALENDAR_DAY_WIDTH *
  (columnSize / CALENDAR_DAY_WIDTH) *
  numericScale;

export const getCanvasSizes = (
  modelWithSizes: MainModelWithSizes,
): { width: number; height: number } => ({
  width: window.innerWidth - LEFT_PANEL_WIDTH_PX - 10,
  height: getLeftPanelHeightPx(modelWithSizes),
});

export const mapFromGanttGetRequestToMainModel = (
  response: GanttGetRequestResponse[],
): MainModel => {
  const sections: AppSection[] = response.map((section) => section);

  return {
    sections,
  };
};

export const rangeIntersection = (s1: number, e1: number, s2: number, e2: number): number[] => {
  if (s1 > e1 || s2 > e2) {
    return [];
  }
  if (s2 > e1 || s1 > e2) {
    return [];
  }
  if (s2 > s1) {
    if (e2 > e1) return [s2, e1];

    return [s2, e2];
  }
  if (e2 > e1) return [s1, e1];

  return [s1, e2];
};

export const hoursToMilliseconds = (hours: number): number => hours * 60 * 60 * 1000;

export const millisecondsToHours = (milliseconds: number): number =>
  milliseconds / (1000 * 60 * 60);

export const workHoursToCalendarMilliseconds = (hours: number): number =>
  hoursToMilliseconds(Math.floor(hours / 8) * 24 + (hours % 8));

export const getStoryStartDate = (periodStart: Date, offsetHours: number, pxInHour: number): Date =>
  add(periodStart, {
    hours: offsetHours * pxInHour,
  });

export const getStoryEndDate = (hours: number, storyStart: number): number => {
  let storyEnd = storyStart + workHoursToCalendarMilliseconds(hours);
  let weekendDays = eachWeekendOfInterval({
    start: new Date(storyStart),
    end: new Date(storyEnd),
  });
  while (weekendDays.length > 0) {
    const weekendStart = weekendDays[0].getTime();
    const weekendEnd = weekendStart + hoursToMilliseconds(TIMELINE_COLUMN_SIZE);

    const intersection = rangeIntersection(storyStart, storyEnd, weekendStart, weekendEnd);
    const intersectionHours = millisecondsToHours(intersection[1] - intersection[0]);

    if (intersectionHours < TIMELINE_COLUMN_SIZE && storyEnd < weekendEnd) {
      storyEnd = weekendEnd + hoursToMilliseconds(intersectionHours);
    } else {
      storyEnd += hoursToMilliseconds(intersectionHours);
    }
    weekendDays = eachWeekendOfInterval({
      start: new Date(weekendEnd),
      end: new Date(storyEnd),
    });
  }

  return storyEnd;
};

export const secAndSto = (
  modelWithSizes: MainModelWithSizes,
  storyId: number | null,
  onSausageClick: (sec: AppSection, sto2: AppStory, evt: KonvaEventObject<MouseEvent>) => void,
  evt: KonvaEventObject<MouseEvent>,
): { sec?: AppSection; sto2?: AppStory } => {
  const sec = modelWithSizes.sections.find((section) =>
    section.stories.find((story) => story.id === storyId),
  );
  const sto2 = sec?.stories.find((story) => story.id === storyId);
  if (sec && sto2) {
    onSausageClick(sec, sto2, evt);
  }

  return {
    sec,
    sto2,
  };
};

export const getLeftStory = (
  modelWithSizes: MainModelWithSizes,
  rightStory: AppStory,
): { sect: AppSection | undefined; leftStory: AppStory | undefined } => {
  const sect = modelWithSizes.sections.find((section) =>
    section.stories.find((story) => story.id === rightStory.id),
  );
  const leftStory = sect
    ? sect.stories.find((story) => story.nextTaskDto.nextTaskId === rightStory.id)
    : undefined;

  return {
    sect,
    leftStory,
  };
};

// for development
export const getStoryById = (model: MainModelWithSizes, id: number | null): AppStory | null => {
  if (id === null) return null;
  const allStories = getAllStories(model);
  const story = allStories.find((item) => item.id === id);

  if (story !== undefined) {
    return story;
  }

  return null;
};

export const getCalendarItemWidth = (numScale: 1 | 2 | 3): number => {
  if (numScale === 2) {
    return WEEK_COLUMN_SIZE;
  }
  if (numScale === 3) {
    return MONTH_COLUMN_SIZE;
  }

  return TIMELINE_COLUMN_SIZE;
};

export const fromStart = (ganttstart: Date, periodStart: Date, pxInHour: number): number => {
  const days = differenceInBusinessDays(periodStart, ganttstart);
  const workHours = days * 8;

  return workHours * pxInHour;
};

export const getOffsetWithoutNoBusiness = (
  startDate: Date,
  story: AppStory,
  storyStartDate: Date,
): number => {
  const startForCompare = new Date(startDate).setHours(0, 0, 0, 0);
  const endForCompare = new Date(storyStartDate).setHours(0, 0, 0, 0);

  if (startForCompare === endForCompare) {
    return 0;
  }
  const weekendsBeforeStart = eachWeekendOfInterval({
    start: startForCompare,
    end: endForCompare,
  }).length;

  return story.offsetHours - weekendsBeforeStart * 8;
};

export const setAdditionsForStories = (stories: AppStory[], startdate: Date): AdaptedStory[] =>
  stories.map((story) => {
    let newStartDate = startdate;
    let newEndDate = new Date(getStoryEndDate(story.hours, startdate.getTime()));
    if (typeof story.startDate === 'string' && typeof story.endDate === 'string') {
      newStartDate = parseISO(story.startDate);
      newEndDate = parseISO(story.endDate);
    }
    const offsetBusinessHours = getOffsetWithoutNoBusiness(startdate, story, newStartDate);

    return {
      ...story,
      offsetBusinessHours,
      startDate: newStartDate,
      endDate: newEndDate,
    };
  });
