import { ChangeEvent, FC, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTheme, SerializedStyles } from '@emotion/react';

import Icon from 'components/Icon';
import {
  Container,
  FileUploaderContainer,
  Button,
  Text,
  DeleteButtonContainer,
  ImageContainer,
  PlusIcon,
} from './FileUploader.styled';

type Props = {
  label?: string;
  accept: string[];
  acceptText: string;
  files: File[];
  inputStyles?: SerializedStyles;
  onChange: (value: File[]) => void;
};

const FileUploader: FC<Props> = ({ label, accept, acceptText, files, inputStyles, onChange }) => {
  const { colors } = useTheme();
  const value = files[0];
  const {
    isDragReject,
    isDragActive,
    isDragAccept,
    open,
    getRootProps,
    getInputProps,
  } = useDropzone({
    maxFiles: 1,
    accept,
    multiple: false,
    noClick: true,
    noKeyboard: true,
    onDrop: (droppedFiles) => onChange(droppedFiles),
  });

  const handleChangeFile = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.files;
    const newFile = newValue ? newValue[0] : null;
    if (newFile) {
      const isValidFileType = accept.includes(newFile.type);
      if (isValidFileType) {
        onChange([newFile]);
      }
    }
  };

  const handleDeleteFile = () => {
    onChange([]);
  };

  const dndContainerProps = !value ? getRootProps() : {};

  const url = value ? URL.createObjectURL(value) : null;

  useEffect(
    () => () => {
      if (url) {
        URL.revokeObjectURL(url);
      }
    },
    [url],
  );

  return (
    <Container
      {...dndContainerProps}
      isDragActive={isDragActive}
      isDragAccept={isDragAccept}
      isDragReject={isDragReject}
      inputStyles={inputStyles}
    >
      {url ? (
        <ImageContainer>
          <DeleteButtonContainer data-comp="icon" onClick={handleDeleteFile}>
            <Icon name="delete" size={16} color={colors.gray[900]} />
          </DeleteButtonContainer>
          <img src={url} alt={value.name} />
        </ImageContainer>
      ) : (
        <FileUploaderContainer>
          <input {...getInputProps()} onChange={handleChangeFile} />
          <Icon name="picture" size={40} />
          <Text error={isDragReject} bold>
            {isDragReject ? 'This file does not support' : label}
          </Text>
          <Text>
            Drop{' '}
            <Text error={isDragReject} bold>
              {acceptText}
            </Text>{' '}
            image
          </Text>
          <Button type="button" onClick={open}>
            <PlusIcon name="plus" color={colors.blue[300]} size={10} />
            upload image
          </Button>
        </FileUploaderContainer>
      )}
    </Container>
  );
};

export default FileUploader;
