import styled from '@emotion/styled';

type Props = {
  header?: boolean;
  rotateLeft?: boolean;
  rotateRight?: boolean;
};

export const Container = styled.div<{ mb: number }>`
  display: flex;
  margin-bottom: ${({ mb }) => `${mb}px`};
`;

export const Box = styled.label`
  align-self: flex-start;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const Label = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  margin-bottom: 4px;
  color: ${({ theme }) => theme.colors.gray[550]};
`;

export const Input = styled.input`
  font-size: 14px;
  color: #333;
  background: ${({ theme }) => theme.colors.white[400]};
  border: 1px solid ${({ theme }) => theme.colors.gray[200]};
  outline: 0;
  align-self: flex-start;
  transition: border-color 0.2s;
  appearance: none;
  border-radius: 4px;
  line-height: 24px;
  padding: 9px 10px 9px 15px;

  &:focus,
  &:hover {
    border-color: ${({ theme }) => theme.colors.blue[700]};
    box-shadow: none;
  }
`;

export const CalendarContainer = styled.div`
  padding: 12px;
  position: absolute;
  z-index: 5;
  border: 1px solid ${({ theme }) => theme.colors.gray[750]};
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
  border-radius: 4px;
  top: 33px;
  left: 0px;
  background-color: ${({ theme }) => theme.colors.white[400]};

  &.calendar-enter {
    opacity: 0;
    transform: translateY(30px);
  }

  &.calendar-enter-active {
    opacity: 1;
    transform: translateY(21px);
    transition: opacity 300ms, transform 300ms;
  }

  &.calendar-enter-done {
    transform: translateY(21px);
  }

  &.calendar-exit {
    opacity: 1;
    transform: translateY(21px);
  }

  &.calendar-exit-active {
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 400ms, transform 400ms;
  }
`;

export const Row = styled.div<Props>`
  display: flex;
  ${({ header }) =>
    header &&
    `
    align-items: center;
    justify-content: space-between;
    padding: 6px 12px;
  `}
`;

export const CellContainer = styled.div`
  padding: 7.5px;
`;

export const WeekRow = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  color: #333;
  cursor: pointer;

  &:hover {
    cursor: default;
    background-color: ${({ theme }) => theme.colors.white[400]};
  }
`;
