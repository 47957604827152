/* eslint-disable no-param-reassign */
import autoTable, { CellHookData } from 'jspdf-autotable';
import JsPDF from 'jspdf';

interface Doc extends JsPDF {
  autoTable: {
    previous: {
      finalY: number;
    };
  };
}

const contentPdfCalculate = (doc: Doc): void => {
  autoTable(doc, {
    html: '#risksPDF',
    startY: 55,
    tableLineColor: [244, 244, 245],
    tableLineWidth: 0.2,
    styles: {
      font: 'Roboto',
      halign: 'center',
      minCellWidth: 30,
    },
    headStyles: {
      valign: 'middle',
    },
    bodyStyles: {
      lineWidth: 0.1,
      lineColor: [244, 244, 245],
    },
    columnStyles: {
      0: { halign: 'left' },
    },
    useCss: true,
    didParseCell(data: CellHookData) {
      if (data.row.section === 'head') {
        data.row.cells[0].styles.halign = 'left';
        for (let i = 0; i <= 1; i += 1) {
          data.row.cells[i].styles.lineWidth = 0.1;
          data.row.cells[i].styles.lineColor = 240;
        }
      }
      if (data.row.section === 'body') {
        data.cell.text[0] = data.cell.text[0].replace('/', '');
        data.cell.styles.lineWidth = 0.01;
        data.cell.styles.lineColor = [244, 244, 245];
      }

      data.cell.styles.fontSize = 9;
    },
  });
};

const contentPdfTable = (doc: Doc): void => {
  autoTable(doc, {
    html: '#tablePDF',
    tableLineColor: [216, 216, 216],
    tableLineWidth: 0.2,
    startY: 55,
    rowPageBreak: 'auto',
    showFoot: 'lastPage',
    styles: {
      font: 'Roboto',
      halign: 'center',
      minCellWidth: 30,
    },
    headStyles: {
      valign: 'middle',
    },
    bodyStyles: {
      lineWidth: 0.1,
      lineColor: [244, 244, 245],
    },
    columnStyles: {
      0: { halign: 'left' },
    },
    useCss: true,
    didParseCell(data: CellHookData) {
      if (data.row.section === 'body') {
        if (data.column.index === 0) {
          const number = parseFloat(data.cell.text[0]);
          if (!Number.isNaN(number)) {
            const updatedTitle = data.cell.text[0].replace(`${number}`, `${number}  `);
            data.cell.text[0] = updatedTitle;
          }
        }
        data.cell.text[0] = data.cell.text[0].replace('/', '');
        data.cell.styles.lineWidth = 0.01;
        data.cell.styles.lineColor = [244, 244, 245];
      }
      data.cell.styles.fontSize = 9;
    },
  });
};

export { contentPdfCalculate, contentPdfTable };
