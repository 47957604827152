import { ChangeEvent, FC, FormEvent, useEffect, useState } from 'react';
import styled from '@emotion/styled';

import CardGrid from 'layouts/CardGrid';
import Container from 'layouts/Container';
import PagePanel from 'components/PagePanel';
import UserCard from 'components/UserCard';
import Input from 'components/Input';

import { getAllUsers, setRole } from 'services/super';
import { BaseUser } from 'services/types/User';

const InputBox = styled.div`
  margin-bottom: 16px;
  margin-top: 15px;
`;

const UsersPage: FC = () => {
  const [users, setUsers] = useState<BaseUser[]>([]);
  const [value, setValue] = useState('');

  useEffect(() => {
    getAllUsers().then((data) => {
      setUsers(data);
    });
  }, []);

  const handleChangeUserRole = (newRole: string, id: number) => {
    setRole(newRole, id).then(() => {
      setUsers(
        users.map((item) => {
          if (item.id === id) {
            return { ...item, role: newRole };
          }

          return item;
        }),
      );
    });
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const filteredUsers = users.filter((user) =>
    user.name.toLocaleLowerCase().includes(value.toLocaleLowerCase()),
  );

  return (
    <>
      <PagePanel title="Users" />
      <Container>
        <InputBox>
          <form onSubmit={handleSubmit} style={{ display: 'flex' }}>
            <Input
              placeholder="User name"
              width={320}
              name="name"
              value={value}
              onChange={handleChange}
            />
          </form>
        </InputBox>
        <CardGrid>
          {filteredUsers.map((user) => (
            <UserCard
              key={user.id}
              userId={user.id}
              fullName={user.name}
              role={user.role}
              imgSrc={user.photo}
              onChangeUserRole={handleChangeUserRole}
            />
          ))}
        </CardGrid>
      </Container>
    </>
  );
};

export default UsersPage;
