import styled from '@emotion/styled';

type OptionProps = {
  isActive: boolean;
};

const OptionStyled = styled.button<OptionProps>`
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.colors.blue[700] : theme.colors.white[400]};
  border: none;
  padding: 6px 12px;
  text-align: start;
  font-size: 14px;
  color: ${({ isActive, theme }) => (isActive ? theme.colors.white[400] : theme.colors.black[100])};
  border-radius: 2px;

  &:hover,
  &:focus {
    cursor: pointer;
    background-color: ${({ isActive, theme }) =>
      isActive ? theme.colors.blue[700] : `${theme.colors.gray[300]}`};
    color: ${({ isActive, theme }) =>
      isActive ? `${theme.colors.white[400]}` : theme.colors.black[100]};
  }
`;

export default OptionStyled;
