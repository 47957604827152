import { FC } from 'react';

import Icon from 'components/Icon';
import { MenuItem, IconWrapper } from './ContextMenu.styled';
import RisksSubMenu from './RisksSubMenu';
import useCanMoveUpAndDown from './useCanMoveUpAndDown';
import { useActions, useTableData } from '../context';
import { Row, RiskType } from '../types';

type Props = {
  data: Row;
};

const Menu: FC<Props> = ({ data }) => {
  const {
    addNewCategory,
    addNewLine,
    moveDown,
    moveUp,
    deleteElement,
    setRiskLevel,
    updateRowData,
  } = useActions();
  const tableData = useTableData();
  const { isSection, riskLevel } = data;

  const handleAddNewCategory = () => {
    addNewCategory(data.sectionIndex);
  };

  const handleAddNewLine = () => {
    addNewLine({ currentRowId: data.rowId, currentSectionIndex: data.sectionIndex });
  };

  const handleMoveUp = () => {
    moveUp({
      isSectionRow: data.isSection,
      currentRowIndex: data.rowIndex,
      currentSectionId: data.sectionId,
      currentSectionIndex: data.sectionIndex,
    });
  };

  const handleMoveDown = () => {
    moveDown({
      isSectionRow: data.isSection,
      currentRowIndex: data.rowIndex,
      currentSectionId: data.sectionId,
      currentSectionIndex: data.sectionIndex,
    });
  };

  const handleDeleteElement = () => {
    deleteElement({
      isSectionRow: data.isSection,
      currentRowId: data.rowId,
      currentRowIndex: data.rowIndex,
      currentSectionIndex: data.sectionIndex,
    });
  };

  const handleRisksClick = (risk: RiskType) => {
    setRiskLevel({ rowId: data.rowId, riskLevel: risk });
  };

  const handleComment = () => {
    updateRowData({
      value: 'Here comment .....',
      dataKey: 'comment',
      rowId: data.rowId,
    });
  };

  const { canMoveUp, canMoveDown } = useCanMoveUpAndDown({ row: data, tableData });

  return (
    <ul>
      <MenuItem onClick={handleAddNewLine}>
        <IconWrapper>
          <Icon className="icon" name="plus" size={14} />
        </IconWrapper>
        <span className="bold">New Line</span>
      </MenuItem>
      {data.type === 'DEVELOPMENT' && (
        <MenuItem onClick={handleAddNewCategory}>
          <IconWrapper>
            <Icon className="icon" name="filter" size={16} />
          </IconWrapper>
          <span className="bold">New Category</span>
        </MenuItem>
      )}
      {data.type === 'DEVELOPMENT' && canMoveUp && (
        <MenuItem className="no-border" onClick={handleMoveUp}>
          <IconWrapper>
            <Icon className="icon" name="angleUp" size={12} />
          </IconWrapper>
          <span>Move Up</span>
        </MenuItem>
      )}
      {data.type === 'DEVELOPMENT' && canMoveDown && (
        <MenuItem onClick={handleMoveDown}>
          <IconWrapper>
            <Icon className="icon" name="angleDown" size={12} />
          </IconWrapper>
          <span>Move Down</span>
        </MenuItem>
      )}
      {!data.isSection && (
        <MenuItem className="parent">
          <IconWrapper>
            <Icon className="icon" name="warning" size={16} />
          </IconWrapper>
          <span className="bold">Risk Level</span>
          <Icon className="sub-menu-icon" name="arrowLeft" size={10} />
          <RisksSubMenu onClick={handleRisksClick} riskLevel={riskLevel} />
        </MenuItem>
      )}
      {!isSection && (
        <MenuItem onClick={handleComment}>
          <IconWrapper>
            <Icon className="icon" name="chat" size={16} />
          </IconWrapper>
          <span>Comment</span>
        </MenuItem>
      )}
      <MenuItem onClick={handleDeleteElement}>
        <IconWrapper>
          <Icon className="icon" name="delete" size={16} />
        </IconWrapper>
        <span className="bold">Delete</span>
      </MenuItem>
    </ul>
  );
};

export default Menu;
