import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type State = {
  startdate: number;
};

const initialState: State = {
  startdate: new Date().getTime(),
};

const estimationSlice = createSlice({
  name: 'estimation',
  initialState,
  reducers: {
    setStartDate(state, action: PayloadAction<number>) {
      state.startdate = action.payload;
    },
  },
});

export const { setStartDate } = estimationSlice.actions;

export default estimationSlice.reducer;
