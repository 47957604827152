import { FC, MouseEvent } from 'react';
import { Controller, Control, ArrayField } from 'react-hook-form';
import { useTheme } from '@emotion/react';

import Select from 'components/Select/v2';
import Icon from 'components/Icon';
import { ButtonRound, EmployeeContainer, InputContainer } from './EmployeeForm.styled';
import { ProjectType } from './types';

type Props = {
  control: Control;
  index: number;
  projectList?: ProjectType[];
  item: Partial<ArrayField<Record<string, number>, 'uuid'>>;
  fields: Partial<ArrayField<Record<string, number>, 'uuid'>>[];
  onAddItem: (value: number) => void;
  onDeleteItem: (value: number) => void;
};

const ProjectItem: FC<Props> = ({
  control,
  item,
  fields,
  index,
  projectList,
  onAddItem,
  onDeleteItem,
}) => {
  const { colors } = useTheme();

  const handleRemoveItem = () => {
    onDeleteItem(index);
  };

  const handleAddItem = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    onAddItem(index);
  };

  const itemList =
    projectList &&
    projectList.map((project) => ({
      label: project.name,
      value: project.id,
    }));

  return (
    <InputContainer>
      <EmployeeContainer>
        <Controller
          name={`projects[${index}].id`}
          control={control}
          defaultValue={item.id}
          render={({ onChange, value }) => {
            const filteredList =
              fields.length > 0
                ? itemList?.filter((project) =>
                    fields.every((field) => field.id !== project.value || project.value === value),
                  )
                : itemList;

            return (
              <Select
                value={value}
                itemList={filteredList || []}
                width={365}
                onChange={onChange}
                disabled={itemList?.length === index}
              />
            );
          }}
        />
        <ButtonRound type="button" onClick={handleRemoveItem} empty={fields.length <= 1}>
          <Icon name="delete" color={colors.blue[300]} />
        </ButtonRound>
        <ButtonRound
          type="button"
          onClick={handleAddItem}
          empty={projectList && projectList.length === index}
        >
          <Icon name="plus" color={colors.blue[300]} />
        </ButtonRound>
      </EmployeeContainer>
    </InputContainer>
  );
};

export default ProjectItem;
