import _ from 'lodash';
import EstimationTableData from '../../components/EstimationTable/types';

const calcDirections = (
  tableData: EstimationTableData,
  sumTime: number,
): { [key: string]: number } => {
  const tableDataType = tableData.map((el) => {
    const newEl = { ...el };
    newEl.rows = el.rows.map((row) => ({ ...row, type: el.type }));

    return newEl;
  });

  const allSections = _(tableDataType).flatMap('rows').value();

  return _(allSections).reduce((result: { [key: string]: number }, nextValue) => {
    const { direction, cells, type } = nextValue;
    let { sum } = nextValue;
    if (!sum) {
      const calc = _(cells).reduce((acc, cell) => {
        if (cell.time) {
          return acc + cell.time;
        }

        return acc;
      }, 0);

      return {
        ...result,
        [direction]: result[direction] ? result[direction] + calc : calc,
      };
    }

    if (type === 'MANAGEMENT') sum = Math.round((sumTime * sum) / 100);

    return {
      ...result,
      [direction]: result[direction] ? result[direction] + sum : sum,
    };
  }, {});
};

export default calcDirections;
