import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { KonvaEventObject } from 'konva/types/Node';
import { useParams } from 'react-router-dom';
import { useEstimationStartdate, useGanttScaleKey } from 'reducers/selectors';
import { DefaultPageLayout } from '../../layouts/DefaultPageLayout/DefaultPageLayout';
import { DefaultContentLayout } from '../../layouts/DefaultContentLayout/DefaultContentLayout';
import {
  mapFromGanttGetRequestToMainModel,
  getLeftStory,
  getDatePeriod,
  getStoryEndDate,
  getStoryStartDate,
} from './utils';
import { RightCanvas } from './components/RightCanvas';
import { LeftPanel } from './components/LeftPanel';
import { AppCamera } from '../../models/AppCamera';
import { INITIAL_APP_CAMERA, TIMELINE_COLUMN_SIZE } from './consts';
import { TopToolbar } from './components/TopToolbar';
import AppSection from '../../models/AppSection';
import AppStory from '../../models/AppStory';
import useUpdateStoryById from './hooks/useUpdateStoryById';
import useContextMenu from '../../components/ContextMenu/useContextMenu';
import { ContextMenu, StyledContextMenuItem } from '../../components/ContextMenu';
import useLoader from '../../utils/hooks/useLoader';
import { SetModel, SetStoryId, SetIsWaiting, LinkActionsEnum, DatePeriod } from './types';
import { ganttGetRequest } from '../../http/controllers/gantt-controller/gantt-get.request';
import { ganttPatchRequest } from '../../http/controllers/gantt-controller/gantt-patch.request';
import useHourPixels from './hooks/useHourPixels';
import useModel from './hooks/useModel';

const MainPage: FC = () => {
  const { id } = useParams<{ id: string }>();
  const [appCamera, setAppCamera] = useState<AppCamera>(INITIAL_APP_CAMERA);
  const [storyId, setStoryId] = useState<number | null>(null);
  const [isWaiting, setIsWaiting] = useState<boolean>(false);
  const [firstStory, setFirstStory] = useState<AppStory | null>(null);
  const [linkAction, setLinkAction] = useState<LinkActionsEnum | null>(null);
  const scaleKey = useGanttScaleKey();
  const pxInHour = useHourPixels(appCamera.scale);
  const startdate = useEstimationStartdate();

  const { data: model, setData: setModel } = useLoader({
    // api: () => Promise.resolve(fixtureModel),
    api: () => ganttGetRequest(id).then((res) => mapFromGanttGetRequestToMainModel(res)),
    deps: [],
  });

  const updateStoryById = useUpdateStoryById(setModel as SetModel);
  const modelWithSizes = useModel(model);

  const [datePeriod, setDatePeriod] = useState<DatePeriod>(
    getDatePeriod(modelWithSizes, new Date(startdate)),
  );

  useEffect(() => {
    setDatePeriod(getDatePeriod(modelWithSizes, new Date(startdate)));
  }, [modelWithSizes, startdate]);

  useEffect(() => {
    setAppCamera((appCameraInState) => ({ ...appCameraInState, scale: scaleKey }));
  }, [scaleKey]);

  const onWheel = (event: KonvaEventObject<WheelEvent>) => {
    if (event.evt.shiftKey || event.evt.deltaX) {
      const delta = event.evt.shiftKey ? -event.evt.deltaY : -event.evt.deltaX;
      const newValue = appCamera.x + delta;
      setAppCamera((camera) => ({
        ...camera,
        x: newValue > TIMELINE_COLUMN_SIZE ? TIMELINE_COLUMN_SIZE : newValue,
      }));
    }
  };

  const dndXMousePosRef = useRef(0);
  const dndInitialOffsetRef = useRef(0);

  const onSausageDragStart = useCallback(
    (section: AppSection, story: AppStory, evt: KonvaEventObject<DragEvent>) => {
      dndXMousePosRef.current = evt.evt.clientX;
      dndInitialOffsetRef.current = story.offsetHours;
      updateStoryById(section, story, { isDragged: true });
    },
    [updateStoryById],
  );

  const onSausageDragEnd = useCallback(
    (section: AppSection, story: AppStory) => {
      updateStoryById(section, story, { isDragged: false });
      const newStartDate = getStoryStartDate(datePeriod.start, story.offsetHours, pxInHour);
      const data = {
        startDate: newStartDate,
        endDate: new Date(getStoryEndDate(story.hours, newStartDate.getTime())),
        offsetHours: story.offsetHours,
      };
      ganttPatchRequest(story.id, story.isSubtask, data);
    },
    [updateStoryById, datePeriod.start, pxInHour],
  );

  const onSausageDragMove = useCallback(
    (section: AppSection, story: AppStory, evt: KonvaEventObject<DragEvent>) => {
      const deltaInPx = evt.evt.clientX - dndXMousePosRef.current;
      const deltaInHours = deltaInPx / pxInHour;
      const newOffset = dndInitialOffsetRef.current + deltaInHours;
      updateStoryById(section, story, { offsetHours: newOffset });
    },
    [pxInHour, updateStoryById],
  );

  const onSausageClick = useCallback(
    (section: AppSection, story: AppStory) => {
      switch (linkAction) {
        case LinkActionsEnum.AddLinkOnTheRight: {
          if (!firstStory || !story || !linkAction) return;
          const updated = {
            isSubTask: true,
            nextTaskId: story.id,
          };
          updateStoryById(section, firstStory, { nextTaskDto: updated });
          ganttPatchRequest(firstStory.id, firstStory.isSubtask, {
            nextTaskDto: updated,
          });
          break;
        }
        case LinkActionsEnum.AddLinkOnTheLeft: {
          if (!firstStory || !story || !linkAction) return;
          const updated = {
            isSubTask: true,
            nextTaskId: firstStory.id,
          };
          updateStoryById(section, story, { nextTaskDto: updated });
          ganttPatchRequest(story.id, story.isSubtask, {
            nextTaskDto: updated,
          });
          break;
        }
        case LinkActionsEnum.RemoveLinkOnTheRight: {
          if (!firstStory || !linkAction) return;
          const updated = {
            isSubTask: false,
            nextTaskId: null,
          };
          updateStoryById(section, firstStory, { nextTaskDto: updated });
          ganttPatchRequest(firstStory.id, firstStory.isSubtask, {
            nextTaskDto: updated,
          });
          break;
        }
        case LinkActionsEnum.RemoveLinkOnTheLeft: {
          // if (!firstStory || !linkAction) return;
          // const updated = {
          //   isSubTask: false,
          //   nextTaskId: null,
          // };
          // updateStoryById(section, story, { nextTaskDto: updated });
          // ganttPatchRequest(story.id, story.isSubtask, {
          //   nextTaskDto: updated,
          // });
          break;
        }
        default:
          // eslint-disable-next-line no-console
          console.error('Действие не выбрано');
      }
    },
    [firstStory, linkAction, updateStoryById],
  );

  const { onContextMenu, isOpen: isContextMenuOpen, position } = useContextMenu();

  const onStoryContextMenu = useCallback(
    (section: AppSection, story: AppStory, evt: KonvaEventObject<MouseEvent>) => {
      evt.evt.preventDefault();
      onContextMenu((evt.evt as never) as React.MouseEvent<Element, MouseEvent>);
      setIsWaiting(true);
      setFirstStory(story);
    },
    [onContextMenu],
  );

  const onAddRightTieClick = useCallback(() => {
    setLinkAction(LinkActionsEnum.AddLinkOnTheRight);
  }, []);

  const onRemoveRightTieClick = useCallback(() => {
    setLinkAction(LinkActionsEnum.RemoveLinkOnTheRight);
    setIsWaiting(false);
  }, []);

  const onAddLeftTieClick = useCallback(() => {
    setLinkAction(LinkActionsEnum.AddLinkOnTheLeft);
  }, []);

  const onRemoveLeftTieClick = useCallback(() => {
    setLinkAction(LinkActionsEnum.RemoveLinkOnTheLeft);
    setIsWaiting(false);
    // console.log('Click!! 1st story: ', firstStory);
    if (!firstStory) return;
    const { sect, leftStory } = getLeftStory(modelWithSizes, firstStory);
    // console.log('Click!! leftStory: ', leftStory);
    // console.log('Click!! linkAction: ', linkAction);
    if (!sect || !leftStory || !linkAction) return;
    const updated = {
      isSubTask: false,
      nextTaskId: null,
    };
    updateStoryById(sect, leftStory, { nextTaskDto: updated });
    ganttPatchRequest(leftStory.id, leftStory.isSubtask, {
      nextTaskDto: updated,
    });
  }, [modelWithSizes, firstStory, linkAction, updateStoryById]);

  return (
    <DefaultPageLayout headerSlot={null}>
      <TopToolbar title="Gantt" />
      <DefaultContentLayout
        leftMenuSlot={
          <LeftPanel
            modelWithSizes={modelWithSizes}
            setModel={setModel as SetModel}
            storyId={storyId}
            setStoryId={setStoryId as SetStoryId}
            appCamera={appCamera}
          />
        }
        canvasSlot={
          <RightCanvas
            datePeriod={datePeriod}
            modelWithSizes={modelWithSizes}
            storyId={storyId}
            setStoryId={setStoryId as SetStoryId}
            isWaiting={isWaiting}
            setIsWaiting={setIsWaiting as SetIsWaiting}
            appCamera={appCamera}
            onWheel={onWheel}
            onSausageDragStart={onSausageDragStart}
            onSausageDragMove={onSausageDragMove}
            onSausageDragEnd={onSausageDragEnd}
            onStoryContextMenu={onStoryContextMenu}
            onSausageClick={onSausageClick}
          />
        }
      />
      {isContextMenuOpen && (
        <ContextMenu x={position.x} y={position.y}>
          <StyledContextMenuItem onClick={onAddRightTieClick}>
            Добавить связь справа
          </StyledContextMenuItem>
          <StyledContextMenuItem onClick={onRemoveRightTieClick}>
            Удалить связь справа
          </StyledContextMenuItem>
          <StyledContextMenuItem onClick={onAddLeftTieClick}>
            Добавить связь слева
          </StyledContextMenuItem>
          <StyledContextMenuItem onClick={onRemoveLeftTieClick}>
            Удалить связь слева
          </StyledContextMenuItem>
        </ContextMenu>
      )}
    </DefaultPageLayout>
  );
};

export default MainPage;
