import styled from '@emotion/styled';
import { SerializedStyles } from '@emotion/react';

import Button from 'components/Button';

type Props = {
  styleModal?: SerializedStyles;
};

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  background: rgba(28, 33, 47, 0.5);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > span {
    :hover {
      cursor: pointer;
    }
  }
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 20px;
`;

export const ModalContainer = styled.div<Props>`
  position: relative;
  overflow-y: auto;
  min-width: 400px;
  max-height: calc(100vh - 50px);
  background-color: ${({ theme }) => theme.colors.white[400]};
  border-radius: 4px;
  padding: 20px 25px;
  ${({ styleModal }) => styleModal};
`;

export const MainContainer = styled.div`
  margin: 40px 0 34px 0;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const CustomButton = styled(Button)`
  margin-right: 16px;
`;
