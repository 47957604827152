import { FC, useContext, DragEvent, useState } from 'react';
import styled from '@emotion/styled';
import { TaskContainer, Tooltip, TaskTitle, ButtonSubtask } from './Task.styled';
import { BoardType, TaskType } from '../types';
import SubTask from '../SubTask';
import Context, { DetailsContext } from '../context';
import Icon from '../../Icon';
import { showDetails } from '../../../pages/EstimationDetailPage/KanbanBoardPage/utils';
import sumHoursSubtasks from './utils';

type Props = {
  task: TaskType;
  board: BoardType;
};

const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.blue[50]};
  margin-left: 8px;
`;

const Task: FC<Props> = ({ task, board }) => {
  const [isOpenSubTasks, setIsOpenSubTask] = useState(false);
  const { currentValues } = useContext(Context);
  const { details, setDetails } = useContext(DetailsContext);
  const { title, substories, id, hours } = task;

  const onToggleSubTasks = () => {
    setIsOpenSubTask((prevState) => !prevState);
  };

  const onDragStart = (event: DragEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;

    currentValues.current = {
      currentBoard: board,
      currentTask: task,
      currentType: 'task',
    };

    setTimeout(() => {
      target.style.visibility = 'hidden';
    }, 0);
  };

  const onDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const onDragEnd = (event: DragEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    target.style.visibility = 'visible';
  };

  const showTaskDetails = () => {
    if (substories.length === 0) {
      showDetails('taskItem', id, details, task, setDetails);
    }
  };

  return (
    <div draggable onDragStart={onDragStart} onDragEnd={onDragEnd} onDragOver={onDragOver}>
      <TaskContainer onClick={showTaskDetails}>
        {substories.length > 0 && (
          <ButtonSubtask onClick={onToggleSubTasks}>
            {isOpenSubTasks ? <Icon name="angleDown" /> : <Icon name="angleUp" />}
          </ButtonSubtask>
        )}
        {title?.length > 20 && <Tooltip>{title}</Tooltip>}
        <TaskTitle>{title}</TaskTitle>
        {!substories.length ? <span>{hours} h</span> : sumHoursSubtasks(substories)}
      </TaskContainer>
      <Container>
        {!isOpenSubTasks &&
          substories?.map((subtask) => (
            <SubTask key={subtask.id} board={board} subTask={subtask} task={task} />
          ))}
      </Container>
    </div>
  );
};

export default Task;
