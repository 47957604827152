import { NewEmployee, EditEmployee } from './types/Employee';
import { protectedDelete, protectedPost, protectedPut } from './protectedAxios';

const EMPLOYEE_URL = '/api/employee';

const getEmployeeUrl = (id: string) => `${EMPLOYEE_URL}/${id}`;

export const deleteEmployee = (id: string): Promise<void> => protectedDelete(getEmployeeUrl(id));

export const createEmployee = (data: NewEmployee): Promise<NewEmployee> =>
  protectedPost(EMPLOYEE_URL, data);

export const updateEmployee = (data: EditEmployee): Promise<EditEmployee> =>
  protectedPut(EMPLOYEE_URL, data);
