import { FC, ChangeEvent, useRef } from 'react';
import styled from '@emotion/styled';
import { SerializedStyles } from '@emotion/react';

import Input from 'components/Input';
import { ListItem } from 'components/Select/v2';
import Menu, { Popover, MenuItem } from 'components/DropDownMenu';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export type Props = {
  label?: string;
  placeholder?: string;
  helpText?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  isError?: boolean;
  errorMessage?: string;
  inputStyles?: SerializedStyles;
  mb?: number;
  value: string;
  list: ListItem[];
  index: number;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  setValue: (
    name: string,
    value: string,
    config?: Partial<{ shouldValidate: boolean; shouldDirty: boolean }> | undefined,
  ) => void;
};

const InputWithPrompt: FC<Props> = ({
  label,
  helpText,
  isRequired = false,
  isDisabled,
  isError = false,
  errorMessage,
  inputStyles,
  mb = 0,
  list,
  value,
  index,
  onChange,
  setValue,
}) => {
  const inputContainerRef = useRef<HTMLDivElement>(null);

  const handleMenuItemClick = (newValue: string | undefined) => {
    if (typeof newValue === 'undefined') {
      return;
    }

    const item = list.find((element) => element.label === newValue);

    if (!item) {
      return;
    }

    setValue(`performers[${index}].position.name`, newValue);
  };

  const filteredList = list.filter((item) =>
    item.label.toLowerCase().includes(value.toLowerCase()),
  );

  return (
    <Container>
      <div ref={inputContainerRef}>
        <Input
          value={value}
          label={label}
          helpText={helpText}
          isRequired={isRequired}
          isDisabled={isDisabled}
          isError={isError}
          errorMessage={errorMessage}
          inputStyles={inputStyles}
          placeholder="Technology"
          mb={mb}
          onChange={onChange}
        />
      </div>
      <Popover
        trigger={inputContainerRef}
        triggerOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        popoutOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <Menu>
          {value &&
            filteredList.map(
              ({ label: itemLabel, value: itemValue }) =>
                itemLabel !== value && (
                  <MenuItem key={itemValue} payload={itemLabel} onClick={handleMenuItemClick}>
                    {itemLabel}
                  </MenuItem>
                ),
            )}
        </Menu>
      </Popover>
    </Container>
  );
};

export default InputWithPrompt;
