import { SUBSTORY_HEIGHT_PX } from '../modules/main/consts';

export const senlaColors = {
  primaryColor: '#0044C6',
  primaryButtonColor: '#1D7DE8',
  dangerButtonColor: '#EB5757 ',
  sausages: {
    noRisks: '#ffffff',

    extraFunctionalityFromSenla: '#D3EADD',
    extraFunctionalityFromSenlaHover: '#219653',
    extraFunctionalityFromSenlaDraggedColor: '#582196',

    clientSideRisk: 'rgba(235,87,87,.2)',
    senlaRisk: 'rgba(242,201,76,.2)',
    senlaRiskHover: 'rgba(242,201,76,1)',
  },
};

const colors = {
  timeLine: {
    backgroundColor: '#ffffff',
    shadowColor: '#e3e3e3',
    horizontalLineColor: '#e3e3e3',
    selectionColor: '#2196f3',
    todayLineColor: '#e53935',
    weekendDayColor: '#ffd7d7',
  },
  sausage: {
    defaultBackgroundColor: '#87d65c',
    hoverBackgroundColor: '#89e755',
    defaultBorderRadius: 6,
    sausageTotalHeight: 10,
  },
  sausageTotal: {
    defaultBackgroundColor: '#ffbe1a',
    hoverBackgroundColor: '#ffd500',
    defaultBorderRadius: 6,
    height: 10,
    topOffset: 0,
  },
  header: {
    backgroundColor: senlaColors.primaryColor,
    heigth: 70,
  },
  rightCanvas: {
    backgroundColor: '#ffffff',
  },
  leftPanel: {
    input: {
      hoverBackgroundColor: 'transparent',
    },
  },
  stories: {
    hoverColor: 'rgba(0,0,0,0.03)',
  },
};

colors.sausageTotal.topOffset = SUBSTORY_HEIGHT_PX / 2 - colors.sausageTotal.height / 2;

export { colors };
