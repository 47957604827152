import { FC } from 'react';

import { Title, Container } from './CardContainer.styled';

type Props = {
  title: string;
};

const CardContainer: FC<Props> = ({ title, children }) => (
  <Container>
    <Title>{title}</Title>
    {children}
  </Container>
);

export default CardContainer;
