import { createContext, FC, useContext, useReducer, useEffect, useMemo, useCallback } from 'react';

import {
  handleAddNewCategory,
  handleAddNewLine,
  handleMoveUp,
  handleMoveDown,
  handleDeleteElement,
  handleSetRiskLevel,
} from './contextUtils';
import { normalizeData } from './utils';
import { Row, EstimationSectionType, RiskType, EstimationData } from './types';

type State = {
  data: Row[];
  technologiesList: string[];
  sendUpdatedData: boolean;
  contextMenuRow: null | Row;
  developmentSectionTimeSum: number;
};

const initialState = {
  data: [],
  technologiesList: [],
  sendUpdatedData: false,
  contextMenuRow: null,
  developmentSectionTimeSum: 0,
};

const SET_DATA = 'SET_DATA';
const UPDATE_ROW_DATA = 'UPDATE_ROW_DATA';
const SEND_UPDATED_DATA = 'SEND_UPDATED_DATA';
const SET_TECHNOLOGIES_LIST = 'SET_TECHNOLOGIES_LIST';
const SET_CONTEXT_MENU_ROW = 'SET_CONTEXT_MENU_ROW';
const ADD_NEW_CATEGORY = 'ADD_NEW_CATEGORY';
const ADD_NEW_LINE = 'ADD_NEW_LINE';
const MOVE_UP = 'MOVE_UP';
const MOVE_DOWN = 'MOVE_DOWN';
const DELETE_ELEMENT = 'DELETE_ELEMENT';
const SET_RISK_LEVEL = 'SET_RISK_LEVEL';

type SetData = {
  type: typeof SET_DATA;
  payload: Row[];
};

type UpdateRowDataPayload = {
  value: string | number;
  rowId: string;
  dataKey: string;
  type?: EstimationSectionType;
};

type SendUpdatedData = {
  type: typeof SEND_UPDATED_DATA;
  payload: boolean;
};

type UpdateRowData = {
  type: typeof UPDATE_ROW_DATA;
  payload: UpdateRowDataPayload;
};

type SetTechnologiesList = {
  type: typeof SET_TECHNOLOGIES_LIST;
  payload: string[];
};

type SetContextMenuRow = {
  type: typeof SET_CONTEXT_MENU_ROW;
  payload: Row | null;
};

type AddNewCategory = {
  type: typeof ADD_NEW_CATEGORY;
  payload: number;
};

type AddNewLine = {
  type: typeof ADD_NEW_LINE;
  payload: {
    currentSectionIndex: number;
    currentRowId: string;
  };
};

type MoveUpAndDownActionPayload = {
  isSectionRow: boolean;
  currentRowIndex: number | undefined;
  currentSectionId: string;
  currentSectionIndex: number;
};

type MoveUp = {
  type: typeof MOVE_UP;
  payload: MoveUpAndDownActionPayload;
};

type MoveDown = {
  type: typeof MOVE_DOWN;
  payload: MoveUpAndDownActionPayload;
};

type DeletePayload = {
  isSectionRow: boolean;
  currentRowId: string;
  currentRowIndex: number | undefined;
  currentSectionIndex: number;
};

type DeleteElement = {
  type: typeof DELETE_ELEMENT;
  payload: DeletePayload;
};

type RiskPayload = {
  rowId: string;
  riskLevel: RiskType;
};

type SetRiskLevel = {
  type: typeof SET_RISK_LEVEL;
  payload: RiskPayload;
};

type Action =
  | SetData
  | UpdateRowData
  | SendUpdatedData
  | SetTechnologiesList
  | SetContextMenuRow
  | AddNewCategory
  | AddNewLine
  | MoveUp
  | MoveDown
  | DeleteElement
  | SetRiskLevel;

const setData = (data: Row[]): SetData => ({
  type: SET_DATA,
  payload: data,
});

const updateRowData = (data: UpdateRowDataPayload): UpdateRowData => ({
  type: UPDATE_ROW_DATA,
  payload: data,
});

const sendUpdatedData = (data: boolean): SendUpdatedData => ({
  type: SEND_UPDATED_DATA,
  payload: data,
});

const setTechnologiesList = (data: string[]): SetTechnologiesList => ({
  type: SET_TECHNOLOGIES_LIST,
  payload: data,
});

const setContextMenuRow = (data: Row | null): SetContextMenuRow => ({
  type: SET_CONTEXT_MENU_ROW,
  payload: data,
});

const addNewCategory = (data: number): AddNewCategory => ({
  type: ADD_NEW_CATEGORY,
  payload: data,
});

const addNewLine = (data: { currentSectionIndex: number; currentRowId: string }): AddNewLine => ({
  type: ADD_NEW_LINE,
  payload: data,
});

const moveUp = (data: MoveUpAndDownActionPayload): MoveUp => ({
  type: MOVE_UP,
  payload: data,
});

const moveDown = (data: MoveUpAndDownActionPayload): MoveDown => ({
  type: MOVE_DOWN,
  payload: data,
});

const deleteElement = (data: DeletePayload): DeleteElement => ({
  type: DELETE_ELEMENT,
  payload: data,
});

const setRiskLevel = (data: RiskPayload): SetRiskLevel => ({
  type: SET_RISK_LEVEL,
  payload: data,
});

type ValueActions = {
  updateRowData: (data: UpdateRowDataPayload) => void;
  setContextMenuRow: (data: null | Row) => void;
  addNewCategory: (data: number) => void;
  addNewLine: (data: { currentSectionIndex: number; currentRowId: string }) => void;
  moveUp: (data: MoveUpAndDownActionPayload) => void;
  moveDown: (data: MoveUpAndDownActionPayload) => void;
  deleteElement: (data: DeletePayload) => void;
  setRiskLevel: (data: RiskPayload) => void;
  onSendData: () => void;
};

const TableContext = createContext<{
  state: State;
  actions: ValueActions;
}>({
  state: initialState,
  actions: {
    updateRowData: () => undefined,
    setContextMenuRow: () => undefined,
    addNewCategory: () => undefined,
    addNewLine: () => undefined,
    moveUp: () => undefined,
    moveDown: () => undefined,
    deleteElement: () => undefined,
    setRiskLevel: () => undefined,
    onSendData: () => undefined,
  },
});

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case SET_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case SET_TECHNOLOGIES_LIST:
      return {
        ...state,
        technologiesList: action.payload,
      };
    case SEND_UPDATED_DATA:
      return {
        ...state,
        sendUpdatedData: action.payload,
      };
    case UPDATE_ROW_DATA: {
      const newData = state.data.map((row) => {
        if (row.rowId === action.payload.rowId) {
          return {
            ...row,
            [action.payload.dataKey]: action.payload.value,
          };
        }

        return row;
      });

      return {
        ...state,
        data: newData,
      };
    }
    case SET_CONTEXT_MENU_ROW:
      return {
        ...state,
        contextMenuRow: action.payload,
      };
    case ADD_NEW_CATEGORY: {
      const result = handleAddNewCategory({
        currentSectionIndex: action.payload,
        data: state.data,
        technologiesList: state.technologiesList,
      });

      return { ...state, data: result, sendUpdatedData: true };
    }
    case ADD_NEW_LINE: {
      const result = handleAddNewLine({
        ...action.payload,
        data: state.data,
        technologiesList: state.technologiesList,
      });

      return { ...state, data: result, sendUpdatedData: true };
    }
    case MOVE_UP: {
      const result = handleMoveUp({ ...action.payload, data: state.data });

      return { ...state, data: result, sendUpdatedData: true };
    }
    case MOVE_DOWN: {
      const result = handleMoveDown({ ...action.payload, data: state.data });

      return { ...state, data: result, sendUpdatedData: true };
    }
    case DELETE_ELEMENT: {
      const result = handleDeleteElement({ ...action.payload, data: state.data });

      return { ...state, data: result, sendUpdatedData: true };
    }
    case SET_RISK_LEVEL: {
      const result = handleSetRiskLevel({ ...action.payload, data: state.data });

      return { ...state, data: result, sendUpdatedData: true };
    }
    default:
      return state;
  }
};

type Props = {
  data: Row[];
  technologiesList: string[];
  onChange: (value: EstimationData) => void;
  changeDirectionData?: (timeSum: number) => void;
};

const TableContextProvider: FC<Props> = ({
  children,
  data,
  technologiesList,
  onChange,
  changeDirectionData,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch(setData(data));
  }, [data]);

  useEffect(() => {
    dispatch(setTechnologiesList(technologiesList));
  }, [technologiesList]);

  useEffect(() => {
    if (state.sendUpdatedData) {
      onChange(normalizeData(state.data, technologiesList));
      dispatch(sendUpdatedData(false));
    }
  }, [onChange, state.data, state.sendUpdatedData, technologiesList]);

  const actions = useMemo(
    () => ({
      updateRowData: (value: UpdateRowDataPayload) => dispatch(updateRowData(value)),
      setContextMenuRow: (value: Row | null) => dispatch(setContextMenuRow(value)),
      addNewCategory: (value: number) => dispatch(addNewCategory(value)),
      addNewLine: (value: { currentSectionIndex: number; currentRowId: string }) =>
        dispatch(addNewLine(value)),
      moveUp: (value: MoveUpAndDownActionPayload) => dispatch(moveUp(value)),
      moveDown: (value: MoveUpAndDownActionPayload) => dispatch(moveDown(value)),
      deleteElement: (value: DeletePayload) => dispatch(deleteElement(value)),
      setRiskLevel: (value: RiskPayload) => dispatch(setRiskLevel(value)),
    }),
    [],
  );

  const handleSendData = useCallback(() => {
    onChange(normalizeData(state.data, technologiesList));
  }, [onChange, state.data, technologiesList]);

  const developmentSectionTimeSum = useMemo(
    () =>
      state.data
        .filter((row) => row.type === 'DEVELOPMENT')
        .reduce((total, row) => {
          const rowTotal = technologiesList
            .map((technology) => row[technology])
            .reduce(
              (rowSum: number, currentValue) =>
                currentValue ? rowSum + Number(currentValue) : rowSum,
              0,
            );

          return rowTotal + total;
        }, 0),
    [state.data, technologiesList],
  );

  const value = useMemo(
    () => ({
      state: { ...state, developmentSectionTimeSum },
      actions: {
        ...actions,
        onSendData: handleSendData,
      },
    }),
    [state, actions, developmentSectionTimeSum, handleSendData],
  );

  useEffect(() => {
    if (changeDirectionData) {
      changeDirectionData(developmentSectionTimeSum);
    }
  }, [developmentSectionTimeSum, changeDirectionData]);

  return <TableContext.Provider value={value}>{children}</TableContext.Provider>;
};

const useTableContext = () => useContext(TableContext);
const useActions = (): ValueActions => useTableContext().actions;
const useTableData = (): Row[] => useTableContext().state.data;
const useTechnologiesList = (): string[] => useTableContext().state.technologiesList;
const useContextMenuRow = (): Row | null => useTableContext().state.contextMenuRow;
const useDevelopmentSectionTimeSum = (): number =>
  useTableContext().state.developmentSectionTimeSum;

export default TableContextProvider;

export {
  useActions,
  useTableData,
  useTechnologiesList,
  useContextMenuRow,
  useDevelopmentSectionTimeSum,
};
