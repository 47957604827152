import { protectedGet, protectedDelete, protectedPut, protectedPost } from './protectedAxios';

import {
  EstimationData,
  NewEstimationData,
  OldEstimationData,
  UpdatedEstimationData,
} from './types/EstimationData';

const ESTIMATION_URL = '/api/estimation';
const getEstimationUrl = (id: string) => `${ESTIMATION_URL}/${id}`;
const getUpdateStatusUrl = () => `${ESTIMATION_URL}/status`;
const copyEstimationUrl = (id: string) => `${ESTIMATION_URL}/${id}`;

export const getEstimation = (id: string): Promise<EstimationData> =>
  protectedGet(getEstimationUrl(id));

export const createEstimation = (data: NewEstimationData): Promise<void> =>
  protectedPost(ESTIMATION_URL, data);

export const deleteEstimation = (id: string): Promise<void> =>
  protectedDelete(getEstimationUrl(id));

export const updateStatus = (data: { id: number; status: string }): Promise<void> =>
  protectedPut(getUpdateStatusUrl(), data);

export const updateEstimation = (data: UpdatedEstimationData): Promise<void> =>
  protectedPut(ESTIMATION_URL, data);

export const copyEstimation = (id: string, data: OldEstimationData): Promise<void> =>
  protectedPost(copyEstimationUrl(id), data);
