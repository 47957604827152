import React from 'react';
import { KonvaEventObject } from 'konva/types/Node';
import AppStory from '../../models/AppStory';
import { MainModel } from '../../models/MainModel';
import AppSection from '../../models/AppSection';
import AppSubstory from '../../models/AppSubstory';

export enum DateScaleEnum {
  Days = 'Days',
  Weeks = 'Weeks',
  Months = 'Months',
  Years = 'Years',
}

export interface AppStoryWithSizes extends AppStory {
  heightInPx: number;
}

export interface AdaptedStory extends AppStory {
  offsetBusinessHours: number;
}

export interface AdaptedSection extends AppSection {
  stories: AdaptedStory[];
  heightInPx: number;
}

export interface DatePeriod {
  start: Date;
  end: Date;
}

export interface MainModelWithSizes {
  sections: AdaptedSection[];
  startDate: Date;
}

export interface Sizes {
  x: number;
  y: number;
  groupX: number;
  groupY: number;
  width: number;
}

export interface WidthAndOffset {
  offsetX: number;
  width: number;
}

export type SetModel = React.Dispatch<React.SetStateAction<MainModel>>;
export type SetSubstoryId = React.Dispatch<React.SetStateAction<number | null>>;
export type SetStoryId = React.Dispatch<React.SetStateAction<number | null>>;
export type SetIsWaiting = React.Dispatch<React.SetStateAction<boolean>>;

export type OnSubstoryContextMenu = (
  story: AppStory,
  substory: AppSubstory,
  evt: KonvaEventObject<MouseEvent>,
) => void;

export type OnStoryContextMenu = (
  section: AppSection,
  story: AppStory,
  evt: KonvaEventObject<MouseEvent>,
) => void;

export enum LinkActionsEnum {
  AddLinkOnTheRight = 'AddLinkOnTheRight',
  AddLinkOnTheLeft = 'AddLinkOnTheLeft',
  RemoveLinkOnTheRight = 'RemoveLinkOnTheRight',
  RemoveLinkOnTheLeft = 'RemoveLinkOnTheLeft',
}
