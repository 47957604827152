import { FC, ReactNode } from 'react';

import Paginator from 'components/Paginator';
import EmptyStub from 'components/EmptyStub';
import CardGrid from 'layouts/CardGrid';
import PaginatorBox from './PaginatedGrid.styled';

export type Props = {
  totalItem?: number;
  children?: ReactNode;
  gridSize?: number;
  select?: number;
  pageNeighbors?: number;
  textPlaceholder?: string;
  onChangePage?: (page: number) => void;
};

const getItemsCount = (items: ReactNode) => {
  if (Array.isArray(items)) {
    return items.length;
  }

  return Number(!!items);
};

const PaginatedGrid: FC<Props> = ({
  children,
  totalItem,
  gridSize = 12,
  textPlaceholder = 'Empty',
  select,
  onChangePage,
  pageNeighbors,
}) => {
  const childrenCount = getItemsCount(children);
  const isNotEmpty = !!childrenCount;
  const totalRecord = Math.ceil((totalItem || childrenCount) / Math.max(gridSize, 1));

  return (
    <div>
      {isNotEmpty ? <CardGrid>{children}</CardGrid> : <EmptyStub title={textPlaceholder} />}
      {totalRecord > 1 && (
        <PaginatorBox>
          <Paginator
            totalRecords={totalRecord}
            onClick={onChangePage}
            select={select}
            pageNeighbors={pageNeighbors}
          />
        </PaginatorBox>
      )}
    </div>
  );
};

export default PaginatedGrid;
