import { FC } from 'react';
import { Item } from './Tabs.styled';

type Props = {
  children: string;
  id: number;
  isActive: boolean;
  onClick: (id: number) => void;
};

const TabItem: FC<Props> = ({ children, id, onClick, isActive }) => {
  const clickHandle = () => {
    onClick(id);
  };

  return (
    <Item onClick={clickHandle} isActive={isActive}>
      {children}
    </Item>
  );
};

export default TabItem;
