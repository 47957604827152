import { FC, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as Logo } from 'assets/images/Logo-white.svg';
import noImage from 'assets/images/noimage.png';

import {
  HeaderContainer,
  FlexContainer,
  HeaderMenu,
  HeaderMenuTitle,
  HeaderPersonImage,
  PersonText,
  PersonContainer,
  CardPersonText,
  PersonLogOut,
} from './Header.styled';

type Props = {
  userName: string | undefined;
  ldapName: string | undefined;
  userRole: string | undefined;
  image?: string;
  onLogoutClick: () => void;
};

const Header: FC<Props> = ({ userName, ldapName, userRole, image, onLogoutClick }) => {
  const [isPersonInfoOpen, setIsPersonInfoOpen] = useState(false);
  const personCardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (personCardRef.current && !personCardRef.current.contains(event.target as Node)) {
        setIsPersonInfoOpen(false);
      }
    }

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleTogglePersonInfo = () => {
    setIsPersonInfoOpen((prevState) => !prevState);
  };

  const handleLogout = () => {
    setIsPersonInfoOpen(false);
    onLogoutClick();
  };

  return (
    <HeaderContainer>
      <FlexContainer>
        <Link to="/main/project-list">
          <Logo />
        </Link>
        <HeaderMenu>
          <HeaderMenuTitle to="/main/project-list">Projects</HeaderMenuTitle>
          <HeaderMenuTitle to="/main/client-list">Clients</HeaderMenuTitle>
          {userRole === 'SUPER' && <HeaderMenuTitle to="/admin/users-list">Admin</HeaderMenuTitle>}
        </HeaderMenu>
      </FlexContainer>
      <FlexContainer ref={personCardRef}>
        <PersonText onClick={handleTogglePersonInfo}>{userName}</PersonText>
        <HeaderPersonImage src={image || noImage} />
        {isPersonInfoOpen && (
          <PersonContainer>
            <FlexContainer withPersonPadding>
              <HeaderPersonImage cardImage src={image || noImage} />
              <div>
                <CardPersonText>{userName}</CardPersonText>
                {ldapName && <CardPersonText cardPersonEmail>{ldapName}@senla.eu</CardPersonText>}
              </div>
            </FlexContainer>
            <PersonLogOut onClick={handleLogout}>Log Out</PersonLogOut>
          </PersonContainer>
        )}
      </FlexContainer>
    </HeaderContainer>
  );
};

export default Header;
