import styled from '@emotion/styled';

export const Container = styled.div`
  position: static;
  padding-bottom: 60px;
`;

export const Table = styled.table`
  box-sizing: border-box;
  width: 100%;
  font-size: ${({ theme }) => theme.fontSizes.standard};
  table-layout: fixed;
  white-space: pre-wrap;
  word-wrap: break-word;
  border-collapse: separate;
  border-spacing: 0;
  position: relative;
`;
