import styled from '@emotion/styled';

export const Table = styled.table`
  box-sizing: border-box;
  width: 100%;
  font-size: ${({ theme }) => theme.fontSizes.standard};
  border-collapse: separate;
  border-spacing: 0;
  position: relative;
  padding-bottom: 60px;
`;

export const HeaderRow = styled.tr`
  color: ${({ theme }) => theme.colors.black[400]};
  text-align: left;
  vertical-align: middle;
`;

export const HeaderCell = styled.th`
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
  padding: 12px 11px;

  &:first-of-type {
    padding-left: 65px;
    border: 1px solid;
    border-color: ${({ theme }) => theme.colors.gray[200]};
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
`;

export const BodyRow = styled.tr`
  color: ${({ theme }) => theme.colors.gray[550]};
  line-height: 20px;

  &:last-of-type {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    td {
      border-bottom: 1px solid;
      border-color: ${({ theme }) => theme.colors.gray[200]};
    }

    td:first-of-type {
      border-bottom-left-radius: 5px;
    }
    td:last-of-type {
      border-bottom-right-radius: 5px;
    }
  }

  &.risks-table {
    font-weight: 700;

    td:last-of-type {
      font-weight: 400;
    }
  }

  &.no-risks {
    color: ${({ theme }) => theme.colors.black[400]};
  }

  &.extra-risks {
    background-color: ${({ theme }) => theme.colors.green[200]};
    color: ${({ theme }) => theme.colors.green[400]};

    td:last-of-type {
      color: ${({ theme }) => theme.colors.gray[550]};
    }
  }

  &.senla-risks {
    background-color: ${({ theme }) => theme.colors.yellow[200]};
    color: ${({ theme }) => theme.colors.yellow[400]};

    td:last-of-type {
      color: ${({ theme }) => theme.colors.gray[550]};
    }
  }

  &.client-risks {
    background-color: ${({ theme }) => theme.colors.red[200]};
    color: ${({ theme }) => theme.colors.red[400]};

    td:last-of-type {
      color: ${({ theme }) => theme.colors.gray[550]};
    }
  }
`;

export const BodyCell = styled.td<{ width?: number }>`
  padding: 12px 11px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[300]};
  border-left: 1px solid ${({ theme }) => theme.colors.gray[200]};
  width: ${({ width }) => (width ? `${width}px` : '100%')};

  &:first-of-type {
    padding-left: 65px;
  }

  &:last-of-type {
    border-right: 1px solid ${({ theme }) => theme.colors.gray[200]};
  }
`;
