import styled from '@emotion/styled';

import loginBg from 'assets/images/bg.png';

export const Container = styled.section`
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-image: url(${loginBg});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
`;

export const Form = styled.form`
  border-radius: 4px;
  box-shadow: 0 5px 15px rgb(19 115 236 / 25%);
  padding: 72px 48px;
  width: 416px;
  background-color: ${({ theme }) => theme.colors.white[400]};
`;

export const IconContainer = styled.div`
  width: 142px;
  margin: 0 auto 48px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 48px;
`;

export const TooltipContainer = styled.div`
  position: relative;
`;

export const TooltipLabel = styled.span`
  color: ${({ theme }) => theme.colors.blue[700]};
  font-weight: 700;
  cursor: pointer;
`;

export const Tooltip = styled.div`
  position: absolute;
  padding: 16px 24px;
  line-height: 1.5;
  background-color: ${({ theme }) => theme.colors.white[400]};
  color: ${({ theme }) => theme.colors.black[100]};
  border: 1px solid ${({ theme }) => theme.colors.gray[750]};
  border-radius: 4px;
  margin: 10px 0 0;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 30%);
  width: 460px;

  &::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 7px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid ${({ theme }) => theme.colors.gray[750]};
  }
  &::after {
    content: '';
    position: absolute;
    top: -8px;
    left: 9px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid ${({ theme }) => theme.colors.white[400]};
  }

  &.tooltip-enter {
    opacity: 0;
    transform: translateY(10px);
  }

  &.tooltip-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms, transform 300ms;
  }

  &.tooltip-exit {
    opacity: 1;
    transform: translateY(0);
  }

  &.tooltip-exit-active {
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 400ms, transform 400ms;
  }
`;

export const Button = styled.button`
  border-radius: 100px;
  transition: all ease 0.3s;
  display: inline-block;
  color: ${({ theme }) => theme.colors.white[400]};
  background-color: ${({ theme }) => theme.colors.blue[300]};
  text-transform: uppercase;
  text-align: center;
  min-width: 114px;
  padding: 10px 12px;
  cursor: pointer;
  line-height: 24px;
  border: none;
  width: 100%;

  &:hover {
    box-shadow: 0 4px 8px rgb(19 115 236 / 25%);
    background: ${({ theme }) => theme.colors.blue[600]};
  }

  &:disabled {
    box-shadow: none;
    background-color: ${({ theme }) => theme.colors.gray[250]};
    cursor: not-allowed;
    color: ${({ theme }) => theme.colors.gray[900]};
  }
`;

export const SubmitError = styled.div`
  border-radius: 4px;
  display: flex;
  color: ${({ theme }) => theme.colors.red[400]};
  background-color: ${({ theme }) => theme.colors.red[100]};
  align-items: center;
  padding: 12px 12px 12px 24px;
  margin-bottom: 24px;
  font-weight: 700;
`;
