import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { css } from '@emotion/react';

import Modal from 'components/Modal';
import { createIteration } from 'services/iteration';
import { ProjectIteration } from 'services/types/Project';
import { getProjectIterations } from 'services/project';
import IterationItem from './IterationItem';
import IterationForm from './IterationForm';

type Props = {
  isNewIteration: boolean;
  onToggleModal: () => void;
};

const stylesModal = css`
  width: 560px;
`;

const defaultValue = {
  description: '',
  name: '',
  performers: [
    {
      id: -1,
      name: '',
      position: {
        id: -1,
        name: '',
      },
    },
  ],
  status: 'INITIAL',
  version: '',
};

const IterationsList: FC<Props> = ({ isNewIteration, onToggleModal }) => {
  const { id } = useParams<{ id: string }>();
  const [iterations, setIterations] = useState<ProjectIteration[]>([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const { control, errors, handleSubmit, setValue } = useForm({
    defaultValues: defaultValue,
    shouldUnregister: false,
  });

  const updateData = () => {
    setIsUpdate((prevState) => !prevState);
  };

  useEffect(() => {
    if (isUpdate) {
      getProjectIterations(id).then((data) => setIterations(data));
    }
    getProjectIterations(id).then((data) => setIterations(data));
  }, [id, isUpdate]);

  const onSubmit = (data: ProjectIteration) => {
    const dataToSend = {
      ...data,
      projectId: id,
      performers: data.performers.map((performer) => ({
        id: performer.id,
        name: performer.name,
        position: {
          id: performer.position.id,
          name: performer.position.name,
        },
      })),
    };
    createIteration(dataToSend).then(() => {
      onToggleModal();
      updateData();
    });
  };

  const handleSubmitEmployee = handleSubmit(onSubmit);

  return (
    <>
      {iterations.map((iteration) => (
        <IterationItem
          key={iteration.id}
          iteration={iteration}
          isUpdate={isUpdate}
          onUpdateData={updateData}
        />
      ))}
      {isNewIteration && (
        <Modal
          title="Add Iteration"
          textCancel="Cancel"
          textConfirm="Save"
          styleTypeConfirm="primary"
          styleModal={stylesModal}
          text={
            <IterationForm
              control={control}
              item={defaultValue}
              errors={errors}
              onSubmit={handleSubmitEmployee}
              setValue={setValue}
            />
          }
          isOpen={isNewIteration}
          onCancel={onToggleModal}
          onClose={onToggleModal}
          onConfirm={handleSubmitEmployee}
        />
      )}
    </>
  );
};
export default IterationsList;
