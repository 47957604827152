import styled from '@emotion/styled';

export const BoardWrap = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 15%;
  width: 25%;
  margin-right: 3vh;
  overflow: hidden;
  height: auto;

  &:last-child {
    margin-right: 0;
  }
`;

export const BoardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 400px;
  height: 100%;
`;
