import { FC } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { ListItem } from 'components/Select/v2';
import Item from './ListItem';
import { IconContainerMode } from '../IconContainer';

type Props = {
  list: ListItem[];
};

const EmployeesList: FC<Props> = ({ list }) => {
  const { control } = useFormContext();
  const { fields, remove, append } = useFieldArray({
    control,
    name: 'employees',
    keyName: 'uuid',
  });

  const addItemHandle = () => {
    append({ value: -1 });
  };

  const removeItemHandle = (index: number) => {
    remove(index);
  };

  const getProps = (
    isLast: boolean,
  ): { mode: IconContainerMode; onClick: (index: number) => void } =>
    isLast
      ? {
          mode: 'add',
          onClick: addItemHandle,
        }
      : {
          mode: 'remove',
          onClick: removeItemHandle,
        };

  return (
    <>
      {fields.map(({ uuid, value }, index) => {
        const newList =
          fields.length > 0
            ? list.filter(
                (elem) =>
                  fields.every((field) => field.value !== elem.value) || elem.value === value,
              )
            : list;

        return (
          <Item
            itemList={newList}
            index={index}
            key={uuid}
            {...getProps(index >= fields.length - 1)}
          />
        );
      })}
    </>
  );
};

export default EmployeesList;
