import styled from '@emotion/styled';
import { SerializedStyles } from '@emotion/react';

const Container = styled.div<{ inputStyles?: SerializedStyles; mb: number }>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ mb }) => `${mb}px`};
  ${({ inputStyles }) => inputStyles};
`;

export const Box = styled.label<{ isError: boolean }>`
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  color: ${({ isError, theme }) => (isError ? theme.colors.red[400] : theme.colors.gray[550])};
`;

const LabelContainer = styled.p`
  margin-bottom: 4px;
  display: flex;
  justify-content: space-between;
`;

const RequiredMark = styled.span`
  color: ${({ theme }) => theme.colors.red[400]};
  margin-left: 3px;
`;

const HelpText = styled.span`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray[500]};
`;

type Props = {
  isError: boolean;
};

const InputStyled = styled.input<Props>`
  border: 1px solid
    ${({ isError, theme }) => (isError ? theme.colors.red[400] : theme.colors.gray[200])};
  border-radius: 4px;
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  padding: 9px 10px 9px 15px;
  line-height: 24px;
  background-color: ${({ theme }) => theme.colors.white[400]};
  color: ${({ theme }) => theme.colors.black[400]};
  font-size: 14px;
  font-weight: 300;
  transition: border-color 0.3s ease;

  &:disabled {
    background-color: ${({ theme }) => theme.colors.gray[400]};
    color: ${({ theme }) => theme.colors.gray[900]};
    cursor: default;
  }

  &:hover,
  &:focus {
    border-color: ${({ isError, theme }) =>
      isError ? theme.colors.red[400] : theme.colors.blue[700]};
  }
  &:disabled:hover,
  &:disabled:focus {
    border-color: ${({ theme }) => theme.colors.gray[200]};
  }
`;

const ErrorContainer = styled.div`
  position: relative;
`;

const ErrorMessage = styled.span`
  position: absolute;
  color: ${({ theme }) => theme.colors.red[400]};
  font-size: 12px;
`;

export {
  InputStyled,
  Container,
  RequiredMark,
  ErrorMessage,
  ErrorContainer,
  LabelContainer,
  HelpText,
};
