import styled from '@emotion/styled';

export default styled.div`
  margin-left: 10px;
  min-width: 32px;
  width: 32px;
  height: 32px;
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.gray[200]};
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.gray[250]};
    box-shadow: 0 4px 8px rgb(0 0 0 / 5%);
  }
  &:active {
    color: ${({ theme }) => theme.colors.blue[300]};
    background-color: ${({ theme }) => theme.colors.gray[250]};
    box-shadow: none;
  }
`;
