import { FC } from 'react';

import { StyledHeaderRow, StyledHeaderCell } from './styled/Header.styled';
import { Column, Row } from './types';

type Props = {
  columns: Column<Row>[];
  offset?: number;
};

const TableHeader: FC<Props> = ({ columns, offset = 0 }) => (
  <thead>
    <StyledHeaderRow>
      {columns.map((column) => (
        <StyledHeaderCell key={column.columnId} offset={offset}>
          {column.title}
        </StyledHeaderCell>
      ))}
    </StyledHeaderRow>
  </thead>
);

export default TableHeader;
