import { FC, ReactNode } from 'react';
import Item, { Props as ItemProps } from './components/MenuItem';
import PopoverWrapper, { Props as PopoverWrapperProps } from './components/Popover';
import SubMenuWrapper, { Props as SubMenuPropsWrapper } from './components/SubMenu';
import Box from './DropDownMenu.styled';

export const MenuItem = Item;
export const Popover = PopoverWrapper;
export const SubMenu = SubMenuWrapper;

export type MenuItemProps = ItemProps;
export type PopoverProps = PopoverWrapperProps;
export type SubMenuProps = SubMenuPropsWrapper;

export type Props = {
  children: ReactNode;
};

const DropDownMenu: FC<Props> = ({ children }) => <Box>{children}</Box>;

export default DropDownMenu;
