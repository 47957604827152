import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Employee } from 'services/types/User';
import { getAllByNameLike } from 'services/user';
import { setEmployees } from 'reducers/lists';
import { useEmployees } from 'reducers/selectors';

const useLoadEmployees = (): Employee[] | null => {
  const list = useEmployees();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!list) {
      getAllByNameLike().then((result) => {
        dispatch(setEmployees(result));
      });
    }
  }, [list, dispatch]);

  return list;
};

export default useLoadEmployees;
