import { FC } from 'react';

import CommentField from './components/CommentField';
import { StyledBodyCell, RowNumber, CellBox, Separator } from './styled/Row.styled';
import { getIsCellReadOnly } from './utils';
import { Column, Row } from './types';
import { useTechnologiesList, useDevelopmentSectionTimeSum } from './context';

type Props = {
  data: Row;
  column: Column<Row>;
  columnIndex: number;
  isLastColumn: boolean;
  value: null | undefined | number | string | boolean;
  onChange: (newValue: string, dataKey: string) => void;
  onBlur: () => void;
  canEdit?: boolean;
};

const BodyCell: FC<Props> = ({
  value,
  column,
  data,
  columnIndex,
  isLastColumn,
  onChange,
  onBlur,
  canEdit,
}) => {
  let enchValue = value;
  const { type, sectionIndex, rowIndex = 0 } = data;
  const technologies = useTechnologiesList();
  const developmentSectionTimeSum = useDevelopmentSectionTimeSum();

  const handleChange = (newValue: string) => {
    onChange(newValue, column.dataKey);
  };

  const handleChangeComment = (newValue: string) => {
    onChange(newValue, 'comment');
  };

  const isEditableCell = getIsCellReadOnly(type, isLastColumn, columnIndex);

  if (isLastColumn && type === 'DEVELOPMENT') {
    const rowSum = technologies
      .map((technology) => data[technology])
      .reduce((total: number, nextValue) => {
        const convertedValue = Number(nextValue);
        if (typeof convertedValue === 'number' && !Number.isNaN(convertedValue)) {
          return total + Number(nextValue);
        }

        return total;
      }, 0);

    return <StyledBodyCell>{rowSum}</StyledBodyCell>;
  }

  if (type === 'MANAGEMENT' && Number(value) && !Number.isNaN(Number(value))) {
    enchValue = Math.round((Number(value) * developmentSectionTimeSum) / 100);
  }

  if (type === 'MANAGEMENT' && !value) {
    enchValue = 0;
  }

  return (
    <StyledBodyCell>
      <CellBox>
        {columnIndex === 0 && rowIndex >= 0 && (
          <>
            <RowNumber>{`${sectionIndex + 1}.${rowIndex + 1}`}</RowNumber>
            <Separator>/</Separator>
          </>
        )}
        <column.renderCell
          type={type}
          value={enchValue}
          readOnly={isEditableCell}
          onChange={handleChange}
          onBlur={onBlur}
          canEdit={canEdit}
        />
      </CellBox>
      {columnIndex === 0 && (
        <CommentField value={data.comment} onChange={handleChangeComment} onBlur={onBlur} />
      )}
    </StyledBodyCell>
  );
};

export default BodyCell;
