import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getTechnologies } from 'services/dictionary';
import Technology from 'services/types/Technology';
import { setTechnologies } from 'reducers/lists';
import { useTechnologies } from 'reducers/selectors';

const useLoadTechnologies = (): Technology[] | null => {
  const list = useTechnologies();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!list) {
      getTechnologies().then((result) => {
        dispatch(setTechnologies(result));
      });
    }
  }, [list, dispatch]);

  return list;
};

export default useLoadTechnologies;
