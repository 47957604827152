import cookie from 'js-cookie';

export const setCookie = (key: string, value: string): void => {
  if (window !== undefined) {
    cookie.set(key, value);
  }
};

export const removeCookie = (key: string): void => {
  if (window !== undefined) {
    cookie.remove(key);
  }
};

export const getCookie = (key: string): undefined | string => {
  if (window !== undefined) {
    return cookie.get(key);
  }

  return undefined;
};

export const isTokenExpired = (): boolean => {
  const currentTime = new Date().getTime();
  const jwtTokenExpDate = getCookie('jwtTokenExpDate');

  if (!jwtTokenExpDate) {
    return true;
  }

  return currentTime > Number(jwtTokenExpDate);
};
