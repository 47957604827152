import { FC } from 'react';
import { ArrayField, Control, Controller, DeepMap, FieldError } from 'react-hook-form';
import { useTheme } from '@emotion/react';

import { Person as PersonType } from 'services/types/EstimationData';
import { Performer as PerformerType } from 'services/types/Iteration';
import Select from 'components/Select/v2';
import Icon from 'components/Icon';
import Input from 'components/Input';
import { VALIDATION_MESSAGES } from 'constants/common';
import {
  ButtonRound,
  InputContainer,
  PerformerContainer,
} from '../IterationForm/IterationForm.styled';

export type EstimationType = {
  description: string;
  name: string;
  status: string;
  technologies?: string[];
  persons: { changeableName: string; position: { name: string } }[];
  version: string;
};

type Props = {
  control: Control;
  errors: DeepMap<EstimationType, FieldError>;
  index: number;
  item: Partial<ArrayField<PersonType, 'uuid'>>;
  fields: Partial<ArrayField<PersonType, 'uuid'>>[];
  persons: PerformerType[];
  onAddItem: (value: number) => void;
  onDeleteItem: (value: number) => void;
};

const PersonItem: FC<Props> = ({
  control,
  errors,
  item,
  fields,
  index,
  persons,
  onAddItem,
  onDeleteItem,
}) => {
  const { colors } = useTheme();

  const handleAddItem = () => {
    onAddItem(index);
  };

  const handleRemoveItem = () => {
    onDeleteItem(index);
  };

  const employeesList =
    persons.map(({ name }) => ({
      value: name,
      label: name,
    })) || [];

  return (
    <InputContainer>
      <PerformerContainer>
        <Controller
          name={`persons[${index}].changeableName`}
          defaultValue={item.changeableName || ''}
          rules={{ required: { value: true, message: VALIDATION_MESSAGES.required } }}
          control={control}
          render={({ value, onChange }, { invalid }) => {
            const filteredEmployeeList =
              fields.length > 0
                ? employeesList.filter(
                    (employee) =>
                      fields.every((field) => field.changeableName !== employee.value) ||
                      employee.value === item.changeableName,
                  )
                : employeesList;
            const position = value
              ? persons.find((person) => person.name === value)?.position.name
              : '';

            return (
              <>
                <Select
                  value={value}
                  placeholder="Choose person"
                  itemList={filteredEmployeeList || []}
                  width={160}
                  errorMessage={errors.persons?.[index]?.changeableName?.message}
                  isError={invalid}
                  disabled={filteredEmployeeList.length === 0}
                  onChange={onChange}
                  verticalLabel
                  isRequired
                />
                <Input name="text" value={position} isDisabled />
              </>
            );
          }}
        />
        <ButtonRound type="button" empty={fields.length === 1} onClick={handleRemoveItem}>
          <Icon name="delete" color={colors.blue[300]} />
        </ButtonRound>
        <ButtonRound
          type="button"
          empty={fields.length === employeesList.length + 1}
          onClick={handleAddItem}
        >
          <Icon name="plus" color={colors.blue[300]} />
        </ButtonRound>
      </PerformerContainer>
    </InputContainer>
  );
};

export default PersonItem;
