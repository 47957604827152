import React, { FC, useCallback } from 'react';
import AppSection from 'pages/EstimationDetailPage/Gantt/models/AppSection';
import { MainModelWithSizes, SetModel, SetStoryId } from '../../types';
import {
  StoryTitle,
  SectionTitleFixedWidth,
  StyledLeftPanelTable,
  StyledLeftPanelTd,
  StyledLeftPanelTh,
  StyledLeftPanelTr,
  StyledTdContentWrapper,
  StyledTitleContentWrapper,
} from './LeftPanelStyles';
import { getTotalStoriesHours } from './utils';
import { AppCamera } from '../../../../models/AppCamera';
import { LeftPanelLine } from './LeftPanelLine/LeftPanelLine';
import { ToggleExpandButton } from './ToggleExpandButton';
import { FlexRowNowrapFlexStartCenter } from '../../../../components/Flex';
import useUpdateSectionById from '../../hooks/useUpdateSectionById';

export interface LeftPanelProps {
  modelWithSizes: MainModelWithSizes;
  setModel: SetModel;
  storyId: number | null;
  setStoryId: SetStoryId;
  appCamera: AppCamera;
}

export const LeftPanel: FC<LeftPanelProps> = ({
  modelWithSizes,
  setModel,
  storyId,
  setStoryId,
  appCamera,
}) => {
  const showShadow = appCamera.x < 0;

  const updateSectionById = useUpdateSectionById(setModel);

  const onToggleSectionClick = useCallback(
    (section: AppSection) => {
      updateSectionById(section.id, { expanded: !section.expanded });
    },
    [updateSectionById],
  );

  return (
    <StyledLeftPanelTable showShadow={showShadow}>
      <tbody>
        <StyledLeftPanelTr>
          <StyledLeftPanelTh>
            <StyledTdContentWrapper>Задача</StyledTdContentWrapper>
          </StyledLeftPanelTh>
          <StyledLeftPanelTh>
            <StyledTdContentWrapper>Часы</StyledTdContentWrapper>
          </StyledLeftPanelTh>
        </StyledLeftPanelTr>
        {modelWithSizes.sections.map((section, sectionIndex) => (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={`section_${sectionIndex}`}>
            <StyledLeftPanelTr>
              <StyledLeftPanelTd>
                <StyledTitleContentWrapper onClick={() => onToggleSectionClick(section)}>
                  <FlexRowNowrapFlexStartCenter>
                    {section.stories.length > 0 && (
                      <ToggleExpandButton expanded={section.expanded} />
                    )}
                    <SectionTitleFixedWidth>{section.title}</SectionTitleFixedWidth>
                  </FlexRowNowrapFlexStartCenter>
                </StyledTitleContentWrapper>
              </StyledLeftPanelTd>
              <StyledLeftPanelTd>
                <StyledTdContentWrapper>
                  <StoryTitle>{getTotalStoriesHours(section)}h</StoryTitle>
                </StyledTdContentWrapper>
              </StyledLeftPanelTd>
            </StyledLeftPanelTr>
            {section.expanded &&
              section.stories.map((story) => (
                <StyledLeftPanelTr
                  /* eslint-disable-next-line react/no-array-index-key */
                  key={`story_${story.id}`}
                >
                  <LeftPanelLine
                    story={story}
                    storyTitle={story.title}
                    storyId={storyId}
                    setStoryId={setStoryId}
                  />
                  <StyledLeftPanelTd>
                    <StyledTdContentWrapper>{story.hours}h</StyledTdContentWrapper>
                  </StyledLeftPanelTd>
                </StyledLeftPanelTr>
              ))}
          </React.Fragment>
        ))}
      </tbody>
    </StyledLeftPanelTable>
  );
};
