import { Dispatch, MutableRefObject, SetStateAction, useEffect, useMemo, useState } from 'react';
import SockJS from 'sockjs-client';
import stompjs from 'stompjs';

import { getCookie } from 'utils/auth';
import { getEstimation } from 'services/estimation';
import { EstimationData } from 'services/types/EstimationData';
import EstimationTableData from 'components/EstimationTable/types';

export const useSocketClient = (tableId: number | null): stompjs.Client | null =>
  useMemo(() => {
    if (tableId) {
      const serverUrl = `${process.env.REACT_APP_API_URL}estimator-stomp-endpoint`;
      const socket = new SockJS(serverUrl);
      const stompClient = stompjs.over(socket);
      stompClient.debug = () => {};

      return stompClient;
    }

    return null;
  }, [tableId]);

type SocketProps = {
  tableId: number | null;
  client: stompjs.Client | null;
  ref: MutableRefObject<EstimationTableData | undefined>;
  setTableData: Dispatch<SetStateAction<EstimationTableData>>;
  setDirectionData: Dispatch<SetStateAction<{ [key: string]: number }>>;
};

export const useSocket = ({
  client,
  tableId,
  ref,
  setTableData,
  setDirectionData,
}: SocketProps): void => {
  useEffect(() => {
    if (client) {
      const token = getCookie('jwtToken');
      const subUrl = `/topic/${tableId}`;
      const sendUrl = `/estimation/${tableId}/table`;
      const connect = () => {
        client.subscribe(
          subUrl,
          ({ body }) => {
            const {
              body: { sections = [] },
            } = JSON.parse(body);

            if (JSON.stringify(ref.current) !== JSON.stringify(sections)) {
              setTableData(sections);
            }
          },
          {
            action: 'get',
            token,
            tableUUID: tableId,
          },
        );

        client.send(
          sendUrl,
          {},
          JSON.stringify({
            action: 'get',
            token,
            tableUUID: tableId,
          }),
        );
      };

      client.connect(
        {},
        () => {
          connect();
        },
        () => {
          connect();
        },
      );
    }
  }, [client, tableId, ref, setTableData, setDirectionData]);
};

export const useLoadEstimationData = (id: string | undefined): EstimationData => {
  const [estimationData, setEstimationData] = useState<EstimationData>({} as EstimationData);

  useEffect(() => {
    let isMounted = true;

    if (id) {
      getEstimation(id).then((response) => {
        if (isMounted) setEstimationData(response);
      });
    }

    return () => {
      isMounted = false;
    };
  }, [id]);

  return estimationData;
};
