import { FC, ChangeEvent } from 'react';

import { ArrowStyled, InputStyled } from './Arrow.styled';

type Props = {
  value: boolean;
  onChange: (value: boolean) => void;
};

const Arrow: FC<Props> = ({ value, onChange }) => {
  const onClick = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    onChange(target.checked);
  };

  return (
    <ArrowStyled isTransform={value}>
      <InputStyled type="checkbox" onChange={onClick} checked={value} />
    </ArrowStyled>
  );
};

export default Arrow;
