import { FC, MouseEvent } from 'react';

import logo from 'assets/images/noimage.png';
import Icon from 'components/Icon';

import {
  ClientStyled,
  WrapperLogoStyled,
  LogoStyled,
  ClientInfoStyled,
  ClientNameStyled,
  ControlsWrapperStyled,
  ProjectsButtonStyled,
  DelButtonStyled,
} from './ClientItem.style';

export type Props = {
  clientLogo?: string;
  projectsCount?: number;
  clientName: string;
  onToggleModal: (event: MouseEvent) => void;
};

const ClientItem: FC<Props> = ({
  projectsCount = 0,
  clientLogo = logo,
  clientName,
  onToggleModal,
}) => (
  <>
    <ClientStyled>
      <WrapperLogoStyled>
        <LogoStyled src={clientLogo} alt={`${clientName} logo`} loading="lazy" />
      </WrapperLogoStyled>

      <ClientInfoStyled>
        <ClientNameStyled>{clientName}</ClientNameStyled>
        <ControlsWrapperStyled>
          <ProjectsButtonStyled>{projectsCount} projects</ProjectsButtonStyled>
          <DelButtonStyled type="button" onClick={onToggleModal} id="delete">
            <Icon name="delete" className="icon" />
          </DelButtonStyled>
        </ControlsWrapperStyled>
      </ClientInfoStyled>
    </ClientStyled>
  </>
);

export default ClientItem;
