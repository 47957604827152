import { InfoDetailType, SubTaskType, TaskType } from '../types';

export const close = (
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setInfoDetails: React.Dispatch<React.SetStateAction<InfoDetailType | null | undefined>>,
): void => {
  setIsOpen(false);
  setTimeout(() => {
    setInfoDetails(null);
  }, 450);
};

const addDetails = (
  showDetails: boolean | undefined,
  taskItem: TaskType | null | undefined,
  subTaskItem: SubTaskType | null | undefined,
  subTaskName: string | null | undefined,
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setInfoDetails: React.Dispatch<React.SetStateAction<InfoDetailType | null | undefined>>,
): void => {
  if (showDetails && taskItem) {
    setInfoDetails({ info: taskItem });
    setIsOpen(true);
  }
  if (showDetails && subTaskItem) {
    setInfoDetails({ info: subTaskItem, additionalInfo: subTaskName });
    setIsOpen(true);
  }
  if (!showDetails) {
    setIsOpen(false);
    setTimeout(() => {
      setInfoDetails(null);
    }, 450);
  }
};

export default addDetails;
