import styled from '@emotion/styled';

export const StyledHeaderRow = styled.tr`
  color: ${({ theme }) => theme.colors.white[400]};
  text-align: left;
  vertical-align: middle;
`;

export const StyledHeaderCell = styled.th<{ offset: number }>`
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase;
  padding: 12px 11px;
  position: sticky;
  z-index: 2;
  top: ${({ offset }) => `${offset}px`};
  background-color: ${({ theme }) => theme.colors.blue[300]};

  &:first-of-type {
    padding-left: 65px;
    width: 50%;
    border-left: 1px solid;
    border-color: ${({ theme }) => theme.colors.blue[300]};
    border-top-left-radius: 5px;
  }
  &:nth-of-type(2) {
    width: 100px;
  }
  &:last-of-type {
    border-right: 1px solid;
    border-color: ${({ theme }) => theme.colors.blue[300]};
    border-top-right-radius: 5px;
  }
  &:not(:first-of-type) {
    text-align: center;
    border-left: 1px solid ${({ theme }) => theme.colors.white[300]};
  }
`;
