import styled from '@emotion/styled';

export const FieldsetStyled = styled.fieldset`
  border: 1px dashed ${({ theme }) => theme.colors.gray[200]};
  padding: 20px;
  width: 535px;
  margin-top: 10px;

  & > * {
    margin-bottom: 16px;
  }

  &:first-of-type {
    margin-right: 20px;
  }
`;

export const EmployeeStyled = styled.div`
  display: flex;
`;

export const TitleStyled = styled.p`
  font-size: 12px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.gray[550]};
  margin-bottom: 0;
`;

export const ButtonStyled = styled.div`
  margin-left: auto;
  margin-top: 10px;
`;
