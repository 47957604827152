import React from 'react';
import { Group, Line, Rect, Text } from 'react-konva';
import { isToday, isWeekend } from 'date-fns';
import differenceInDays from 'date-fns/differenceInDays';
import isThisWeek from 'date-fns/isThisWeek';
import getWeek from 'date-fns/getWeek';
import add from 'date-fns/add';
import sub from 'date-fns/sub';
import isMonday from 'date-fns/isMonday';
import nextMonday from 'date-fns/nextMonday';
import startOfMonth from 'date-fns/startOfMonth';
import isFirstDayOfMonth from 'date-fns/isFirstDayOfMonth';
import getMonth from 'date-fns/getMonth';
import isThisMonth from 'date-fns/isThisMonth';
import { AppScaleEnum } from 'pages/EstimationDetailPage/Gantt/models/AppScaleEnum';
import { nanoid } from 'nanoid';
import {
  CalendarBodyProps,
  CalendarLabelProps,
  CalendarDayProps,
  CalendarWeekProps,
  CalendarMonthProps,
  TimeLineProps,
  TodayLabelProps,
} from './types';
import { CALENDAR_DAY_WIDTH, monthNames, OFFSET_Y } from './consts';
import { colors } from '../../../../theme/colors';
import { getCanvasWidth, getCalendarItemWidth } from '../../utils';
import useGapFromStart from './useGapFromStart';
import { TIMELINE_COLUMN_SIZE } from '../../consts';

const CalendarLabel: React.FC<CalendarLabelProps> = ({ month, size, isNext }) => (
  <Group>
    <Rect
      width={68 * size}
      height={CALENDAR_DAY_WIDTH * size}
      fill={!isNext ? colors.timeLine.selectionColor : 'grey'}
      // stroke={isNext ? '#5D3188' : undefined}
      // strokeWidth={2}
    />
    <Text
      text={month}
      wrap="char"
      align="center"
      width={68 * size}
      height={CALENDAR_DAY_WIDTH * size}
      y={7 * size}
      fontSize={12 * size}
      fill="white"
    />
  </Group>
);

const TodayLabel: React.FC<TodayLabelProps> = ({ size }) => (
  <Group y={-OFFSET_Y * size}>
    {/* <Rect */}
    {/*  width={CALENDAR_DAY_WIDTH * 1.55 * camera.scale} */}
    {/*  height={1} */}
    {/*  y={12 * size} */}
    {/*  x={-(CALENDAR_DAY_WIDTH / 2) * camera.scale} */}
    {/*  fill="#1E73B9" */}
    {/* /> */}
    {/* <Text */}
    {/*  x={-(CALENDAR_DAY_WIDTH / 2) * camera.scale} */}
    {/*  width={CALENDAR_DAY_WIDTH * 1.55 * camera.scale} */}
    {/*  align="center" */}
    {/*  text="сегодня" */}
    {/*  fontSize={12 * size} */}
    {/*  fill="white" */}
    {/* /> */}
  </Group>
);

const CalendarDay: React.FC<CalendarDayProps> = ({
  date,
  calendarHeight,
  index,
  isTodayFlag,
  size,
  camera,
  columnWidth,
}) => {
  const day = date?.getDate();
  const isWeekendDay = isWeekend(date);

  return (
    <Group x={CALENDAR_DAY_WIDTH * size * index * camera.scale}>
      {day === 1 && (
        <CalendarLabel
          month={`${monthNames[date.getMonth()]} ${date.getFullYear()}`}
          size={1}
          isNext={
            date.getMonth() > new Date().getMonth() &&
            date.getFullYear() >= new Date().getFullYear()
          }
        />
      )}
      <Line
        points={[0, CALENDAR_DAY_WIDTH + CALENDAR_DAY_WIDTH * size + 2, 0, calendarHeight]}
        stroke={isTodayFlag ? colors.timeLine.todayLineColor : colors.timeLine.horizontalLineColor}
      />
      <Rect
        y={CALENDAR_DAY_WIDTH}
        width={columnWidth}
        height={CALENDAR_DAY_WIDTH * size}
        fill={
          /* eslint-disable-next-line no-nested-ternary */
          isTodayFlag
            ? colors.timeLine.selectionColor
            : isWeekendDay
            ? colors.timeLine.weekendDayColor
            : undefined
        }
      />

      <Text
        y={CALENDAR_DAY_WIDTH + 8 * size}
        text={`${day < 10 ? `0${day}` : day}`}
        wrap="char"
        align="center"
        width={columnWidth}
        height={CALENDAR_DAY_WIDTH * size}
        fontSize={(CALENDAR_DAY_WIDTH / 2 - 1) * size}
        fill={isTodayFlag ? 'white' : '#A2A4AA'}
      />
      {isTodayFlag && <TodayLabel size={size} camera={camera} />}
    </Group>
  );
};

const CalendarWeek: React.FC<CalendarWeekProps> = ({
  date,
  calendarHeight,
  index,
  isThisWeekFlag,
  size,
  camera,
  columnWidth,
}) => {
  const week = getWeek(sub(date, { days: 7 }), { weekStartsOn: 1 });

  return (
    <Group x={CALENDAR_DAY_WIDTH * size * index * camera.scale}>
      {week === 1 && (
        <CalendarLabel
          month={`${monthNames[date.getMonth()]} ${date.getFullYear()}`}
          size={1}
          isNext={
            date.getMonth() > new Date().getMonth() &&
            date.getFullYear() >= new Date().getFullYear()
          }
        />
      )}
      <Line
        points={[0, CALENDAR_DAY_WIDTH + CALENDAR_DAY_WIDTH * size + 2, 0, calendarHeight]}
        stroke={
          isThisWeekFlag ? colors.timeLine.todayLineColor : colors.timeLine.horizontalLineColor
        }
      />
      <Rect
        y={CALENDAR_DAY_WIDTH}
        width={columnWidth}
        height={CALENDAR_DAY_WIDTH * size}
        fill={
          /* eslint-disable-next-line no-nested-ternary */
          isThisWeekFlag ? colors.timeLine.selectionColor : undefined
        }
      />

      <Text
        y={CALENDAR_DAY_WIDTH + 8 * size}
        text={`${week < 10 ? `0${week}` : week}`}
        wrap="char"
        align="center"
        width={columnWidth}
        height={CALENDAR_DAY_WIDTH * size}
        fontSize={(CALENDAR_DAY_WIDTH / 2 - 1) * size}
        fill={isThisWeekFlag ? 'white' : '#A2A4AA'}
      />
      {isThisWeekFlag && <TodayLabel size={size} camera={camera} />}
    </Group>
  );
};

const CalendarMonth: React.FC<CalendarMonthProps> = ({
  date,
  calendarHeight,
  index,
  isThisMonthFlag,
  size,
  camera,
  columnWidth,
}) => {
  const month = getMonth(date) + 1;

  return (
    <Group x={CALENDAR_DAY_WIDTH * size * index * camera.scale}>
      {month === 1 && (
        <CalendarLabel
          month={`${date.getFullYear()}`}
          size={1}
          isNext={
            date.getMonth() > new Date().getMonth() &&
            date.getFullYear() >= new Date().getFullYear()
          }
        />
      )}
      <Line
        points={[0, CALENDAR_DAY_WIDTH + CALENDAR_DAY_WIDTH * size + 2, 0, calendarHeight]}
        stroke={
          isThisMonthFlag ? colors.timeLine.todayLineColor : colors.timeLine.horizontalLineColor
        }
      />
      <Rect
        y={CALENDAR_DAY_WIDTH}
        width={columnWidth}
        height={CALENDAR_DAY_WIDTH * size}
        fill={
          /* eslint-disable-next-line no-nested-ternary */
          isThisMonthFlag ? colors.timeLine.selectionColor : undefined
        }
      />

      <Text
        y={CALENDAR_DAY_WIDTH + 8 * size}
        text={`${month < 10 ? `0${month}` : month}`}
        wrap="char"
        align="center"
        width={columnWidth}
        height={CALENDAR_DAY_WIDTH * size}
        fontSize={(CALENDAR_DAY_WIDTH / 2 - 1) * size}
        fill={isThisMonthFlag ? 'white' : '#A2A4AA'}
      />
      {isThisMonthFlag && <TodayLabel size={size} camera={camera} />}
    </Group>
  );
};

const CalendarBody: React.FC<CalendarBodyProps> = ({ width, calendarHeight, size }) => (
  <>
    <Rect width={width} height={calendarHeight} fill={colors.timeLine.backgroundColor} />
    <Rect
      y={CALENDAR_DAY_WIDTH}
      width={width}
      height={CALENDAR_DAY_WIDTH * size}
      fill={colors.timeLine.backgroundColor}
      shadowColor={colors.timeLine.shadowColor}
      shadowBlur={10}
      shadowOffsetY={3}
    />
  </>
);

const TimeLine: React.FC<TimeLineProps> = ({
  calendarHeight = 500,
  y = 0,
  camera,
  appCamera,
  datePeriod,
}) => {
  const graphDays = new Array(Math.abs(differenceInDays(datePeriod.start, datePeriod.end))).fill(0);
  const graphWeeks = new Array(graphDays.length).fill(0);
  const graphMonths = new Array(graphDays.length).fill(0);
  const weekStartDate = isMonday(datePeriod.start)
    ? datePeriod.start
    : sub(nextMonday(datePeriod.start), { days: 7 });
  const monthStartDate = isFirstDayOfMonth(datePeriod.start)
    ? datePeriod.start
    : startOfMonth(datePeriod.start);
  const sizeFromPixels = TIMELINE_COLUMN_SIZE / CALENDAR_DAY_WIDTH;
  const canvasWidth = getCanvasWidth(datePeriod, camera.scale, TIMELINE_COLUMN_SIZE);
  const calendarItemWidth = getCalendarItemWidth(camera.scale as 1 | 2 | 3);

  useGapFromStart(weekStartDate, monthStartDate, datePeriod);

  return (
    <Group x={camera.x} y={(y + OFFSET_Y) * sizeFromPixels}>
      <CalendarBody width={canvasWidth} calendarHeight={calendarHeight} size={sizeFromPixels} />
      {appCamera.scale === AppScaleEnum.Days && (
        <Group y={(y + OFFSET_Y) * sizeFromPixels}>
          {graphDays.map((day, index) => (
            <CalendarDay
              key={nanoid()}
              date={add(datePeriod.start, { days: index })}
              isTodayFlag={isToday(add(datePeriod.start, { days: index }))}
              calendarHeight={calendarHeight}
              index={index}
              size={sizeFromPixels}
              camera={camera}
              columnWidth={calendarItemWidth}
            />
          ))}
        </Group>
      )}
      {appCamera.scale === AppScaleEnum.Weeks && (
        <Group y={(y + OFFSET_Y) * sizeFromPixels}>
          {graphWeeks.map((week, index) => (
            <CalendarWeek
              key={nanoid()}
              date={add(weekStartDate, { weeks: index })}
              isThisWeekFlag={isThisWeek(add(weekStartDate, { weeks: index }), {
                weekStartsOn: 1,
              })}
              calendarHeight={calendarHeight}
              index={index}
              size={sizeFromPixels}
              camera={camera}
              columnWidth={calendarItemWidth}
            />
          ))}
        </Group>
      )}
      {appCamera.scale === AppScaleEnum.Months && (
        <Group y={(y + OFFSET_Y) * sizeFromPixels}>
          {graphMonths.map((month, index) => (
            <CalendarMonth
              key={nanoid()}
              date={add(monthStartDate, { months: index })}
              isThisMonthFlag={isThisMonth(add(monthStartDate, { months: index }))}
              calendarHeight={calendarHeight}
              index={index}
              size={sizeFromPixels}
              camera={camera}
              columnWidth={calendarItemWidth}
            />
          ))}
        </Group>
      )}
    </Group>
  );
};

export default TimeLine;
