import styled from '@emotion/styled';

export const Card = styled.div`
  width: 297px;
  display: block;
  min-height: 300px;
  border: 1px solid ${({ theme }) => theme.colors.gray[200]};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.white[400]};
  color: ${({ theme }) => theme.colors.black[600]};
  font-size: 14px;
  padding: 0 0 24px;
  box-sizing: content-box;
`;

export const CardImg = styled.img`
  width: 100%;
  height: 240px;
  display: block;
  object-fit: contain;
  margin-bottom: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[200]};
`;

export const CardInfo = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  align-items: center;
  width: 100%;
  height: max-content;
  box-sizing: border-box;
`;

export const UserFullName = styled.span`
  margin: 0 2px;
  color: ${({ theme }) => theme.colors.black[400]};
  word-wrap: break-word;
  max-width: 132px;
`;

export const UserLabel = styled.span`
  width: 130px;
  display: inline-block;
  min-width: 90px;
  padding: 8px 12px;
  text-align: center;
  line-height: 22px;
  font-weight: 700;
  border-radius: 20px;
  color: ${({ theme }) => theme.colors.blue[100]};
  background-color: ${({ theme }) => theme.colors.gray[200]};
  text-transform: capitalize;
  cursor: pointer;

  &.basic {
    color: ${({ theme }) => theme.colors.green[400]};
    background-color: ${({ theme }) => theme.colors.green[200]};
  }

  &.admin {
    color: ${({ theme }) => theme.colors.yellow[400]};
    background-color: ${({ theme }) => theme.colors.yellow[200]};
  }

  &.super {
    color: ${({ theme }) => theme.colors.red[400]};
    background-color: ${({ theme }) => theme.colors.red[200]};
  }
`;
