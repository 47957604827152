import { TaskStatusType } from './types/Dictionary';
import { protectedGet } from './protectedAxios';
import Technology from './types/Technology';
import { Employee } from './types/Employee';

const DICTIONARY_URL = '/api/extra';
const getEstimationStatusesUrl = () => `${DICTIONARY_URL}/estimation-statuses`;
const getTechnologiesUrl = () => `${DICTIONARY_URL}/technologies`;
const getEmployeePositionsUrl = () => `${DICTIONARY_URL}/employee-positions`;

const getTaskStatusesUrl = () => `${DICTIONARY_URL}/task-statuses`;

export const getEstimationStatuses = (): Promise<string[]> =>
  protectedGet(getEstimationStatusesUrl());
export const getTechnologies = (): Promise<Technology[]> => protectedGet(getTechnologiesUrl());
export const getEmployeePositions = (): Promise<Employee[]> =>
  protectedGet(getEmployeePositionsUrl());

export const getTaskStatuses = (): Promise<TaskStatusType[]> => protectedGet(getTaskStatusesUrl());

export default getEstimationStatuses;
