import { FC, useEffect, useState } from 'react';
import { BodyCell, BodyRow } from '../styled/Table.styled';
import NumberField from '../../../components/EstimationTable/components/NumberField';
import { NewItemType } from './types';

type DirectionRowProps = {
  keyItem: string;
  value: NewItemType;
  changeTotal: ([key, val]: [string, number]) => void;
};

const DirectionRow: FC<DirectionRowProps> = ({ keyItem, value, changeTotal }) => {
  const [rowData, setRowData] = useState<{ rate: number; sum: number }>({ rate: 0, sum: 0 });

  useEffect(() => {
    changeTotal([keyItem, rowData.sum]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowData]);

  const ChangeHAndlerRate = (val: number) => {
    setRowData({ rate: val, sum: value.value * val });
  };

  const ChangeHAndlerSum = (val: number) => {
    setRowData({ rate: +(val / value.value).toFixed(2), sum: val });
  };

  const type2 = 'DEVELOPMENT';

  return (
    <BodyRow key={keyItem}>
      <BodyCell width={100}>{keyItem === 'null' ? 'Не распределено' : keyItem}</BodyCell>
      <BodyCell width={220}>{value.value || 0}</BodyCell>
      <BodyCell width={220}>
        <NumberField
          type={type2}
          value={rowData.rate}
          onChange={ChangeHAndlerRate}
          onBlur={() => {}}
          canEdit={false}
        />
      </BodyCell>
      <BodyCell width={220}>
        <NumberField
          type={type2}
          value={rowData.sum}
          onChange={ChangeHAndlerSum}
          onBlur={() => {}}
          canEdit={false}
        />
      </BodyCell>
    </BodyRow>
  );
};

export default DirectionRow;
