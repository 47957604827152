import { FC } from 'react';

import OptionStyled from './Option.styled';

export type OptionType = {
  label: string;
  value: string;
};

type Props = {
  option: OptionType;
  isActive: boolean;
  onClick: (value: string) => void;
  isVisible: boolean;
};

const Option: FC<Props> = ({ option: { label, value }, onClick, isActive, isVisible }) => {
  const buttonClickHandler = () => {
    onClick(value);
  };

  return (
    <OptionStyled
      type="button"
      onClick={buttonClickHandler}
      isActive={isActive}
      tabIndex={isVisible ? 0 : -1}
    >
      {label}
    </OptionStyled>
  );
};

export default Option;
