import React, { FC } from 'react';
import { StyledTopToolbar, ToolbarTitle } from './TopToolbarStyles';
import { ScaleSelector } from '../ScaleSelector/ScaleSelector';

export interface TopToolbarProps {
  title?: string;
}

export const TopToolbar: FC<TopToolbarProps> = ({ title }) => (
  <StyledTopToolbar>
    <ToolbarTitle>{title}</ToolbarTitle>
    <ScaleSelector />
  </StyledTopToolbar>
);
