import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTheme } from '@emotion/react';
import { useHistory, Link } from 'react-router-dom';

import PagePanel from 'components/PagePanel';
import Button from 'components/Button';
import Icon from 'components/Icon';
import { postFile } from 'services/file';
import { createProject } from 'services/project';
import ProjectDetails from './components/ProjectDetails';
import ClientDetails from './components/ClientDetails';
import IterationDescription from './components/IterationDescription';
import {
  Container,
  ButtonsContainer,
  Box,
  InfoContainer,
  InfoText,
  RequiredMark,
  ProjectDetailsContainer,
} from './NewProjectPage.styled';

type FormValues = {
  projectName: string;
  projectOwner: string | null;
  projectStatus: string | null;
  language: string;
  deadline: string;
  description: string;
  projectLogo: File[];
  companyId: number | null;
  employees: { value: number | string }[];
  iteration: { name: string; technologies: string[] };
};

const NewProjectPage: FC = () => {
  const history = useHistory();
  const methods = useForm<FormValues>({
    defaultValues: {
      projectName: '',
      projectOwner: null,
      projectStatus: null,
      language: 'RU',
      deadline: '',
      description: '',
      companyId: null,
      projectLogo: [],
      employees: [{ value: -1 }],
      iteration: { name: '', technologies: [] },
    },
  });
  const { colors } = useTheme();

  const onSubmit = methods.handleSubmit(
    ({
      companyId,
      deadline,
      description,
      employees,
      iteration,
      language,
      projectName,
      projectOwner,
      projectStatus,
      projectLogo,
    }: FormValues) => {
      if (companyId && projectOwner && projectStatus) {
        const data = {
          companyId,
          name: projectName,
          description,
          deadline: Date.parse(deadline),
          language: language.toUpperCase(),
          status: projectStatus?.toUpperCase(),
          projectOwner,
          employees: employees?.map(({ value }) => value) || [],
          iteration: {
            ...iteration,
            technologies: iteration.technologies.map((item) => ({ name: item, id: null })),
          },
        };
        createProject(data)
          .then((res) => {
            if (projectLogo[0]) {
              const formData = new FormData();
              formData.append('file', projectLogo[0]);

              postFile(formData, {
                fileSubType: 'AVATAR',
                fileType: 'IMAGE',
                ownerId: res.id,
                ownerType: 'PROJECT',
              });
            }
          })
          .then(() => {
            history.push('/main/project-list');
          });
      }
    },
  );

  return (
    <FormProvider {...methods}>
      <PagePanel title="New Project" />
      <form onSubmit={onSubmit}>
        <Container>
          <InfoContainer>
            <Icon name="filledWarning" className="icon" color={colors.gray[550]} />
            <InfoText>
              All fields marked with <RequiredMark>*</RequiredMark> are required
            </InfoText>
          </InfoContainer>
          <Box>
            <ProjectDetailsContainer>
              <ProjectDetails />
            </ProjectDetailsContainer>
            <ClientDetails />
            <IterationDescription />
          </Box>
          <ButtonsContainer>
            <Button label="save" styleType="primary" type="submit" />
            <Link to="/main/project-list">
              <Button label="cancel" />
            </Link>
          </ButtonsContainer>
        </Container>
      </form>
    </FormProvider>
  );
};

export default NewProjectPage;
