import styled from '@emotion/styled';

export const TitleRow = styled.tr`
  color: ${({ theme }) => theme.colors.gray[600]};
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  min-height: 45px;
  background-color: ${({ theme }) => theme.colors.gray[300]};
`;

export const TitleRowCell = styled.td`
  padding: 7px 11px;

  &:first-of-type {
    padding-left: 65px;
    border-left: 1px solid ${({ theme }) => theme.colors.gray[200]};
  }
  &:last-of-type {
    border-right: 1px solid ${({ theme }) => theme.colors.gray[200]};
  }
  &:not(:first-of-type) {
    text-align: center;
    border-left: 1px solid ${({ theme }) => theme.colors.gray[300]};
  }
`;

export const TitleCellContentBox = styled.div`
  display: flex;
  align-items: baseline;
`;

export const StyledBodyRow = styled.tr`
  color: ${({ theme }) => theme.colors.gray[600]};
  line-height: 20px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[300]};
  }

  &.extra-risks {
    background-color: ${({ theme }) => theme.colors.green[200]};
  }

  &.senla-risks {
    background-color: ${({ theme }) => theme.colors.yellow[200]};
  }

  &.client-risks {
    background-color: ${({ theme }) => theme.colors.red[200]};
  }
`;

export const StyledBodyCell = styled.td`
  padding: 6px 11px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[300]};

  &:first-of-type {
    padding-left: 65px;
    width: 100%;
    border-left: 1px solid ${({ theme }) => theme.colors.gray[200]};
  }

  &:last-of-type {
    border-right: 1px solid ${({ theme }) => theme.colors.gray[200]};
  }

  &:not(:first-of-type) {
    text-align: center;
    border-left: 1px solid ${({ theme }) => theme.colors.gray[300]};
  }
`;

export const CellBox = styled.div`
  display: flex;
  align-items: baseline;
`;

export const RowNumber = styled.span`
  margin-top: 6px;
  margin-right: 10px;
  flex-shrink: 0;
  position: 'relative';
`;

export const Separator = styled.span`
  visibility: hidden;
`;
