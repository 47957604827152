import { FC, useState, useEffect, useRef, ChangeEvent } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import styled from '@emotion/styled';

import usePreviousValue from 'utils/hooks/usePreviousValue';
import { updateEstimation } from 'services/estimation';
import { EstimationData, Person } from 'services/types/EstimationData';

const Row = styled.div`
  display: flex;
  align-items: flex=start;
`;

const Container = styled.div`
  cursor: text;
  margin-right: 3px;
`;

const Input = styled.input`
  border: 1px solid transparent;
  line-height: 20px;
  padding: 0 5px;
  margin-top: -2px;
  margin-left: -5px;
  min-width: 100px;
  width: 100%;
  max-width: 250px;
`;

const DisplayValue = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;

const Position = styled.span`
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray[900]};
`;

type FieldProps = {
  name: string;
  value: string;
  isSubmitting: boolean;
  onChange: (value: string) => void;
  onBlur: () => void;
};

const Field: FC<FieldProps> = ({ value, name, isSubmitting, onChange, onBlur }) => {
  const [isActive, setIsActive] = useState(false);
  const ref = useRef<HTMLInputElement>(null);
  const previousValue = usePreviousValue(value);

  useEffect(() => {
    if (isActive) {
      ref.current?.focus();
    }
  }, [isActive]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value: newValue } = event.target;
    onChange(newValue);
  };

  const handleShowInput = () => {
    setIsActive(true);
  };

  const handleHideInput = () => {
    setIsActive(false);
    if (previousValue !== value) {
      onBlur();
    }
  };

  return (
    <Container onClick={handleShowInput}>
      {isActive && !isSubmitting ? (
        <Input
          ref={ref}
          type="text"
          name={name}
          value={value}
          onChange={handleChange}
          onBlur={handleHideInput}
        />
      ) : (
        <DisplayValue>{value}</DisplayValue>
      )}
    </Container>
  );
};

type Props = {
  data: EstimationData;
};

const Form: FC<Props> = ({ data }) => {
  const { id, status, technologies, name: estimationName, persons } = data;
  const { control, formState, watch, handleSubmit } = useForm<EstimationData>({
    defaultValues: {
      persons,
    },
  });
  const { fields } = useFieldArray({
    control,
    name: 'persons',
    keyName: 'uuid',
  });

  const personsValue: Person[] = watch('persons');

  const onSubmit = handleSubmit((value: EstimationData) => {
    const result = {
      description: '',
      id,
      name: estimationName,
      persons: value.persons,
      status,
      technologies: technologies.map((technology) => technology.name),
      version: '',
    };

    updateEstimation(result);
  });

  const handleBlur = () => {
    const result = {
      description: '',
      id,
      name: estimationName,
      persons: personsValue,
      status,
      technologies: technologies.map((technology) => technology.name),
      version: '',
    };

    updateEstimation(result);
  };

  return (
    <form onSubmit={onSubmit}>
      {fields.map((field, index) => (
        <Row key={field.uuid}>
          <Controller
            name={`persons[${index}].changeableName`}
            control={control}
            defaultValue={field.changeableName}
            render={({ value, name, onChange }) => (
              <Field
                name={name}
                value={value}
                onChange={onChange}
                onBlur={handleBlur}
                isSubmitting={formState.isSubmitting}
              />
            )}
          />
          <Controller
            name={`persons[${index}].id`}
            control={control}
            defaultValue={field.id}
            as={<input type="hidden" />}
          />
          <Position>/ {field.position.name}</Position>
        </Row>
      ))}
    </form>
  );
};

export default Form;
