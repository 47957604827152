import { protectedPatch } from 'services/protectedAxios';
import NextTaskDto from 'pages/EstimationDetailPage/Gantt/models/NextTaskDto';

export const GANTT_TASK_PATCH_REQUEST_URL = '/api/tasks/task';
export const GANTT_SUBTASK_PATCH_REQUEST_URL = '/api/tasks/subtask';

export interface GanttPatchRequestBody {
  startDate?: Date;
  endDate?: Date;
  offsetHours?: number;
  nextTaskDto?: NextTaskDto;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const ganttPatchRequest = (id: number, isSubtask: boolean, data: GanttPatchRequestBody) =>
  protectedPatch(
    isSubtask
      ? `${GANTT_SUBTASK_PATCH_REQUEST_URL}/${id}`
      : `${GANTT_TASK_PATCH_REQUEST_URL}/${id}`,
    data,
  );
