import styled from '@emotion/styled';

export const TableBase = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray[200]};
  background: ${({ theme }) => theme.colors.white[400]};
`;

export const HeaderRow = styled.div`
  display: flex;
  font-weight: 500;
  padding: 18px 63px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[200]};

  & > *:nth-of-type(1) {
    flex: 1 1 auto;
  }

  & > *:nth-of-type(2) {
    width: 90px;
  }

  & > *:nth-of-type(3) {
    width: 92px;
  }
`;

export const HeaderCeil = styled.div`
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.fontSizes.standard};
`;
