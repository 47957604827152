import { FC, KeyboardEvent, useState, ChangeEvent, useRef } from 'react';
import { SerializedStyles } from '@emotion/react';

import Input from 'components/Input';
import { ListItem } from 'components/Select/v2';
import Menu, { Popover, MenuItem } from 'components/DropDownMenu';
import ChipsItem from './ChipsItem';
import { Container, ChipsListContainer } from './ChipsSelect.styled';

export type Props = {
  label?: string;
  placeholder?: string;
  helpText?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  isError?: boolean;
  errorMessage?: string;
  inputStyles?: SerializedStyles;
  mb?: number;
  value: string[];
  list: ListItem[];
  onChange: (value: string[]) => void;
};

const ChipsSelect: FC<Props> = ({
  label,
  helpText,
  isRequired = false,
  isDisabled,
  isError = false,
  errorMessage,
  inputStyles,
  mb = 0,
  list,
  value,
  onChange,
}) => {
  const inputContainerRef = useRef<HTMLDivElement>(null);
  const [inputValue, setInputValue] = useState('');
  const [existingItem, setExistingItem] = useState<{
    label: string;
    value: string | number;
  } | null>(null);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleMenuItemClick = (newValue: string | undefined) => {
    if (typeof newValue === 'undefined') {
      return;
    }

    const item = list.find((element) => element.label === newValue);
    const isItemExist = value.find((element) => element === item?.label);

    if (!item) {
      return;
    }

    setInputValue('');

    if (isItemExist) {
      setExistingItem(item);
    }

    if (item && !isItemExist) {
      onChange([...value, item.label]);
    }
  };

  const handleEnter = (event: KeyboardEvent<HTMLInputElement>): void => {
    const { value: newValue } = event.target as HTMLInputElement;
    if (event.key === 'Enter') {
      event.preventDefault();
      handleMenuItemClick(newValue);
      setInputValue('');

      if (newValue) {
        onChange([...value.filter((item) => item !== newValue), newValue]);
      }
    }
  };

  const handleDeleteItem = (item: string) => {
    onChange(value.filter((element) => element !== item));
  };

  const handleAnimationEnd = () => {
    setExistingItem(null);
  };

  const filteredList = list.filter((item) =>
    item.label.toLowerCase().includes(inputValue.toLowerCase()),
  );

  return (
    <Container>
      <div ref={inputContainerRef}>
        <Input
          label={label}
          helpText={helpText}
          isRequired={isRequired}
          isDisabled={isDisabled}
          isError={isError}
          errorMessage={errorMessage}
          inputStyles={inputStyles}
          value={inputValue}
          placeholder="Technology"
          mb={mb}
          onChange={handleInputChange}
          onKeyDown={handleEnter}
        />
      </div>
      <Popover
        trigger={inputContainerRef}
        triggerOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        popoutOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <Menu>
          {inputValue &&
            filteredList.map(({ label: itemLabel, value: itemValue }) => (
              <MenuItem key={itemValue} payload={itemLabel} onClick={handleMenuItemClick}>
                {itemLabel}
              </MenuItem>
            ))}
        </Menu>
      </Popover>
      <ChipsListContainer>
        {value.map((item) => (
          <ChipsItem
            key={item}
            title={item}
            isExisting={item === existingItem?.label}
            onAnimationEnd={handleAnimationEnd}
            onDeleteItem={handleDeleteItem}
          />
        ))}
      </ChipsListContainer>
    </Container>
  );
};

export default ChipsSelect;
