import styled from '@emotion/styled';
import { keyframes, css, Theme } from '@emotion/react';

const highlight = (theme: Theme) => keyframes`
  0% {
    background: ${theme.colors.orange[400]}; 
  }
  100% {
    background: none;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ChipsListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

type Props = {
  isExisting: boolean;
};

export const ChipsItemContainer = styled.div<Props>`
  transition: all ease-in-out 0.2s;
  position: relative;
  min-width: 60px;
  padding: 8px 12px;
  text-align: center;
  border-radius: 16px;
  cursor: default;
  margin-right: 9px;
  margin-bottom: 9px;
  background-color: ${({ theme }) => theme.colors.blue[300]};
  color: ${({ theme }) => theme.colors.white[400]};
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ isExisting, theme }) =>
    isExisting &&
    css`
      animation: ${highlight(theme)} 0.6s ease-in-out;
    `}
  &:hover {
    background-color: ${({ theme }) => theme.colors.blue[600]};
    color: ${({ theme }) => theme.colors.white[400]};
    box-shadow: 0 4px 8px rgb(0 0 0 / 25%);
  }
`;

export const DeleteIconContainer = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.white[400]};
  transition: transform ease-in-out 0.1s;
  cursor: pointer;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    transform: scale(1.3);
  }
`;
