import styled from '@emotion/styled';

type WarningProps = {
  isWarning: boolean;
};

export const BorderStyled = styled.div<WarningProps>`
  border: 1px solid ${({ theme, isWarning }) => (isWarning ? theme.colors.red[400] : 'transparent')};
  border-radius: 4px;
  padding: 14px 10px 16px;
  display: flex;
  justify-content: space-between;
  width: 395px;
  align-items: center;
`;

export const NoticeStyled = styled.strong<WarningProps>`
  font-size: 12px;
  line-height: 12px;
  color: ${({ theme, isWarning }) => (isWarning ? theme.colors.red[400] : 'transparent')};
  font-weight: 400;
  display: block;
  width: 100%;
`;

export const ContactStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 0;
`;
