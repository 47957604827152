import { FC, useState, useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { css } from '@emotion/react';

import Input from 'components/Input';
import TextArea from 'components/TextArea';
import Select from 'components/Select/v2';
import FileUploader from 'components/FileUploader';
import Calendar from 'components/Calendar';
import { STATUSES, LANGUAGES, VALIDATION_MESSAGES } from 'constants/common';
import { getAllByNameLike } from 'services/user';
import Row from './ProjectDetails.styled';
import CardContainer from '../CardContainer';

const LANGUAGES_LIST = LANGUAGES.filter((item) => item !== 'ALL').map((item) => ({
  value: item,
  label: item,
}));

const STATUSES_LIST = STATUSES.map((item) => ({
  value: item,
  label: item,
}));

const fileUploaderStyles = css`
  margin-top: 20px;
`;

const ACCEPTED_FILES = ['image/png', 'image/jpeg', 'image/bmp', 'image/svg+xml'];

const NewProjectPage: FC = () => {
  const { control, errors } = useFormContext();
  const [employees, setEmployees] = useState<{ value: string; label: string }[]>([]);

  useEffect(() => {
    getAllByNameLike().then((response) =>
      setEmployees(response.map((item) => ({ value: item.name, label: item.name }))),
    );
  }, []);

  return (
    <CardContainer title="Project Details">
      <Controller
        name="projectName"
        control={control}
        rules={{ required: { value: true, message: VALIDATION_MESSAGES.required } }}
        render={({ name, value, onChange, onBlur }, { invalid }) => (
          <Input
            name={name}
            mb={16}
            label="Project Name"
            placeholder="Project Name"
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            isError={invalid}
            errorMessage={errors.projectName?.message}
            isRequired
          />
        )}
      />
      <Controller
        name="projectOwner"
        control={control}
        rules={{ required: { value: true, message: VALIDATION_MESSAGES.required } }}
        render={({ value, onChange }, { invalid }) => (
          <Select
            isRequired
            verticalLabel
            withSearch
            mb={16}
            label="Project Owner (From Senla side Only)"
            itemList={employees}
            value={value}
            onChange={onChange}
            placeholder="Select an owner"
            isError={invalid}
            errorMessage={errors.projectOwner?.message}
          />
        )}
      />
      <Row>
        <Controller
          name="language"
          control={control}
          render={({ value, onChange }) => (
            <Select
              verticalLabel
              label="Language"
              itemList={LANGUAGES_LIST}
              value={value}
              onChange={onChange}
            />
          )}
        />
        <Controller
          name="projectStatus"
          control={control}
          rules={{ required: { value: true, message: VALIDATION_MESSAGES.required } }}
          render={({ value, onChange }, { invalid }) => (
            <Select
              isRequired
              verticalLabel
              label="Add status"
              itemList={STATUSES_LIST}
              value={value}
              onChange={onChange}
              placeholder="Project status"
              isError={invalid}
              errorMessage={errors.projectStatus?.message}
            />
          )}
        />
      </Row>
      <Controller
        name="deadline"
        control={control}
        render={({ name, value, onChange }) => (
          <Calendar label="Deadline" name={name} value={value} mb={16} onChange={onChange} />
        )}
      />
      <Row>
        <Controller
          name="description"
          control={control}
          render={({ name, value, onChange }) => (
            <TextArea
              label="Project Description"
              name={name}
              value={value}
              mb={16}
              onChange={onChange}
            />
          )}
        />
        <Controller
          name="projectLogo"
          control={control}
          render={({ value, onChange }) => (
            <FileUploader
              label="Add project logo"
              acceptText="PNG, JPG, BMP, SVG"
              accept={ACCEPTED_FILES}
              files={value || []}
              inputStyles={fileUploaderStyles}
              onChange={onChange}
            />
          )}
        />
      </Row>
    </CardContainer>
  );
};

export default NewProjectPage;
