import styled from '@emotion/styled';

export const SubMenuContainer = styled.div`
  display: none;
  position: absolute;
  top: -1px;
  right: -196px;
  width: 197px;
  border: 1px solid ${({ theme }) => theme.colors.gray[200]};
  box-shadow: 0 4px 8px rgb(0 0 0 / 15%);
  border-radius: 4px;
`;

export const SubMenuItem = styled.li`
  height: 50px;
  padding-left: 18px;
  padding-right: 18px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[200]};
  text-transform: capitalize;
  background: ${({ theme }) => theme.colors.white[400]};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[400]};
    color: ${({ theme }) => theme.colors.black[100]};
  }
`;

export const RiskStatus = styled.span`
  height: 22px;
  border-radius: 4px;
  width: 100%;
  text-align: center;
  padding-top: 2px;

  &.extra-risks {
    color: ${({ theme }) => theme.colors.green[400]};
    background: ${({ theme }) => theme.colors.green[200]};
  }

  &.inner-risks {
    color: ${({ theme }) => theme.colors.yellow[400]};
    background: ${({ theme }) => theme.colors.yellow[200]};
  }

  &.client-risks {
    color: ${({ theme }) => theme.colors.red[400]};
    background: ${({ theme }) => theme.colors.red[200]};
  }
`;

export const IconWrapper = styled.span`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
`;

export const MenuItem = styled.li`
  height: 50px;
  padding-left: 25px;
  padding-right: 28px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[200]};
  text-transform: capitalize;
  background: ${({ theme }) => theme.colors.white[400]};
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease, color 0.3s ease;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.gray[550]};

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[400]};
    color: ${({ theme }) => theme.colors.black[100]};
  }

  &:last-of-type,
  &.no-border {
    border-bottom: none;
  }

  & .bold {
    font-weight: 500;
  }

  & .icon {
    color: ${({ theme }) => theme.colors.gray[500]};
    transition: color 0.3s ease;
  }

  .sub-menu-icon {
    color: ${({ theme }) => theme.colors.gray[500]};
    margin-left: auto;
    margin-top: 2px;
  }

  &:hover .icon,
  &:hover .sub-menu-icon {
    color: ${({ theme }) => theme.colors.gray[550]};
  }

  &.parent {
    position: relative;

    &:hover .sub-menu {
      display: block;
    }
  }
`;

type ContainerProps = {
  xPos: string;
  yPos: string;
};

export const MenuContainer = styled.div<ContainerProps>`
  position: absolute;
  top: ${({ yPos }) => yPos};
  left: ${({ xPos }) => xPos};
  width: 282px;
  border: 1px solid ${({ theme }) => theme.colors.gray[200]};
  box-shadow: 0 4px 8px rgb(0 0 0 / 15%);
  border-radius: 4px;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
`;
