import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { loadCurrentUser, resetUser } from 'reducers/user';
import { resetLists } from 'reducers/lists';
import { useUserData } from 'reducers/selectors';
import { setToken } from 'services/protectedAxios';
import { getCookie, removeCookie } from 'utils/auth';
import Header from 'components/Header';
import Container from './AppContainer.styled';

const AppContainer: FC = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useUserData();
  const jwtTokenExpDate = getCookie('jwtTokenExpDate');

  useEffect(() => {
    const token = getCookie('jwtToken');

    if (token) {
      setToken({ token });
    }
  }, []);

  useEffect(() => {
    dispatch(loadCurrentUser());
  }, [dispatch]);

  // logout from other opened apps
  useEffect(() => {
    function logOut(event: StorageEvent) {
      if (event.key === 'logout') {
        setToken({ token: null });
        removeCookie('jwtToken');
        removeCookie('jwtTokenExpDate');
        dispatch(resetUser());
        dispatch(resetLists());
        history.push('/');
        localStorage.removeItem('logout');
      }
    }

    window.addEventListener('storage', logOut);

    return () => {
      window.removeEventListener('storage', logOut);
    };
  }, [dispatch, history]);

  // logout from current open app
  const handleLogout = () => {
    setToken({ token: null });
    removeCookie('jwtToken');
    removeCookie('jwtTokenExpDate');
    dispatch(resetUser());
    dispatch(resetLists());
    localStorage.setItem('logout', JSON.stringify(Date.now()));
    history.push('/');
  };

  useEffect(() => {
    if (jwtTokenExpDate) {
      const currentTime = new Date().getTime();
      if (currentTime > Number(jwtTokenExpDate)) {
        handleLogout();
      }
    }
  });

  return (
    <>
      <Header
        userName={user?.name}
        ldapName={user?.ldapName}
        userRole={user?.role}
        onLogoutClick={handleLogout}
      />
      <Container>{children}</Container>
    </>
  );
};

export default AppContainer;
