import { FC } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { useUserData } from 'reducers/selectors';
import { getCookie, isTokenExpired } from 'utils/auth';

type Props = {
  path: string;
};

const AdminRoute: FC<Props> = ({ path, ...rest }) => {
  const token = getCookie('jwtToken');
  const isExpired = isTokenExpired();
  const user = useUserData();

  if (token && !isExpired && user && (user.role === 'SUPER' || user.role === 'ADMIN')) {
    return <Route path={path} {...rest} exact />;
  }

  if (token && !isExpired) {
    return <Redirect to="/main/project-list" />;
  }

  return <Redirect to="/" />;
};

export default AdminRoute;
