import { FC, useState, useEffect } from 'react';

import Option, { OptionType } from './Option';
import { SortStyled, TitleStyled, OptionsStyled, SelectedOptionStyled } from './Sort.styled';

const Designation = {
  FULL: `Escape`,
  ABBREVIATED: `Esc`,
};

export type Props = {
  title: string;
  options: OptionType[];
  onChange: (value: string) => void;
  value: string;
};

const Sort: FC<Props> = ({ title, options, onChange, value = '' }) => {
  const [isHiddenOptions, setHiddenOptions] = useState(true);
  const optionClickHandler = (currentValue: string) => {
    if (value !== currentValue) {
      onChange(currentValue);
    }
    setHiddenOptions(true);
  };

  const selectClickHandler = () => setHiddenOptions((prevState) => !prevState);

  useEffect(() => {
    const documentClickHandler = () => {
      setHiddenOptions(true);
    };

    const documentEscKeydown = (event: KeyboardEvent) => {
      const isEscKey = event.key === Designation.FULL || event.key === Designation.ABBREVIATED;
      if (isEscKey) {
        setHiddenOptions(true);
      }
    };

    if (!isHiddenOptions) {
      document.addEventListener(`click`, documentClickHandler);
      document.addEventListener(`keydown`, documentEscKeydown);
    }

    return () => {
      document.removeEventListener(`click`, documentClickHandler);
      document.removeEventListener(`keydown`, documentEscKeydown);
    };
  }, [isHiddenOptions]);

  return (
    <SortStyled>
      <TitleStyled onClick={selectClickHandler}>{title}</TitleStyled>
      <SelectedOptionStyled onClick={selectClickHandler}>
        {options.find((it) => it.value === value)?.label}
      </SelectedOptionStyled>
      <OptionsStyled isHidden={isHiddenOptions}>
        {options.map((option) => (
          <Option
            option={option}
            onClick={optionClickHandler}
            isActive={option.value === value}
            isVisible={!isHiddenOptions}
            key={option.value}
          />
        ))}
      </OptionsStyled>
    </SortStyled>
  );
};

export default Sort;
