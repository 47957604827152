import { DragEvent, FC, useContext } from 'react';
import { updateGroupSubTasksStatus, updateSubTaskStatus, updateTaskStatus } from 'services/tasks';
import Context, { DetailsContext } from '../context';
import StatusBoard from '../StatusBoard';
import Task from '../Task';
import { BoardType, TaskType } from '../types';
import { BoardWrap, BoardContainer } from './Board.styled';
import {
  changeBoards,
  changeDetails,
  deleteSubTask,
  deleteTask,
  findIndexSubTask,
  pushSubTask,
  pushTask,
} from './utils';

type Props = {
  board: BoardType;
  boards: BoardType[];
};

const Board: FC<Props> = ({ board, boards }) => {
  const { onChangeBoard, currentValues } = useContext(Context);
  const { details, setDetails } = useContext(DetailsContext);
  const { tasks, status } = board;

  const onDrop = (event: DragEvent<HTMLDivElement>) => {
    const currentTarget = event.currentTarget as HTMLDivElement;
    currentTarget.style.border = 'none';
    currentTarget.style.backgroundColor = '#faf9fa';
    const { current } = currentValues;
    if (!current) {
      return;
    }

    if (current.currentBoard.id === board.id) {
      return;
    }

    const isTaskYet = tasks.some((task: TaskType) => task.id === current.currentTask.id);

    if (current.currentType === 'subTask' && current.currentSubTask) {
      if (isTaskYet) {
        updateSubTaskStatus(current.currentSubTask.id, status.id);
        pushSubTask(tasks, current.currentTask, current.currentSubTask);
      } else {
        updateSubTaskStatus(current.currentSubTask.id, status.id);
        pushTask(tasks, current.currentTask, current.currentSubTask);
      }
      const currentIndex = findIndexSubTask(
        current.currentBoard,
        current.currentTask,
        current.currentSubTask,
      );
      deleteSubTask(current.currentBoard, current.currentTask, currentIndex);
      changeDetails(
        'currentSubTask',
        'subTaskItem',
        setDetails,
        details,
        current.currentSubTask,
        status,
      );
    }
    if (current.currentType === 'task') {
      if (isTaskYet) {
        updateGroupSubTasksStatus(current.currentTask.substories, status.id);
        tasks
          .find((task) => task.id === current.currentTask.id)
          ?.substories.push(...current.currentTask.substories);
      } else {
        updateTaskStatus(current.currentTask.id, status.id);
        updateGroupSubTasksStatus(current.currentTask.substories, status.id);
        tasks.push(current.currentTask);
      }
      const currentIndex = current.currentBoard.tasks.indexOf(current.currentTask);
      deleteTask(current.currentBoard.tasks, currentIndex);

      changeDetails('currentTask', 'taskItem', setDetails, details, current.currentTask, status);
    }

    onChangeBoard(changeBoards(boards, board, current.currentBoard));
  };

  const onDragLeave = (event: DragEvent<HTMLDivElement>) => {
    const currentTarget = event.currentTarget as HTMLDivElement;
    currentTarget.style.border = 'none';
    currentTarget.style.backgroundColor = '#faf9fa';
  };

  const onDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const currentTarget = event.currentTarget as HTMLDivElement;
    currentTarget.style.border = '1px dashed #3991f3';
    currentTarget.style.backgroundColor = '#d2e5fa';
  };

  return (
    <BoardWrap>
      <StatusBoard name={status.name} />
      <BoardContainer onDrop={onDrop} onDragOver={onDragOver} onDragLeave={onDragLeave}>
        {tasks?.map((task: TaskType) => (
          <Task key={task.id} task={task} board={board} />
        ))}
      </BoardContainer>
    </BoardWrap>
  );
};
export default Board;
