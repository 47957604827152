import { nanoid } from 'nanoid';

type CurrentMonth = [string, Date[]];

const useCurrentMonthValues = ({ selectedMonth }: { selectedMonth: Date }): CurrentMonth[] => {
  const firstDayMonth = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), 1);
  const firstDayWeek = new Date(
    firstDayMonth.setDate(firstDayMonth.getDate() - firstDayMonth.getDay()),
  );
  const calendar: Date[][] = [];

  for (let row = 0; row < 5; row += 1) {
    calendar.push([]);
    for (let cell = 0; cell < 7; cell += 1) {
      calendar[row].push(
        new Date(firstDayWeek.setDate(firstDayWeek.getDate() + (row + cell === 0 ? 0 : 1))),
      );
    }
  }

  const calendarWithId: { [key: string]: Date[] } = calendar.reduce(
    (accumulator, currentValue) => ({
      ...accumulator,
      [nanoid()]: currentValue,
    }),
    {},
  );

  return Object.entries(calendarWithId);
};

export default useCurrentMonthValues;
