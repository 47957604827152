import { FC } from 'react';

import {
  Card,
  CardImg,
  CardTitle,
  CardCompany,
  CardFooter,
  CardStatus,
  CardDeadline,
} from './ProjectCard.styled';

export type Props = {
  title: string;
  company: string;
  status: string;
  deadline: Date | string | number;
  imgSrc?: string;
};

const ProjectCard: FC<Props> = ({ title, company, imgSrc, status, deadline }) => {
  const date = typeof deadline === 'number' ? new Date(deadline) : deadline;
  let preparedDate = date;
  if (date instanceof Date) {
    const month = date.toLocaleString('en', { month: 'long' }).substr(0, 3);
    const day = date.getDate();
    const year = date.getFullYear();

    preparedDate = `${month} ${day}, ${year}`;
  }

  return (
    <Card>
      <CardImg src={imgSrc || ' '} alt={title} loading="lazy" />
      <CardTitle>{title}</CardTitle>
      <CardCompany>{company}</CardCompany>
      <CardFooter>
        <CardStatus>{status}</CardStatus>
        <CardDeadline>
          Deadline <span>{preparedDate}</span>
        </CardDeadline>
      </CardFooter>
    </Card>
  );
};

export default ProjectCard;
