import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import get from 'lodash/get';

import Select from 'components/Select/v2';
import { Data } from 'components/Select/v2/Item';
import { VALIDATION_MESSAGES } from 'constants/common';
import { Box, SelectBox, ButtonBox } from './ListItem.styled';
import IconsContainer, { IconContainerMode } from '../IconContainer';

type Props = {
  index: number;
  mode: IconContainerMode;
  itemList: Data[];
  onClick: (index: number) => void;
};

const ListItem: FC<Props> = ({ index, mode, itemList, onClick }) => {
  const { control, errors } = useFormContext();

  const handleClick = () => {
    onClick(index);
  };

  return (
    <Box>
      <Controller
        defaultValue=""
        rules={{ required: { value: true, message: VALIDATION_MESSAGES.required } }}
        name={`employees[${index}].value`}
        control={control}
        render={({ value, onChange }, { invalid }) => (
          <SelectBox>
            <Select
              verticalLabel
              label="Employee Name"
              mb={16}
              isError={invalid}
              isRequired
              placeholder="Select an employee"
              value={value}
              itemList={itemList}
              errorMessage={get(errors, `employees[${index}].value.message`)}
              onChange={onChange}
            />
          </SelectBox>
        )}
      />
      <ButtonBox>
        <IconsContainer onClick={handleClick} mode={mode} />
      </ButtonBox>
    </Box>
  );
};

export default ListItem;
