import { FC } from 'react';
import { css, useTheme } from '@emotion/react';
import { Controller, Control, ArrayField } from 'react-hook-form';

import Select from 'components/Select/v2';
import Input from 'components/Input';
import Icon from 'components/Icon';
import { Data } from 'components/Select/v2/Item';
import { ContactType } from './types';
import { ButtonRound, EmployeeContainer, InputContainer } from './EmployeeForm.styled';

const style = css`
  width: 200px;
  margin-left: 10px;
`;

type Props = {
  control: Control;
  index: number;
  item: Partial<ArrayField<ContactType, 'id'>>;
  fields: Partial<ArrayField<ContactType, 'id'>>[];
  itemsList: Data[];
  onAddItem: (value: number) => void;
  onDeleteItem: (value: number) => void;
};

const ContactItem: FC<Props> = ({
  control,
  item,
  fields,
  index,
  itemsList,
  onAddItem,
  onDeleteItem,
}) => {
  const { colors } = useTheme();

  const handleAddItem = () => {
    onAddItem(index);
  };

  const handleRemoveItem = () => {
    onDeleteItem(index);
  };

  return (
    <InputContainer>
      <EmployeeContainer>
        <Controller
          name={`contacts[${index}].type`}
          control={control}
          defaultValue={item.type}
          render={({ onChange, value }) => (
            <Select itemList={itemsList} width={140} onChange={onChange} value={value} />
          )}
        />
        <Controller
          name={`contacts[${index}].value`}
          control={control}
          defaultValue={item.value === null ? '' : item.value}
          render={({ onChange, value, name }) => (
            <Input name={name} value={value} inputStyles={style} onChange={onChange} />
          )}
        />
        <ButtonRound type="button" onClick={handleRemoveItem} empty={fields.length === 1}>
          <Icon name="delete" color={colors.blue[300]} />
        </ButtonRound>
        <ButtonRound type="button" onClick={handleAddItem}>
          <Icon name="plus" color={colors.blue[300]} />
        </ButtonRound>
      </EmployeeContainer>
    </InputContainer>
  );
};

export default ContactItem;
