import { FC } from 'react';
import Icon from 'components/Icon';
import Container from './IconContainer.styled';

type Props = {
  rotateLeft?: boolean;
  onClick: (nextMonth: boolean) => void;
};

const IconContainer: FC<Props> = ({ rotateLeft = false, onClick }) => {
  const handleSetMonth = () => {
    onClick(!rotateLeft);
  };

  return (
    <Container rotateLeft={rotateLeft}>
      <Icon onClick={handleSetMonth} name="angleUp" />
    </Container>
  );
};

export default IconContainer;
