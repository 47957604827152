import React, { useState, FC, ChangeEvent } from 'react';
import { AppScaleEnum } from 'pages/EstimationDetailPage/Gantt/models/AppScaleEnum';
import { useDispatch } from 'react-redux';
import { setAppScale } from 'reducers/gantt';
import { ScaleSelectorContainer, StyledInput, StyledUl, StyledLi } from './ScaleSelectorStyles';

export const numberToAppScale = (value: number): AppScaleEnum => {
  switch (value) {
    case 1:
      return AppScaleEnum.Days;
    case 2:
      return AppScaleEnum.Weeks;
    case 3:
      return AppScaleEnum.Months;
    default:
      return AppScaleEnum.Days;
  }
};

export const ScaleSelector: FC = () => {
  const [scale, setScale] = useState<number>(1);
  const dispatch = useDispatch();

  return (
    <ScaleSelectorContainer>
      <StyledInput
        id="scaleSelector"
        list="scaleSelectorList"
        type="range"
        min={1}
        max={3}
        step={1}
        value={scale}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setScale(+e.target.value);
          dispatch(
            setAppScale({
              key: numberToAppScale(+e.target.value),
              value: +e.target.value,
            }),
          );
        }}
      />
      <StyledUl>
        <StyledLi>1Д</StyledLi>
        <StyledLi>1Н</StyledLi>
        <StyledLi>1М</StyledLi>
      </StyledUl>
    </ScaleSelectorContainer>
  );
};
