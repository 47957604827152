import React, { ReactElement } from 'react';
import { Subtitle } from './TaskDetail.styled';

type Props = {
  child: string | ReactElement | Date;
  name: string;
};
const StringDetail = ({ child, name }: Props): JSX.Element => {
  return (
    <div>
      {child ? (
        <div>
          <Subtitle>{name}</Subtitle> <span> {child}</span>
        </div>
      ) : null}
    </div>
  );
};

export default StringDetail;
