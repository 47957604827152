/* eslint-disable-next-line import/prefer-default-export */
export const updateIfIdMatches = <T extends { id: number }>(
  id: number,
  updater: (item: T) => T,
): ((item: T) => T) => (item: T) => {
  if (item.id !== id) {
    return item;
  }

  return updater(item);
};
