import styled from '@emotion/styled';

export const PhraseStyled = styled.p`
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.gray[550]};
  width: 100%;

  & .icon {
    margin-right: 5px;
    color: ${({ theme }) => theme.colors.gray[550]};
  }
`;

export const StarStyled = styled.span`
  color: ${({ theme }) => theme.colors.red[400]};
`;

export const FormStyled = styled.form`
  width: 1245px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 16px;
  justify-content: space-between;
`;

type WrapperProps = {
  width?: number;
};

export const WrapperStyled = styled.div<WrapperProps>`
  border: 1px solid ${({ theme }) => theme.colors.gray[200]};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.white[400]};
  padding: 20px;
  margin-bottom: 16px;
  width: ${({ width }) => (width ? `${width}%` : 'calc(50% - 8px)')};
`;

export const ContainerStyled = styled.div`
  width: 100%;
  padding-top: 4px;

  & > * {
    margin-right: 15px;
  }
`;

export const SubmitError = styled.div`
  border-radius: 4px;
  display: flex;
  width: 100%;
  color: ${({ theme }) => theme.colors.red[400]};
  background-color: ${({ theme }) => theme.colors.red[100]};
  align-items: center;
  padding: 12px 12px 12px 24px;
  font-weight: 700;
`;
