import { FC } from 'react';

import { BaseContainer, CenterContainer } from './Container.styled';

const Container: FC = ({ children }) => (
  <BaseContainer>
    <CenterContainer>{children}</CenterContainer>
  </BaseContainer>
);

export default Container;
