import styled from '@emotion/styled';

const CompanyStyled = styled.fieldset`
  border: 0;
  margin: 0;
  padding: 0;

  & > * {
    margin-bottom: 16px;
  }
`;

export default CompanyStyled;
