import styled from '@emotion/styled';
import { FlexRowNowrapSpaceBetweenCenter } from 'pages/EstimationDetailPage/Gantt/components/Flex';

export const ScaleSelectorContainer = styled(FlexRowNowrapSpaceBetweenCenter)`
  flex-direction: column;
  margin-top: -30px;
`;

export const StyledInput = styled.input`
  /* stylelint-disable */
  -webkit-appearance: none;
  /* stylelint-enable */
  margin: 4px 0;
  width: 100%;
  background: #faf9fa;

  &:focus {
    outline: none;
  }
  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    box-shadow: 1px 1px 1px #fcfcfc, 0px 0px 1px #e3e3e3;
    background: linear-gradient(to top, #fcfcfc, #e3e3e3);
    border-radius: 3px;
    border: 0.2px solid #e3e3e3;
  }
  &::-webkit-slider-thumb {
    box-shadow: 1px 1px 1px #ffffff, 0px 0px 1px #e3e3e3;
    border: 1px solid #2196f3;
    height: 20px;
    width: 20px;
    border-radius: 10px;
    background: #2196f3;
    cursor: pointer;
    /* stylelint-disable */
    -webkit-appearance: none;
    /* stylelint-enable */
    margin-top: -6px;
  }
  &:focus::-webkit-slider-runnable-track {
    background: linear-gradient(to top, #ffffff, #e3e3e3);
  }
  &::-moz-range-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    box-shadow: 1px 1px 1px #fcfcfc, 0px 0px 1px #e3e3e3;
    background: linear-gradient(to top, #ffffff, #e3e3e3);
    border-radius: 3px;
    border: 0.2px solid #e3e3e3;
  }
  &::-moz-range-thumb {
    border: 1px solid #2196f3;
    height: 20px;
    width: 20px;
    border-radius: 10px;
    background: #2196f3;
    cursor: pointer;
  }
  &::-ms-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
  }
  &::-ms-fill-lower {
    background: linear-gradient(to top, #ffffff, #e3e3e3);
    border: 1px solid #e4e6ec;
    border-radius: 3px;
    box-shadow: 1px 1px 1px #ffffff, 0px 0px 1px #e3e3e3;
  }
  &::-ms-fill-upper {
    background: linear-gradient(to top, #ffffff, #e3e3e3);
    border: 1px solid #e4e6ec;
    border-radius: 3px;
    box-shadow: 1px 1px 1px #ffffff, 0px 0px 1px #e3e3e3;
  }
  &::-ms-thumb {
    box-shadow: 1px 1px 1px #ffffff, 0px 0px 1px #e3e3e3;
    border: 1px solid #2196f3;
    height: 16px;
    width: 16px;
    border-radius: 8px;
    background: #2196f3;
    cursor: pointer;
  }
  &:focus::-ms-fill-lower {
    background: linear-gradient(to top, #ffffff, #e3e3e3);
  }
  &:focus::-ms-fill-upper {
    background: linear-gradient(to top, #ffffff, #e3e3e3);
  }
`;

export const StyledUl = styled.ul`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: top;
`;

export const StyledLi = styled.li`
  list-style: none;
  padding: 2px 0;
`;
