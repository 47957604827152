import { TaskType, SubTaskType, BoardType, DetailsType } from '../types';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const pushSubTask = (
  tasks: TaskType[],
  currentTask: TaskType,
  currentSubTask: SubTaskType,
): void => {
  tasks.find((task: TaskType) => task.id === currentTask.id)?.substories.push(currentSubTask);
};

export const pushTask = (
  tasks: TaskType[],
  currentTask: TaskType,
  currentSubTask: SubTaskType,
): void => {
  const task = {
    ...currentTask,
    substories: currentTask.substories.filter((subTask) => subTask.id === currentSubTask.id),
  };
  tasks.push(task);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const findIndexSubTask = (
  currentBoard: BoardType,
  currentTask: TaskType,
  currentSubTask: SubTaskType,
): number | undefined =>
  currentBoard.tasks.find((task) => task.id === currentTask.id)?.substories.indexOf(currentSubTask);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const deleteSubTask = (
  currentBoard: BoardType,
  currentTask: TaskType,
  currentIndex: number | undefined,
): void => {
  currentBoard.tasks
    .find((task) => task.id === currentTask.id)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    ?.substories.splice(currentIndex!, 1);
};

export const deleteTask = (tasks: TaskType[], currentIndex: number): void => {
  tasks.splice(currentIndex, 1);
};

export const changeBoards = (
  boards: BoardType[],
  board: BoardType,
  currentBoard: BoardType,
): BoardType[] =>
  boards
    .map((item) => {
      if (item.id === board.id) {
        return board;
      }
      if (board.id === currentBoard.id) {
        return currentBoard;
      }

      return item;
    })
    .map((item) => {
      return {
        ...item,
        tasks: item.tasks.filter(
          (task: TaskType) => task.substories.length || currentBoard.sectionType !== 'DEVELOPMENT',
        ),
      };
    });

export const changeDetails = (
  value: string,
  variables: string,
  setDetails: React.Dispatch<React.SetStateAction<DetailsType>>,
  details: DetailsType,
  current: SubTaskType | TaskType,
  status: { name: string; id: number },
): void => {
  setDetails({
    showDetails: details.showDetails,
    [variables]: current,
    idItem: details.idItem,
    prev: details.prev,
    additionalInfo: details.additionalInfo,
  });
  setDetails(
    (prevDetails: DetailsType): DetailsType => {
      const { taskItem, subTaskItem } = prevDetails;
      if (taskItem) {
        taskItem.taskStatus.title = status.name;
      }
      if (subTaskItem) {
        subTaskItem.taskStatus.title = status.name;
      }

      return { ...prevDetails };
    },
  );
};
