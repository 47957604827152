import { FC, useEffect, useState } from 'react';
import Button from 'components/Button';
import { useParams } from 'react-router-dom';
import { getProjectFiles } from 'services/project';
import { ProjectFile, FileSubType } from 'services/types/File';
import { deleteFile, getFile, postFile } from 'services/file';
import { useDropzone } from 'react-dropzone';
import Tabs, { ListItem } from './Tabs';
import Table, { FileData } from './Table';
import { ButtonBox, MarginBox, TableBox } from './Files.styled';

const tabList: ListItem[] = [
  { id: 1, title: 'initial documents', fileType: 'INITIAL_DOC' },
  { id: 2, title: 'internal documents', fileType: 'INTERNAL_DOC' },
  { id: 3, title: 'artifacts', fileType: 'ARTIFACT' },
];

const Files: FC = () => {
  const { id } = useParams<{ id: string }>();
  const [files, setFiles] = useState<ProjectFile[]>([]);
  const [activeType, setActiveType] = useState<FileSubType>('INITIAL_DOC');
  const { isDragActive, open, getInputProps, getRootProps } = useDropzone({
    multiple: true,
    noClick: true,
    noKeyboard: true,

    onDrop: (droppedFiles) => {
      droppedFiles.forEach((droppedFile) => {
        const res = files.find((file) => file.fileName === droppedFile.name);
        if (res) {
          return;
        }

        const formData = new FormData();
        formData.append('file', droppedFile);

        postFile(formData, {
          fileSubType: activeType,
          fileType: 'DOCUMENT',
          ownerId: id,
          ownerType: 'PROJECT',
        }).then(({ uuid }) => {
          setFiles((prev) => [
            ...prev,
            {
              fileName: droppedFile.name,
              uuid,
              date: droppedFile.lastModified,
              canEdit: true,
              extension: droppedFile.name.split('.').pop() || '',
            },
          ]);
        });
      });
    },
  });

  useEffect(() => {
    getProjectFiles(id, activeType).then((data) => {
      setFiles(data);
    });
  }, [id, activeType]);

  const changeTypeHandle = (item: ListItem) => {
    setActiveType(item.fileType);
  };

  const deleteFileHandle = (file: FileData) => {
    deleteFile(file.uuid).then(() => {
      setFiles(files.filter((elm) => elm.uuid !== file.uuid));
    });
  };

  const downloadFileHandle = (file: FileData) => {
    getFile(file.uuid).then((data) => {
      const url = URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file.fileName);
      link.click();
    });
  };

  let activeClassName;
  if (isDragActive) {
    activeClassName = 'active';
  }

  return (
    <>
      <ButtonBox>
        <input {...getInputProps()} />
        <Button type="button" styleType="add" label="ADD FILES" onClick={open} />
      </ButtonBox>
      <MarginBox>
        <Tabs list={tabList} onChange={changeTypeHandle} />
      </MarginBox>
      <TableBox {...getRootProps()} className={activeClassName}>
        <Table files={files} onDeleteFile={deleteFileHandle} onDownloadFile={downloadFileHandle} />
      </TableBox>
    </>
  );
};
export default Files;
