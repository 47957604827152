import AppStory from 'pages/EstimationDetailPage/Gantt/models/AppStory';
import { protectedGet } from 'services/protectedAxios';
import { Relation } from './gantt-put.request';

// export const GANTT_GET_REQUEST_URL = '/api/gantt';
export const GANTT_GET_REQUEST_URL = '/api/tasks';

export interface ResponseTask {
  id: number;
  name: string;
  duration: number;
  dateStart: string;
  children: ResponseTask[];
  relations?: Relation[] | undefined;
}

export interface SectionType {
  id: number;
  title: string;
}

export interface GanttGetRequestResponse {
  id: number;
  number: string;
  sectionType: SectionType;
  stories: AppStory[];
  title: string;
  expanded?: boolean;
}

export const ganttGetRequest = (estimationId: string): Promise<GanttGetRequestResponse[]> =>
  protectedGet(`${GANTT_GET_REQUEST_URL}/${estimationId}/gantt`);
