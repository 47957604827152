import React, { FC, useCallback } from 'react';
import { Rect } from 'react-konva';
import { KonvaEventObject } from 'konva/types/Node';
import { colors, senlaColors } from '../../../../theme/colors';
import useHover from '../../../../utils/hooks/useHover';

export interface SausageTotalProps {
  color?: string;
  hoverColor?: string;
  x?: number;
  y?: number;
  width: number;
  height?: number;
  onMouseEnter?: (evt: KonvaEventObject<MouseEvent>) => void;
  onMouseLeave?: (evt: KonvaEventObject<MouseEvent>) => void;
}

export const SausageTotal: FC<SausageTotalProps> = ({
  x = 0,
  y = 0,
  color = senlaColors.sausages.senlaRisk,
  hoverColor = senlaColors.sausages.senlaRiskHover,
  width,
  height = colors.sausageTotal.height,
  onMouseEnter: onMouseEnterFromProps,
  onMouseLeave: onMouseLeaveFromProps,
}) => {
  const {
    onMouseEnter: onMouseEnterFromHook,
    onMouseLeave: onMouseLeaveFromHook,
    hover,
  } = useHover();
  const bgColor = hover ? hoverColor : color;

  const onMouseEnter = useCallback(
    (evt: KonvaEventObject<MouseEvent>) => {
      if (onMouseEnterFromProps) {
        onMouseEnterFromProps(evt);
      }
      onMouseEnterFromHook();
    },
    [onMouseEnterFromHook, onMouseEnterFromProps],
  );

  const onMouseLeave = useCallback(
    (evt: KonvaEventObject<MouseEvent>) => {
      if (onMouseLeaveFromProps) {
        onMouseLeaveFromProps(evt);
      }
      onMouseLeaveFromHook();
    },
    [onMouseLeaveFromHook, onMouseLeaveFromProps],
  );

  return (
    <Rect
      x={x}
      y={y}
      width={width}
      height={height}
      fill={bgColor}
      cornerRadius={3}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  );
};
