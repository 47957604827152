import React, { FC, useEffect, useState } from 'react';
import { ReactReduxContext, Provider } from 'react-redux';
import { Group, Layer, Rect, Stage } from 'react-konva';
import { KonvaEventObject } from 'konva/types/Node';
import AppSection from 'pages/EstimationDetailPage/Gantt/models/AppSection';
import { AppCamera } from 'pages/EstimationDetailPage/Gantt/models/AppCamera';
import AppStory from 'pages/EstimationDetailPage/Gantt/models/AppStory';
import { colors } from 'pages/EstimationDetailPage/Gantt/theme/colors';
import { useGanntStartPoint, useGanttScaleValue } from 'reducers/selectors';
import {
  DatePeriod,
  MainModelWithSizes,
  OnStoryContextMenu,
  SetStoryId,
  SetIsWaiting,
} from '../../types';
import { SUBSTORY_HEIGHT_PX, TIMELINE_COLUMN_SIZE } from '../../consts';
import TimeLine from '../TimeLine/TimeLine';
import { getCanvasSizes, getCanvasWidth } from '../../utils';
import { Section } from '../Section/Section';

const CANVAS_SIZES = {
  width: 1000,
  height: 600,
};

export interface RightCanvasProps {
  datePeriod: DatePeriod;
  modelWithSizes: MainModelWithSizes;
  storyId: number | null;
  setStoryId: SetStoryId;
  isWaiting: boolean;
  setIsWaiting: SetIsWaiting;
  appCamera: AppCamera;
  onWheel: (evt: KonvaEventObject<WheelEvent>) => void;
  onSausageDragStart?: (
    section: AppSection,
    story: AppStory,
    evt: KonvaEventObject<DragEvent>,
  ) => void;
  onSausageDragMove?: (
    section: AppSection,
    story: AppStory,
    evt: KonvaEventObject<DragEvent>,
  ) => void;
  onSausageDragEnd?: (section: AppSection, story: AppStory) => void;
  onStoryContextMenu: OnStoryContextMenu;
  onSausageClick: (section: AppSection, story: AppStory, evt: KonvaEventObject<MouseEvent>) => void;
}

export const RightCanvas: FC<RightCanvasProps> = ({
  datePeriod,
  modelWithSizes,
  storyId,
  setStoryId,
  isWaiting,
  setIsWaiting,
  onWheel,
  appCamera,
  onSausageDragStart,
  onSausageDragMove,
  onSausageDragEnd,
  onStoryContextMenu,
  onSausageClick,
}) => {
  const [camera, setCamera] = useState({ x: appCamera.x, y: 0, scale: 1 });
  const canvasSizes = getCanvasSizes(modelWithSizes);
  const offsetX = appCamera.x;
  const canvasWidth = getCanvasWidth(datePeriod, camera.scale, TIMELINE_COLUMN_SIZE);
  const scaleValue = useGanttScaleValue();
  const startXPoint = useGanntStartPoint();

  useEffect(() => {
    setCamera((cameraState) => ({ ...cameraState, scale: scaleValue }));
  }, [scaleValue]);

  return (
    <ReactReduxContext.Consumer>
      {({ store }) => (
        <Stage
          width={canvasWidth}
          height={canvasSizes.height}
          onWheel={onWheel}
          className="rightCanvasBox"
        >
          <Provider store={store}>
            <Layer>
              <Rect
                fill={colors.rightCanvas.backgroundColor}
                width={CANVAS_SIZES.width}
                height={CANVAS_SIZES.height}
              />
            </Layer>
            <Layer>
              <TimeLine
                datePeriod={datePeriod}
                camera={camera}
                appCamera={appCamera}
                calendarHeight={canvasSizes.height}
                x={offsetX}
                y={0}
              />
            </Layer>
            <Layer>
              <Group x={offsetX + startXPoint} y={SUBSTORY_HEIGHT_PX}>
                {modelWithSizes.sections.map((section, sectionIndex) => (
                  <Section
                    groupX={offsetX}
                    groupY={SUBSTORY_HEIGHT_PX}
                    key={section.id}
                    section={section}
                    sectionIndex={sectionIndex}
                    canvasSizes={canvasSizes}
                    datePeriod={datePeriod}
                    modelWithSizes={modelWithSizes}
                    storyId={storyId}
                    setStoryId={setStoryId}
                    isWaiting={isWaiting}
                    setIsWaiting={setIsWaiting as SetIsWaiting}
                    onSausageDragStart={onSausageDragStart}
                    onSausageDragMove={onSausageDragMove}
                    onSausageDragEnd={onSausageDragEnd}
                    onStoryContextMenu={onStoryContextMenu}
                    onSausageClick={onSausageClick}
                  />
                ))}
              </Group>
            </Layer>
          </Provider>
        </Stage>
      )}
    </ReactReduxContext.Consumer>
  );
};
