import { FC } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { Contact } from 'services/types/Client';
import ContactItem from './ContactItem';

type Props = {
  nestIndex: number;
};

const Contacts: FC<Props> = ({ nestIndex }) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `employees[${nestIndex}].contacts`,
  });

  const handleDelete = (index: number) => {
    remove(index);
  };

  const handleAppend = (value: Contact) => {
    append(value);
  };

  return (
    <>
      {fields.map(({ id, type, value }, index) => {
        const isFirst = index > 0;
        const isLast = index === fields.length - 1;

        return (
          <ContactItem
            key={id}
            nestIndex={nestIndex}
            index={index}
            onAppend={handleAppend}
            onRemove={handleDelete}
            type={type}
            defaultValue={value}
            isRemove={isFirst}
            isAdd={isLast}
          />
        );
      })}
    </>
  );
};

export default Contacts;
