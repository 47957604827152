import React, { FC, ReactNode } from 'react';
import { StyledDefaultContentLayout } from './DefaultContentLayoutStyles';

export interface DefaultContentLayoutProps {
  leftMenuSlot?: ReactNode;
  canvasSlot?: ReactNode;
}

export const DefaultContentLayout: FC<DefaultContentLayoutProps> = ({
  leftMenuSlot,
  canvasSlot,
}) => (
  <StyledDefaultContentLayout>
    {leftMenuSlot}
    {canvasSlot}
  </StyledDefaultContentLayout>
);
