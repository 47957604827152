import styled from '@emotion/styled';

export const TaskContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  background-color: ${({ theme }) => theme.colors.blue[50]};
  border-left: 8px solid rgba(29, 125, 232, 0.5);
  cursor: pointer;
  padding: 8px 12px;

  & > span {
    color: ${({ theme }) => theme.colors.gray[950]};
  }

  & span:last-child {
    font-weight: 500;
  }

  &:hover > div {
    visibility: visible;
  }
`;

export const Tooltip = styled.div`
  position: absolute;
  bottom: 45px;
  left: 1.5vw;
  word-wrap: break-word;
  text-align: center;
  padding: 12px 16px;
  visibility: hidden;
  max-width: 220px;
  width: 95%;
  height: 75px;
  color: ${({ theme }) => theme.colors.white[400]};
  font-weight: 500;
  background-color: ${({ theme }) => theme.colors.gray[950]};
  border-radius: 2px;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 15vh;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 10px solid ${({ theme }) => theme.colors.gray[950]};
    transform: rotate(180deg);
  }
`;

export const TaskTitle = styled.span`
  width: 75%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ButtonSubtask = styled.div``;
