import { css, keyframes } from '@emotion/react';

export const loadEventAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(30px);
  }

  50% {
    opacity: 25%;
    transform: translateY(-2px);
  }

  100% {
    opacity: 100%;
    transform: translateY(0px);
  }
`;

export const loadEventAnimationDefaultCss = css`
  animation: 0.2s ${loadEventAnimation} ease-in-out;
`;
