import { ChangeEvent, FocusEvent, FC, useState, memo, useEffect, useRef } from 'react';
import styled from '@emotion/styled';

import usePreviousValue from 'utils/hooks/usePreviousValue';

const Container = styled.div`
  min-height: 32px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  cursor: text;
`;

const TextArea = styled.textarea`
  border: 1px solid transparent;
  line-height: 20px;
  padding: 5px 0 5px 5px;
  resize: none;
  width: 100%;
  margin-top: 2px;
`;

type Props = {
  value: null | string;
  onChange: (value: string) => void;
  onBlur: () => void;
  canEdit?: boolean;
};

const TextAreaField: FC<Props> = ({ value, onChange, onBlur, canEdit }) => {
  const [isActive, setIsActive] = useState(false);
  const ref = useRef<HTMLTextAreaElement>(null);
  const previousValue = usePreviousValue(value);

  useEffect(() => {
    if (isActive) {
      ref.current?.focus();
    }
  }, [isActive]);

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { value: newValue } = event.target;
    onChange(newValue.trimLeft());
  };

  const handleShowInput = () => {
    if (canEdit) return;
    setIsActive(true);
  };

  const handleHideInput = () => {
    setIsActive(false);
    if (previousValue !== value) {
      onBlur();
    }
  };

  const handleFocus = (event: FocusEvent<HTMLTextAreaElement>) => {
    event.currentTarget.setSelectionRange(
      event.currentTarget.value.length,
      event.currentTarget.value.length,
    );
  };

  return (
    <Container onClick={handleShowInput}>
      {isActive && !canEdit ? (
        <TextArea
          ref={ref}
          onFocus={handleFocus}
          onChange={handleChange}
          onBlur={handleHideInput}
          rows={2}
          value={value || ''}
        />
      ) : (
        <p>{value || ''}</p>
      )}
    </Container>
  );
};

export default memo(TextAreaField);
