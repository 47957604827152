import { FC, RefObject, ReactChild, useEffect, useRef, useState } from 'react';

import { PopoverBox, PositionedBox } from './Popover.styled';

type Vertical = 'top' | 'center' | 'bottom';
type Horizontal = 'left' | 'center' | 'right';

const DEFAULT_VERTICAL_ORIGIN: Vertical = 'top';
const TRIGGER_DEFAULT_VERTICAL_ORIGIN: Vertical = 'bottom';
const DEFAULT_HORIZONTAL_ORIGIN: Horizontal = 'left';

export type OriginParam = {
  vertical?: Vertical;
  horizontal?: Horizontal;
};

export type Props = {
  children: ReactChild;
  triggerOrigin?: OriginParam;
  popoutOrigin?: OriginParam;
  defaultOpen?: boolean;
  trigger: RefObject<HTMLElement>;
};

const getVerticalAlign = (sideSize: number, align: Vertical) => {
  if (align === 'center') {
    return sideSize / 2;
  }
  if (align === 'top') {
    return sideSize;
  }

  return 0;
};

const getHorizontalAlign = (sideSize: number, align: Horizontal) => {
  if (align === 'center') {
    return sideSize / 2;
  }
  if (align === 'right') {
    return sideSize;
  }

  return 0;
};

const getSize = (elm?: HTMLElement) => [elm?.clientWidth || 0, elm?.clientHeight || 0];

const Popover: FC<Props> = ({
  children,
  triggerOrigin = {
    vertical: TRIGGER_DEFAULT_VERTICAL_ORIGIN,
    horizontal: DEFAULT_HORIZONTAL_ORIGIN,
  },
  popoutOrigin = { vertical: DEFAULT_VERTICAL_ORIGIN, horizontal: DEFAULT_HORIZONTAL_ORIGIN },
  defaultOpen = false,
  trigger,
}) => {
  const popoutRef = useRef<HTMLDivElement>(null);

  const [{ x, y }, setPosition] = useState({ x: 0, y: 0 });

  const [open, setOpen] = useState(defaultOpen);
  const menuHide = () => setOpen(false);
  const menuShow = () => setOpen(true);

  useEffect(() => {
    const triggerEl = trigger?.current as HTMLElement;
    const menuEl = popoutRef.current?.firstChild as HTMLElement;

    const [triggerWidth, triggerHeight] = getSize(triggerEl);
    const [popoutWidth, popoutHeight] = getSize(menuEl);

    let left = getHorizontalAlign(
      triggerWidth,
      triggerOrigin.horizontal || DEFAULT_HORIZONTAL_ORIGIN,
    );
    let top = -getVerticalAlign(
      triggerHeight,
      triggerOrigin.vertical || TRIGGER_DEFAULT_VERTICAL_ORIGIN,
    );

    left -= getHorizontalAlign(popoutWidth, popoutOrigin.horizontal || DEFAULT_HORIZONTAL_ORIGIN);
    top -=
      popoutHeight -
      getVerticalAlign(popoutHeight, popoutOrigin.vertical || DEFAULT_VERTICAL_ORIGIN);

    setPosition({ x: left, y: top });

    const menuToggle = (event: MouseEvent) => {
      if (popoutRef.current && !popoutRef.current.contains(event.target as Node) && open) {
        menuHide();
      } else if (triggerEl.contains(event.target as Node)) {
        menuShow();
      }
    };

    document.addEventListener('click', menuToggle);

    return () => {
      document.removeEventListener('click', menuToggle);
    };
  }, [trigger, triggerOrigin, open, popoutOrigin]);

  return open ? (
    <div>
      <PopoverBox>
        <PositionedBox x={x} y={y} ref={popoutRef}>
          {children}
        </PositionedBox>
      </PopoverBox>
    </div>
  ) : null;
};
export default Popover;
