import { FC, MouseEvent } from 'react';

import {
  TitleRow,
  TitleRowCell,
  TitleCellContentBox,
  RowNumber,
  Separator,
} from './styled/Row.styled';
import TextAreaField from './components/TextAreaField';
import {
  useActions,
  useTableData,
  useTechnologiesList,
  useDevelopmentSectionTimeSum,
} from './context';
import { Row } from './types';

type Props = {
  data: Row;
  onContextMenuClick: (value: { clientX: number; clientY: number }) => void;
  canEdit?: boolean;
};

const BodyTitleRow: FC<Props> = ({ data, onContextMenuClick, canEdit }) => {
  const technologiesList = useTechnologiesList();
  const developmentSectionTimeSum = useDevelopmentSectionTimeSum();
  const { updateRowData, setContextMenuRow, onSendData } = useActions();
  const { title, sectionIndex, sectionId, type } = data;

  const tableData = useTableData();

  const sectionSum = tableData
    .filter((row) => row.sectionId === sectionId)
    .reduce((sum, value) => {
      if (type === 'ANALYSIS' && (typeof value.sum === 'number' || typeof value.sum === 'string')) {
        return sum + Number(value.sum);
      }

      if (type === 'MANAGEMENT' && !Number.isNaN(Number(value.sum))) {
        return sum + Math.round((Number(value.sum) * developmentSectionTimeSum) / 100);
      }

      if (type === 'DEVELOPMENT') {
        let technologiesSum = 0;
        technologiesList.forEach((item) => {
          const technologyValue = value[item];
          if (
            technologyValue &&
            (typeof technologyValue === 'number' || typeof technologyValue === 'string')
          ) {
            technologiesSum += Number(technologyValue);
          }
        });

        return sum + technologiesSum;
      }

      return sum;
    }, 0);

  const handleChange = (newValue: string) => {
    updateRowData({
      dataKey: 'title',
      value: newValue,
      rowId: data.rowId,
    });
  };

  const handleBlur = () => {
    onSendData();
  };

  const handleContextMenu = (event: MouseEvent<HTMLTableRowElement>) => {
    event.preventDefault();
    const { pageX: clientX, pageY: clientY } = event;
    setContextMenuRow(data);
    onContextMenuClick({ clientX, clientY });
  };

  return (
    <TitleRow data-id={data.rowId} onContextMenu={handleContextMenu}>
      <TitleRowCell colSpan={technologiesList.length + 2}>
        <TitleCellContentBox>
          <RowNumber>{sectionIndex + 1}</RowNumber>
          <Separator>/</Separator>
          <TextAreaField
            value={title}
            onChange={handleChange}
            onBlur={handleBlur}
            canEdit={canEdit}
          />
        </TitleCellContentBox>
      </TitleRowCell>
      <TitleRowCell>{sectionSum}</TitleRowCell>
    </TitleRow>
  );
};

export default BodyTitleRow;
