import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppScaleEnum } from 'pages/EstimationDetailPage/Gantt/models/AppScaleEnum';

type ScaleState = {
  key: AppScaleEnum;
  value: number;
};

type State = {
  scale: ScaleState;
  startXPoint: number;
};

const initialState: State = {
  scale: {
    key: AppScaleEnum.Days,
    value: 1,
  },
  startXPoint: 0,
};

const ganttSlice = createSlice({
  name: 'gantt',
  initialState,
  reducers: {
    setAppScale(state, action: PayloadAction<ScaleState>) {
      state.scale = action.payload;
    },
    setAppStartPoint(state, action: PayloadAction<number>) {
      state.startXPoint = action.payload;
    },
  },
});

export const { setAppScale, setAppStartPoint } = ganttSlice.actions;

export default ganttSlice.reducer;
