import styled from '@emotion/styled';

export const InfoContainer = styled.div`
  margin-bottom: 8px;
  display: flex;
  align-items: center;

  & .icon {
    margin-right: 5px;
  }
`;

export const InfoText = styled.p`
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.gray[550]};
`;

export const RequiredMark = styled.span`
  color: ${({ theme }) => theme.colors.red[400]};
`;

export const Container = styled.div`
  width: 1245px;
  max-width: 100%;
  margin: 0 auto;
`;

export const Box = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: max-content 1fr;
  margin-bottom: 16px;
`;

export const ProjectDetailsContainer = styled.div`
  grid-row: 1 / 3;
`;

export const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 114px 114px;
  column-gap: 15px;
  padding-bottom: 16px;
`;
