import { useMemo } from 'react';
import { nanoid } from 'nanoid';

import { Column } from './types';
import NumberField from './components/NumberField';
import TextAreaField from './components/TextAreaField';
import DirectionField from './components/DirectionField';

const useGetColumns = <
  T extends {
    sum?: string | number | null | undefined;
    title: string | number | null | undefined;
    [key: string]: string | number | boolean | null | undefined;
  }
>(
  technologies: string[],
): Column<T>[] =>
  useMemo(() => {
    const technologiesColumns: Column<T>[] = technologies.map((technology) => ({
      columnId: nanoid(),
      title: technology,
      dataKey: technology,
      renderCell: NumberField,
      getValue: (row) => row[technology],
    }));

    return [
      {
        columnId: nanoid(),
        title: 'Описание',
        dataKey: 'title',
        renderCell: TextAreaField,
        getValue: (row) => row.title,
      },
      {
        columnId: nanoid(),
        title: 'ROLE',
        dataKey: 'direction',
        renderCell: DirectionField,
        getValue: (row) => row.direction,
      },
      ...technologiesColumns,
      {
        columnId: nanoid(),
        title: 'Сумма',
        dataKey: 'sum',
        renderCell: NumberField,
        getValue: (row) => row.sum,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [technologies]);

export default useGetColumns;
