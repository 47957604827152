/* eslint-disable no-param-reassign */
import { FC } from 'react';
import JsPDF from 'jspdf';

import Icon from 'components/Icon';
import { Person } from 'services/types/EstimationData';
import { GenerateButton } from '../styled/EstimationPage.styled';
import PDFConstants from './PDFConstants';

interface Doc extends JsPDF {
  autoTable: {
    previous: {
      finalY: number;
    };
  };
}

type Props = {
  estimationName: string;
  persons: Person[];
  getContent: (doc: Doc) => void;
};

const GeneratePdf: FC<Props> = ({ estimationName, persons, getContent }) => {
  const downloadDocument = () => {
    const doc = new JsPDF('landscape') as Doc;
    doc.addFileToVFS('Roboto-Regular.ttf', PDFConstants.RobotoRegular);
    doc.addFileToVFS('Roboto-Bold.ttf', PDFConstants.RobotoBold);
    doc.addFont('Roboto-Regular.ttf', 'Roboto', 'normal');
    doc.addFont('Roboto-Bold.ttf', 'Roboto', 'bold');
    doc.setFont('Roboto');

    const senla = PDFConstants.SENLALogoIcon;
    const now = new Date();

    const {
      internal: { pageSize },
    } = doc;
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();

    doc.addImage(senla, 15, 15, 40, 10);

    doc.setFontSize(10);
    doc.setTextColor(141, 144, 151);
    doc.text(['welcom@senla.ru', 'www.senlainc.com'], pageWidth - 15, 20, { align: 'right' });

    doc.setFontSize(16);
    doc.setTextColor(0, 0, 0);
    doc.text(estimationName, 15, 40, { maxWidth: pageWidth / 2 });
    // const splitText = doc.splitTextToSize(estimationName, pageWidth / 2, { fontSize: 16 });
    // const titleHeight = doc.getTextDimensions(splitText, { fontSize: 16 });

    doc.setFontSize(10);
    doc.setTextColor(141, 144, 151);
    doc.text(['Estimation Date:', 'Responced Person:'], pageWidth - 90, 40, {
      align: 'left',
      lineHeightFactor: 1.6,
    });

    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);
    doc.text([now.toLocaleDateString(), persons[0]?.changeableName], pageWidth - 15, 40, {
      align: 'right',
      lineHeightFactor: 1.6,
    });

    getContent(doc);

    const pageHeight = doc.internal.pageSize.height;

    doc.setDrawColor(230, 230, 230);
    doc.setLineWidth(0.2);
    doc.line(0, pageHeight - 25, pageWidth, pageHeight - 25);

    doc.setFontSize(8);
    doc.setTextColor(0, 0, 0);
    doc.text(['230005 Республика Беларусь', 'г.Гродно ул.Гаспадарчая 19'], 15, pageHeight - 15, {
      align: 'left',
    });

    doc.setFontSize(8);
    doc.setTextColor(0, 0, 0);
    doc.text(['email:', 'тел:'], pageWidth - 42, pageHeight - 15, {
      align: 'right',
    });

    doc.setFontSize(8);
    doc.setTextColor(29, 125, 232);
    doc.text(['sales@senlainc.com', '+375 25 988 14 01'], pageWidth - 15, pageHeight - 15, {
      align: 'right',
    });

    doc.save('Estimation.pdf');
  };

  return (
    <GenerateButton type="button" onClick={downloadDocument}>
      <Icon name="add" size={24} className="icon" />
      Generate PDF
    </GenerateButton>
  );
};

export default GeneratePdf;
