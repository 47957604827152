import { protectedDelete, protectedGet, protectedPost } from './protectedAxios';
import { Params, FileId } from './types/File';

const FILE_URL = '/api/file';
const getFileUrl = (id: string | null) => `${FILE_URL}/${id}`;

export const getFile = (id: string | null): Promise<Blob> =>
  protectedGet(
    getFileUrl(id),
    {},
    {
      responseType: 'blob',
    },
  );

export const postFile = (data: FormData, params: Params): Promise<FileId> =>
  protectedPost<FormData, FileId>(FILE_URL, data, params);

export const deleteFile = (id: string): Promise<void> => protectedDelete(getFileUrl(id));
