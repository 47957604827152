import { FC, useContext, useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import { Table } from '../styled/Table.styled';
import {
  StyledHeaderCell,
  StyledHeaderRow,
} from '../../../components/EstimationTable/styled/Header.styled';
import { FooterCell, FooterRow } from '../../../components/EstimationTable/styled/Footer.styled';
import { NewItemType } from './types';
import DirectionRow from './DirectionRow';
import formatDataDirectionTable from './utils';
import TablesContext from '../context';

type DitectionProps = {
  items: Record<string, number>;
};

const columns = [
  { title: 'Направление', columnsId: nanoid() },
  { title: 'Трудозатраты', columnsId: nanoid() },
  { title: 'Ставка', columnsId: nanoid() },
  { title: 'Сумма', columnsId: nanoid() },
];

const DirectionTable: FC<DitectionProps> = ({ items }) => {
  const [total, setTotal] = useState<{ [key: string]: number }>({});
  const [newItems, setNewItems] = useState<{ [key: string]: NewItemType }>({});
  const [totalTime, setTotalTime] = useState<number>(0);
  const { tablesContext, setTablesContext } = useContext(TablesContext);

  useEffect(() => {
    const changeItems: { [key: string]: NewItemType } = {};
    const keys = Object.keys(items);
    keys.forEach((key) => {
      changeItems[key] = {
        value: items[key],
        rate: 0,
        sum: 0,
      };
    });
    setTotalTime(Object.values(changeItems).reduce((acc, val) => acc + val.value, 0));
    setNewItems((prev) => ({ ...prev, ...changeItems }));
  }, [items]);

  const changeTotal = ([key, val]: [string, number]) => {
    setTotal((prev) => ({ ...prev, ...{ [key]: val } }));
  };

  const getTotal = (): number => Object.values(total).reduce((acc, val) => acc + val, 0);

  const getTotalValue = getTotal();

  /* eslint-disable */
  useEffect(() => {
    const data = formatDataDirectionTable(columns, newItems);
    setTablesContext({ ...tablesContext, directionTable: data });
  }, [columns, newItems]);
  /* eslint-enable */

  return (
    <Table id="risksPDF">
      <thead>
        <StyledHeaderRow>
          {columns.map((column) => (
            <StyledHeaderCell key={column.columnsId} offset={0}>
              {column.title}
            </StyledHeaderCell>
          ))}
        </StyledHeaderRow>
      </thead>
      <tbody>
        {Object.entries(newItems).map(([key, value]) => (
          <DirectionRow key={key} keyItem={key} value={value} changeTotal={changeTotal} />
        ))}
      </tbody>
      <tfoot>
        <FooterRow>
          <FooterCell colSpan={1}>Итого:</FooterCell>
          <FooterCell>{totalTime || 0}</FooterCell>
          <FooterCell />
          <FooterCell>{getTotalValue || ''}</FooterCell>
        </FooterRow>
      </tfoot>
    </Table>
  );
};

export default DirectionTable;
