import { FC, useState } from 'react';
import { css } from '@emotion/react';
import { useForm } from 'react-hook-form';

import { createEstimation } from 'services/estimation';
import Picture from 'assets/images/Picture1.png';
import { Performer as PerformerType } from 'services/types/Iteration';
import Modal from 'components/Modal';
import EstimationForm from '../EstimationForm';
import { NewEstimationContainer, SecondTitle } from '../Iterations.styled';
import { DataToPostEstimation } from '../../types';

const stylesModal = css`
  width: 560px;
`;

const defaultValue = {
  name: '',
  version: '',
  status: '',
  description: '',
  technologies: [],
  persons: [
    {
      changeableName: '',
      position: {
        name: '',
      },
    },
  ],
};

type Props = {
  persons: PerformerType[];
  id: number;
  onUpdateData: () => void;
};

const AddEstimation: FC<Props> = ({ persons, id, onUpdateData }) => {
  const [isOpenAddEstimationModal, setIsOpenAddEstimationModal] = useState(false);
  const { control, errors, handleSubmit } = useForm({
    defaultValues: defaultValue,
    shouldUnregister: false,
  });

  const onToggleAddEstimationModal = () => {
    setIsOpenAddEstimationModal((prevState) => !prevState);
  };

  const onSubmit = (data: DataToPostEstimation) => {
    const dataToPost = {
      ...data,
      iterationId: id,
      persons:
        data.persons &&
        // eslint-disable-next-line
        data.persons.map((person) => persons.find((p) => p.name === person.changeableName)!.id),
    };
    createEstimation(dataToPost).then(() => {
      onUpdateData();
      onToggleAddEstimationModal();
    });
  };

  const handleSubmitEmployee = handleSubmit(onSubmit);

  return (
    <>
      <NewEstimationContainer type="button" onClick={onToggleAddEstimationModal}>
        <SecondTitle>Add new estimation</SecondTitle>
        <img src={Picture} alt="Add new estimation" />
      </NewEstimationContainer>
      {isOpenAddEstimationModal && (
        <Modal
          title="Add Estimation"
          textCancel="Cancel"
          textConfirm="Save"
          styleTypeConfirm="primary"
          styleModal={stylesModal}
          text={
            <EstimationForm
              item={defaultValue}
              control={control}
              errors={errors}
              persons={persons}
            />
          }
          isOpen={isOpenAddEstimationModal}
          onCancel={onToggleAddEstimationModal}
          onClose={onToggleAddEstimationModal}
          onConfirm={handleSubmitEmployee}
        />
      )}
    </>
  );
};

export default AddEstimation;
