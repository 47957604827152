import { FC } from 'react';

import TabPaneStyled from './TabPane.style';

type Props = {
  name: string;
};

const TabPane: FC<Props> = ({ children }) => <TabPaneStyled>{children}</TabPaneStyled>;

export default TabPane;
