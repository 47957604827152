import styled from '@emotion/styled';

export const Box = styled.ul`
  display: flex;
`;

export const Item = styled.li<{ isActive?: boolean }>`
  flex: 1 1 auto;
  text-align: center;
  border: 1px solid
    ${({ theme, isActive }) => (isActive ? theme.colors.blue[300] : theme.colors.gray[200])};

  color: ${({ theme, isActive }) => (isActive ? theme.colors.white[400] : theme.colors.gray[500])};
  cursor: pointer;
  line-height: 40px;
  background: ${({ theme, isActive }) =>
    isActive ? theme.colors.blue[300] : theme.colors.white[400]};
  text-transform: uppercase;
  transition: background-color 0.2s;

  &:hover {
    background: ${({ theme, isActive }) => (isActive ? 'transparent' : theme.colors.white[400])};
    color: ${({ theme }) => theme.colors.gray[500]};
  }
`;
