import { FC, ComponentPropsWithoutRef, ChangeEvent } from 'react';
import { SerializedStyles } from '@emotion/react';

import {
  Container,
  StyledTextArea,
  Label,
  RequiredMark,
  ErrorMessage,
  ErrorContainer,
  Box,
} from './TextArea.styled';

export type Props = {
  name: string;
  value: string;
  label?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  isError?: boolean;
  errorMessage?: string;
  mb?: number;
  textareaStyles?: SerializedStyles;
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
} & ComponentPropsWithoutRef<'textarea'>;

const TextArea: FC<Props> = ({
  label,
  isRequired = false,
  isDisabled = false,
  isError = false,
  errorMessage,
  mb = 0,
  textareaStyles,
  ...rest
}) => (
  <Container>
    <Box isError={isError}>
      <Label>
        {label}
        {label && isRequired && <RequiredMark>*</RequiredMark>}
      </Label>
      <StyledTextArea
        required={isRequired}
        disabled={isDisabled}
        isError={isError}
        mb={mb}
        textareaStyles={textareaStyles}
        {...rest}
      />
    </Box>
    <ErrorContainer>{isError && <ErrorMessage>{errorMessage}</ErrorMessage>}</ErrorContainer>
  </Container>
);

export default TextArea;
