import { useCallback, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useHover = () => {
  const [hover, setHover] = useState(false);

  const onMouseEnter = useCallback(() => {
    setHover(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    setHover(false);
  }, []);

  return {
    onMouseEnter,
    onMouseLeave,
    hover,
  };
};

export default useHover;
