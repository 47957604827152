import { ReactElement } from 'react';
import { Route, Switch } from 'react-router-dom';
import AppContainer from 'layouts/AppContainer';
import LoginPage from 'pages/LoginPage';
import ProjectListPage from 'pages/ProjectListPage';
import UsersPage from 'pages/UsersPage';
import ClientsPage from 'pages/ClientsPage';
import CompanyDetailPage from 'pages/CompanyDetailPage';
import NotFoundPage from 'pages/NotFoundPage';
import EstimationPage from 'pages/EstimationPage';
import NewProjectPage from 'pages/NewProjectPage';
import ProjectDetailPage from 'pages/ProjectDetailPage';
import NewCompanyPage from 'pages/NewCompanyPage';
import ProtectedRoute from './ProtectedRoute';
import AdminRoute from './AdminRoute';
import EstimationDetailPage from '../pages/EstimationDetailPage';

const AppRouter = (): ReactElement => (
  <Switch>
    <Route path="/" component={LoginPage} exact />
    <AppContainer>
      <ProtectedRoute path="/main/project-list">
        <ProjectListPage />
      </ProtectedRoute>
      <ProtectedRoute path="/main/project-list/detail/:id">
        <ProjectDetailPage />
      </ProtectedRoute>
      <ProtectedRoute path="/main/client-list">
        <ClientsPage />
      </ProtectedRoute>
      <ProtectedRoute path="/main/client-list/detail/:id">
        <CompanyDetailPage />
      </ProtectedRoute>
      <ProtectedRoute path="/main/estimation/table/:id">
        <EstimationPage />
      </ProtectedRoute>
      <ProtectedRoute path="/main/project-list/create">
        <NewProjectPage />
      </ProtectedRoute>
      <ProtectedRoute path="/main/client-list/create">
        <NewCompanyPage />
      </ProtectedRoute>
      <ProtectedRoute path="/main/estimation/:id">
        <EstimationDetailPage />
      </ProtectedRoute>
      <AdminRoute path="/admin/users-list">
        <UsersPage />
      </AdminRoute>
    </AppContainer>
    <Route path="*" component={NotFoundPage} />
  </Switch>
);

export default AppRouter;
