import { FC, useEffect, useMemo, useState } from 'react';
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form';
import { css } from '@emotion/react';
import { format } from 'date-fns';

import Input from 'components/Input';
import Select from 'components/Select/v2';
import TextArea from 'components/TextArea';
import ChipsSelect from 'components/ChipsSelect';
import useLoadTechnologies from 'utils/hooks/useLoadTechnologies';
import { STATUSES_ESTIMATION, VALIDATION_MESSAGES } from 'constants/common';
import { getEstimation } from 'services/estimation';
import { EstimationData } from 'services/types/EstimationData';
import { Estimation, Performer as PerformerType } from 'services/types/Iteration';
import PersonsList from './PersonsList';
import { Container, DateBox, FirstBox } from '../IterationForm/IterationForm.styled';
import { EstimationType } from './PersonItem';

const textareaStyles = css`
  height: 190px;
`;

const statusesList = STATUSES_ESTIMATION.map((item) => ({
  value: item.toUpperCase().replaceAll(' ', '_'),
  label: item,
}));

type Props = {
  control: Control;
  errors: DeepMap<EstimationType, FieldError>;
  item: EstimationType | Estimation;
  id?: number;
  persons: PerformerType[];
  onSubmit?: () => void;
};

const DATE_FORMAT = 'yyyy-MM-dd';

const EstimationForm: FC<Props> = ({ control, item, id, errors, persons, onSubmit }) => {
  const technologies = useLoadTechnologies();
  const [estimation, setEstimation] = useState<EstimationData>();
  const estimationStart = useMemo(() => {
    if (estimation?.startdate) {
      return format(estimation.startdate, DATE_FORMAT);
    }

    return format(new Date(), DATE_FORMAT);
  }, [estimation]);

  useEffect(() => {
    if (id) {
      getEstimation(id.toString()).then((data) => setEstimation(data));
    }
  }, [id]);

  const formattedTechnologies =
    technologies?.map((tech) => ({
      label: tech.name,
      value: tech.id,
    })) || [];

  return (
    <form onSubmit={onSubmit}>
      <Container>
        <Controller
          name="name"
          control={control}
          defaultValue={estimation?.name || item.name}
          rules={{
            required: { value: true, message: VALIDATION_MESSAGES.required },
            minLength: { value: 2, message: VALIDATION_MESSAGES.length },
          }}
          render={({ onChange, value, name }, { invalid }) => (
            <Input
              value={value}
              name={name}
              type="text"
              label="Estimation Name"
              errorMessage={errors.name?.message}
              isError={invalid}
              onChange={onChange}
              isRequired
            />
          )}
        />
        <FirstBox>
          <Controller
            name="version"
            control={control}
            defaultValue={item.version || ''}
            rules={{
              required: { value: true, message: VALIDATION_MESSAGES.required },
            }}
            render={({ onChange, value, name }, { invalid }) => (
              <Input
                value={value}
                name={name}
                type="text"
                label="Version"
                width={120}
                placeholder="1"
                onChange={onChange}
                errorMessage={errors.version?.message}
                isError={invalid}
                isRequired
              />
            )}
          />
          <Controller
            name="status"
            control={control}
            defaultValue={item.status}
            rules={{
              required: { value: true, message: VALIDATION_MESSAGES.required },
            }}
            render={({ onChange, value }, { invalid }) => (
              <Select
                value={value}
                label="Estimation Status"
                itemList={statusesList}
                width={340}
                onChange={onChange}
                errorMessage={errors.status?.message}
                isError={invalid}
                isRequired
                verticalLabel
              />
            )}
          />
        </FirstBox>
        <Controller
          name="description"
          control={control}
          defaultValue={item.description || ''}
          render={({ onChange, value, name }) => (
            <TextArea
              name={name}
              value={value}
              label="Estimation description"
              placeholder="Estimation description"
              textareaStyles={textareaStyles}
              mb={16}
              onChange={onChange}
            />
          )}
        />
        <DateBox>
          {estimation ? (
            <Controller
              name="startdate"
              control={control}
              defaultValue={estimationStart}
              render={({ onChange, value, name }, { invalid }) => (
                <Input
                  value={value}
                  name={name}
                  type="date"
                  label="Start Date"
                  onChange={onChange}
                  errorMessage={VALIDATION_MESSAGES.required}
                  isError={invalid}
                />
              )}
            />
          ) : null}
        </DateBox>
        <PersonsList control={control} errors={errors} persons={persons} />
        <Controller
          name="technologies"
          control={control}
          rules={{
            validate: {
              required: (value) => value.length > 0,
            },
          }}
          render={({ value, onChange }, { invalid }) => (
            <ChipsSelect
              label="Technologies"
              placeholder="Technology"
              value={value}
              mb={16}
              isError={invalid}
              errorMessage={VALIDATION_MESSAGES.required}
              list={formattedTechnologies}
              onChange={onChange}
              isRequired
            />
          )}
        />
      </Container>
    </form>
  );
};

export default EstimationForm;
