import { FC } from 'react';

import { SubMenuContainer, SubMenuItem, RiskStatus } from './ContextMenu.styled';
import { RiskType } from '../types';

type Risk = {
  title: string;
  value: RiskType;
  className: string | null;
};

const RISKS: Risk[] = [
  {
    title: 'No Risks',
    value: 'NO_RISKS',
    className: null,
  },
  {
    title: 'Extra Functionality',
    value: 'EXTRA_FUNCTIONALITY',
    className: 'extra-risks',
  },
  {
    title: 'SENLA Risks',
    value: 'SENLA_SIDE_RISK',
    className: 'inner-risks',
  },
  {
    title: 'Client Risks',
    value: 'CLIENT_SIDE_RISK',
    className: 'client-risks',
  },
];

type MenuItemProps = {
  riskLevel?: RiskType;
  title: string;
  value: RiskType;
  className: string | null;
  onClick: (value: RiskType) => void;
};

const RiskMenuItem: FC<MenuItemProps> = ({ riskLevel, title, value, className, onClick }) => {
  const handleClick = () => {
    if (riskLevel && riskLevel !== value) {
      onClick(value);
    }
  };

  return className ? (
    <SubMenuItem onClick={handleClick}>
      <RiskStatus className={className}>{title}</RiskStatus>
    </SubMenuItem>
  ) : (
    <SubMenuItem onClick={handleClick}>{title}</SubMenuItem>
  );
};

type Props = {
  riskLevel?: RiskType;
  onClick: (value: RiskType) => void;
};

const RisksSubMenu: FC<Props> = ({ riskLevel, onClick }) => (
  <SubMenuContainer className="sub-menu">
    <ul>
      {RISKS.map((risk) => (
        <RiskMenuItem key={risk.value} {...risk} onClick={onClick} riskLevel={riskLevel} />
      ))}
    </ul>
  </SubMenuContainer>
);

export default RisksSubMenu;
