import { FC } from 'react';
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form';

import Input from 'components/Input/';
import { EMAIL_VALIDATION, VALIDATION_MESSAGES } from 'constants/common';
import ContactList from './ContactList';
import ProjectList from './ProjectList';
import { Data, ProjectType } from './types';
import { Main, EmployeeInfo, Label, InputContainer } from './EmployeeForm.styled';

export type Props = {
  item: Data;
  control: Control;
  errors: DeepMap<Data, FieldError>;
  projectList?: ProjectType[];
  onSubmit: () => Promise<void>;
};

const EmployeeForm: FC<Props> = ({ control, errors, projectList, item, onSubmit }) => (
  <form onSubmit={onSubmit}>
    <Main>
      <EmployeeInfo>
        <InputContainer>
          <Controller
            name="name"
            control={control}
            defaultValue={item.name}
            rules={{
              required: { value: true, message: VALIDATION_MESSAGES.required },
              minLength: { value: 2, message: 'VALIDATION_FIELD_NAME' },
            }}
            render={({ onChange, value, name }, { invalid }) => (
              <Input
                name={name}
                value={value}
                label="Employee Name"
                placeholder="Employee Name"
                isError={invalid}
                errorMessage={errors.name?.message}
                onChange={onChange}
                isRequired
              />
            )}
          />
        </InputContainer>
        <InputContainer>
          <Controller
            name="email"
            control={control}
            defaultValue={item.email}
            rules={{
              required: { value: true, message: VALIDATION_MESSAGES.required },
              pattern: { value: EMAIL_VALIDATION, message: 'VALIDATION_FIELD_EMAIL' },
            }}
            render={({ onChange, value, name }, { invalid }) => (
              <Input
                name={name}
                value={value}
                label="Employee Email"
                placeholder="Employee Email"
                isError={invalid}
                errorMessage={errors.email?.message}
                onChange={onChange}
                isRequired
              />
            )}
          />
        </InputContainer>
        <InputContainer>
          <Controller
            name="position"
            control={control}
            defaultValue={item.position}
            render={({ onChange, value, name }) => (
              <Input
                name={name}
                value={value}
                label="Employee position"
                placeholder="Employee Position"
                onChange={onChange}
              />
            )}
          />
        </InputContainer>
      </EmployeeInfo>
      <Label>Employee contacts</Label>
      <ContactList control={control} />
      <Label>Employee projects</Label>
      <ProjectList control={control} projectList={projectList} />
    </Main>
  </form>
);

export default EmployeeForm;
