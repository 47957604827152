import { protectedGet, protectedPost } from './protectedAxios';
import Role from './types/Role';
import { BaseUser } from './types/User';

const SUPER_URL = '/api/super';
const getAllRolesUrl = () => `${SUPER_URL}/roles`;
const getAllUsersUrl = () => `${SUPER_URL}/users`;
const getSettingRoleUrl = () => SUPER_URL;

export const getAllRoles = (): Promise<Role[]> => protectedGet(getAllRolesUrl());
export const getAllUsers = (): Promise<BaseUser[]> => protectedGet(getAllUsersUrl());
export const setRole = (role: string, userId: number): Promise<void> =>
  protectedPost(getSettingRoleUrl(), { role: role.toUpperCase(), userId });
