import React, { FC } from 'react';
import { Rect } from 'react-konva';
import AppStory from 'pages/EstimationDetailPage/Gantt/models/AppStory';
import { colors } from '../../../../theme/colors';
import { SetStoryId } from '../../types';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface StoryLineProps {
  y: number;
  width: number;
  height: number;
  story: AppStory;
  storyId: number | null;
  setStoryId: SetStoryId;
}

export const StoryLine: FC<StoryLineProps> = ({ y, width, height, story, storyId, setStoryId }) => (
  <Rect
    y={y}
    width={width}
    height={height}
    onMouseEnter={() => setStoryId(story.id)}
    // onMouseLeave={() => setStoryId(null)}
    fill={story.id === storyId ? colors.stories.hoverColor : 'transparent'}
    moveToBottom
  />
);
