import styled from '@emotion/styled';
import { SerializedStyles } from '@emotion/react';

type Props = {
  styleType?: string;
  styleButton?: SerializedStyles;
};

export const Text = styled.span<Props>`
  color: ${({ theme }) => theme.colors.blue[300]};
  ${({ styleType, theme }) =>
    styleType === 'primary' &&
    `
    color: ${theme.colors.white[400]};
  `}
  ${({ styleType, theme }) =>
    styleType === 'warning' &&
    `
    color: ${theme.colors.white[400]};
  `}
  ${({ styleType, theme }) =>
    styleType === 'disabled' &&
    `
    color: ${theme.colors.gray[900]};
  `}
  ${({ styleType, theme }) =>
    styleType === 'add' &&
    `
    color: ${theme.colors.white[400]};

    &::before {
      content: '';
      position: absolute;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: ${theme.colors.white[400]};
      top: 10px;
      left: 13px;
    }
  `}
`;

export const Container = styled.button<Props>`
  ${({ styleButton }) => styleButton}
  border-radius: 4px;
  transition: all ease-in-out 0.2s;
  display: inline-block;
  color: ${({ theme }) => theme.colors.white[400]};
  text-transform: uppercase;
  text-align: center;
  min-width: 114px;
  padding: 10px 12px;
  cursor: pointer;
  line-height: 24px;
  border: 1px solid ${({ theme }) => theme.colors.gray[200]};
  background-color: transparent;

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.blue[300]};
    background-color: ${({ theme }) => theme.colors.gray[250]};
    box-shadow: 0 4px 8px rgb(0 0 0 / 5%);
  }

  &:active {
    color: ${({ theme }) => theme.colors.blue[300]};
    background-color: ${({ theme }) => theme.colors.gray[250]};
    box-shadow: none;
  }

  ${({ styleType, theme }) =>
    styleType === 'warning' &&
    `
    background-color: ${theme.colors.red[400]};
    &:hover, &:focus {
      background-color: ${theme.colors.red[300]};
      box-shadow: 0 4px 8px rgb(235 87 87 / 25%);
    }
    &:active {
      background-color: ${theme.colors.red[250]};
    }
  `}

  ${({ styleType, theme }) =>
    styleType === 'primary' &&
    `
      text-decoration: none;
      border: none;
      -webkit-transition: 0.2s ease-in-out;
      transition: all ease-in-out 0.2s;
      display: inline-block;
      color: ${theme.colors.white[400]};
      background-color: ${theme.colors.blue[300]};
      text-transform: uppercase;
      text-align: center;
      min-width: 114px;
      padding: 10px 12px;
      cursor: pointer;
      line-height: 24px;

    &:hover, &:focus {
      box-shadow: 0 4px 8px rgb(19 115 236 / 25%);
      background: ${theme.colors.blue[600]};
    }

    &:active {
      background-color: ${theme.colors.blue[350]};
      box-shadow: none;
    }
  `}

  ${({ styleType, theme }) =>
    styleType === 'disabled' &&
    `
      text-decoration: none;
      border: none;
      transition: all ease-in-out 0.2s;
      background-color: ${theme.colors.gray[250]};
    &:hover, &:focus {
      box-shadow: none;
      background: ${theme.colors.gray[250]};
      cursor: auto;
    }
    &:active {
      background-color: ${theme.colors.gray[250]};
      box-shadow: none;
    }
  `}

  ${({ styleType, theme }) =>
    styleType === 'add' &&
    `
      text-decoration: none;
      border: none;
      transition: all ease-in-out 0.2s;
      color: ${theme.colors.white[400]};
      background-color: ${theme.colors.blue[300]};
      text-align: center;
      min-width: 114px;
      border-radius: 100px;
      padding-left: 45px;
      position: relative;

    &:hover, &:focus {
      box-shadow: 0 4px 8px rgb(19 115 236 / 25%);
      background: ${theme.colors.blue[600]};

      &::before,
      &::after {
        background-color: ${theme.colors.blue[600]};
      }
    }

    &:active {
      background-color: ${theme.colors.blue[350]};
      box-shadow: none;

      &::before,
      &::after {
        background-color: ${theme.colors.blue[350]};
      }
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 14px;
      height: 2px;
      background-color: ${theme.colors.blue[300]};
      top: 21px;
      left: 18px;
      z-index: 1;
    }

    &::before {
      transform: rotate(90deg);
    }
  `}
  ${({ styleType, theme }) =>
    styleType === 'empty' &&
    `
      min-width: 44px;
      width: 44px;
      height: 44px;
      border-radius: 50%;
      padding: 10px;
      line-height: 0;
      text-decoration: none;
      border: none;
      transition: all ease-in-out 0.2s;
      display: inline-block;
      color: ${theme.colors.white[400]};
      background-color: ${theme.colors.blue[300]};
      text-transform: uppercase;
      text-align: center;
      cursor: pointer;

    &:hover, &:focus {
      box-shadow: 0 4px 8px rgb(19 115 236 / 25%);
      background: ${theme.colors.blue[600]};
    }

    &:active {
      background-color: ${theme.colors.blue[350]};
      box-shadow: none;
    }
  `}
`;
