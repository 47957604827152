import { useCallback } from 'react';
import AppSection from 'pages/EstimationDetailPage/Gantt/models/AppSection';
import AppStory from '../../../models/AppStory';
import useUpdateSectionById from './useUpdateSectionById';
import { updateIfIdMatches } from '../../../utils/algorithms';
import { SetModel } from '../types';

const useUpdateStoryById = (
  setModel: SetModel,
): ((section: AppSection, story: AppStory, changes?: Partial<AppStory>) => void) => {
  const updateSectionById = useUpdateSectionById(setModel);

  return useCallback(
    (section: AppSection, story: AppStory, changes?: Partial<AppStory>) => {
      updateSectionById(section.id, {
        stories: section.stories.map(
          updateIfIdMatches(story.id, (storyToUpdate) => ({
            ...storyToUpdate,
            ...changes,
          })),
        ),
      });
    },
    [updateSectionById],
  );
};

export default useUpdateStoryById;
