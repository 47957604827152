import { FC, useState } from 'react';
import { FileSubType } from 'services/types/File';
import { Box } from './Tabs.styled';
import TabItem from './TabItem';

export type ListItem = {
  id: number;
  title: string;
  fileType: FileSubType;
};

export type Props = {
  list: ListItem[];
  onChange: (tab: ListItem) => void;
};

const Tabs: FC<Props> = ({ list, onChange }) => {
  const [select, setSelect] = useState(list[0].id);

  const changeHandle = (id: number) => {
    const item = list.find((elm) => elm.id === id);
    if (!item) {
      return;
    }

    setSelect(id);
    onChange(item);
  };

  return (
    <Box>
      {list.map(({ id, title }) => (
        <TabItem isActive={id === select} key={id} id={id} onClick={changeHandle}>
          {title}
        </TabItem>
      ))}
    </Box>
  );
};

export default Tabs;
