import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

const showCompany = keyframes`
  0% {
    opacity: 0;
    transform: scale(1, 0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
`;

const ClientStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  animation-name: ${showCompany};
  animation-duration: 0.2s;
  animation-timing-function: ease-in-out;
  width: 299px;
  box-sizing: border-box;
  transition: all ease-in-out 0.1s;
  border: 1px solid ${({ theme }) => theme.colors.gray[200]};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.white[400]};

  &:hover,
  &:focus {
    border-color: ${({ theme }) => theme.colors.blue[300]};
    box-shadow: 0px 10px 25px rgba(29, 125, 232, 0.25);
    transform: scale(1.025, 1.05);
    cursor: pointer;
  }
`;

const ClientInfoStyled = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  text-align: left;
`;

const ClientNameStyled = styled('h3')`
  font-weight: normal;
  margin: 0;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.black[500]};
  word-break: break-all;
`;

const WrapperLogoStyled = styled('p')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 95px;
  margin: 0 auto;
  overflow: hidden;
`;

const LogoStyled = styled('img')`
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 4px;
`;

const ControlsWrapperStyled = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  margin-left: 10px;
`;

const ProjectsButtonStyled = styled('button')`
  margin-right: 10px;
  font-size: 14px;
  line-height: 16px;
  border: none;
  transition: all ease-in-out 0.2s;
  position: relative;
  display: inline-block;
  min-width: 60px;
  max-width: 100px;
  padding: 8px 12px;
  text-align: center;
  color: ${({ theme }) => theme.colors.gray[550]};
  background-color: ${({ theme }) => theme.colors.gray[300]};
  border-radius: 16px;
  cursor: normal;

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.gray[550]};
    background-color: ${({ theme }) => theme.colors.gray[250]};
    box-shadow: 0 4px 8px rgb(0 0 0 / 25%);
  }
`;

const DelButtonStyled = styled('button')`
  color: ${({ theme }) => theme.colors.gray[900]};
  width: 32px;
  height: 32px;
  padding: 6px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.gray[200]};
  background-color: transparent;
  transition: all ease-in-out 0.2s;
  display: inline-block;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.gray[250]};
    box-shadow: 0 4px 8px rgb(0 0 0 / 5%);
  }

  & .icon {
    color: #000;
    transition: all ease-in-out 0.2s;
  }

  &:hover .icon,
  &:focus .icon {
    color: ${({ theme }) => theme.colors.gray[550]};
  }
`;

export {
  ClientStyled,
  WrapperLogoStyled,
  LogoStyled,
  ClientInfoStyled,
  ClientNameStyled,
  ControlsWrapperStyled,
  ProjectsButtonStyled,
  DelButtonStyled,
};
