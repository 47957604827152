import { useSelector } from 'react-redux';

import User, { Employee } from 'services/types/User';
import { AllClients } from 'services/types/Client';
import Role from 'services/types/Role';
import Technology from 'services/types/Technology';
import { Employee as Position } from 'services/types/Employee';
import { AppScaleEnum } from 'pages/EstimationDetailPage/Gantt/models/AppScaleEnum';
import { RootState } from '.';

export const useUserData = (): User | null =>
  useSelector((state: RootState) => {
    const { user } = state.user;

    return user;
  });

export const useRoles = (): Role[] | null => useSelector((state: RootState) => state.lists.roles);

export const useEmployees = (): Employee[] | null =>
  useSelector((state: RootState) => state.lists.employees);

export const useEstimationStatuses = (): string[] | null =>
  useSelector((state: RootState) => state.lists.estimationStatuses);

export const useClients = (): AllClients | null =>
  useSelector((state: RootState) => state.lists.clients);

export const useTechnologies = (): Technology[] | null =>
  useSelector((state: RootState) => state.lists.technologies);

export const usePositions = (): Position[] | null =>
  useSelector((state: RootState) => state.lists.positions);

export const useGanttScaleKey = (): AppScaleEnum =>
  useSelector((state: RootState) => state.gantt.scale.key);

export const useGanttScaleValue = (): number =>
  useSelector((state: RootState) => state.gantt.scale.value);

export const useEstimationStartdate = (): number =>
  useSelector((state: RootState) => state.estimation.startdate);

export const useGanntStartPoint = (): number =>
  useSelector((state: RootState) => state.gantt.startXPoint);
