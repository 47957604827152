import styled from '@emotion/styled';
import { SerializedStyles, Theme } from '@emotion/react';

import Icon from 'components/Icon';

type GetColor = {
  theme: Theme;
  error?: boolean;
  isDragActive: boolean;
  isDragAccept: boolean;
  isDragReject: boolean;
};

const getColor = ({ theme, error, isDragActive, isDragAccept, isDragReject }: GetColor): string => {
  if (isDragAccept) {
    return theme.colors.green[400];
  }
  if (isDragReject || error) {
    return theme.colors.red[400];
  }
  if (isDragActive) {
    return theme.colors.blue[400];
  }

  return theme.colors.gray[200];
};

type Props = {
  error?: boolean;
  isDragActive: boolean;
  isDragAccept: boolean;
  isDragReject: boolean;
  inputStyles?: SerializedStyles;
};

export const Container = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  min-height: 160px;
  border: 1px dashed ${(props) => getColor(props)};
  background: transparent;
  color: ${({ theme }) => theme.colors.gray[900]};
  outline: none;
  transition: border 0.3s ease;
  ${({ inputStyles }) => inputStyles}
`;

export const FileUploaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 10px 0;
`;

export const Text = styled.span<{ bold?: boolean; error?: boolean }>`
  margin-bottom: 10px;
  text-align: center;
  font-size: ${({ bold }) => (bold ? '12px' : '10px')};
  font-weight: ${({ bold }) => (bold ? 700 : 400)};
  color: ${({ theme, error }) => (error ? theme.colors.red[400] : theme.colors.gray[900])};
`;

export const Button = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.blue[300]};
  text-transform: uppercase;
  text-align: center;
  min-width: 114px;
  padding: 10px 12px;
  line-height: 24px;
  border: 1px solid ${({ theme }) => theme.colors.gray[200]};
  border-radius: 100px;
  transform: scale(0.75);
  transition: all 0.3s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.blue[300]};
    background-color: ${({ theme }) => theme.colors.gray[250]};
    box-shadow: 0 4px 8px rgb(0 0 0 / 5%);
  }
`;

export const PlusIcon = styled(Icon)`
  margin-right: 8px;
`;

export const ImageContainer = styled.div`
  position: relative;
  height: inherit;
  border: 1px solid ${({ theme }) => theme.colors.gray[200]};
  display: flex;
  align-items: center;

  &:hover {
    :before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
  &:hover [data-comp='icon'] {
    opacity: 1;
  }
`;

export const DeleteButtonContainer = styled.div`
  opacity: 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.gray[300]};
  padding: 3px;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.gray[900]};
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 24px;
  top: 24px;
  z-index: 2;
  transition: opacity ease-in-out 0.2s;
  cursor: pointer;
`;
