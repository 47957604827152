import { IExcelData, ITableRow, NewItemType } from './types';

const formatDataDirectionTable = (
  columns: { title: string; columnsId: string }[],
  newItems: { [p: string]: NewItemType },
): IExcelData => {
  // table's header

  const tableTitles: string[] = [];
  columns.forEach((el) => {
    tableTitles.push(el.title);
  });

  // table's body

  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const tableRows: ITableRow[] = [];
  const arr = Object.entries(newItems);

  const addValue = (el: [string, NewItemType], field: 'value' | 'rate' | 'sum') =>
    el[1][field] ? el[1][field] : null;
  const addName = (el: [string, NewItemType]) => (el[0] === 'null' ? 'Не определено' : el[0]);

  arr.forEach((el: [string, NewItemType], i) => {
    const objRow = () => {
      const obj: ITableRow = {
        name: addName(el),
        value: addValue(el, 'value'),
        rate: addValue(el, 'rate'),
        sum: '',
      };

      const indexColumnSum = Object.keys(obj).indexOf('sum');
      obj.sum = `=${alphabet[indexColumnSum - 2]}${i + 2}*${alphabet[indexColumnSum - 1]}${i + 2}`;

      return obj;
    };

    tableRows.push(objRow());
  });

  // table's footer
  const footerRow: ITableRow = {
    name: 'Итого:',
    value: '',
    sum: '',
  };

  const verticalSum = (x: number) => {
    const y = tableRows.length;

    return `=SUM(${alphabet[x]}${2}:${alphabet[x]}${y + 1})`;
  };

  footerRow.value = verticalSum(1);
  footerRow.sum = verticalSum(3);

  tableRows.push(footerRow);

  return { titlesExcel: tableTitles, rowsExcel: tableRows };
};
export default formatDataDirectionTable;
