import { FC } from 'react';

import TabNameStyled from './TabName.style';

export type Props = {
  onClick: (value: string) => void;
  tabName: string;
  active: boolean;
};

const TabName: FC<Props> = ({ onClick, tabName, active }) => {
  const tabClickHandler = () => onClick(tabName);

  return (
    <li>
      <TabNameStyled onClick={tabClickHandler} active={active}>
        {tabName}
      </TabNameStyled>
    </li>
  );
};

export default TabName;
