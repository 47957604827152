import styled from '@emotion/styled';

type ArrowProps = {
  isTransform: boolean;
};

export const InputStyled = styled.input`
  display: none;
`;

export const ArrowStyled = styled.label<ArrowProps>`
  display: flex;
  position: relative;
  width: 20px;
  height: 20px;
  border: none;
  background-color: transparent;
  padding: 0;

  &:hover,
  &:focus {
    cursor: pointer;
  }

  &::before {
    content: '';
    margin: auto;
    border-style: solid;
    border-color: transparent;
    border-bottom-width: 8px;
    border-left-width: 5.5px;
    border-top-width: 0;
    border-right-width: 6px;
    border-top-color: transparent;
    border-bottom-color: black;
    transition: all ease-in-out 0.2s;
    transform: ${({ isTransform }) => (isTransform ? 'rotate(180deg)' : 'rotate(0deg)')};
  }
`;
