import { FC, useState } from 'react';
import Modal from 'components/Modal';
import { TableBase, HeaderCeil, HeaderRow } from './Table.styled';
import Row from './Row';

export type FileData = {
  fileName: string;
  date: number;
  extension: string;
  uuid: string;
};

export type Props = {
  files: FileData[];
  onDeleteFile: (file: FileData) => void;
  onDownloadFile: (file: FileData) => void;
};

const Table: FC<Props> = ({ files, onDeleteFile, onDownloadFile }) => {
  const [open, setOpen] = useState(false);
  const [activeFile, setActiveFile] = useState<FileData>();

  const openModal = () => {
    setOpen(true);
  };

  const hideModal = () => {
    setOpen(false);
  };

  const findActiveFile = (uuid: string) => {
    const file = files.find((elm) => elm.uuid === uuid);
    setActiveFile(file);
  };

  const deleteHandle = (uuid: string) => {
    findActiveFile(uuid);
    openModal();
  };

  const downloadHandle = (uuid: string) => {
    findActiveFile(uuid);
    if (!activeFile) {
      return;
    }
    onDownloadFile(activeFile);
  };

  const confirmHandle = () => {
    hideModal();
    if (!activeFile) {
      return;
    }
    onDeleteFile(activeFile);
  };

  return (
    <>
      <TableBase>
        <HeaderRow>
          <HeaderCeil>file name</HeaderCeil>
          <HeaderCeil>date</HeaderCeil>
          <HeaderCeil>extension</HeaderCeil>
        </HeaderRow>
        {files.map(({ fileName, extension, date, uuid }) => (
          <Row
            uuid={uuid}
            filename={fileName}
            date={date}
            extension={extension}
            key={uuid}
            onDelete={deleteHandle}
            onDownload={downloadHandle}
          />
        ))}
      </TableBase>
      <Modal
        isOpen={open}
        onConfirm={confirmHandle}
        onCancel={hideModal}
        onClose={hideModal}
        text={`Are you sure want to delete ${activeFile?.fileName}?`}
        textCancel="Cancel"
        textConfirm="Delete"
        styleTypeConfirm="warning"
      />
    </>
  );
};

export default Table;
