import { FC } from 'react';

import IconContainer from './components/IconContainer';
import { Row } from './Calendar.styled';

type Month = {
  [key: number]: string;
};

const month: Month = {
  0: 'January',
  1: 'February',
  2: 'March',
  3: 'April',
  4: 'May',
  5: 'June',
  6: 'July',
  7: 'August',
  8: 'September',
  9: 'October',
  10: 'November',
  11: 'December',
};

type Props = {
  selectedMonth: Date;
  onSetMonth: (value: boolean) => void;
};

const CalendarHeader: FC<Props> = ({ selectedMonth, onSetMonth }) => (
  <Row header>
    <IconContainer rotateLeft onClick={onSetMonth} />
    <span>{`${month[selectedMonth.getMonth()]} ${selectedMonth.getFullYear()}`}</span>
    <IconContainer onClick={onSetMonth} />
  </Row>
);

export default CalendarHeader;
