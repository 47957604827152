import styled from '@emotion/styled';

type OptionsProps = {
  isHidden: boolean;
};

export const OptionsStyled = styled.div<OptionsProps>`
  background: white;
  transition: all ease-in-out 0.2s;
  opacity: ${({ isHidden }) => (isHidden ? '0' : '1')};
  display: flex;
  position: absolute;
  z-index: ${({ isHidden }) => (isHidden ? '-10' : '10')};
  width: 200px;
  top: 150%;
  margin-left: -100px;
  flex-direction: column;
  border: 1px solid #d5dee9;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 16%);
  border-radius: 4px;
  background-color: #ffffff;
`;

export const SortStyled = styled.section`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
`;

export const TitleStyled = styled.h3`
  color: #c6c7cb;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  margin-right: 6px;

  &:hover,
  &:focus {
    cursor: pointer;
  }
`;

export const SelectedOptionStyled = styled.p`
  background-color: transparent;
  font-size: 14px;
  line-height: 20px;
  border: none;
  padding: 0;
  color: #333333;
  min-width: 55px;
  text-align: left;

  &:hover,
  &:focus {
    cursor: pointer;
  }
`;
