import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

const showProject = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

export const Card = styled.div`
  width: 297px;
  height: 205px;
  border: 1px solid ${({ theme }) => theme.colors.gray[200]};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.white[400]};
  overflow: hidden;
  color: ${({ theme }) => theme.colors.black[600]};
  font-size: 14px;
  padding: 0 0 24px;
  box-sizing: content-box;
  animation: ${showProject} 0.2s ease-in-out;
  transition: all ease-in-out 0.1s;

  &:hover,
  &:focus {
    border-color: ${({ theme }) => theme.colors.blue[300]};
    box-shadow: 0 10px 25px rgb(29, 125, 232, 25%);
    transform: scale(1.05);
    cursor: pointer;
  }
`;

export const CardImg = styled.img`
  width: 100%;
  height: 95px;
  display: block;
  object-fit: cover;
  margin-bottom: 20px;
`;

export const CardTitle = styled.h4`
  margin: 0 0 2px;
  padding-left: 20px;
  color: ${({ theme }) => theme.colors.black[400]};
  font-weight: 500;
  font-size: 19px;
`;

export const CardCompany = styled.div`
  margin-bottom: 10px;
  padding-left: 20px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.gray[900]};
`;

export const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
`;

export const CardStatus = styled.div`
  display: inline-block;
  min-width: 90px;
  padding: 8px 12px;
  text-align: center;
  line-height: 22px;
  font-weight: 700;
  border-radius: 20px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.blue[700]};
`;

export const CardDeadline = styled.div`
  color: ${({ theme }) => theme.colors.gray[900]};
  line-height: 1.6;
  text-align: right;
  display: flex;
  flex-direction: column;

  & > span {
    color: ${({ theme }) => theme.colors.black[500]};
  }
`;
