import { FC, useEffect, useState, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import {
  DropDownContainer,
  DropDownHeader,
  DropDownList,
  ListStatus,
} from './StatusesSelect.styled';

type ItemProps = {
  data: string;
  onChange: (value: string) => void;
};

const getDisplayValue = (value: string): string => value.toLowerCase().replaceAll('_', ' ');

const classNames: { [key: string]: string } = {
  INITIAL: 'initial',
  DISCUSSION: 'discussion',
  DOCUMENTATION: 'documentation',
  IN_DEV: 'in_dev',
  COMPLETE: 'complete',
  CANCELLED: 'cancelled',
  ON_HOLD: 'on_hold',
  DRAFT: 'draft',
  READY: 'ready',
  SENT_TO_APPROVE: 'sent_to_approve',
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
};

const ListItem: FC<ItemProps> = ({ data, onChange }) => {
  const handleClick = () => {
    onChange(data);
  };

  return (
    <ListStatus onClick={handleClick}>
      <span className={`circle ${classNames[data]}`} />
      <span className="value">{getDisplayValue(data)}</span>
    </ListStatus>
  );
};

type ListProps = {
  list: string[];
  onChange: (value: string) => void;
};

const StatusesList: FC<ListProps> = ({ list, onChange }) => (
  <DropDownList>
    {list.map((item) => (
      <ListItem key={item} data={item} onChange={onChange} />
    ))}
  </DropDownList>
);

type Props = {
  value: string;
  items: string[];
  onChange: (value: string) => void;
};

const StatusesSelect: FC<Props> = ({ value, items, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const target = event.target as HTMLElement;
      if (ref.current && !ref.current.contains(target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleChange = (newValue: string) => {
    onChange(newValue);
    setIsOpen(false);
  };

  const onToggle = () => setIsOpen((prevState) => !prevState);

  const displayValue =
    items &&
    items
      .find((item) => item === value)
      ?.toLowerCase()
      .replaceAll('_', ' ');

  return (
    <DropDownContainer ref={ref}>
      <DropDownHeader onClick={onToggle} className={classNames[value]}>
        {displayValue}
      </DropDownHeader>
      <CSSTransition in={isOpen} timeout={300} classNames="dropdown" unmountOnExit>
        <StatusesList list={items} onChange={handleChange} />
      </CSSTransition>
    </DropDownContainer>
  );
};

export default StatusesSelect;
