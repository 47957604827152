import styled from '@emotion/styled';

export const EmployeeContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const NoEmployees = styled.div`
  color: #f2994a;
  background-color: #efe1d5;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 12px 12px 12px 24px;
  margin-bottom: 24px;
`;

export const StyledSelect = styled.div`
  flex: 1;
`;
