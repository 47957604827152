import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { LEFT_PANEL_WIDTH_PX, SUBSTORY_HEIGHT_PX } from '../../consts';
import { FlexRowNowrapFlexStartCenter } from '../../../../components/Flex';
import { colors } from '../../../../theme/colors';

export const StyledLeftPanelTr = styled.tr<{ storyHover?: boolean }>`
  ${(props) =>
    props.storyHover &&
    css`
      background-color: ${colors.stories.hoverColor};
    `}
`;

export const StyledLeftPanelTd = styled.td<{ storyHover?: boolean }>`
  height: ${SUBSTORY_HEIGHT_PX}px;
  margin: 0;
  padding: 0;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  box-shadow: inset 0 0 1px 0 #acacac;

  font-weight: 300;
  font-size: 14px;

  overflow: hidden;
  :hover {
    background-color: ${colors.stories.hoverColor};
  }
`;

export const StyledTdContentWrapper = styled(FlexRowNowrapFlexStartCenter)`
  height: ${SUBSTORY_HEIGHT_PX}px;
  max-height: ${SUBSTORY_HEIGHT_PX}px;
  overflow: hidden;
`;

export const StoryTitleFixedWidth = styled.div`
  font-weight: bold;
  width: 300px;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SectionTitleFixedWidth = styled.div`
  font-weight: bold;
  width: 300px;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StoryTitle = styled.div`
  font-weight: bold;
`;

export const StyledLeftPanelTh = styled.th`
  height: ${SUBSTORY_HEIGHT_PX}px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
`;

export const StyledLeftPanelTable = styled.table<{ showShadow?: boolean }>`
  width: ${LEFT_PANEL_WIDTH_PX}px;
  border-spacing: 0;

  transition: 0.2s ease-in-out;

  ${(props) =>
    props.showShadow &&
    css`
      z-index: 1;
      box-shadow: 0 19px 38px rgba(0, 0, 0, 0.15), 0 15px 12px rgba(0, 0, 0, 0.11);
    `};
`;

export const StyledTitleContentWrapper = styled(StyledTdContentWrapper)`
  cursor: pointer;

  :hover {
    background-color: ${colors.leftPanel.input.hoverBackgroundColor};
  }
`;
