import { FC, useState, memo, useEffect } from 'react';
import styled from '@emotion/styled';

import Select from '../../Select/v2';

const Container = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  cursor: text;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  input[type='number'] {
    appearance: textfield;
  }
`;

const items = [
  {
    value: 'Developer',
    label: 'Developer',
  },
  {
    value: 'Architect',
    label: 'Architect',
  },
  {
    value: 'BA',
    label: 'BA',
  },
  {
    value: 'UI/UX',
    label: 'UI/UX',
  },
  {
    value: 'PM',
    label: 'PM',
  },
  {
    value: 'QA',
    label: 'QA',
  },
  {
    value: 'AQA',
    label: 'AQA',
  },
  {
    value: 'DevOps',
    label: 'DevOps',
  },
];

type Props = {
  value: string;
  onChange: (value: string) => void;
  onBlur: () => void;
  canEdit?: boolean;
};

const DirectionField: FC<Props> = ({ value, onChange, onBlur, canEdit }) => {
  const [isActive, setActive] = useState(false);
  const [isOnBlur, setOnblur] = useState(false);

  const handleShowInput = () => {
    if (canEdit) return;
    setActive(true);
  };

  useEffect(() => {
    if (isActive) setActive(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (!isActive && isOnBlur) {
      setOnblur(true);
      onBlur();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  const handleChange = (newValue: string | number) => {
    onChange(String(newValue));
    setOnblur(true);
  };

  return (
    <Container onClick={handleShowInput}>
      {isActive && !canEdit ? (
        <Select
          placeholder="Select a direction"
          width={100}
          value={value}
          itemList={items}
          textAlign="center"
          onChange={handleChange}
        />
      ) : (
        <span>{value || ''}</span>
      )}
    </Container>
  );
};

export default memo(DirectionField);
