import { FC } from 'react';
import Container from './Cell.styled';

import { isDatesEqual } from '../../utils';

type Props = {
  date: Date;
  currentDate: Date;
  selectedDate: Date;
  selectedMonth: Date;
  onClick: (date: Date) => void;
};

const Cell: FC<Props> = ({ date, currentDate, selectedDate, selectedMonth, onClick }) => {
  const handleDateClick = () => {
    onClick(date);
  };

  return (
    <Container
      currentDate={isDatesEqual(currentDate, date)}
      selectedDate={isDatesEqual(selectedDate, date)}
      disabled={date.getMonth() !== selectedMonth.getMonth()}
      onClick={handleDateClick}
    >
      {date.getDate()}
    </Container>
  );
};

export default Cell;
