import { FC } from 'react';

import BodyTitleRow from './BodyTitleRow';
import BodyRow from './BodyRow';
import { useTableData } from './context';
import { Column, Row } from './types';

type Props = {
  columns: Column<Row>[];
  onContextMenuClick: (value: { clientX: number; clientY: number }) => void;
  canEdit?: boolean;
};

const Body: FC<Props> = ({ columns, onContextMenuClick, canEdit }) => {
  const data = useTableData();

  return (
    <tbody>
      {data.map((row) =>
        row.isSection ? (
          <BodyTitleRow
            key={row.rowId}
            data={row}
            onContextMenuClick={onContextMenuClick}
            canEdit={canEdit}
          />
        ) : (
          <BodyRow
            key={row.rowId}
            data={row}
            columns={columns}
            onContextMenuClick={onContextMenuClick}
            canEdit={canEdit}
          />
        ),
      )}
    </tbody>
  );
};
export default Body;
