import styled from '@emotion/styled';
import { colors } from '../../../../../theme/colors';

// eslint-disable-next-line import/prefer-default-export
export const StyledLeftPanelInput = styled.input`
  width: 100%;
  height: 30px;
  padding: 0;
  margin: 0;
  border: 0;
  box-sizing: border-box;

  :focus {
    border-radius: ${colors.sausage.defaultBorderRadius}px;

    background-color: #f5f5f5;

    outline: none;
  }

  :hover {
    border-radius: ${colors.sausage.defaultBorderRadius}px;
  }
`;
