import { AppCamera } from '../../models/AppCamera';
import { AppScaleEnum } from '../../models/AppScaleEnum';

export const TIMELINE_COLUMN_SIZE = 24;
export const WEEK_COLUMN_SIZE = TIMELINE_COLUMN_SIZE * 2;
export const MONTH_COLUMN_SIZE = TIMELINE_COLUMN_SIZE * 3;
export const SUBSTORY_HEIGHT_PX = 40;
export const SAUSAGE_HEIGHT_PX = 30;
export const INITIAL_APP_CAMERA: AppCamera = {
  x: TIMELINE_COLUMN_SIZE,
  y: 0,
  scale: AppScaleEnum.Days,
};
export const SCROLL_DELTA_MULTIPLIER = 0.3;
export const LEFT_PANEL_WIDTH_PX = 400;
