import AppSection from 'pages/EstimationDetailPage/Gantt/models/AppSection';
import { useMemo } from 'react';
import { useEstimationStartdate } from 'reducers/selectors';
import { MainModel } from '../../../models/MainModel';
import { SUBSTORY_HEIGHT_PX } from '../consts';
import { AdaptedSection, MainModelWithSizes } from '../types';
import { setAdditionsForStories } from '../utils';

const emptyModel: MainModelWithSizes = {
  sections: [],
  startDate: new Date(),
};

const mapToSectionSizes = (appSection: AppSection, startdate: number): AdaptedSection => ({
  ...appSection,
  stories: setAdditionsForStories(appSection.stories, new Date(startdate)),
  heightInPx: (appSection.stories.length + 1) * SUBSTORY_HEIGHT_PX,
});

const mapToSizes = (model: MainModel | null, startdate: number): MainModelWithSizes => {
  if (!model) {
    return emptyModel;
  }

  return {
    sections: model.sections.map((section) => mapToSectionSizes(section, startdate)),
    startDate: new Date(startdate),
  };
};

const useModel = (model: MainModel | null): MainModelWithSizes => {
  const startdate = useEstimationStartdate();

  return useMemo(() => mapToSizes(model, startdate), [model, startdate]);
};

export default useModel;
