import React, { FC, ReactNode } from 'react';
import { StyledDefaultLayout } from './DefaultLayoutStyles';

export interface DefaultLayoutProps {
  headerSlot?: ReactNode;
}

export const DefaultPageLayout: FC<DefaultLayoutProps> = ({ headerSlot, children }) => (
  <StyledDefaultLayout>
    {headerSlot}
    {children}
  </StyledDefaultLayout>
);
