import { FC, useRef } from 'react';
import { SerializedStyles } from '@emotion/react';

import Icon from 'components/Icon';
import Button from 'components/Button';
import {
  TitleContainer,
  Title,
  ModalContainer,
  MainContainer,
  Wrapper,
  ButtonContainer,
  CustomButton,
} from './Modal.styled';

type Props = {
  isOpen: boolean;
  textCancel?: string;
  textConfirm?: string;
  styleTypeCancel?: string;
  styleTypeConfirm?: string;
  title?: string;
  text?: string | React.ReactNode;
  styleModal?: SerializedStyles;
  onClose: () => void;
  onCancel: () => void;
  onConfirm: () => void;
};

const Modal: FC<Props> = ({
  onClose,
  onCancel,
  onConfirm,
  textCancel,
  textConfirm,
  text,
  styleModal,
  title,
  isOpen,
  styleTypeConfirm,
  styleTypeCancel,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);

  return (
    <>
      {isOpen && (
        <Wrapper>
          <ModalContainer ref={modalRef} styleModal={styleModal}>
            <TitleContainer>
              <Title>{title}</Title>
              <Icon name="cross" onClick={onClose} />
            </TitleContainer>
            <MainContainer>{text}</MainContainer>
            <ButtonContainer>
              <CustomButton
                label={textCancel || ''}
                styleType={styleTypeCancel}
                onClick={onCancel}
              />
              <Button
                type="button"
                onClick={onConfirm}
                label={textConfirm || ''}
                styleType={styleTypeConfirm}
              />
            </ButtonContainer>
          </ModalContainer>
        </Wrapper>
      )}
    </>
  );
};

export default Modal;
