import styled from '@emotion/styled';

// eslint-disable-next-line import/prefer-default-export
export const StyledDefaultContentLayout = styled.main`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  .rightCanvasBox {
    overflow-x: auto;
  }
`;
