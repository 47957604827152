import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Notification = styled.div`
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.gray[550]};
`;

export const Mark = styled.span`
  color: ${({ theme }) => theme.colors.red[400]};
`;

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: calc(50% - 16px);
  border: 1px solid ${({ theme }) => theme.colors.gray[200]};
  border-radius: 4px;
  padding: 24px;
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

export const DeleteContainer = styled.div`
  display: flex;
`;

export const WarningContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: end;
  margin-right: 20px;
`;

export const Warning = styled.span`
  font-size: 19px;
  color: ${({ theme }) => theme.colors.red[900]};
  font-weight: 700;
  text-transform: uppercase;
`;

export const WarningText = styled.p`
  color: ${({ theme }) => theme.colors.gray[900]};
`;
