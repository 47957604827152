import { AppScaleEnum } from 'pages/EstimationDetailPage/Gantt/models/AppScaleEnum';
import { useMemo } from 'react';
import { useGanttScaleKey } from 'reducers/selectors';
import { SUBSTORY_HEIGHT_PX } from '../../consts';
import useHourPixels from '../../hooks/useHourPixels';
import { AdaptedSection, DatePeriod } from '../../types';
import { getStoryStartDate, millisecondsToHours, getStoryEndDate } from '../../utils';

type SizesProps = {
  datePeriod: DatePeriod;
  groupX: number;
  groupY: number;
  section: AdaptedSection;
  sectionYWithTitleOffset: number;
  calendarItemWidth: number;
};

type StorySizes = {
  x: number;
  y: number;
  groupX: number;
  groupY: number;
  width: number;
};

const useSizes = ({
  datePeriod,
  groupX,
  groupY,
  section,
  sectionYWithTitleOffset,
  calendarItemWidth,
}: SizesProps): StorySizes[] => {
  const scaleKey = useGanttScaleKey();
  const pxInHour = useHourPixels(scaleKey);

  return useMemo(
    () =>
      section.stories.map((story, storyIndex) => {
        const actualOffset =
          scaleKey === AppScaleEnum.Days ? story.offsetHours : story.offsetBusinessHours;
        const x = actualOffset * pxInHour;

        const storyStart = getStoryStartDate(
          datePeriod.start,
          story.offsetHours,
          pxInHour,
        ).getTime();

        const storyEnd = getStoryEndDate(story.hours, storyStart);

        const calendarHoursDelta = millisecondsToHours(storyEnd - storyStart);

        const workingHoursDelta =
          Math.floor(calendarHoursDelta / calendarItemWidth) * 8 +
          ((calendarHoursDelta % calendarItemWidth) / calendarItemWidth) * 8;

        const widthPx = workingHoursDelta * pxInHour;
        const y = storyIndex * SUBSTORY_HEIGHT_PX + 5;

        return {
          x,
          y,
          groupX: groupX + x,
          groupY: groupY + sectionYWithTitleOffset,
          width: widthPx,
        };
      }),
    [
      datePeriod.start,
      pxInHour,
      scaleKey,
      groupX,
      groupY,
      section.stories,
      sectionYWithTitleOffset,
      calendarItemWidth,
    ],
  );
};

export default useSizes;
