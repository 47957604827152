import { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { css } from '@emotion/react';

import { updateProfile, deleteCompany } from 'services/company';
import { getFile, postFile } from 'services/file';
import { Company } from 'services/types/Company';
import Input from 'components/Input';
import Button from 'components/Button';
import FileUploader from 'components/FileUploader';
import Modal from 'components/Modal';
import TextArea from 'components/TextArea';
import { VALIDATION_MESSAGES } from 'constants/common';
import {
  Notification,
  Mark,
  Container,
  DataContainer,
  DeleteContainer,
  FooterContainer,
  WarningContainer,
  WarningText,
  Warning,
} from './Profile.styled';

const stylesButton = css`
  margin-right: 20px;
`;

type FormData = {
  name: string;
  description: string;
  logo: File[];
};

const ACCEPTED_FILES = ['image/png', 'image/jpeg', 'image/bmp', 'image/svg+xml'];

type Props = {
  data: Company | null;
};

const Profile: FC<Props> = ({ data }) => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isServerError, setIsServerError] = useState(false);
  const { control, errors, handleSubmit, setValue } = useForm<FormData>({
    defaultValues: {
      name: '',
      description: '',
      logo: [],
    },
  });

  useEffect(() => {
    if (data) {
      setValue('name', data.name);
      setValue('description', data.description || '');
      getFile(data.logo)
        .then((file) => {
          setValue('logo', [file]);
        })
        .catch(() => setIsServerError(true));
    }
  }, [data, setValue]);

  const onToggleModal = () => {
    setIsOpenModal((prevState) => !prevState);
  };

  const onDeleteCompany = () => {
    deleteCompany(id).catch(() => setIsServerError(true));
  };

  const onSubmit = (formValues: FormData) => {
    const companyInfo = {
      id,
      name: formValues.name,
      description: formValues.description,
    };
    updateProfile(companyInfo)
      .then(() => {
        if (formValues.logo[0]) {
          const formData = new FormData();
          formData.append('file', formValues.logo[0]);

          postFile(formData, {
            fileSubType: 'AVATAR',
            fileType: 'IMAGE',
            ownerId: id,
            ownerType: 'COMPANY',
          });
        }
      })
      .catch(() => setIsServerError(true));
  };

  const onCancel = () => {
    history.push('/main/client-list');
  };

  const handleSubmitEmployee = handleSubmit(onSubmit);

  return (
    <>
      <Notification>
        <span>
          All fields marked with <Mark>*</Mark> are required
        </span>
      </Notification>
      <form onSubmit={handleSubmitEmployee}>
        <Container>
          <DataContainer>
            <Controller
              name="name"
              control={control}
              rules={{ required: { value: true, message: VALIDATION_MESSAGES.required } }}
              render={({ onChange, value, name }, { invalid }) => (
                <Input
                  name={name}
                  value={value}
                  label="Company Name"
                  placeholder="Company Name"
                  isError={invalid}
                  errorMessage={errors.name?.message}
                  mb={10}
                  onChange={onChange}
                  isRequired
                />
              )}
            />
            <Controller
              name="description"
              control={control}
              render={({ name, value, onChange }) => (
                <TextArea
                  label="Company Description"
                  name={name}
                  value={value}
                  mb={16}
                  onChange={onChange}
                />
              )}
            />
          </DataContainer>
          <DataContainer>
            <Controller
              name="logo"
              control={control}
              render={({ value, onChange }) => (
                <FileUploader
                  label="Add project logo"
                  acceptText="PNG, JPG, BMP, SVG"
                  accept={ACCEPTED_FILES}
                  files={value}
                  onChange={onChange}
                />
              )}
            />
          </DataContainer>
        </Container>
        <FooterContainer>
          <div>
            <Button label="Save" type="submit" styleType="primary" styleButton={stylesButton} />
            <Button label="Cancel" onClick={onCancel} />
          </div>
          <DeleteContainer>
            <WarningContainer>
              <Warning>Warning</Warning>
              <WarningText>Это приведёт к удалению компании и всех ее проектов.</WarningText>
            </WarningContainer>
            <Button label="Delete" styleType="warning" onClick={onToggleModal} />
          </DeleteContainer>
        </FooterContainer>
      </form>
      {isOpenModal && isServerError ? (
        <p>Something go wrong</p>
      ) : (
        <Modal
          title="Delete Company"
          textCancel="Cancel"
          textConfirm="Delete"
          styleTypeConfirm="warning"
          text={`Are you sure you want to delete company "${data?.name}"?`}
          isOpen={isOpenModal}
          onCancel={onToggleModal}
          onClose={onToggleModal}
          onConfirm={onDeleteCompany}
        />
      )}
    </>
  );
};

export default Profile;
