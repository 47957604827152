import styled from '@emotion/styled';

export const RowBase = styled.div`
  display: flex;
  align-items: center;
  padding: 11px 20px;
  font-weight: 400;
  font-size: ${({ theme }) => theme.fontSizes.standard};
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray[550]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[200]};

  & > * {
    padding: 0 5px;
  }
`;

export const FileIconBox = styled.div`
  flex-basis: 35px;
`;

export const ButtonGroup = styled.div`
  flex: 0 0 90px;
  display: flex;
`;

export const FileName = styled.div`
  flex: 1 1 auto;
`;

export const DateLabel = styled.div`
  flex-basis: 93px;
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

export const Extension = styled.div`
  flex-basis: 50px;
  font-size: ${({ theme }) => theme.fontSizes.small};
  text-transform: uppercase;
`;
