import { FC, useState } from 'react';

import { ProjectType } from 'components/EmployeeForm/types';
import { deleteEmployee } from 'services/employee';
import Modal from 'components/Modal';
import EmployeeItem, { EmployeeData } from '../EmployeeItem';
import { Wrapper } from './EmployeeList.styled';

export type Props = {
  employees: EmployeeData[];
  projects: ProjectType[];
  toUpdateData: () => void;
};

const EmployeeList: FC<Props> = ({ employees, projects, toUpdateData }) => {
  const [isModalDelete, setIsModalDelete] = useState(false);
  const [employeeId, setEmployeeId] = useState<number | null>(null);
  const [isServerError, setIsServerError] = useState(false);

  const onToggleModal = () => {
    setIsModalDelete((prevState) => !prevState);
  };

  const handleConfirmDeleteEmployee = () => {
    if (employeeId) {
      deleteEmployee(employeeId.toString())
        .then(() => toUpdateData())
        .then(() => onToggleModal())
        .catch(() => setIsServerError((prevState) => !prevState));
    }
  };

  const handleDeleteEmployee = (id: number) => {
    onToggleModal();
    setEmployeeId(id);
  };

  return (
    <Wrapper>
      {employees.map((item, index) => (
        <EmployeeItem
          key={item.id}
          item={item}
          isLast={employees.length === index + 1}
          onDeleteEmployee={handleDeleteEmployee}
          projectList={projects}
          toUpdateData={toUpdateData}
        />
      ))}
      {isModalDelete && isServerError ? (
        <p>Something go wrong</p>
      ) : (
        <Modal
          title="Delete Employee"
          textCancel="Cancel"
          textConfirm="Delete"
          styleTypeConfirm="warning"
          text='Are you sure you want to delete employee "Employee name"?'
          isOpen={isModalDelete}
          onCancel={onToggleModal}
          onClose={onToggleModal}
          onConfirm={handleConfirmDeleteEmployee}
        />
      )}
    </Wrapper>
  );
};
export default EmployeeList;
