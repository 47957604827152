import { FC } from 'react';

import Icon, { IconName, nameToComponentMap } from 'components/Icon';
import Button from '../Button.styled';
import { RowBase, ButtonGroup, FileIconBox, FileName, DateLabel, Extension } from './Row.styled';

type Props = {
  filename: string;
  date: number;
  extension: string;
  uuid: string;
  onDelete: (uuid: string) => void;
  onDownload: (uuid: string) => void;
};

const formatDate = (timestamp: number) => {
  const date = new Date(timestamp);
  const month = date.toLocaleString('en', { month: 'long' }).substr(0, 3);
  const day = date.getDate();
  const year = date.getFullYear();

  return `${month} ${day}, ${year}`;
};

const Row: FC<Props> = ({ filename, date, extension, uuid, onDelete, onDownload }) => {
  const deleteHandle = () => {
    onDelete(uuid);
  };

  const downloadHandle = () => {
    onDownload(uuid);
  };

  const preparedExtension = extension.toLowerCase();

  const iconName =
    preparedExtension in nameToComponentMap ? (preparedExtension as IconName) : 'unknown';

  return (
    <RowBase>
      <FileIconBox>
        <Icon name={iconName} size={24} />
      </FileIconBox>
      <FileName>{filename}</FileName>
      <DateLabel>{formatDate(date)}</DateLabel>
      <Extension>{extension}</Extension>
      <ButtonGroup>
        <Button onClick={downloadHandle}>
          <Icon name="arrowDown" />
        </Button>
        <Button onClick={deleteHandle}>
          <Icon name="delete" />
        </Button>
      </ButtonGroup>
    </RowBase>
  );
};

export default Row;
