import { FC } from 'react';
import { useTheme } from '@emotion/react';

import Icon, { IconName } from 'components/Icon';
import { ListItem, IconContainer } from './Select.styled';

export type Data = {
  iconName?: string;
  value: string | number;
  label: string;
};

type Props = {
  data: Data;
  value: string | number;
  isContact?: boolean;
  onChange: (value: string | number) => void;
};

const Item: FC<Props> = ({ data, value, onChange }) => {
  const { colors } = useTheme();

  const handleClick = () => {
    onChange(data.value);
  };

  return (
    <ListItem selected={data.value === value} onClick={handleClick}>
      {data.iconName && (
        <IconContainer>
          <Icon
            name={data.iconName as IconName}
            size={16}
            color={data.value === value ? colors.white[400] : colors.blue[700]}
          />
        </IconContainer>
      )}
      {data.label}
    </ListItem>
  );
};

export default Item;
