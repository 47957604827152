import { FC, useContext, useEffect } from 'react';

import { useTableData, useTechnologiesList, useDevelopmentSectionTimeSum } from './context';
import { FooterTitleRow, FooterTitleRowCell, FooterCell, FooterRow } from './styled/Footer.styled';
import { Column, Row } from './types';
import TablesContext from '../../pages/EstimationPage/context';
import { formatDataEstimationTable } from './utils';

type Props = {
  technologiesListLength: number;
  columns: Column<Row>[];
};

const TableFooter: FC<Props> = ({ technologiesListLength, columns }) => {
  const tableData = useTableData();
  const technologiesList = useTechnologiesList();
  const developmentSectionTimeSum = useDevelopmentSectionTimeSum();
  const { tablesContext, setTablesContext } = useContext(TablesContext);

  const sectionSum = tableData.reduce((sum, row) => {
    if (row.type === 'ANALYSIS' && (typeof row.sum === 'number' || typeof row.sum === 'string')) {
      return sum + Number(row.sum);
    }

    if (row.type === 'MANAGEMENT' && !Number.isNaN(Number(row.sum))) {
      return sum + Math.round((Number(row.sum) * developmentSectionTimeSum) / 100);
    }

    if (row.type === 'DEVELOPMENT') {
      let technologiesSum = 0;
      technologiesList.forEach((item) => {
        const technologyValue = row[item];
        if (
          technologyValue &&
          (typeof technologyValue === 'number' || typeof technologyValue === 'string')
        ) {
          technologiesSum += Number(technologyValue);
        }
      });

      return sum + technologiesSum;
    }

    return sum;
  }, 0);
  /* eslint-disable */
  useEffect(() => {
    const data = formatDataEstimationTable(columns, tableData);
    setTablesContext({ ...tablesContext, estimationTable: data });
  }, [columns, tableData]);
  /* eslint-enable */

  return (
    <tfoot>
      <FooterTitleRow>
        <FooterTitleRowCell colSpan={technologiesListLength + 2}>Итого</FooterTitleRowCell>
        <FooterTitleRowCell>Сумма</FooterTitleRowCell>
      </FooterTitleRow>
      <FooterRow>
        <FooterCell colSpan={technologiesListLength + 2} />
        <FooterCell>{sectionSum}</FooterCell>
      </FooterRow>
    </tfoot>
  );
};

export default TableFooter;
