import { FC } from 'react';

import { Row, CellContainer, WeekRow } from './Calendar.styled';

const dayOfWeek = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

const WeekDays: FC = () => (
  <Row>
    {dayOfWeek.map((weekName) => (
      <CellContainer key={weekName}>
        <WeekRow>{weekName}</WeekRow>
      </CellContainer>
    ))}
  </Row>
);

export default WeekDays;
