import styled from '@emotion/styled';

const Box = styled.ul`
  min-width: 150px;
  list-style: none;
  width: max-content;
  box-sizing: content-box;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgb(0 0 0 / 15%);

  & > li {
    border-left: 1px solid ${({ theme }) => theme.colors.gray[200]};
    border-right: 1px solid ${({ theme }) => theme.colors.gray[200]};
  }

  & > li:first-of-type {
    border-top: 1px solid ${({ theme }) => theme.colors.gray[200]};
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  & > li:last-of-type {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray[200]};
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

export default Box;
