import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { getProjects } from 'services/project';
import { useUserData } from 'reducers/selectors';
import { AllProjects } from 'services/types/Project';
import { getPagesCount, getInCaps } from 'utils/common';
import PagePanel from 'components/PagePanel';
import PaginatedGrid from 'components/PaginatedGrid';
import Container from 'layouts/Container';
import {
  STATUSES,
  LANGUAGES,
  SORTS,
  SHOWN_QUANTITY,
  PAGE_NEIGHBOR_QUANTITY,
  initialFilter,
} from 'constants/common';
import useActiveTab from 'utils/hooks/useActiveTab';
import ProjectItem from './components/ProjectItem';
import Filter from './components/Filter';
import { FilterType } from './types';

const getParams = (filter: FilterType, page: number) => {
  const { language, status } = filter;
  const languageValue = language === LANGUAGES[0] ? '' : language;
  const statusName = status === STATUSES[0] ? '' : getInCaps(status);

  return {
    asc: filter.asc,
    name: filter.search,
    language: languageValue,
    page,
    sort: filter.sort.toLowerCase(),
    size: SHOWN_QUANTITY,
    status: statusName,
  };
};

const useQuery = () => new URLSearchParams(useLocation().search);

const ProjectListPage: FC = () => {
  const currentPage = useQuery().get('activeTab');
  const currentTab = currentPage !== null ? +currentPage : 1;
  const [page, setPage] = useState(currentTab);
  const [filter, setFilter] = useState(initialFilter);
  const [viewedProjects, setViewedProjects] = useState<AllProjects>();
  const user = useUserData();

  useActiveTab(page);

  useEffect(() => {
    const params = getParams(filter, page);
    getProjects(params)
      .then((res) => {
        if (res.totalCount > 0 && res.values.length === 0) {
          return setPage(getPagesCount(res.totalCount, SHOWN_QUANTITY));
        }

        return res;
      })
      .then((result) => result && setViewedProjects(result));
  }, [filter, page]);

  const { control, formState, reset, handleSubmit } = useForm({
    defaultValues: initialFilter,
  });

  const handleReset = () => {
    reset();
    setFilter(initialFilter);
  };

  const onSubmit = handleSubmit((data: FilterType) => {
    setFilter(data);
  });

  return (
    <div>
      <PagePanel
        buttonText={
          user && (user.role === 'SUPER' || user.role === 'ADMIN') ? 'Create project' : ''
        }
        title="Projects"
        link="/main/project-list/create"
      />
      <Container>
        <Filter
          onSubmit={onSubmit}
          isDirty={formState.isDirty}
          control={control}
          statusItems={STATUSES}
          currentStatus={filter.status}
          languageList={LANGUAGES}
          currentLanguage={filter.language}
          setFilter={setFilter}
          onReset={handleReset}
          sorts={SORTS}
        />
        <PaginatedGrid
          totalItem={viewedProjects?.totalCount}
          select={page}
          pageNeighbors={PAGE_NEIGHBOR_QUANTITY}
          onChangePage={setPage}
          textPlaceholder="There is no projects for now"
        >
          {viewedProjects &&
            viewedProjects.values.map((project) => <ProjectItem item={project} key={project.id} />)}
        </PaginatedGrid>
      </Container>
    </div>
  );
};

export default ProjectListPage;
