import { FC, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { BoardType } from './types';
import Board from './Board';
import Context from './context';

const Wrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  background-color: #faf9fa;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.colors.gray[200]};
`;
export type Props = {
  data: BoardType[];
  showBoards: boolean;
};

const KanbanBoard: FC<Props> = ({ data, showBoards }) => {
  const [boards, setBoards] = useState<BoardType[]>(data);

  const currentValues = useRef(null);

  const onChangeBoard = (changeBoards: BoardType[]) => {
    setBoards(changeBoards);
  };

  return (
    <Context.Provider value={{ currentValues, onChangeBoard }}>
      {showBoards && (
        <Wrapper>
          {boards.map((board) => (
            <Board key={board.id} board={board} boards={boards} />
          ))}
        </Wrapper>
      )}
    </Context.Provider>
  );
};

export default KanbanBoard;
