import { FC, useState, useEffect, useRef, MouseEvent as ReactMouseEvent } from 'react';
import { CSSTransition } from 'react-transition-group';

import useCurrentMonthValues from './useCurrentMonthValues';
import { insertZeroInDate, isDatesEqual } from './utils';
import CalendarHeader from './CalendarHeader';
import WeekDays from './WeekDays';
import Cell from './components/Cell';
import {
  Row,
  Box,
  CellContainer,
  CalendarContainer,
  Input,
  Container,
  Label,
} from './Calendar.styled';

type CurrentMonth = [string, Date[]];

const currentDate = new Date();

type Props = {
  label?: string;
  name: string;
  value: Date | string;
  mb?: number;
  onChange: (value: Date) => void;
};

const Calendar: FC<Props> = ({ value, name, label, mb = 0, onChange, ...rest }) => {
  const formattedValue = value instanceof Date ? value : currentDate;
  const [selectedMonth, setSelectedMonth] = useState(formattedValue);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const calendarRef = useRef<HTMLLabelElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (calendarRef.current && !calendarRef.current.contains(event.target as Node)) {
        setIsCalendarOpen(false);
      }
    }

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const currentMonthValues = useCurrentMonthValues({ selectedMonth });

  const handleDateClick = (date: Date) => {
    onChange(date);
  };

  const handleSetMonth = (nextMonth?: boolean) => {
    setSelectedMonth(
      (previousDate) =>
        new Date(previousDate.getFullYear(), previousDate.getMonth() + (nextMonth ? 1 : -1)),
    );
  };

  const handleInputClick = () => {
    setIsCalendarOpen((prevState) => !prevState);
  };

  const handleCalendarClick = (event: ReactMouseEvent) => {
    event.preventDefault();
  };

  const displayValue = isDatesEqual(formattedValue, currentDate)
    ? ''
    : `${insertZeroInDate(formattedValue.getDate())}.${insertZeroInDate(
        formattedValue.getMonth() + 1,
      )}.${formattedValue.getFullYear()}`;

  return (
    <Container mb={mb} {...rest}>
      <Box ref={calendarRef}>
        {label && <Label>{label}</Label>}
        <Input
          readOnly
          autoComplete="off"
          type="text"
          name={name}
          value={displayValue}
          onClick={handleInputClick}
        />
        <CSSTransition in={isCalendarOpen} timeout={300} classNames="calendar" unmountOnExit>
          <CalendarContainer onClick={handleCalendarClick}>
            <CalendarHeader selectedMonth={selectedMonth} onSetMonth={handleSetMonth} />
            <WeekDays />
            {currentMonthValues.map((week: CurrentMonth) => (
              <Row key={week[0]}>
                {week[1].map((date: Date) => (
                  <CellContainer key={date.getTime()}>
                    <Cell
                      currentDate={currentDate}
                      selectedDate={formattedValue}
                      date={date}
                      selectedMonth={selectedMonth}
                      onClick={handleDateClick}
                    />
                  </CellContainer>
                ))}
              </Row>
            ))}
          </CalendarContainer>
        </CSSTransition>
      </Box>
    </Container>
  );
};

export default Calendar;
