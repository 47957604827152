import { protectedDelete, protectedGet, protectedPost, protectedPut } from './protectedAxios';

import {
  AllProjects,
  Params,
  NewProject,
  ProjectDetail,
  UpdatedProject,
  ProjectIteration,
} from './types/Project';
import { FileSubType, ProjectFile } from './types/File';

const PROJECTS_URL = '/api/project';
const getProjectsUrl = () => `${PROJECTS_URL}/all`;
const getProjectUrl = (id: string) => `${PROJECTS_URL}/${id}`;
const getProjectFilesUrl = (id: string) => `${getProjectUrl(id)}/files`;
const getProjectIterationsUrl = (id: string) => `${PROJECTS_URL}/${id}/iterations`;

export const getProjects = (params: Params): Promise<AllProjects> =>
  protectedGet(getProjectsUrl(), params);

export const createProject = (data: NewProject): Promise<{ id: number }> =>
  protectedPost(PROJECTS_URL, data);

export const updateProject = (data: UpdatedProject): Promise<{ id: number }> =>
  protectedPut(PROJECTS_URL, data);

export const deleteProject = (id: string): Promise<void> => protectedDelete(getProjectUrl(id));

export const getDetails = (id: string): Promise<ProjectDetail> => protectedGet(getProjectUrl(id));

export const getProjectFiles = (id: string, type: FileSubType): Promise<ProjectFile[]> =>
  protectedGet(getProjectFilesUrl(id), { type });

export const getProjectIterations = (id: string): Promise<ProjectIteration[]> =>
  protectedGet(getProjectIterationsUrl(id));
