import styled from '@emotion/styled';
import { SerializedStyles } from '@emotion/react';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Box = styled.label<{ isError: boolean }>`
  display: flex;
  flex-direction: column;
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  color: ${({ isError, theme }) => (isError ? theme.colors.red[400] : theme.colors.gray[550])};
`;

export const Label = styled.span`
  margin-bottom: 4px;
`;

export const RequiredMark = styled.span`
  color: ${({ theme }) => theme.colors.red[400]};
  margin-left: 3px;
`;

type Props = {
  mb: number;
  isError: boolean;
  textareaStyles?: SerializedStyles;
};

export const StyledTextArea = styled.textarea<Props>`
  border: 1px solid
    ${({ isError, theme }) => (isError ? theme.colors.red[400] : theme.colors.gray[200])};
  border-radius: 4px;
  overflow: auto;
  display: block;
  min-width: 320px;
  width: 100%;
  min-height: 136px;
  max-height: 320px;
  resize: vertical;
  background-color: ${({ theme }) => theme.colors.white[400]};
  color: ${({ theme }) => theme.colors.black[400]};
  font-size: 14px;
  font-weight: 400;
  padding: 12px 15px;
  margin-bottom: ${({ mb }) => `${mb}px`};
  transition: border-color 0.3s ease;
  ${({ textareaStyles }) => textareaStyles}

  &:disabled {
    background-color: ${({ theme }) => theme.colors.gray[400]};
    cursor: default;
  }

  &:hover,
  &:focus {
    border-color: ${({ isError, theme }) =>
      isError ? theme.colors.red[400] : theme.colors.blue[700]};
  }
  &:disabled:hover,
  &:disabled:focus {
    border-color: ${({ theme }) => theme.colors.gray[200]};
  }
`;

export const ErrorContainer = styled.div`
  position: relative;
`;

export const ErrorMessage = styled.span`
  position: absolute;
  color: ${({ theme }) => theme.colors.red[400]};
  font-size: 12px;
`;
