import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

import emptyStubImage from 'assets/images/empty-stub.png';

const showStub = keyframes`
  0% {
    opacity: 0;
    min-height: 0;
  }

  50% {
    opacity: 0;
    min-height: 716px;
  }

  100% {
    opacity: 1;
    min-height: 716px;
  }
`;

const StubStyled = styled.section`
  background: url(${emptyStubImage}) no-repeat 0 0;
  animation: 0.5s ease-in-out forwards ${showStub};
  max-width: 1245px;
  margin: 0 auto;
`;

const StubTitleStyled = styled.h2`
  font-size: 32px;
  font-weight: 400;
  color: #565e65;
  text-align: center;
  padding-top: 80px;
`;

export { StubStyled, StubTitleStyled };
