import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { css } from '@emotion/react';

import Input from 'components/Input';
import TextArea from 'components/TextArea';
import { VALIDATION_MESSAGES } from 'constants/common';
import CompanyStyled from './styled/Company.styled';

const textareaStyles = css`
  height: 190px;
`;

const Company: FC = () => {
  const { control, errors } = useFormContext();

  return (
    <CompanyStyled>
      <Controller
        control={control}
        name="name"
        rules={{ required: { value: true, message: VALIDATION_MESSAGES.required } }}
        render={({ onChange, value }, { invalid }) => (
          <Input
            label="Company Name"
            placeholder="Company name"
            value={value}
            onChange={onChange}
            isError={invalid}
            errorMessage={errors.name?.message}
            isRequired
          />
        )}
      />
      <Controller
        control={control}
        name="description"
        rules={{ maxLength: { value: 255, message: 'VALIDATION_FIELD_LENGTH < 255' } }}
        render={({ onChange, value }, { invalid }) => (
          <TextArea
            name="name"
            label="Company Description"
            value={value}
            isError={invalid}
            errorMessage={errors.description?.message}
            onChange={onChange}
            placeholder="Type here..."
            textareaStyles={textareaStyles}
          />
        )}
      />
    </CompanyStyled>
  );
};

export default Company;
