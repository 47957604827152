import React, { FC } from 'react';
import AppStory from 'pages/EstimationDetailPage/Gantt/models/AppStory';
import { SetStoryId } from '../../../types';
import { StyledLeftPanelTd, StyledTdContentWrapper } from '../LeftPanelStyles';
import { StyledLeftPanelInput } from '../LeftPanelInput';
import { colors } from '../../../../../theme/colors';

export interface LeftPaneLineProps {
  story: AppStory;
  storyTitle: string;
  storyId: number | null;
  setStoryId: SetStoryId;
}

export const LeftPanelLine: FC<LeftPaneLineProps> = ({
  story,
  storyTitle,
  storyId,
  setStoryId,
}) => {
  const onHover = () => {
    setStoryId(story.id);
  };

  return (
    <StyledLeftPanelTd
      onMouseEnter={onHover}
      style={{
        backgroundColor: story.id === storyId ? colors.stories.hoverColor : 'transparent',
      }}
    >
      <StyledTdContentWrapper>
        <StyledLeftPanelInput defaultValue={storyTitle} readOnly />
      </StyledTdContentWrapper>
    </StyledLeftPanelTd>
  );
};
