import styled from '@emotion/styled';

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 16px;

  &:last-of-type {
    margin-bottom: 0;
  }

  & > div:first-of-type {
    flex-basis: 320px;
    margin-right: 15px;
  }

  & > div:last-of-type {
    flex-basis: 225px;
  }
`;

export default Row;
