import { EmployeeData } from 'pages/CompanyDetailPage/Employees/EmployeeItem';

import { protectedDelete, protectedGet, protectedPut, protectedPost } from './protectedAxios';
import { AllClients, Params, NewClient, ClientId } from './types/Client';
import { Company, Profile } from './types/Company';
import { AllProjects } from './types/Project';

const COMPANY_URL = '/api/company';
const getCompanyUrl = (id: string) => `${COMPANY_URL}/${id}`;
const getCompaniesAllUrl = () => `${COMPANY_URL}/all`;
const getCompanyProfile = () => `${COMPANY_URL}/profile`;
const getCompanyProjectsUrl = (id: string) => `${COMPANY_URL}/${id}/projects`;
const getCompanyEmployeesUrl = (id: string) => `${COMPANY_URL}/${id}/employees`;

export const getCompany = (id: string): Promise<Company> => protectedGet(getCompanyUrl(id));
export const getCompanies = (params?: Params): Promise<AllClients> =>
  protectedGet(getCompaniesAllUrl(), params);
export const getCompanyEmployees = (id: string): Promise<EmployeeData[]> =>
  protectedGet(getCompanyEmployeesUrl(id));

export const getCompanyProjects = (id: string, params?: Params): Promise<AllProjects> =>
  protectedGet(getCompanyProjectsUrl(id), params);

export const updateProfile = (data: Profile): Promise<void> =>
  protectedPut(getCompanyProfile(), data);

export const deleteCompany = (id: string): Promise<void> => protectedDelete(getCompanyUrl(id));

export const createNewCompany = (data: NewClient): Promise<ClientId> =>
  protectedPost<NewClient, ClientId>(COMPANY_URL, data);
