import styled from '@emotion/styled';

type Props = {
  hide?: boolean;
};

export const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray[200]};
  padding: 24px 34px 16px;
  background-color: ${({ theme }) => theme.colors.white[400]};
`;

export const EstimationContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray[200]};
  border-top: none;
  padding: 24px 34px 16px;
  background-color: ${({ theme }) => theme.colors.white[400]};
`;

export const IterationContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
`;

export const NewEstimationContainer = styled.button`
  display: flex;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white[300]};
  justify-content: space-between;
  align-items: center;
  flex-flow: row nowrap;
  border: 1px solid ${({ theme }) => theme.colors.gray[200]};
  border-top: none;
  padding: 26px 25px 26px 48px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  margin-bottom: 24px;

  & img {
    position: absolute;
    width: 300px;
    right: 40px;
    top: 80px;
    opacity: 0.6;
    transition: all ease-in-out 0.2s;
    border-style: none;
    max-width: 100%;
    height: auto;
  }

  &:hover img {
    top: 20px;
    transform: scale(1.2);
  }
`;

export const Title = styled.span`
  display: block;
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 20px;
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.colors.blue[300]};
  }
`;

export const Description = styled.p`
  color: ${({ theme }) => theme.colors.gray[900]};
  line-height: 1.5;
  font-size: 12px;
  word-break: break-word;
`;

export const TechContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 280px;
`;

export const LeftContainer = styled.div`
  display: flex;
  width: 600px;
  justify-content: space-around;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 50%;
  margin-right: 20px;
`;

export const RightContainer = styled.div`
  display: flex;
  width: 450px;
  justify-content: space-between;
`;

export const Subtitle = styled.span`
  display: inline-block;
  font-weight: 700;
  margin: 10px 0 20px;
  font-size: 12px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.gray[900]};
`;

export const PerformersContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 170px;
`;

export const PerformerName = styled.span`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray[550]};
`;

export const EmployeesContainer = styled.div<Props>`
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 5px;
`;

export const Performer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`;

export const Position = styled.span`
  font-size: 14px;
  text-align: right;
  letter-spacing: -0.24px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray[500]};
  word-break: break-word;
`;

export const Slash = styled.span`
  padding: 0 5px 0 10px;
  color: ${({ theme }) => theme.colors.gray[500]};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80px;
`;

export const ButtonRound = styled.button`
  color: ${({ theme }) => theme.colors.gray[900]};
  width: 32px;
  height: 32px;
  padding: 8px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.gray[200]};
  background-color: transparent;
  cursor: pointer;
  display: flex;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[250]};
  }
`;

export const ShowMore = styled.span`
  color: ${({ theme }) => theme.colors.blue[300]};
  cursor: pointer;
`;

export const SecondTitle = styled.span`
  font-weight: 500;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.blue[300]};
`;

export const EstimationTitle = styled.span`
  display: block;
  font-weight: 700;
  word-wrap: break-word;

  & > a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.gray[900]};
    :hover {
      color: ${({ theme }) => theme.colors.blue[300]};
    }
  }
`;

export const VersionTitle = styled.span`
  text-align: right;
  width: 120px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.gray[900]};
`;

export const EstimationRightContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 400px;
`;
