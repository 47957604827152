import React, { useCallback, useEffect, useMemo, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useContextMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState({
    x: 0,
    y: 0,
  });

  const openContextMenu = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const closeContextMenu = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const onContextMenu: React.MouseEventHandler = useCallback(
    (event) => {
      openContextMenu();
      setPosition({
        x: event.clientX,
        y: event.clientY,
      });
    },
    [openContextMenu],
  );

  useEffect(() => {
    document.addEventListener('click', closeContextMenu);

    return () => {
      document.removeEventListener('click', closeContextMenu);
    };
  }, [closeContextMenu]);

  return useMemo(
    () => ({
      isOpen,
      setIsOpen,
      position,
      onContextMenu,
    }),
    [isOpen, onContextMenu, position],
  );
};

export default useContextMenu;
