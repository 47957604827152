import styled from '@emotion/styled';

const TabsStyled = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;

const HeaderStyled = styled.header`
  max-width: 1245px;
  margin: auto auto 30px;
`;

const Container = styled.div`
  position: relative;
  top: -59px;
`;

export { TabsStyled, HeaderStyled, Container };
