import styled from '@emotion/styled';

const Main = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 25px;
  background-color: ${({ theme }) => theme.colors.white[500]};
`;

const EmployeeInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 14px;
`;

const Label = styled.label`
  color: ${({ theme }) => theme.colors.gray[550]};
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 4px;
`;

const EmployeeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const EmployeesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ButtonRound = styled.button<{ empty?: boolean }>`
  color: ${({ theme }) => theme.colors.gray[900]};
  width: 32px;
  height: 32px;
  padding: 8px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.gray[200]};
  background-color: transparent;
  cursor: pointer;
  display: flex;
  visibility: ${({ empty }) => (empty ? 'hidden' : 'visible')};

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[250]};
  }
`;

export {
  Main,
  EmployeeInfo,
  Label,
  InputContainer,
  EmployeesWrapper,
  EmployeeContainer,
  ButtonRound,
};
