import { FC } from 'react';
import { css } from '@emotion/react';
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form';

import Input from 'components/Input';
import { Performer as PerformerType } from 'services/types/Iteration';
import Select from 'components/Select/v2';
import { STATUSES, VALIDATION_MESSAGES } from 'constants/common';
import TextArea from 'components/TextArea';
import PerformersList from './PerformersList';
import { Container, FirstBox } from './IterationForm.styled';
import { DataToPost } from '../../types';

type Props = {
  control: Control;
  errors: DeepMap<DataToPost, FieldError>;
  item: {
    description: string;
    name: string;
    performers: PerformerType[];
    status: string;
    version: string;
  };
  onSubmit: () => void;
  setValue: (
    name: string,
    value: string,
    config?: Partial<{ shouldValidate: boolean; shouldDirty: boolean }> | undefined,
  ) => void;
};

const statusesList = STATUSES.slice(1).map((status) => ({
  label: status,
  value: status.toUpperCase().replaceAll(' ', '_'),
}));

const textareaStyles = css`
  height: 190px;
`;

const IterationForm: FC<Props> = ({ control, item, errors, onSubmit, setValue }) => (
  <form onSubmit={onSubmit}>
    <Container>
      <Controller
        name="name"
        control={control}
        defaultValue={item.name}
        render={({ onChange, value, name }) => (
          <Input value={value} name={name} type="text" label="Iteration Name" onChange={onChange} />
        )}
      />
      <FirstBox>
        <Controller
          name="version"
          control={control}
          defaultValue={item.version}
          rules={{
            required: { value: true, message: VALIDATION_MESSAGES.required },
          }}
          render={({ onChange, value, name }, { invalid }) => (
            <Input
              value={value}
              name={name}
              type="text"
              label="Version"
              width={120}
              placeholder="1"
              onChange={onChange}
              isError={invalid}
              errorMessage={errors.version?.message}
              isRequired
            />
          )}
        />
        <Controller
          name="status"
          control={control}
          defaultValue={item.status}
          rules={{
            required: { value: true, message: VALIDATION_MESSAGES.required },
          }}
          render={({ onChange, value }, { invalid }) => (
            <Select
              value={value}
              label="Iteration Status"
              itemList={statusesList}
              width={340}
              onChange={onChange}
              isRequired
              isError={invalid}
              errorMessage={errors.status?.message}
              verticalLabel
            />
          )}
        />
      </FirstBox>
      <Controller
        name="description"
        control={control}
        defaultValue={item.description || ''}
        render={({ onChange, value, name }) => (
          <TextArea
            name={name}
            value={value}
            label="Iteration description"
            placeholder="Iteration description"
            textareaStyles={textareaStyles}
            mb={16}
            onChange={onChange}
          />
        )}
      />
      <PerformersList control={control} errors={errors} setValue={setValue} />
    </Container>
  </form>
);

export default IterationForm;
