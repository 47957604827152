import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import Filter from 'pages/ProjectListPage/components/Filter';
import {
  initialFilter,
  LANGUAGES,
  PAGE_NEIGHBOR_QUANTITY,
  SHOWN_QUANTITY,
  SORTS,
  STATUSES,
} from 'constants/common';
import PaginatedGrid from 'components/PaginatedGrid';
import ProjectItem from 'pages/ProjectListPage/components/ProjectItem';
import { FilterType } from 'pages/ProjectListPage/types';
import { AllProjects } from 'services/types/Project';
import { getCompanyProjects } from 'services/company';
import { useParams } from 'react-router-dom';
import { getInCaps, getPagesCount } from 'utils/common';

const getParams = (filter: FilterType, page: number) => {
  const { language, status } = filter;
  const languageValue = language === LANGUAGES[0] ? '' : language;
  const statusName = status === STATUSES[0] ? '' : getInCaps(status);

  return {
    asc: filter.asc,
    name: filter.search,
    language: languageValue,
    page,
    sort: filter.sort.toLowerCase(),
    size: SHOWN_QUANTITY,
    status: statusName,
  };
};

const Projects: FC = () => {
  const { id } = useParams<{ id: string }>();
  const [filter, setFilter] = useState(initialFilter);
  const [viewedProjects, setViewedProjects] = useState<AllProjects>();
  const [page, setPage] = useState(1);

  const { control, formState, reset, handleSubmit } = useForm({
    defaultValues: initialFilter,
  });

  useEffect(() => {
    const params = getParams(filter, page);
    getCompanyProjects(id, params)
      .then((res) => {
        if (res.totalCount > 0 && res.values.length === 0) {
          return setPage(getPagesCount(res.totalCount, SHOWN_QUANTITY));
        }

        return res;
      })
      .then((result) => result && setViewedProjects(result));
  }, [filter, page, id]);

  const onSubmit = handleSubmit((data: FilterType) => {
    setFilter(data);
  });

  const handleReset = () => {
    reset();
    setFilter(initialFilter);
  };

  return (
    <>
      <Filter
        onSubmit={onSubmit}
        isDirty={formState.isDirty}
        control={control}
        statusItems={STATUSES}
        currentStatus={filter.status}
        languageList={LANGUAGES}
        currentLanguage={filter.language}
        setFilter={setFilter}
        onReset={handleReset}
        sorts={SORTS}
      />
      <PaginatedGrid
        totalItem={viewedProjects?.totalCount}
        select={page}
        pageNeighbors={PAGE_NEIGHBOR_QUANTITY}
        onChangePage={setPage}
        textPlaceholder="There is no projects for now"
      >
        {viewedProjects &&
          viewedProjects.values.map((project) => <ProjectItem item={project} key={project.id} />)}
      </PaginatedGrid>
    </>
  );
};

export default Projects;
