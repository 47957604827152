import { Row } from '../types';

type Props = {
  row: Row;
  tableData: Row[];
};

const useCanMoveUpAndDown = ({
  row,
  tableData,
}: Props): { canMoveUp: boolean; canMoveDown: boolean } => {
  let canMoveUp = false;
  let canMoveDown = false;

  const { isSection, rowId, sectionId, sectionIndex, rowIndex = 0 } = row;
  const currentRowIndex = tableData.findIndex((element) => element.rowId === rowId);
  const currentSection = tableData.filter((element) => element.sectionId === sectionId);
  const nextSection = tableData.find((element) => element.sectionIndex === sectionIndex + 1);
  const lastRowInSection = currentSection[currentSection.length - 1];

  if (isSection && currentRowIndex > 0) {
    const rowBeforeCurrentRow = tableData[currentRowIndex - 1];

    if (rowBeforeCurrentRow.type === 'DEVELOPMENT') {
      canMoveUp = true;
    }
    if (nextSection && nextSection.type === 'DEVELOPMENT') {
      canMoveDown = true;
    }
  } else if (!isSection && rowIndex > 0) {
    canMoveUp = true;
  }

  if (!isSection && lastRowInSection.rowId !== rowId) {
    canMoveDown = true;
  }

  return {
    canMoveUp,
    canMoveDown,
  };
};

export default useCanMoveUpAndDown;
