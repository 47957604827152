import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getCompanies } from 'services/company';
import { AllClients } from 'services/types/Client';
import { setClients } from 'reducers/lists';
import { useClients } from 'reducers/selectors';

const useLoadClients = (): AllClients | null => {
  const list = useClients();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!list) {
      getCompanies().then((result) => {
        dispatch(setClients(result));
      });
    }
  });

  return list;
};

export default useLoadClients;
