import styled from '@emotion/styled';

export const Box = styled.div<{ verticalLabel: boolean; mb: number }>`
  display: flex;
  flex-direction: ${({ verticalLabel }) => (verticalLabel ? 'column' : 'row')};
  margin-bottom: ${({ mb }) => `${mb}px`};
`;

export const DropDownContainer = styled.div<{ width?: number }>`
  position: relative;
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  min-width: max-content;
`;

export const DropDownHeader = styled.div<{ disabled?: boolean; isError?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 15px 9px 16px;
  gap: 6px;
  background: ${({ theme }) => theme.colors.white[400]};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  width: 100%;
  border: 1px solid
    ${({ isError, theme }) => (isError ? theme.colors.red[400] : theme.colors.gray[200])};
  color: ${({ isError, theme }) => (isError ? theme.colors.red[900] : theme.colors.black[500])};
  box-sizing: border-box;
  border-radius: 4px;
  line-height: 24px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

export const DropDownHeaderValue = styled.div<{ textAlign: string }>`
  text-align: ${({ textAlign }) => textAlign};
  width: 100%;
`;

export const DropDownListContainer = styled.div`
  width: 100%;
  position: absolute;
  z-index: 1;
  border: 1px solid ${({ theme }) => theme.colors.gray[200]};
  background: ${({ theme }) => theme.colors.white[400]};
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);

  &.dropdown-enter {
    opacity: 0;
    transform: translateY(10px);
  }

  &.dropdown-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms, transform 300ms;
  }

  &.dropdown-exit {
    opacity: 1;
    transform: translateY(0);
  }

  &.dropdown-exit-active {
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 400ms, transform 400ms;
  }
`;

export const DropDownList = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100%;
  max-height: 415px;
  overflow-y: auto;
`;

export const ListItem = styled.li<{ selected: boolean }>`
  margin: 0;
  cursor: pointer;
  padding: 9px 16px;
  list-style: none;
  line-height: 24px;
  color: ${({ selected, theme }) => (selected ? theme.colors.white[400] : theme.colors.black[400])};
  background-color: ${({ selected, theme }) =>
    selected ? theme.colors.blue[700] : theme.colors.white[400]};

  &:hover {
    background-color: ${({ selected, theme }) =>
      selected ? theme.colors.blue[700] : theme.colors.gray[450]};
  }
`;

export const Label = styled.div`
  position: relative;
  flex-shrink: 0;
  z-index: 1;
  padding: 9px 16px;
  border: 1px solid ${({ theme }) => theme.colors.gray[200]};
  border-radius: 3px 0 0 3px;
  color: ${({ theme }) => theme.colors.gray[550]};
  font-weight: 700;
  line-height: 24px;
  margin-right: -4px;
  background: ${({ theme }) => theme.colors.white[500]};
  width: 100%;
`;

export const IconContainer = styled.div`
  display: inline-block;
  margin-right: 10px;
`;

export const VerticalLabel = styled.span<{ isError: boolean }>`
  margin-bottom: 4px;
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  color: ${({ isError, theme }) => (isError ? theme.colors.red[400] : theme.colors.gray[550])};
`;

export const RequiredMark = styled.span`
  color: ${({ theme }) => theme.colors.red[400]};
  margin-left: 3px;
`;

export const SearchInputContainer = styled.div`
  padding: 6px 12px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[400]};
  width: 100%;
  position: relative;
`;

export const SearchInput = styled.input`
  width: 100%;
  font-weight: 400;
  border-radius: 4px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.black[400]};
  border: 1px solid #a6a6a6;
  transition: border-color 0.2s;
  appearance: none;
  text-decoration: none;
  padding: 6px 28px 6px 6px;
  outline: 0;

  &:focus,
  &:hover {
    border-color: ${({ theme }) => theme.colors.blue[700]};
  }
`;

export const SearchIconContainer = styled.span`
  top: 14px;
  right: 21px;
  color: ${({ theme }) => theme.colors.blue[700]};
  position: absolute;
`;

export const ErrorContainer = styled.div`
  position: relative;
`;

export const ErrorMessage = styled.span`
  position: absolute;
  color: ${({ theme }) => theme.colors.red[400]};
  font-size: 12px;
`;
