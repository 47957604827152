import { useMemo } from 'react';
import { nanoid } from 'nanoid';
import groupBy from 'lodash/groupBy';

import Technology from 'services/types/Technology';
import {
  EstimationData,
  EstimateSection,
  EstimationSectionType,
  Row,
  Column,
  IExcelEstimationData,
  ITableEstimationRow,
} from './types';

export const useFormattedData = (data: EstimationData): Row[] =>
  useMemo(
    () =>
      data
        .map((section, sectionIndex) => {
          const { title, type, rows } = section;
          const sectionId = nanoid();

          const sectionRow = {
            title,
            type,
            sectionId,
            rowId: nanoid(),
            sectionIndex,
            isSection: true,
            direction: null,
          };

          const formattedRows = rows.map((row, rowIndex) => {
            const { cells, ...rest } = row;
            const formattedCells =
              cells && cells.reduce((acc, cur) => ({ ...acc, [cur.label]: cur.time }), {});

            return {
              ...rest,
              ...formattedCells,
              type,
              sectionId,
              sectionIndex,
              isSection: false,
              rowIndex,
              rowId: nanoid(),
            };
          });

          return [sectionRow, ...formattedRows];
        })
        .flat(),
    [data],
  );

export const useGetTechnologiesList = (
  value: EstimateSection,
  technologies: Technology[],
): string[] =>
  useMemo(() => {
    const { rows } = value;
    const [firstRow] = rows;
    const { cells } = firstRow;

    return cells !== null ? cells.map((cell) => cell.label) : technologies.map((t) => t.name);
  }, [value, technologies]);

export const getIsCellReadOnly = (
  type: EstimationSectionType,
  isLastColumn: boolean,
  columnIndex: number,
): boolean => {
  if (
    (type !== 'DEVELOPMENT' && isLastColumn) ||
    (type !== 'DEVELOPMENT' && columnIndex === 0) ||
    (type === 'DEVELOPMENT' && !isLastColumn)
  ) {
    return false;
  }

  return true;
};

export const normalizeData = (data: Row[], technologiesList: string[]): EstimationData => {
  const groupedDataBySection = Object.values(groupBy(data, 'sectionIndex'));

  return groupedDataBySection.reduce((acc: EstimateSection[], cur: Row[]) => {
    const [first, ...rest] = cur;

    const section = {
      title: first.title,
      type: first.type,
    };

    const rows = rest.map((row) => {
      const cells = technologiesList.map((technology) => ({
        label: technology,
        time: row[technology],
      }));

      return {
        title: row.title,
        sum: row.sum,
        riskLevel: row.riskLevel,
        comment: row.comment,
        direction: row.direction,
        cells,
      };
    });

    const estimateSection = {
      ...section,
      rows,
    } as EstimateSection;

    return [...acc, estimateSection];
  }, []);
};

export const formatDataEstimationTable = (
  columns: Column<Row>[],
  data: Row[],
): IExcelEstimationData => {
  // table's header

  const tableTitles: string[] = [];
  columns.forEach((el) => {
    tableTitles.push(`${el.title}`);
  });

  // table's body

  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

  const tableRows: ITableEstimationRow[] = [];
  let counterStings = 0;
  const sectionSumString: number[] = [];

  const addSum = (el: Row) => {
    if (el.isSection) {
      sectionSumString.push(counterStings);

      return 'sum';
    }
    if (el.type === 'DEVELOPMENT') {
      return 'sum';
    }

    return el.sum;
  };

  data.forEach((el) => {
    counterStings += 1;
    const obj = (): ITableEstimationRow => ({
      name: el.title,
      direction: el.direction,
      frontend: el.frontend,
      mobile: el.mobile,
      backend: el.backend,
      sum: addSum(el),
    });

    tableRows.push(obj());
  });

  // table's footer

  sectionSumString.push(counterStings + 1);

  // add excel sum functions
  const sectionSum = (xStart: number, xEnd: number, yStart: number, yEnd: number) =>
    `=SUM(${alphabet[xStart]}${yStart + 1}:${alphabet[xEnd]}${yEnd})`;

  const findX = (field: string) => (tableRows[0] ? Object.keys(tableRows[0]).indexOf(field) : 0);

  let countIteration = 0;
  data.forEach((el, i) => {
    if (el.isSection) {
      tableRows[i].sum = sectionSum(
        findX('sum'),
        findX('sum'),
        sectionSumString[countIteration] + 1,
        sectionSumString[countIteration + 1],
      );
      countIteration += 1;
    }
    if (el.type === 'DEVELOPMENT' && !el.isSection) {
      tableRows[i].sum = sectionSum(findX('frontend'), findX('backend') - 1, i, i + 2);
    }
  });

  const finishSum = (x: number) => {
    let sumFunc = '';
    sectionSumString.forEach((el) => {
      sumFunc += `${alphabet[x]}${el + 1}:`;
    });

    return `=SUM(${sumFunc.slice(0, -1)})`;
  };
  tableRows.push({ name: 'Итого:', sum: 'Сумма' }, { sum: finishSum(findX('sum')) });

  return {
    titlesExcel: tableTitles,
    rowsExcel: tableRows,
  };
};
