import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Employee } from 'services/types/Client';
import Input from 'components/Input';
import Icon from 'components/Icon';
import { EMAIL_VALIDATION, VALIDATION_MESSAGES } from 'constants/common';
import Contacts from './Contacts';
import {
  FieldsetStyled,
  EmployeeStyled,
  TitleStyled,
  ButtonStyled,
} from './styled/EmployeeItem.styled';
import Button from './styled/Button.styled';

type Props = {
  index: number;
  name: string;
  email: string;
  position: string;
  isAdd: boolean;
  isRemove: boolean;
  onAppend: (value: Employee[]) => void;
  onRemove: (value: number) => void;
};

const EmployeeItem: FC<Props> = ({
  name,
  index,
  email,
  position,
  isAdd,
  isRemove,
  onAppend,
  onRemove,
}) => {
  const { control, errors, getValues } = useFormContext();

  const handleRemove = () => {
    onRemove(index);
  };

  const handleAppend = () => {
    onAppend([
      ...getValues().employees,
      {
        position: '',
        email: '',
        name: '',
        contacts: [
          {
            type: '',
            value: '',
          },
        ],
      },
    ]);
  };

  return (
    <EmployeeStyled>
      <FieldsetStyled>
        <Controller
          control={control}
          name={`employees[${index}].name`}
          rules={{ required: { value: true, message: VALIDATION_MESSAGES.required } }}
          defaultValue={name}
          render={({ onChange, value }, { invalid }) => (
            <Input
              label="Employee Name"
              placeholder="Employee name"
              value={value}
              onChange={onChange}
              width="490"
              isError={invalid}
              errorMessage={errors?.employees?.[index]?.name?.message}
              isRequired
            />
          )}
        />
        <Controller
          control={control}
          name={`employees[${index}].email`}
          rules={{
            required: { value: true, message: VALIDATION_MESSAGES.required },
            pattern: { value: EMAIL_VALIDATION, message: 'VALIDATION_FIELD_EMAIL' },
          }}
          defaultValue={email}
          render={({ onChange, value }, { invalid }) => (
            <Input
              label="Employee Email"
              placeholder="Employee Email"
              value={value}
              onChange={onChange}
              width="490"
              isError={invalid}
              errorMessage={errors.employees?.[index]?.email?.message}
              isRequired
            />
          )}
        />
        <Controller
          control={control}
          name={`employees[${index}].position`}
          rules={{ required: { value: true, message: VALIDATION_MESSAGES.required } }}
          defaultValue={position}
          render={({ onChange, value }, { invalid }) => (
            <Input
              label="Employee Position"
              placeholder="Employee Position"
              value={value}
              onChange={onChange}
              width="490"
              isError={invalid}
              errorMessage={errors.employees?.[index]?.position?.message}
              isRequired
            />
          )}
        />
      </FieldsetStyled>

      <FieldsetStyled>
        <TitleStyled>Employee contacts</TitleStyled>
        <Contacts nestIndex={index} />
      </FieldsetStyled>

      {isRemove && (
        <ButtonStyled>
          <Button type="button" onClick={handleRemove}>
            <Icon name="delete" className="icon" />
          </Button>
        </ButtonStyled>
      )}

      {isAdd && (
        <ButtonStyled>
          <Button type="button" onClick={handleAppend}>
            <Icon name="plus" size={14} className="icon" />
          </Button>
        </ButtonStyled>
      )}
    </EmployeeStyled>
  );
};

export default EmployeeItem;
