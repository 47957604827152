import { FC } from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';

import Button from 'components/Button';
import { Box, Title, HeaderBox, ButtonsContainer } from './PagePanel.styled';

type Props = {
  title: string;
  buttonText?: string;
  link?: string;
  buttonIcon?: string;
  onClick?: () => void;
  onClickSecondButton?: () => void;
};

const stylesButton = css`
  margin-left: 20px;
`;

const PagePanel: FC<Props> = ({
  title,
  buttonText,
  link,
  buttonIcon,
  onClick,
  onClickSecondButton,
}) => (
  <Box>
    <HeaderBox>
      <Title>{title}</Title>
      <ButtonsContainer>
        {buttonText && link && (
          <Link to={link}>
            <Button label={buttonText} icon={buttonIcon} styleType="add" onClick={onClick} />
          </Link>
        )}
        {buttonText && !link && <Button label={buttonText} styleType="add" onClick={onClick} />}
        {buttonIcon && !link && (
          <Button
            icon={buttonIcon}
            styleType="empty"
            onClick={onClickSecondButton}
            styleButton={stylesButton}
          />
        )}
      </ButtonsContainer>
    </HeaderBox>
  </Box>
);

export default PagePanel;
