export const getPagesCount = (value: number, amount: number): number => {
  let result = 1;
  if (value > amount) {
    result = Math.ceil(value / amount);
  }

  return result;
};

export const getInCaps = (value: string): string => value.trim().toUpperCase().replace(' ', '_');
