import { publicPost } from './publicAxios';

type Data = {
  username: string;
  password: string;
  remember: boolean;
};

type Response = {
  expires: string;
  token: string;
};

const LOGIN_URL = '/login';

const login = (data: Data): Promise<Response> => publicPost(LOGIN_URL, data);

export default login;
