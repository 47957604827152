import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import Input from 'components/Input';
import Checkbox from 'components/Checkbox';
import { Employee } from 'services/types/Employee';
import { EMAIL_VALIDATION_WITH_COMMA, URL_VALIDATION, VALIDATION_MESSAGES } from 'constants/common';
import { Container, CheckboxContainer, Title } from './ShareProject.styled';

type Props = {
  employees?: Employee[];
  handleChange: () => Promise<void>;
};

const ShareProject: FC<Props> = ({ handleChange, employees }) => {
  const { control, errors } = useFormContext();

  return (
    <form onSubmit={handleChange}>
      <Container>
        <Controller
          name="link"
          defaultValue=""
          control={control}
          rules={{
            required: { value: true, message: VALIDATION_MESSAGES.required },
            pattern: { value: URL_VALIDATION, message: 'VALIDATION_FIELD_URL' },
          }}
          render={({ value, onChange }, { invalid }) => (
            <Input
              type="text"
              name="link"
              value={value}
              label="Link"
              placeholder="https://"
              isError={invalid}
              errorMessage={errors.link?.message}
              onChange={onChange}
              mb={20}
              isRequired
            />
          )}
        />
        <CheckboxContainer>
          <Title>Share with</Title>
          {employees &&
            employees.map((employee) => (
              <div key={employee.id}>
                <Controller
                  defaultValue={false}
                  name={employee.name}
                  control={control}
                  render={({ value, onChange }) => (
                    <Checkbox
                      name="checkbox"
                      value={value}
                      label={employee.name}
                      mb={10}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
            ))}
        </CheckboxContainer>
        <Controller
          name="emails"
          control={control}
          defaultValue=""
          rules={{
            required: { value: true, message: VALIDATION_MESSAGES.required },
            pattern: { value: EMAIL_VALIDATION_WITH_COMMA, message: 'VALIDATION_FIELD_URL' },
          }}
          render={({ value, onChange }, { invalid }) => (
            <Input
              type="text"
              name="emails"
              value={value}
              label="Share to other emails"
              placeholder="Add emails separated by comma"
              isError={invalid}
              errorMessage={errors.emails?.message}
              onChange={onChange}
              mb={20}
              isRequired
            />
          )}
        />
      </Container>
    </form>
  );
};

export default ShareProject;
