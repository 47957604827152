import styled from '@emotion/styled';

export const Box = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[200]};
  background-color: ${({ theme }) => theme.colors.white[300]};
  margin-bottom: 16px;
`;

export const HeaderBox = styled.div`
  width: 1245px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.div`
  margin: 48px 0;
  font-weight: 400;
  font-family: Gilroy, Roboto, Arial, sans-serif;
  font-size: 42px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
