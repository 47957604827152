import React, { FC, useCallback } from 'react';
import { Circle, Rect } from 'react-konva';
import { KonvaEventObject } from 'konva/types/Node';
import AppStory from 'pages/EstimationDetailPage/Gantt/models/AppStory';
import AppSection from 'pages/EstimationDetailPage/Gantt/models/AppSection';
import { senlaColors } from '../../../../theme/colors';
import useHover from '../../../../utils/hooks/useHover';
import { MainModelWithSizes, SetIsWaiting } from '../../types';
// eslint-disable-next-line import/no-unresolved
import { SAUSAGE_HEIGHT_PX } from '../../consts';
import { secAndSto } from '../../utils';

export interface SausageProps {
  color?: string;
  hoverColor?: string;
  draggedColor?: string;
  x?: number;
  y?: number;
  groupX?: number;
  groupY?: number;
  width: number;
  height: number;
  onMouseEnter?: (evt: KonvaEventObject<MouseEvent>) => void;
  onMouseLeave?: (evt: KonvaEventObject<MouseEvent>) => void;
  onDragStart?: (evt: KonvaEventObject<DragEvent>) => void;
  onDragMove?: (evt: KonvaEventObject<DragEvent>) => void;
  onDragEnd?: (evt: KonvaEventObject<DragEvent>) => void;
  draggable?: boolean;
  isDragged?: boolean;
  onContextMenu?: (evt: KonvaEventObject<PointerEvent>) => void;
  isWaiting: boolean;
  setIsWaiting: SetIsWaiting;
  storyId: number | null;
  modelWithSizes: MainModelWithSizes;
  onSausageClick: (section: AppSection, story: AppStory, evt: KonvaEventObject<MouseEvent>) => void;
}

export const Sausage: FC<SausageProps> = ({
  // groupX = 0,
  groupY = 0,
  x = 0,
  y = 0,
  color = senlaColors.sausages.extraFunctionalityFromSenla,
  hoverColor = senlaColors.sausages.extraFunctionalityFromSenlaHover,
  draggedColor = senlaColors.sausages.extraFunctionalityFromSenlaDraggedColor,
  width,
  height,
  onMouseEnter: onMouseEnterFromProps,
  onMouseLeave: onMouseLeaveFromProps,
  onDragStart,
  onDragEnd,
  onDragMove,
  draggable = false,
  isDragged = false,
  onContextMenu,
  isWaiting,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setIsWaiting,
  storyId,
  modelWithSizes,
  onSausageClick,
}) => {
  const {
    onMouseEnter: onMouseEnterFromHook,
    onMouseLeave: onMouseLeaveFromHook,
    hover,
  } = useHover();

  const onMouseEnter = useCallback(
    (evt: KonvaEventObject<MouseEvent>) => {
      if (onMouseEnterFromProps) {
        onMouseEnterFromProps(evt);
      }
      onMouseEnterFromHook();
    },
    [onMouseEnterFromHook, onMouseEnterFromProps],
  );

  const onMouseLeave = useCallback(
    (evt: KonvaEventObject<MouseEvent>) => {
      if (onMouseLeaveFromProps) {
        onMouseLeaveFromProps(evt);
      }
      onMouseLeaveFromHook();
    },
    [onMouseLeaveFromHook, onMouseLeaveFromProps],
  );

  const clickHandler = useCallback(
    (evt: KonvaEventObject<MouseEvent>) => {
      if (!isWaiting) return;
      secAndSto(modelWithSizes, storyId, onSausageClick, evt);
    },
    [isWaiting, storyId, modelWithSizes, onSausageClick],
  );

  const fillColor = (() => {
    if (hover) {
      return hoverColor;
    }
    if (isDragged) {
      return draggedColor;
    }

    return color;
  })();

  return (
    <>
      <Rect
        draggable={draggable}
        onDragStart={onDragStart}
        onDragMove={onDragMove}
        onDragEnd={onDragEnd}
        x={x}
        y={y}
        width={width}
        height={height}
        fill={hover ? hoverColor : fillColor}
        cornerRadius={6}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onContextMenu={onContextMenu}
        dragBoundFunc={(pos) => ({
          x: pos.x,
          y: groupY + y,
        })}
        onClick={(evt: KonvaEventObject<MouseEvent>) => {
          clickHandler(evt);
        }}
      />
      {hover && (
        <Circle
          fill="#dedede"
          radius={10}
          y={y + SAUSAGE_HEIGHT_PX / 2}
          x={x + 10}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        />
      )}
      {hover && (
        <Circle
          fill="#dedede"
          radius={10}
          y={y + SAUSAGE_HEIGHT_PX / 2}
          x={x + width - 10}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        />
      )}
    </>
  );
};
