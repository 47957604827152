import { protectedDelete, protectedGet, protectedPost, protectedPut } from './protectedAxios';
import { Estimation, IterationToSend, UpdatedIteration } from './types/Iteration';

const ITERATION_URL = '/api/iteration';

const getIterationIterationUrl = (id: number) => `${ITERATION_URL}/${id}`;
const getIterationEstimationsUrl = (id: number) => `${ITERATION_URL}/${id}/estimations`;
const getIterationStatusUrl = () => `${ITERATION_URL}/status`;

export const createIteration = (data: IterationToSend): Promise<void> =>
  protectedPost(ITERATION_URL, data);

export const getIterationEstimations = (id: number): Promise<Estimation[]> =>
  protectedGet(getIterationEstimationsUrl(id));

export const updateIteration = (data: UpdatedIteration): Promise<void> =>
  protectedPut(ITERATION_URL, data);

export const deleteIteration = (id: number): Promise<Estimation[]> =>
  protectedDelete(getIterationIterationUrl(id));

export const updateStatus = (statusData: { id: number; status: string }): Promise<void> =>
  protectedPut(getIterationStatusUrl(), statusData);
