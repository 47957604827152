import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: max-content;
  padding: 10px 15px;
  background-color: ${({ theme }) => theme.colors.white[500]};
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: flex-start;
  margin-bottom: 10px;
`;

export const Title = styled.span`
  font-size: 12px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.gray[550]};
  margin-bottom: 8px;
}
`;
