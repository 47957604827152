import { FC, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { AxiosError } from 'axios';

import { NewClient } from 'services/types/Client';
import { createNewCompany } from 'services/company';
import { postFile } from 'services/file';
import PagePanel from 'components/PagePanel';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Company from './components/Company';
import File from './components/File';
import Employees from './components/Employees';
import {
  PhraseStyled,
  StarStyled,
  FormStyled,
  WrapperStyled,
  ContainerStyled,
  SubmitError,
} from './components/styled/NewCompanyPage.styled';

type Form = NewClient & { logo: File[] };

const initialValue: Form = {
  name: '',
  description: '',
  employees: [
    {
      position: '',
      email: '',
      name: '',
      contacts: [
        {
          type: '',
          value: '',
        },
      ],
    },
  ],
  logo: [],
};

const NewClientPage: FC = () => {
  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const history = useHistory();
  const methods = useForm<Form>({
    defaultValues: initialValue,
  });
  const { reset, handleSubmit } = methods;

  const resetHandler = () => {
    reset();
    setIsError(false);
  };

  const submitHandler = handleSubmit((data: Form) => {
    setErrorText('');
    setIsSubmitting(true);

    const { logo, ...client } = data;

    createNewCompany(client)
      .then(({ id }) => {
        if (logo[0]) {
          const formData = new FormData();
          formData.append('file', logo[0]);
          postFile(formData, {
            fileSubType: 'AVATAR',
            fileType: 'IMAGE',
            ownerId: id,
            ownerType: 'COMPANY',
          });
        }
      })
      .then(() => {
        history.push('/main/client-list');
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          setErrorText(err.response.data);
        } else {
          setErrorText(err.message);
        }
        setIsError(true);
        setIsSubmitting(false);
      });
  });

  return (
    <>
      <PagePanel title="New Company" />
      <FormProvider {...methods}>
        <FormStyled onSubmit={submitHandler}>
          {isError && (
            <SubmitError>
              <span>{errorText || 'Something went wrong'}</span>
            </SubmitError>
          )}
          <PhraseStyled>
            <Icon name="filledWarning" className="icon" />
            All fields marked with <StarStyled>*</StarStyled> are required
          </PhraseStyled>

          <WrapperStyled width={45}>
            <Company />
          </WrapperStyled>

          <WrapperStyled width={53.3}>
            <File />
          </WrapperStyled>

          <WrapperStyled width={100}>
            <Employees />
          </WrapperStyled>

          <ContainerStyled>
            <Button label="Save" styleType="primary" type="submit" disabled={isSubmitting} />
            <Button label="Cancel" type="button" onClick={resetHandler} />
          </ContainerStyled>
        </FormStyled>
      </FormProvider>
    </>
  );
};

export default NewClientPage;
