import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getEmployeePositions } from 'services/dictionary';
import { Employee } from 'services/types/Employee';
import { setPositions } from 'reducers/lists';
import { usePositions } from 'reducers/selectors';

const useLoadEmployeePositions = (): Employee[] | null => {
  const list = usePositions();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!list) {
      getEmployeePositions().then((result) => {
        dispatch(setPositions(result));
      });
    }
  }, [list, dispatch]);

  return list;
};

export default useLoadEmployeePositions;
