import styled from '@emotion/styled';

const Button = styled.button`
  box-sizing: border-box;
  width: 32px;
  height: 32px;
  border: 1px solid ${({ theme }) => theme.colors.gray[200]};
  border-radius: 50%;
  background-color: transparent;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all ease-in-out 0.2s;
  margin-left: auto;

  & .icon {
    color: ${({ theme }) => theme.colors.blue[300]};
  }

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.gray[200]};
    box-shadow: 0 4px 8px rgb(0 0 0 / 5%);
    cursor: pointer;
  }
`;

export default Button;
