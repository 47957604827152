import { FC } from 'react';

import { Estimation, Performer as PerformerType } from 'services/types/Iteration';
import EstimationItem from '../EstimationItem';

type Props = {
  estimations?: Estimation[];
  performers: PerformerType[];
  onUpdateData: () => void;
};

const EstimationsList: FC<Props> = ({ estimations, performers, onUpdateData }) => (
  <>
    {estimations &&
      estimations.map((estimation) => (
        <EstimationItem
          estimation={estimation}
          key={estimation.id}
          performers={performers}
          onUpdateData={onUpdateData}
          onCopyData={onUpdateData}
        />
      ))}
  </>
);

export default EstimationsList;
