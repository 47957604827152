import { FC, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import PagePanel from 'components/PagePanel';
import Tabs from 'components/Tabs';
import TabPane from 'components/Tabs/TabPane';
import useActiveTab from 'utils/hooks/useActiveTab';
import { getCompany } from 'services/company';
import { Company } from 'services/types/Company';
import Profile from './Profile';
import Employees from './Employees';
import Projects from './Projects';

const useQuery = () => new URLSearchParams(useLocation().search);

const CompanyDetailPage: FC = () => {
  const { id } = useParams<{ id: string }>();
  const currentPage = useQuery().get('activeTab');
  const [tab, setTab] = useState(currentPage || 'projects');
  const [company, setCompany] = useState<Company | null>(null);
  const [isNewEmployeeModal, setIsNewEmployeeModal] = useState(false);

  useActiveTab(tab);

  useEffect(() => {
    getCompany(id).then((data) => {
      setCompany(data);
    });
  }, [id]);

  const handleChangeTab = (newTab: string) => {
    setTab(newTab);
  };

  const onToggleEmployeeModal = () => {
    setIsNewEmployeeModal((prevState) => !prevState);
  };

  const pagePanelProps = () => {
    if (tab === 'profile') {
      return {
        title: company?.name || '',
      };
    }
    if (tab === 'employees') {
      return {
        title: 'Company Employees',
        buttonText: 'add  employee',
        onClick: onToggleEmployeeModal,
      };
    }

    return {
      title: 'Company Projects',
      buttonText: 'create project',
      link: '/main/project-list/create',
    };
  };

  return (
    <>
      <PagePanel {...pagePanelProps()} />
      <Tabs active={tab} onChange={handleChangeTab}>
        <TabPane name="profile">
          <Profile data={company} />
        </TabPane>
        <TabPane name="projects">
          <Projects />
        </TabPane>
        <TabPane name="employees">
          <Employees isNewEmployee={isNewEmployeeModal} onToggleModal={onToggleEmployeeModal} />
        </TabPane>
      </Tabs>
    </>
  );
};
export default CompanyDetailPage;
