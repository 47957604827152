import { ComponentPropsWithoutRef, FC, ReactNode } from 'react';
import { SerializedStyles, useTheme } from '@emotion/react';

import Icon, { IconName } from 'components/Icon';
import { Text, Container } from './Button.styled';

type Props = {
  label?: string | ReactNode;
  styleType?: string;
  styleButton?: SerializedStyles;
  isDisable?: boolean;
  onClick?: () => void;
  type?: string;
  icon?: string;
} & ComponentPropsWithoutRef<'button'>;

const Button: FC<Props> = ({
  label,
  styleType,
  styleButton,
  isDisable = false,
  onClick,
  type = 'button',
  icon,
  ...rest
}) => {
  const { colors } = useTheme();

  return (
    <Container
      styleType={styleType}
      styleButton={styleButton}
      disabled={isDisable}
      onClick={onClick}
      type={type}
      {...rest}
    >
      <Text styleType={styleType}>
        {icon && <Icon name={icon as IconName} color={colors.white[400]} size={18} />}
        {label}
      </Text>
    </Container>
  );
};

export default Button;
