import { FC, useEffect, useState } from 'react';
import { getTaskStatuses } from 'services/dictionary';
import { TaskStatusType } from 'services/types/Dictionary';
import { StoriesType } from 'services/types/tasks';
import Story from './Story';
import { StoriesWrapper } from './styled/KanbanBoard.styled';

type Props = {
  data: StoriesType[];
};

const StoriesList: FC<Props> = ({ data }) => {
  const [statusList, setStatusList] = useState<TaskStatusType[]>([]);

  useEffect(() => {
    (async () => {
      const response = await getTaskStatuses();
      setStatusList(response);
    })();
  }, []);

  return (
    <>
      <StoriesWrapper>
        {data?.map((story, index) => (
          <Story key={story.id} data={story} index={index} statusList={statusList} />
        ))}
      </StoriesWrapper>
    </>
  );
};

export default StoriesList;
