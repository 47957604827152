import { FC } from 'react';
import { Control, DeepMap, FieldError, useFieldArray } from 'react-hook-form';

import { Performer as PerformerType } from 'services/types/Iteration';
import PersonItem, { EstimationType } from './PersonItem';
import { Label } from '../IterationForm/IterationForm.styled';

type Props = {
  control: Control;
  errors: DeepMap<EstimationType, FieldError>;
  persons: PerformerType[];
};

const PersonsList: FC<Props> = ({ control, errors, persons }) => {
  const { fields, remove, insert } = useFieldArray({
    control,
    name: 'persons',
    keyName: 'uuid',
  });

  const handleAddItem = (index: number) => {
    insert(index + 1, {
      changeableName: '',
      position: {
        name: '',
      },
    });
  };

  const handleRemoveItem = (index: number) => {
    remove(index);
  };

  return (
    <>
      <Label>Involved specialists</Label>
      {fields.map((item, index) => (
        <PersonItem
          key={item.uuid}
          index={index}
          fields={fields}
          item={item}
          control={control}
          errors={errors}
          persons={persons}
          onAddItem={handleAddItem}
          onDeleteItem={handleRemoveItem}
        />
      ))}
    </>
  );
};
export default PersonsList;
