import { FC } from 'react';
import { Person } from 'services/types/EstimationData';
import GeneratePdf from './PdfFileLoader/GeneratePdf';
import {
  PageHeaderContainer,
  HeaderBox,
  Container,
  InnerContainer,
  Title,
  FileButtonContainer,
} from './styled/EstimationPage.styled';
import { contentPdfTable } from './PdfFileLoader/utilsPdf';
import ExcelLoad from './ExcelFileLoader/ExcelLoad';
import { IExcelData } from './DirectionTable/types';
import { IExcelEstimationData } from '../../components/EstimationTable/types';

type Props = {
  name: string;
  persons: Person[];
  tablesContext: {
    estimationTable?: IExcelEstimationData | null;
    directionTable?: IExcelData | null;
  };
};

const PageHeader: FC<Props> = ({ name, persons, tablesContext }) => (
  <PageHeaderContainer>
    <Container>
      <InnerContainer>
        <HeaderBox>
          <Title>{name}</Title>
          <FileButtonContainer>
            <GeneratePdf estimationName={name} persons={persons} getContent={contentPdfTable} />
            <ExcelLoad csvData={tablesContext.estimationTable} fileName="estimation-exel" />
          </FileButtonContainer>
        </HeaderBox>
      </InnerContainer>
    </Container>
  </PageHeaderContainer>
);

export default PageHeader;
