import styled from '@emotion/styled';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.gray[200]};
`;

const EmployeeWrap = styled.div<{ isLast: boolean }>`
  border-bottom: ${({ isLast, theme }) => (isLast ? '0' : `1px solid ${theme.colors.gray[200]}`)};
  width: auto;
`;

const MainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  padding: 24px 24px 24px 48px;
`;

const LeftContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.gray[550]};
  width: 45%;
`;

const Name = styled.span`
  font-weight: 500;
  font-size: 20px;
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.colors.blue[300]};
  }
`;

const Position = styled.span`
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  letter-spacing: -0.24px;
`;

const Slash = styled.span`
  padding: 0 5px;
`;

const RightContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 35%;
`;

const EmailContainer = styled.div`
  display: flex;
  align-items: center;
  width: auto;
`;

const Email = styled.span`
  margin-left: 10px;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.gray[900]};
  width: auto;
`;

const IconsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 120px;
  cursor: pointer;
`;

const ButtonRound = styled.button`
  color: ${({ theme }) => theme.colors.gray[900]};
  width: 32px;
  height: 32px;
  padding: 8px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.gray[200]};
  background-color: transparent;
  cursor: pointer;
  display: flex;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[250]};
  }
`;

const SecondContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px 24px 16px 48px;
`;

const ContactsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px 8px 8px;
  width: 100%;
  margin-right: 20px;
`;

const TextBold = styled.span`
  font-weight: 700;
  margin-left: 10px;
`;

const ContactBox = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 50%;
`;

const ProjectBox = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
`;

const ProjectsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProjectsTitle = styled.span`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.gray[550]};
  margin: 8px 0 20px;
`;

const ButtonProject = styled.button`
  min-width: 60px;
  padding: 8px 12px;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.gray[300]};
  border: 0px solid ${({ theme }) => theme.colors.gray[750]};
  border-radius: 16px;
  cursor: pointer;
  margin-right: 8px;
  margin-bottom: 8px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[250]};
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
  }

  > a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.gray[550]};
  }
`;

const ExpandIconContainer = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export {
  Wrapper,
  EmployeeWrap,
  MainContainer,
  Name,
  Email,
  Position,
  Slash,
  LeftContainer,
  RightContainer,
  EmailContainer,
  IconsContainer,
  ButtonRound,
  SecondContainer,
  ContactsContainer,
  TextBold,
  ButtonProject,
  ContactBox,
  ProjectsContainer,
  ProjectsTitle,
  ProjectBox,
  ExpandIconContainer,
};
