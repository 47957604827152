import React, { FC, memo } from 'react';
import { Group, Line, Rect } from 'react-konva';
import { KonvaEventObject } from 'konva/types/Node';
import AppSection from 'pages/EstimationDetailPage/Gantt/models/AppSection';
import { useGanttScaleValue } from 'reducers/selectors';
import {
  MainModelWithSizes,
  OnStoryContextMenu,
  SetStoryId,
  SetIsWaiting,
  DatePeriod,
  AdaptedSection,
} from '../../types';
import { getStoriesCountBefore, getTotalStoryWidthFromSizes } from '../RightCanvas/utils';
import { SAUSAGE_HEIGHT_PX, SUBSTORY_HEIGHT_PX } from '../../consts';
import { colors } from '../../../../theme/colors';
import { SausageTotal } from '../SausageTotal';
import { Sausage } from '../Sausage';
import { getCalendarItemWidth } from '../../utils';
import AppStory from '../../../../models/AppStory';
import { StoryLine } from '../StoryLine/StoryLine';
import useSizes from './useSizes';

export interface SectionProps {
  groupX?: number;
  groupY?: number;
  section: AdaptedSection;
  sectionIndex: number;
  modelWithSizes: MainModelWithSizes;
  canvasSizes: {
    width: number;
    height: number;
  };
  datePeriod: DatePeriod;
  storyId: number | null;
  setStoryId: SetStoryId;
  isWaiting: boolean;
  setIsWaiting: SetIsWaiting;
  onSausageDragStart?: (
    section: AppSection,
    story: AppStory,
    evt: KonvaEventObject<DragEvent>,
  ) => void;
  onSausageDragMove?: (
    section: AppSection,
    story: AppStory,
    evt: KonvaEventObject<DragEvent>,
  ) => void;
  onSausageDragEnd?: (section: AppSection, story: AppStory) => void;
  onStoryContextMenu: OnStoryContextMenu;
  onSausageClick: (section: AppSection, story: AppStory, evt: KonvaEventObject<MouseEvent>) => void;
}

export const Section: FC<SectionProps> = memo(
  ({
    groupX = 0,
    groupY = 0,
    section,
    sectionIndex,
    modelWithSizes,
    canvasSizes,
    datePeriod,
    storyId,
    setStoryId,
    isWaiting,
    setIsWaiting,
    onSausageDragStart,
    onSausageDragMove,
    onSausageDragEnd,
    onStoryContextMenu,
    onSausageClick,
  }) => {
    const scaleValue = useGanttScaleValue();
    const calendarItemWidth = getCalendarItemWidth(scaleValue as 1 | 2 | 3);

    const sectionY =
      getStoriesCountBefore(sectionIndex, modelWithSizes.sections) * SUBSTORY_HEIGHT_PX;
    const sectionYWithTitleOffset = sectionY + SUBSTORY_HEIGHT_PX * (sectionIndex + 1);
    const totalSausageOffsetY = sectionY + SUBSTORY_HEIGHT_PX * sectionIndex;

    const sizes = useSizes({
      datePeriod,
      groupX,
      groupY,
      section,
      sectionYWithTitleOffset,
      calendarItemWidth,
    });

    const { offsetX: totalSausageOffsetX, width: totalSausageWidth } = getTotalStoryWidthFromSizes(
      sizes,
    );

    return (
      <React.Fragment key={`section_${sectionIndex}`}>
        <Group y={totalSausageOffsetY}>
          <Rect width={canvasSizes.width * 100} height={SUBSTORY_HEIGHT_PX} />
          <SausageTotal
            width={totalSausageWidth}
            x={totalSausageOffsetX}
            y={colors.sausageTotal.topOffset}
          />
        </Group>
        <Group y={sectionYWithTitleOffset}>
          {section.expanded && (
            <>
              {section.stories.map((story, storyIndex) => {
                if (!story.nextTaskDto || !story.nextTaskDto.isSubTask) {
                  return null;
                }
                const storyDrawLineTo = section.stories.find(
                  (s) => s.id === story.nextTaskDto.nextTaskId,
                );
                const storyDrawLineToIndex = section.stories.findIndex(
                  (s) => s.id === story.nextTaskDto.nextTaskId,
                );
                if (!storyDrawLineTo) {
                  return null;
                }
                const { x: storyFromX, y: storyFromY, width: storyFromWidth } = sizes[storyIndex];
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { x: storyToX, y: storyToY, width: storyToWidth } = sizes[
                  storyDrawLineToIndex
                ];
                const PADDING_RIGHT = 20;

                const lineStartX = storyFromX + storyFromWidth;
                const lineStartY = storyFromY + SAUSAGE_HEIGHT_PX / 2;

                const lineEndX = storyToX - lineStartX;
                const lineEndY = storyToY + SAUSAGE_HEIGHT_PX / 2 - lineStartY;

                const centerLineX = PADDING_RIGHT;
                const centerLineY = lineEndY;

                return (
                  <Line
                    key={`Line from ${story.id} to ${story.nextTaskDto.nextTaskId}`}
                    x={lineStartX}
                    y={lineStartY}
                    points={[0, 0, PADDING_RIGHT, 0, centerLineX, centerLineY, lineEndX, lineEndY]}
                    stroke="#a0a0a0"
                  />
                );
              })}
              {section.stories.map((story, storyIndex) => {
                const { x, y, groupX: groupXtoUse, groupY: groupYToUse, width } = sizes[storyIndex];

                return (
                  <Group>
                    <StoryLine
                      y={storyIndex * SUBSTORY_HEIGHT_PX}
                      width={canvasSizes.width * 100}
                      height={SUBSTORY_HEIGHT_PX}
                      story={story}
                      storyId={storyId}
                      setStoryId={setStoryId}
                    />
                    <Sausage
                      x={x}
                      y={y}
                      key={`section_${section.id}_story_${story.id}`}
                      groupX={groupXtoUse}
                      groupY={groupYToUse}
                      draggable
                      isDragged={story.isDragged}
                      width={width}
                      height={SAUSAGE_HEIGHT_PX}
                      onDragStart={(evt) => onSausageDragStart?.(section, story, evt)}
                      onDragMove={(evt) => onSausageDragMove?.(section, story, evt)}
                      onDragEnd={() => onSausageDragEnd?.(section, story)}
                      onContextMenu={(event) => onStoryContextMenu(section, story, event)}
                      onMouseEnter={() => setStoryId(storyId)}
                      isWaiting={isWaiting}
                      setIsWaiting={setIsWaiting}
                      storyId={storyId}
                      modelWithSizes={modelWithSizes}
                      onSausageClick={onSausageClick}
                    />
                  </Group>
                );
              })}
            </>
          )}
        </Group>
      </React.Fragment>
    );
  },
);
