import styled from '@emotion/styled';

const PaginatorBox = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
  padding-bottom: 16px;
`;

export default PaginatorBox;
