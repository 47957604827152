import styled from '@emotion/styled';

type Props = {
  disabled?: boolean;
  currentDate?: boolean;
  selectedDate?: boolean;
  withoutHover?: boolean;
};

export default styled.div<Props>`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  color: #333;
  cursor: pointer;
  transition: all 0.3s ease;

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.5;
  `}
  &:hover {
    ${({ selectedDate, currentDate, disabled, theme }) =>
      !selectedDate &&
      !currentDate &&
      !disabled &&
      `
      background-color: ${theme.colors.gray[400]};
    `}
    ${({ disabled }) =>
      disabled &&
      `
      cursor: default;
    `}
    ${({ withoutHover, theme }) =>
      withoutHover &&
      `
      cursor: default;
      background-color: ${theme.colors.white[400]};
    `}
  }
  ${({ currentDate }) =>
    currentDate &&
    `
    background-color: #d0d0d0;
  `}
  ${({ selectedDate, theme }) =>
    selectedDate &&
    `
    color: ${theme.colors.white[400]};
    background-color: ${theme.colors.blue[700]};
  `}
`;
