import { FC, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Gantt from 'pages/EstimationDetailPage/Gantt';
import KanbanBoardPage from 'pages/EstimationDetailPage/KanbanBoardPage';
import PagePanel from 'components/PagePanel';
import Tabs from 'components/Tabs';
import TabPane from 'components/Tabs/TabPane';
import { getEstimation } from 'services/estimation';
import useActiveTab from 'utils/hooks/useActiveTab';
import { setStartDate } from 'reducers/estimation';

const useQuery = () => new URLSearchParams(useLocation().search);

const EstimationDetailPage: FC = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const currentPage = useQuery().get('activeTab');
  const [tab, setTab] = useState(currentPage || 'kanban');
  const [estimationName, setEstimationName] = useState<string>('');

  useEffect(() => {
    if (id) {
      getEstimation(id).then((response) => {
        dispatch(setStartDate(new Date(response.startdate).getTime()));
        setEstimationName(response.name);
      });
    }
  }, [id, dispatch]);

  const handleChangeTab = (newTab: string) => {
    setTab(newTab);
  };

  useActiveTab(tab);

  return (
    <>
      <PagePanel title={estimationName} />
      <Tabs active={tab} onChange={handleChangeTab}>
        <TabPane name="kanban">
          <KanbanBoardPage />
        </TabPane>
        <TabPane name="gantt">
          <Gantt />
        </TabPane>
      </Tabs>
    </>
  );
};

export default EstimationDetailPage;
