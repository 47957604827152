import { createContext, MutableRefObject } from 'react';
import { BoardType, TaskType, SubTaskType, DetailsType } from './types';

export type CurrentType = {
  currentTask: TaskType;
  currentBoard: BoardType;
  currentSubTask?: SubTaskType;
  currentType: string;
};

type Props = {
  currentValues: MutableRefObject<null | CurrentType>;
  onChangeBoard: (boards: BoardType[]) => void;
};

type DetailContextType = {
  details: DetailsType;
  setDetails: React.Dispatch<React.SetStateAction<DetailsType>>;
};

export const DetailsContext = createContext<DetailContextType>({
  details: {
    showDetails: false,
    idItem: '',
    prev: null,
  },
  setDetails: () => {},
});

const Context = createContext<Props>({
  currentValues: {
    current: null as CurrentType | null,
  },
  onChangeBoard: () => undefined,
});

export default Context;
