import React from 'react';
import { CSVLink } from 'react-csv';
import { IExcelData } from '../DirectionTable/types';
import { GenerateButton } from '../styled/EstimationPage.styled';
import Icon from '../../../components/Icon';
import { IExcelEstimationData } from '../../../components/EstimationTable/types';

type Props = {
  csvData: IExcelData | IExcelEstimationData | null | undefined;
  fileName: string;
};
const ExcelLoad = ({ csvData, fileName }: Props): JSX.Element | null => {
  if (csvData && csvData.rowsExcel[0]) {
    const { titlesExcel, rowsExcel } = csvData;
    const keys = Object.keys(rowsExcel[0]);
    const headers: { label: string; key: string }[] = [];

    keys.forEach((el, i) => {
      headers.push({ label: titlesExcel[i], key: keys[i] });
    });

    return (
      <CSVLink data={rowsExcel} filename={fileName} headers={headers}>
        <GenerateButton>
          <Icon name="add" size={24} className="icon" />
          Generate excel
        </GenerateButton>
      </CSVLink>
    );
  }

  return null;
};

export default ExcelLoad;
