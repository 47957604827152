import { FC } from 'react';
import { useTheme } from '@emotion/react';

import Icon from 'components/Icon';
import Container from './IconContainer.styled';

export type IconContainerMode = 'add' | 'remove';

type Props = {
  mode: IconContainerMode;
  onClick: () => void;
};

const IconContainer: FC<Props> = ({ onClick, mode }) => {
  const { colors } = useTheme();

  return (
    <Container onClick={onClick}>
      <Icon name={mode === 'add' ? 'plus' : 'delete'} color={colors.blue[300]} />
    </Container>
  );
};

export default IconContainer;
