import { useState, useEffect } from 'react';

import { getFile } from 'services/file';
import noLogoImage from 'assets/images/noimage.png';

const useImage = (logo: string | null): string => {
  const [image, setImage] = useState(noLogoImage);

  useEffect(() => {
    if (logo) {
      getFile(logo).then((res) => {
        setImage(URL.createObjectURL(res));
      });
    }
  }, [logo]);

  useEffect(
    () => () => {
      if (image) {
        URL.revokeObjectURL(image);
      }
    },
    [image],
  );

  return image;
};

export default useImage;
