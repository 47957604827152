import styled from '@emotion/styled';

export const PageHeaderContainer = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[200]};
  background-color: ${({ theme }) => theme.colors.white[300]};
`;

export const HeaderBox = styled.div`
  height: 145px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Container = styled.div`
  width: 1920px;
  max-width: 100%;
  margin: 0 auto;
  padding-left: 24px;
  padding-right: 24px;
`;

export const InnerContainer = styled.div`
  width: 1245px;
  margin: 0 auto;
`;

export const Title = styled.h1`
  font-size: 42px;
  line-height: 48px;
  letter-spacing: 1px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.black[400]};
`;

export const GenerateButton = styled.button`
  cursor: pointer;
  height: 44px;
  border: 1px solid ${({ theme }) => theme.colors.gray[700]};
  border-radius: 100px;
  background-color: ${({ theme }) => theme.colors.gray[700]};
  color: ${({ theme }) => theme.colors.gray[800]};
  text-transform: uppercase;
  font-weight: 700;
  padding-right: 15px;
  padding-left: 12px;
  display: flex;
  align-items: center;
  transition: all 0.4s ease;
  margin: 0 5px;

  &:hover {
    color: ${({ theme }) => theme.colors.gray[900]};
  }

  &:focus {
    color: ${({ theme }) => theme.colors.white[400]};
    background-color: ${({ theme }) => theme.colors.blue[600]};
  }

  & .icon {
    margin-right: 6px;
  }
`;

export const EstimationInfo = styled.div`
  padding-top: 15px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;

export const Grid = styled.div<{ rows: number }>`
  display: grid;
  grid-template-columns: 140px auto;
  grid-template-rows: ${({ rows }) => `repeat(${rows}, 20px)`};
  row-gap: 12px;

  & .label {
    grid-row-start: 1;
    grid-row-end: ${({ rows }) => rows + 1};
  }
`;

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StatusLabel = styled.span`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.gray[900]};
  margin-right: 12px;
`;

export const EstimationDateContainer = styled.p`
  margin-bottom: 8px;
  display: flex;
`;

export const EstimationDateLabel = styled.span`
  font-weight: 500;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray[550]};
  margin-right: 8px;
`;

export const EstimationDate = styled.time`
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray[900]};
`;

export const FileButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: 15px;
`;
