import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import Role from 'services/types/Role';
import { Employee } from 'services/types/User';
import { Employee as Position } from 'services/types/Employee';
import { AllClients } from 'services/types/Client';
import Technology from 'services/types/Technology';

type State = {
  roles: null | Role[];
  employees: null | Employee[];
  estimationStatuses: null | string[];
  clients: null | AllClients;
  technologies: null | Technology[];
  positions: null | Position[];
};

const initialState: State = {
  roles: null,
  employees: null,
  estimationStatuses: null,
  clients: null,
  technologies: null,
  positions: null,
};

const listsSlice = createSlice({
  name: 'lists',
  initialState,
  reducers: {
    setRoles(state, action: PayloadAction<Role[]>) {
      state.roles = action.payload;
    },
    setEmployees(state, action: PayloadAction<Employee[]>) {
      state.employees = action.payload;
    },
    setTechnologies(state, action: PayloadAction<Technology[]>) {
      state.technologies = action.payload;
    },
    setEstimationStatuses(state, action: PayloadAction<string[]>) {
      state.estimationStatuses = action.payload;
    },
    setClients(state, action: PayloadAction<AllClients>) {
      state.clients = action.payload;
    },
    setPositions(state, action: PayloadAction<Position[]>) {
      state.positions = action.payload;
    },
    resetLists() {
      return initialState;
    },
  },
});

export const {
  setRoles,
  setEmployees,
  setEstimationStatuses,
  setClients,
  setTechnologies,
  resetLists,
  setPositions,
} = listsSlice.actions;

export default listsSlice.reducer;
