import { FC, MouseEvent } from 'react';

import Icon, { IconName } from 'components/Icon';
import { DropDownList, DropDownListContainer, ListStatus, IconContainer } from './Select.styled';

type Props = {
  itemList?: string[];
  value: string;
  onChange: (event: MouseEvent<HTMLUListElement>) => void;
  labeled: boolean;
  isContact?: boolean;
};

const ItemList: FC<Props> = ({ itemList, value, onChange, labeled, isContact }) => (
  <DropDownListContainer labeled={labeled}>
    <DropDownList onClick={onChange}>
      {itemList &&
        itemList.map((item) => {
          const iconName = item[0].toLowerCase() + item.slice(1);

          return (
            <ListStatus selected={item === value} key={item}>
              {isContact && (
                <IconContainer>
                  <Icon
                    name={iconName as IconName}
                    size={16}
                    color={item === value ? '#fff' : '#007ad9'}
                  />
                </IconContainer>
              )}
              {item}
            </ListStatus>
          );
        })}
    </DropDownList>
  </DropDownListContainer>
);

export default ItemList;
