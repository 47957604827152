import styled from '@emotion/styled';

const Button = styled.span`
  display: inline-block;
  width: 32px;
  height: 32px;
  padding: 6px 7px 7px 8px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.gray[200]};
  color: ${({ theme }) => theme.colors.gray[900]};
  margin: 0 8px;
  cursor: pointer;
  transition: all ease-in-out 0.2s;

  &:hover {
    background-color: #e8e9ea;
    box-shadow: 0 4px 8px rgb(0 0 0 / 5%);
  }

  &:active {
    box-shadow: none;
  }
`;

export default Button;
