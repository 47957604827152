import React, { FC } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import styled from '@emotion/styled';

export interface ToggleExpandButtonProps {
  expanded?: boolean;
  onClick?: () => void;
}

export const StyledToggleExpandButton = styled.button<{ expanded?: boolean }>`
  border: 0;

  background-color: transparent;

  transition: 0.1s ease-in-out;
  ${(props) => props.expanded && `transform: rotate(90deg);`}

  :focus {
    outline: none;
  }
`;

export const ToggleExpandButton: FC<ToggleExpandButtonProps> = ({ expanded, onClick }) => (
  <StyledToggleExpandButton onClick={onClick} expanded={expanded}>
    <FaArrowRight />
  </StyledToggleExpandButton>
);
