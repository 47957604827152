import React, { FC, useState, useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import Icon, { IconName } from 'components/Icon';
import ItemsList from './ItemsList';
import { Data } from './Item';
import {
  DropDownContainer,
  DropDownHeader,
  Box,
  Label,
  IconContainer,
  VerticalLabel,
  RequiredMark,
  ErrorMessage,
  ErrorContainer,
  DropDownHeaderValue,
} from './Select.styled';

export type ListItem = Data;

export type Props = {
  value: string | number;
  itemList: Data[];
  label?: string;
  verticalLabel?: boolean;
  width?: number;
  disabled?: boolean;
  placeholder?: string;
  isRequired?: boolean;
  isError?: boolean;
  errorMessage?: string;
  withSearch?: boolean;
  mb?: number;
  textAlign?: string;
  onChange: (value: string | number) => void;
};

const Select: FC<Props> = ({
  value,
  itemList,
  width,
  disabled,
  label = '',
  verticalLabel = false,
  isError = false,
  isRequired = false,
  withSearch = false,
  mb = 0,
  textAlign = 'start',
  placeholder = 'Select',
  errorMessage,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleChange = (newValue: string | number) => {
    onChange(newValue);
    setIsOpen(false);
  };

  const onToggle = () => setIsOpen((prevState) => !prevState && !disabled);

  const displayValue =
    (itemList && itemList.find((item) => item.value === value)?.label) || placeholder;

  return (
    <Box ref={ref} verticalLabel={verticalLabel} mb={mb}>
      {label && !verticalLabel && <Label>{label}</Label>}
      {label && verticalLabel && (
        <VerticalLabel isError={isError}>
          {label}
          {isRequired && <RequiredMark>*</RequiredMark>}
        </VerticalLabel>
      )}
      <DropDownContainer width={width}>
        <DropDownHeader disabled={disabled} isError={isError} onClick={onToggle}>
          <DropDownHeaderValue textAlign={textAlign}>
            {itemList.map(
              (item) =>
                item.iconName &&
                item.value === value && (
                  <IconContainer key={item.iconName}>
                    <Icon name={item.iconName as IconName} size={16} />
                  </IconContainer>
                ),
            )}
            {displayValue}
          </DropDownHeaderValue>
          <Icon name="angleDown" />
        </DropDownHeader>
        <CSSTransition in={isOpen} timeout={300} classNames="dropdown" unmountOnExit>
          <ItemsList
            value={value}
            withSearch={withSearch}
            itemsList={itemList}
            onChange={handleChange}
          />
        </CSSTransition>
      </DropDownContainer>
      <ErrorContainer>{isError && <ErrorMessage>{errorMessage}</ErrorMessage>}</ErrorContainer>
    </Box>
  );
};

export default Select;
