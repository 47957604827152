import styled from '@emotion/styled';

type Props = {
  selected?: boolean;
  disabled?: boolean;
};

const createStyleGetter = (base: string, select: string, disable: string) => (args: Props) => {
  if (args.disabled) {
    return disable;
  }

  return args.selected ? select : base;
};

const getBackgroundColor = createStyleGetter('#ffffff', '#1d7ce8', '#fcfcfc');
const getBorderColor = createStyleGetter('#d5dee9', '#1d7ce8', '#e7ecf1');
const getFontColor = createStyleGetter('#c6c7cb', '#ffffff', '#e0e0e2');

const getBackgroundColorHover = createStyleGetter('#e0e0e0', '#1d7ce8', '#fcfcfc');
const getFontColorHover = createStyleGetter('#333', '#ffffff', '#e0e0e2');

const getCursor = createStyleGetter('pointer', 'default', 'default');
const getScale = createStyleGetter('0.9', '1', '1');

export default styled.li<Props>`
  background: ${getBackgroundColor};
  border: 1px solid ${getBorderColor};
  color: ${getFontColor};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px 13px;
  margin: 0 4px;
  transition: all ease-in-out 0.1s;
  cursor: ${getCursor};

  &:hover {
    background: ${getBackgroundColorHover};
    color: ${getFontColorHover};
  }

  &:active {
    transform: scale(${getScale});
  }
`;
