import { FC, MouseEvent } from 'react';

import BodyCell from './BodyCell';
import { StyledBodyRow } from './styled/Row.styled';
import { Column, RiskType, Row } from './types';
import { useActions } from './context';

type Props = {
  data: Row;
  columns: Column<Row>[];
  onContextMenuClick: (value: { clientX: number; clientY: number }) => void;
  canEdit?: boolean;
};

const RISKS_CLASSNAMES: { [key in RiskType]: string } = {
  NO_RISKS: '',
  EXTRA_FUNCTIONALITY: 'extra-risks',
  SENLA_SIDE_RISK: 'senla-risks',
  CLIENT_SIDE_RISK: 'client-risks',
};

const BodyRow: FC<Props> = ({ data, columns, onContextMenuClick, canEdit }) => {
  const { updateRowData, setContextMenuRow, onSendData } = useActions();

  const handleChange = (newValue: string, dataKey: string) => {
    updateRowData({
      value: newValue,
      dataKey,
      rowId: data.rowId,
    });
  };

  const handleBlur = () => {
    onSendData();
  };

  const handleContextMenu = (event: MouseEvent<HTMLTableRowElement>) => {
    event.preventDefault();
    const { pageX: clientX, pageY: clientY } = event;
    setContextMenuRow(data);
    onContextMenuClick({ clientX, clientY });
  };

  return (
    <StyledBodyRow
      onContextMenu={handleContextMenu}
      className={data?.riskLevel ? RISKS_CLASSNAMES[data.riskLevel] : ''}
    >
      {columns.map((column, idx) => (
        <BodyCell
          key={column.columnId}
          data={data}
          column={column}
          columnIndex={idx}
          isLastColumn={idx === columns.length - 1}
          value={column.getValue && column.getValue(data)}
          onChange={handleChange}
          onBlur={handleBlur}
          canEdit={canEdit}
        />
      ))}
    </StyledBodyRow>
  );
};

export default BodyRow;
