import { FC, useState, useRef, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';

import useImage from 'utils/hooks/useImage';
import { Card, CardImg, CardInfo, UserFullName, UserLabel } from './UserCard.styled';
import Menu from './EditMenu';

export type Props = {
  userId: number;
  fullName: string;
  role: string;
  imgSrc?: string | null;
  onChangeUserRole: (newRole: string, id: number) => void;
};

const roles = {
  super: 'SUPER',
  admin: 'ADMIN',
  basic: 'BASIC',
};

const UserCard: FC<Props> = ({ fullName, role, userId, imgSrc = null, onChangeUserRole }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLSpanElement>(null);
  const userRole = role.toLowerCase();

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const toggleMenu = () => setIsOpen((prev) => !prev);
  const hideMenu = () => setIsOpen(false);

  const handleMenuClick = (newRole: string) => {
    hideMenu();
    if (newRole !== role) {
      onChangeUserRole(newRole, userId);
    }
  };

  const img = useImage(imgSrc);

  return (
    <Card>
      <CardImg src={img} alt={fullName} />
      <CardInfo>
        <UserFullName>{fullName}</UserFullName>
        <UserLabel ref={ref} className={userRole} onClick={toggleMenu}>
          {userRole}
        </UserLabel>
        <CSSTransition in={isOpen} timeout={300} classNames="menu" unmountOnExit>
          <Menu onClick={handleMenuClick} items={roles} />
        </CSSTransition>
      </CardInfo>
    </Card>
  );
};

export default UserCard;
