import styled from '@emotion/styled';

export const Box = styled.li`
  display: flex;
  line-height: 50px;
  padding-left: 25px;
  padding-right: 20px;
  cursor: pointer;
  background: white;
  overflow: hidden;
  color: #565e65;
  font-weight: 500;

  &:hover {
    background: ${({ theme }) => theme.colors.gray[400]};
  }
`;

export const Before = styled.span`
  margin-right: 15px;
`;

export const After = styled.span`
  margin-left: auto;
  padding-left: 15px;
`;

export const Content = styled.span``;
