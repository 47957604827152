import { FC, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { css } from '@emotion/react';
import { FormProvider, useForm } from 'react-hook-form';

import PagePanel from 'components/PagePanel';
import Tabs from 'components/Tabs';
import TabPane from 'components/Tabs/TabPane';
import Modal from 'components/Modal';
import UseActiveTab from 'utils/hooks/useActiveTab';
import { getDetails } from 'services/project';
import { Employee } from 'services/types/Employee';
import { getCompanyEmployees } from 'services/company';
import IterationsList from './Iterations';
import Files from './Files';
import Details from './Details';
import ShareProject from './Details/ShareProject';

const useQuery = () => new URLSearchParams(useLocation().search);

const stylesModal = css`
  width: 560px;
`;

const ProjectDetailPage: FC = () => {
  const currentPage = useQuery().get('activeTab');
  const methods = useForm();
  const { handleSubmit } = methods;
  const [employees, setEmployees] = useState<Employee[]>([]);
  const { id } = useParams<{ id: string }>();
  const [tab, setTab] = useState(currentPage || 'iterations');
  const [projectName, setProjectName] = useState<string>();
  const [isOpenShareModal, setIsOpenShareModal] = useState(false);
  const [isOpenNewIterationModal, setIsOpenNewIterationModal] = useState(false);

  UseActiveTab(tab);

  useEffect(() => {
    getDetails(id).then((data) => {
      setProjectName(data.name);
      getCompanyEmployees(data.company.id).then((employee) => {
        setEmployees(
          employee.map((item) => ({
            name: item.name,
            id: item.id,
          })),
        );
      });
    });
  }, [id]);

  const handleChangeTab = (newTab: string) => {
    setTab(newTab);
  };

  const onToggleShareModal = () => {
    setIsOpenShareModal((prevState) => !prevState);
  };

  const onToggleNewIterationModal = () => {
    setIsOpenNewIterationModal((prevState) => !prevState);
  };

  const pagePanelProps = () => {
    if (tab === 'details') {
      return {
        title: projectName || '',
        onClickSecondButton: onToggleShareModal,
        buttonIcon: 'share',
      };
    }
    if (tab === 'files') {
      return {
        title: 'Files',
        buttonIcon: 'share',
        onClickSecondButton: onToggleShareModal,
      };
    }

    return {
      title: 'Iterations',
      buttonText: 'Add iteration',
      buttonIcon: 'share',
      onClick: onToggleNewIterationModal,
      onClickSecondButton: onToggleShareModal,
    };
  };

  const onSubmit = handleSubmit(() => {});

  return (
    <FormProvider {...methods}>
      <PagePanel {...pagePanelProps()} />
      <Tabs active={tab} onChange={handleChangeTab}>
        <TabPane name="details">
          <Details />
        </TabPane>
        <TabPane name="iterations">
          <IterationsList
            isNewIteration={isOpenNewIterationModal}
            onToggleModal={onToggleNewIterationModal}
          />
        </TabPane>
        <TabPane name="files">
          <Files />
        </TabPane>
      </Tabs>
      {isOpenShareModal && (
        <Modal
          title="Share Project"
          textCancel="Cancel"
          textConfirm="Save"
          styleTypeConfirm="primary"
          styleModal={stylesModal}
          text={<ShareProject handleChange={onSubmit} employees={employees} />}
          isOpen={isOpenShareModal}
          onCancel={onToggleShareModal}
          onClose={onToggleShareModal}
          onConfirm={onSubmit}
        />
      )}
    </FormProvider>
  );
};
export default ProjectDetailPage;
