import { FC } from 'react';

import ProjectCard from 'components/ProjectCard';
import useImage from 'utils/hooks/useImage';
import { ProjectItem as ProjectItemType } from 'services/types/Project';
import LinkStyled from './ProjectItem.styled';

type Props = {
  item: ProjectItemType;
};

const ProjectItem: FC<Props> = ({ item }) => {
  const { id, name, companyName, status, deadline, logo } = item;
  const image = useImage(logo);

  return (
    <LinkStyled to={`/main/project-list/detail/${id}`}>
      <ProjectCard
        title={name}
        company={companyName}
        status={status}
        deadline={deadline}
        imgSrc={image}
      />
    </LinkStyled>
  );
};

export default ProjectItem;
