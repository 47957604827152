import { FC, MouseEvent, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import ClientItem from 'components/ClientItem';
import useImage from 'utils/hooks/useImage';
import { Client } from 'services/types/Client';
import Modal from 'components/Modal';
import { deleteCompany } from 'services/company';

type Props = {
  item: Client;
};

const ProjectItem: FC<Props> = ({ item }) => {
  const { id, name, logo, projectsCount } = item;
  const [isOpenModal, setIsOpenModal] = useState(false);
  const image = useImage(logo);
  const history = useHistory();

  const onOpenModal = (event: MouseEvent) => {
    event.preventDefault();
    setIsOpenModal(true);
  };

  const onCloseModal = () => {
    setIsOpenModal(false);
  };
  const deleteClientClickHandler = () => {
    deleteCompany(id.toString()).then(() => {
      onCloseModal();
      history.push('/main/project-list');
    });
  };

  return (
    <>
      <Link to={`/main/client-list/detail/${id}`}>
        <ClientItem
          clientLogo={image}
          projectsCount={projectsCount}
          clientName={name}
          onToggleModal={onOpenModal}
        />
      </Link>
      {isOpenModal && (
        <Modal
          title="Delete Company"
          textCancel="Cancel"
          textConfirm="Delete"
          styleTypeConfirm="warning"
          text={`Are you sure want to delete "${name}"?`}
          isOpen={isOpenModal}
          onCancel={onCloseModal}
          onClose={onCloseModal}
          onConfirm={deleteClientClickHandler}
        />
      )}
    </>
  );
};
export default ProjectItem;
