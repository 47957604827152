import { FC, DragEvent, useContext } from 'react';
import { BoardType, SubTaskType, TaskType } from '../types';
import { Container, TaskName, TooltipSubtask } from './SubTask.styled';
import Context, { DetailsContext } from '../context';
import { showDetails } from '../../../pages/EstimationDetailPage/KanbanBoardPage/utils';

type Props = {
  board: BoardType;
  task: TaskType;
  subTask: SubTaskType;
};

const SubTask: FC<Props> = ({ board, subTask, task }) => {
  const { currentValues } = useContext(Context);
  const { details, setDetails } = useContext(DetailsContext);
  const { hours, id, technology } = subTask;
  const substoryTitle = `${task.title} (${technology.name})`;

  const onDragStart = (event: DragEvent<HTMLDivElement>) => {
    event.stopPropagation();

    const target = event.target as HTMLDivElement;

    currentValues.current = {
      currentBoard: board,
      currentTask: task,
      currentSubTask: subTask,
      currentType: 'subTask',
    };

    setTimeout(() => {
      target.style.visibility = 'hidden';
    }, 0);
  };

  const onDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const onDragEnd = (event: DragEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    target.style.visibility = 'visible';
  };

  const showSubtaskDetails = () => {
    showDetails('subTaskItem', id, details, subTask, setDetails, substoryTitle);
  };

  return (
    <>
      <Container
        draggable
        className="item"
        onDragOver={onDragOver}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        id={id.toString()}
        onClick={showSubtaskDetails}
      >
        {substoryTitle.length > 33 && <TooltipSubtask>{substoryTitle}</TooltipSubtask>}
        <TaskName>{substoryTitle}</TaskName>
        <span>{hours} hours</span>
      </Container>
    </>
  );
};

export default SubTask;
