import styled from '@emotion/styled';

import { TitleRow, TitleRowCell } from './Row.styled';

export const FooterTitleRow = styled(TitleRow)`
  text-transform: uppercase;
  height: 45px;
`;

export const FooterTitleRowCell = styled(TitleRowCell)`
  &:last-of-type {
    font-weight: 600;
    border-left: 1px solid ${({ theme }) => theme.colors.gray[300]};
  }
`;

export const FooterRow = styled.tr`
  color: ${({ theme }) => theme.colors.gray[600]};
  line-height: 20px;
  height: 45px;
`;

export const FooterCell = styled.td`
  padding: 6px 11px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[200]};
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black[400]};

  &:first-of-type {
    border-left: 1px solid ${({ theme }) => theme.colors.gray[200]};
    border-bottom-left-radius: 5px;
  }

  &:last-of-type {
    border-right: 1px solid ${({ theme }) => theme.colors.gray[200]};
    border-left: 1px solid ${({ theme }) => theme.colors.gray[300]};
    border-bottom-right-radius: 5px;
    text-align: center;
  }
`;
