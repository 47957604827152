import React, { useContext, useEffect, useState } from 'react';
import { DetailsContext } from '../context';
import {
  Container,
  CloseButton,
  CloseIcon,
  TaskDetailContainer,
  ItemTitle,
  StatusTitle,
  DateContainer,
} from './TaskDetail.styled';
import { InfoDetailType } from '../types';
import addDetails, { close } from './utils';
import StringDetail from './StringDetail';

const TaskDetail = (): null | JSX.Element => {
  const [infoDetails, setInfoDetails] = useState<InfoDetailType | null>();
  const [isOpen, setIsOpen] = useState(false);
  const { details } = useContext(DetailsContext);
  const { showDetails, taskItem, subTaskItem, additionalInfo } = details;
  useEffect(
    () => addDetails(showDetails, taskItem, subTaskItem, additionalInfo, setIsOpen, setInfoDetails),
    [subTaskItem, taskItem, showDetails, additionalInfo],
  );

  if (infoDetails?.info) {
    const { title, taskStatus, startDate, endDate, hours, riskLevel } = infoDetails.info;

    return (
      <TaskDetailContainer isOpen={isOpen}>
        <Container>
          <CloseButton type="button" onClick={() => close(setIsOpen, setInfoDetails)}>
            <CloseIcon />
          </CloseButton>
        </Container>
        {infoDetails.additionalInfo && <ItemTitle>{infoDetails.additionalInfo}</ItemTitle>}
        {title && <ItemTitle>{title}</ItemTitle>}
        {taskStatus ? <StatusTitle>{taskStatus.title}</StatusTitle> : null}
        <StringDetail name="Estimate:" child={<>{hours} h</>} />
        <DateContainer>
          <StringDetail name="Start date: " child={startDate} />
          <StringDetail name="End date: " child={endDate} />
        </DateContainer>
        <StringDetail name="Risk level: " child={riskLevel?.title} />
        {subTaskItem && <StringDetail name="Technology: " child={subTaskItem.technology?.name} />}
      </TaskDetailContainer>
    );
  }

  return null;
};
export default TaskDetail;
