import { FC, MouseEvent, useState } from 'react';

import Icon, { IconName, nameToComponentMap } from 'components/Icon';
import ItemsList from './ItemsList';
import { DropDownContainer, DropDownHeader, Box, Label, IconContainer } from './Select.styled';

export type Props = {
  itemList?: string[];
  value: string;
  onChange: (value: string) => void;
  label?: string;
  disabled?: boolean;
  width?: number;
  isContact?: boolean;
};

const Select: FC<Props> = ({ itemList, value, onChange, label, disabled, width, isContact }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value);
  const icon = isContact ? selectedValue[0].toLowerCase() + selectedValue.slice(1) : '';

  const handleChange = ({ target }: MouseEvent<HTMLElement>) => {
    const { innerText } = target as HTMLElement;
    onChange(innerText);
    setSelectedValue(innerText);
    setIsOpen(false);
  };

  const onToggle = () => setIsOpen((prevState) => !prevState && !disabled);

  return (
    <Box>
      <div>{label && <Label>{label}</Label>}</div>
      <DropDownContainer w={width}>
        <DropDownHeader disabled={disabled} onClick={onToggle}>
          <div>
            {isContact && Object.keys(nameToComponentMap).includes(icon) && (
              <IconContainer>
                <Icon name={icon as IconName} size={16} />
              </IconContainer>
            )}
            {value}
          </div>
          <Icon name="angleDown" />
        </DropDownHeader>
        {isOpen && (
          <ItemsList
            onChange={handleChange}
            itemList={itemList}
            value={value}
            labeled={!!label}
            isContact={isContact}
          />
        )}
      </DropDownContainer>
    </Box>
  );
};

export default Select;
