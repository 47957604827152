import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';

import { getMe } from 'services/user';
import User from 'services/types/User';

type State = {
  user: User | null;
};

export const loadCurrentUser = createAsyncThunk('user/loadCurrentUser', async () => {
  const response = await getMe();

  return response as User;
});

const initialState: State = {
  user: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    removeUser(state) {
      state.user = null;
    },
    resetUser(state) {
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadCurrentUser.fulfilled, (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    });
  },
});

export const { resetUser, removeUser } = userSlice.actions;

export default userSlice.reducer;
