import { FC, useEffect, useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { css } from '@emotion/react';

import { getCompanyEmployees, getCompanyProjects } from 'services/company';
import EmployeeList from 'pages/CompanyDetailPage/Employees/EmployeesList';
import Modal from 'components/Modal';
import { EmployeeData } from 'pages/CompanyDetailPage/Employees/EmployeeItem';
import { Data, ProjectType } from 'components/EmployeeForm/types';
import { createEmployee } from 'services/employee';
import EmployeeForm from 'components/EmployeeForm';
import PaginatedGrid from 'components/PaginatedGrid';

const stylesModal = css`
  width: 560px;
`;

const defaultValue = {
  name: '',
  email: '',
  position: '',
  contacts: [
    {
      type: '',
      value: '',
    },
  ],
  projects: [
    {
      id: 0,
      name: '',
    },
  ],
};

type Props = {
  isNewEmployee: boolean;
  onToggleModal: () => void;
};

const Employees: FC<Props> = ({ isNewEmployee, onToggleModal }) => {
  const { id } = useParams<{ id: string }>();
  const { control, errors, handleSubmit } = useForm({
    mode: 'onBlur',
    defaultValues: defaultValue,
  });
  const [employees, setEmployees] = useState<EmployeeData[]>([]);
  const [projects, setProjects] = useState<ProjectType[]>([]);
  const [isServerError, setIsServerError] = useState(false);
  const [dataIsChange, setDataIsChange] = useState(false);

  const handleChangeData = () => {
    setDataIsChange((prevState) => !prevState);
  };

  const updateData = useCallback(() => {
    getCompanyEmployees(id)
      .then((data: EmployeeData[]) =>
        setEmployees(
          data.map((employee) => ({
            ...employee,
            projects: !employee.projects.length ? [{ id: 0, name: '' }] : [...employee.projects],
          })),
        ),
      )
      .catch(() => setIsServerError(true));

    getCompanyProjects(id)
      .then((res) =>
        setProjects(
          res.values.map((item) => ({
            id: item.id,
            name: item.name,
          })),
        ),
      )
      .catch(() => setIsServerError(true));
  }, [id]);

  useEffect(() => {
    if (dataIsChange) {
      updateData();
      handleChangeData();
    }
    updateData();
  }, [updateData, dataIsChange]);

  const onSubmit = (data: Data) => {
    const dataToSend = {
      ...data,
      companyId: id,
      projects: data.projects
        .filter((item) => item.id !== 0 && item.name !== '')
        .map((item) => item.id),
    };
    createEmployee(dataToSend)
      .then(() => updateData())
      .then(() => onToggleModal())
      .catch(() => setIsServerError((prevState) => !prevState));
  };

  const handleSubmitEmployee = handleSubmit(onSubmit);

  return (
    <>
      {isServerError && <p>Something go wrong</p>}
      {employees.length === 0 ? (
        <PaginatedGrid textPlaceholder="There is no employees for now" />
      ) : (
        <EmployeeList employees={employees} projects={projects} toUpdateData={handleChangeData} />
      )}
      {isNewEmployee && (
        <Modal
          title="Add Employee"
          textCancel="Cancel"
          textConfirm="Save"
          styleTypeConfirm="primary"
          styleModal={stylesModal}
          text={
            <EmployeeForm
              item={defaultValue}
              control={control}
              errors={errors}
              projectList={projects}
              onSubmit={handleSubmitEmployee}
            />
          }
          isOpen={isNewEmployee}
          onCancel={onToggleModal}
          onClose={onToggleModal}
          onConfirm={handleSubmitEmployee}
        />
      )}
    </>
  );
};
export default Employees;
