import { FC, useState, useRef, Children, ReactElement } from 'react';

import { TabsStyled, HeaderStyled, Container } from './Tabs.style';

import TabName from './TabName/index';

export type Props = {
  active: string;
  children: Array<ReactElement>;
  onChange: (value: string) => void;
};

const Tabs: FC<Props> = ({ children, active, onChange }) => {
  const [currentTab, setCurrentTab] = useState(active);
  const onChangeTabs = (value: string) => {
    if (currentTab !== value) {
      setCurrentTab(value);
      onChange(value);
    }
  };

  const tabNames = useRef<Array<string>>(Children.map(children, (child) => child.props.name));

  const currentPane = Children.map(children, (child) =>
    child.props.name === currentTab ? child : null,
  );

  return (
    <Container>
      <HeaderStyled>
        <TabsStyled>
          {tabNames.current.map((tab) => (
            <TabName onClick={onChangeTabs} tabName={tab} active={tab === currentTab} key={tab} />
          ))}
        </TabsStyled>
      </HeaderStyled>
      {currentPane}
    </Container>
  );
};

export default Tabs;
