import { ChangeEvent, FocusEvent, FC, useState, memo, useEffect, useRef } from 'react';
import styled from '@emotion/styled';

import Icon from 'components/Icon';
import usePreviousValue from 'utils/hooks/usePreviousValue';

const Container = styled.div`
  min-height: 32px;
  flex-grow: 1;
  display: flex;
  align-items: baseline;
  box-sizing: border-box;
  margin-top: 5px;
  cursor: text;

  & .icon {
    margin-right: 20px;
  }
`;

const TextArea = styled.textarea`
  border: 1px solid transparent;
  line-height: 20px;
  padding: 5px 0 5px 5px;
  width: 100%;
  resize: none;
`;

type Props = {
  className?: string;
  value?: null | string;
  onChange: (value: string) => void;
  onBlur: () => void;
};

const CommentField: FC<Props> = ({ value, onChange, onBlur, ...rest }) => {
  const [isActive, setIsActive] = useState(false);
  const ref = useRef<HTMLTextAreaElement>(null);
  const previousValue = usePreviousValue(value);

  useEffect(() => {
    if (isActive) {
      ref.current?.focus();
    }
  }, [isActive]);

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { value: newValue } = event.target;
    onChange(newValue.trimLeft());
  };

  const handleShowInput = () => {
    setIsActive(true);
  };

  const handleHideInput = () => {
    setIsActive(false);
    if (previousValue !== value) {
      onBlur();
    }
  };

  const handleFocus = (event: FocusEvent<HTMLTextAreaElement>) => {
    event.currentTarget.setSelectionRange(
      event.currentTarget.value.length,
      event.currentTarget.value.length,
    );
  };

  if (!isActive && !value) {
    return null;
  }

  return (
    <Container onClick={handleShowInput}>
      {isActive ? (
        <>
          <Icon className="icon" name="question" size={16} />
          <TextArea
            {...rest}
            ref={ref}
            onFocus={handleFocus}
            onChange={handleChange}
            onBlur={handleHideInput}
            rows={2}
            value={value || ''}
          />
        </>
      ) : (
        <>
          <Icon className="icon" name="question" size={16} />
          <p className="ss">{value || ''}</p>
        </>
      )}
    </Container>
  );
};

export default memo(CommentField);
