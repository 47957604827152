import { FC, useEffect, useState } from 'react';
import Container from 'layouts/Container';
import { useParams } from 'react-router-dom';
import getTasks from 'services/tasks';
import { StoriesType } from 'services/types/tasks';
import { DetailsContext } from 'components/KanbanBoard/context';
import StoriesList from './StoriesList';
import { MainContainer } from './styled/KanbanBoard.styled';
import { DetailsType } from '../../../components/KanbanBoard/types';
import TaskDetail from '../../../components/KanbanBoard/TaskDetail/TaskDetail';

const KanbanBoardPage: FC = () => {
  const [storyData, setStoryData] = useState([] as StoriesType[]);

  const [details, setDetails] = useState<DetailsType>({
    showDetails: false,
    idItem: '',
    prev: null,
  });

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    (async () => {
      const response = await getTasks(+id);
      setStoryData(response);
    })();
  }, [id]);

  return (
    <section>
      <Container>
        <MainContainer>
          <DetailsContext.Provider value={{ details, setDetails }}>
            <StoriesList data={storyData} />
            <TaskDetail />
          </DetailsContext.Provider>
        </MainContainer>
      </Container>
    </section>
  );
};
export default KanbanBoardPage;
