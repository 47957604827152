import { FC } from 'react';
import { Control, useFieldArray } from 'react-hook-form';

import ProjectItem from './ProjectItem';
import { ProjectType } from './types';

type Props = {
  projectList?: ProjectType[];
  control: Control;
};

const ProjectList: FC<Props> = ({ projectList = [], control }) => {
  const { fields, remove, insert } = useFieldArray({
    control,
    name: 'projects',
    keyName: 'uuid',
  });

  const handleAddItem = (index: number) => {
    insert(index + 1, {
      id: fields.length + 1,
      name: 'Select Project',
    });
  };

  const handleRemoveItem = (index: number) => {
    remove(index);
  };

  return (
    <>
      {fields.map((item, index) => (
        <ProjectItem
          key={item.uuid}
          index={index}
          fields={fields}
          item={item}
          control={control}
          projectList={projectList}
          onAddItem={handleAddItem}
          onDeleteItem={handleRemoveItem}
        />
      ))}
    </>
  );
};
export default ProjectList;
