import { FC, Dispatch, SetStateAction, BaseSyntheticEvent } from 'react';
import { Controller, Control } from 'react-hook-form';

import Input from 'components/Input';
import Select from 'components/Select';
import Button from 'components/Button';
import Sort from 'components/Sort';
import Arrow from 'components/Arrow';
import { FilterStyled, ContainerStyled } from './Filter.styled';
import { SortType, FilterType } from '../../types';

type Props = {
  statusItems: string[];
  currentStatus: string;
  languageList: string[];
  currentLanguage: string;
  setFilter: Dispatch<SetStateAction<FilterType>>;
  control: Control;
  isDirty: boolean;
  onSubmit: (event?: BaseSyntheticEvent) => Promise<void>;
  onReset: () => void;
  sorts: SortType[];
};

const Filter: FC<Props> = ({
  statusItems,
  setFilter,
  languageList,
  control,
  isDirty,
  onSubmit,
  onReset,
  sorts,
}) => (
  <FilterStyled onSubmit={onSubmit}>
    <Controller
      name="search"
      control={control}
      render={({ name, value, onChange, onBlur }) => {
        const handleBlur = () => {
          onBlur();
          setFilter((prevState) => ({ ...prevState, search: value }));
        };

        return (
          <Input
            width={320}
            placeholder="Project name"
            name={name}
            value={value}
            onChange={onChange}
            onBlur={handleBlur}
          />
        );
      }}
    />
    <Controller
      name="status"
      control={control}
      render={({ value, onChange }) => {
        const handleChange = (newValue: string) => {
          onChange(newValue);
          setFilter((prevState) => ({ ...prevState, status: newValue }));
        };

        return (
          <Select
            itemList={statusItems}
            value={value}
            onChange={handleChange}
            label="Project status"
            width={160}
          />
        );
      }}
    />
    <Controller
      name="language"
      control={control}
      render={({ value, onChange }) => {
        const handleChange = (newValue: string) => {
          onChange(newValue);
          setFilter((prevState) => ({ ...prevState, language: newValue }));
        };

        return (
          <Select
            itemList={languageList}
            value={value}
            onChange={handleChange}
            label="Language"
            width={80}
          />
        );
      }}
    />
    <Button
      label="Reset filters"
      isDisable={!isDirty}
      onClick={onReset}
      styleType={!isDirty ? 'disabled' : 'primary'}
    />
    <ContainerStyled>
      <Controller
        name="sort"
        control={control}
        render={({ value, onChange }) => {
          const handleChange = (newValue: string) => {
            onChange(newValue);
            setFilter((prevState) => ({ ...prevState, sort: newValue }));
          };

          return <Sort title="Sort by:" options={sorts} onChange={handleChange} value={value} />;
        }}
      />
      <Controller
        name="asc"
        control={control}
        render={({ value, onChange }) => {
          const handleChange = (newValue: boolean) => {
            onChange(newValue);
            setFilter((prevState) => ({ ...prevState, asc: newValue }));
          };

          return <Arrow onChange={handleChange} value={value} />;
        }}
      />
    </ContainerStyled>
  </FilterStyled>
);

export default Filter;
