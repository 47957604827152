import styled from '@emotion/styled';

export const FlexRowNowrapFlexStartCenter = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
`;

export const FlexRowNowrapSpaceBetweenCenter = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`;
