import { FC, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import PagePanel from 'components/PagePanel';
import PaginatedGrid from 'components/PaginatedGrid';
import Container from 'layouts/Container';
import { AllClients } from 'services/types/Client';
import { getCompanies } from 'services/company';
import useActiveTab from 'utils/hooks/useActiveTab';
import { getInCaps, getPagesCount } from 'utils/common';
import { STATUSES, SORTS, SHOWN_QUANTITY, PAGE_NEIGHBOR_QUANTITY } from 'constants/common';
import Filter from './components/Filter';
import Client from './components/Client';
import { FilterType } from './types';
import { useUserData } from '../../reducers/selectors';

const initialFilter: FilterType = {
  search: '',
  status: STATUSES[0],
  sort: SORTS[0].value,
  asc: true,
};

const getParams = (filter: FilterType, page: number) => {
  const { status } = filter;
  const statusName = status === STATUSES[0] ? '' : getInCaps(status);

  return {
    asc: filter.asc,
    name: filter.search,
    page,
    size: SHOWN_QUANTITY,
    sort: filter.sort.toLowerCase(),
    status: statusName,
  };
};

const useQuery = () => new URLSearchParams(useLocation().search);

const ClientsPage: FC = () => {
  const currentPage = useQuery().get('activeTab');
  const currentTab = currentPage !== null ? +currentPage : 1;
  const [filter, setFilter] = useState(initialFilter);
  const [clients, setClients] = useState<AllClients>();
  const [page, setPage] = useState(currentTab);
  const user = useUserData();

  useActiveTab(page);

  useEffect(() => {
    const params = getParams(filter, page);
    getCompanies(params)
      .then((res) => {
        if (res.totalCount > 0 && res.values.length === 0) {
          return setPage(getPagesCount(res.totalCount, SHOWN_QUANTITY));
        }

        return res;
      })
      .then((result) => result && setClients(result));
  }, [filter, page]);

  const { control, formState, reset, handleSubmit } = useForm({
    defaultValues: initialFilter,
  });

  const handleReset = () => {
    reset();
    setFilter(initialFilter);
  };

  const onSubmit = handleSubmit((data: FilterType) => {
    setFilter(data);
  });

  return (
    <div>
      <PagePanel
        buttonText={
          user && (user.role === 'SUPER' || user.role === 'ADMIN') ? 'Create project' : ''
        }
        title="Clients"
        link="/main/client-list/create"
      />
      <Container>
        <Filter
          onSubmit={onSubmit}
          isDirty={formState.isDirty}
          control={control}
          statusItems={STATUSES}
          currentStatus={filter.status}
          setFilter={setFilter}
          onReset={handleReset}
          sorts={SORTS}
        />
        <PaginatedGrid
          totalItem={clients?.totalCount}
          select={page}
          pageNeighbors={PAGE_NEIGHBOR_QUANTITY}
          onChangePage={setPage}
          textPlaceholder="There is no clients for now"
        >
          {clients && clients.values.map((client) => <Client key={client.id} item={client} />)}
        </PaginatedGrid>
      </Container>
    </div>
  );
};

export default ClientsPage;
