import { FC, useEffect, useState, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Base64 } from 'js-base64';

import EstimationTable from 'components/EstimationTable';
import EstimationTableData, { IExcelEstimationData } from 'components/EstimationTable/types';
import { getCookie } from 'utils/auth';
import PageHeader from './PageHeader';
import RisksTable from '../../components/RisksTable/RisksTable';
import StatusesSelect from './StatusesSelect';
import Form from './Form';
import {
  Container,
  InnerContainer,
  EstimationInfo,
  Grid,
  StatusContainer,
  StatusLabel,
  EstimationDateContainer,
  EstimationDateLabel,
  EstimationDate,
  FileButtonContainer,
} from './styled/EstimationPage.styled';
import { useLoadEstimationData, useSocket, useSocketClient } from './data';
import DirectionTable from './DirectionTable/DirectionTable';
import { contentPdfCalculate } from './PdfFileLoader/utilsPdf';
import GeneratePdf from './PdfFileLoader/GeneratePdf';
import calcDirections from './utils';
import { IExcelData } from './DirectionTable/types';
import TablesContext from './context';
import ExcelLoad from './ExcelFileLoader/ExcelLoad';

const HEADER_OFFSET = 72;

const EstimationPage: FC = () => {
  const token = getCookie('jwtToken');
  const { id } = useParams<{ id: string | undefined }>();
  const [tableData, setTableData] = useState<EstimationTableData>([]);
  const [directionData, setDirectionData] = useState({});
  const [canEdit, setCanEdit] = useState(true);
  const estimationData = useLoadEstimationData(id);

  const [tablesContext, setTablesContext] = useState<{
    estimationTable?: IExcelEstimationData | null;
    directionTable?: IExcelData | null;
  }>({
    estimationTable: null,
    directionTable: null,
  });
  const {
    tableId = null,
    created = null,
    persons = [],
    status = null,
    name,
    technologies,
    id: estimationId,
  } = estimationData;

  const ref = useRef<EstimationTableData>();
  const client = useSocketClient(tableId);

  useSocket({ client, ref, tableId, setTableData, setDirectionData });

  const handleChangeDirectionData = useCallback(
    (timeSum: number) => {
      setDirectionData(calcDirections(tableData, timeSum));
    },
    [tableData],
  );

  useEffect(() => {
    setCanEdit(status === 'READY');
  }, [status]);

  useEffect(
    () => () => {
      if (client && tableId) {
        client.disconnect(() => {});
      }
    },
    [client, tableId],
  );

  const handleChangeEstimationTable = (newTableData: EstimationTableData) => {
    const data = JSON.stringify(
      {
        action: 'update',
        token,
        tableUUID: tableId,
        table: Base64.toBase64(JSON.stringify({ sections: newTableData })),
      },
      null,
      0,
    );
    if (client) {
      ref.current = newTableData;
      const sendUrl = `/estimation/${tableId}/table`;
      client.send(sendUrl, {}, data);
    }
  };

  const date = created ? new Date(created) : '';
  const year = date ? date.getFullYear() : '';
  const month = date ? date.getMonth() + 1 : '';
  const day = date ? date.getDate() : '';

  return (
    <section>
      <TablesContext.Provider value={{ tablesContext, setTablesContext }}>
        <PageHeader name={name} persons={persons} tablesContext={tablesContext} />
        <Container>
          <InnerContainer>
            <EstimationInfo>
              <div>
                {date && (
                  <EstimationDateContainer>
                    <EstimationDateLabel>Date:</EstimationDateLabel>
                    <EstimationDate
                      dateTime={`${year}-${month}-${day}`}
                    >{`${day}.${month}.${year}`}</EstimationDate>
                  </EstimationDateContainer>
                )}
                <Grid rows={persons.length}>
                  <EstimationDateLabel className="label">Responced persons:</EstimationDateLabel>
                  {persons?.length && <Form data={estimationData} />}
                </Grid>
              </div>
              {status && (
                <StatusContainer>
                  <StatusLabel>Status:</StatusLabel>
                  <StatusesSelect status={status} estimationId={estimationId} canEdit={canEdit} />
                </StatusContainer>
              )}
            </EstimationInfo>
            {tableData.length && (
              <EstimationTable
                data={tableData}
                offset={HEADER_OFFSET}
                technologies={technologies}
                onChange={handleChangeEstimationTable}
                changeDirectionData={handleChangeDirectionData}
                canEdit={canEdit}
              />
            )}

            <FileButtonContainer>
              <GeneratePdf
                estimationName={name}
                persons={persons}
                getContent={contentPdfCalculate}
              />
              <ExcelLoad csvData={tablesContext.directionTable} fileName="direction-exel" />
            </FileButtonContainer>

            <DirectionTable items={directionData} />
            <RisksTable />
          </InnerContainer>
        </Container>
      </TablesContext.Provider>
    </section>
  );
};
export default EstimationPage;
