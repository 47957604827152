import styled from '@emotion/styled';

const selectedItem = '#007ad9';

export const DropDownHeader = styled.div<{ disabled?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 15px 9px 16px;
  background: #ffffff;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  width: 100%;
  border: 1px solid #d5dee9;
  box-sizing: border-box;
  border-radius: 4px;
  line-height: 24px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

export const DropDownListContainer = styled.div<{ labeled: boolean }>`
  width: 100%;
  position: absolute;
  z-index: 1;
  margin-left: ${({ labeled = false }) => (labeled ? '3px' : '0')};
`;

export const DropDownList = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
`;

export const ListStatus = styled.li<{ selected: boolean }>`
  margin: 0;
  padding: 9px 16px;
  list-style: none;
  line-height: 24px;
  color: ${(props) => (props.selected ? '#fff' : '#000')};
  background-color: ${(props) => (props.selected ? selectedItem : '#fff')};

  &:hover {
    background-color: ${(props) => (props.selected ? selectedItem : '#e3e3e3')};
  }
`;

export const Box = styled.div<{ w?: number }>`
  display: flex;
`;

export const Label = styled.div`
  position: relative;
  z-index: 1;
  padding: 9px 16px;
  border: 1px solid #d5dee9;
  border-radius: 3px 0 0 3px;
  color: #565e65;
  font-weight: 700;
  line-height: 24px;
  margin-right: -4px;
  background: #fafafa;
  width: max-content;
`;

export const DropDownContainer = styled.div<{ w?: number }>`
  position: relative;
  width: ${({ w }) => (w ? `${w}px` : '100%')};
  min-width: max-content;
`;

export const IconContainer = styled.div`
  display: inline-block;
  margin-right: 10px;
`;
