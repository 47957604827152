import styled from '@emotion/styled';

export const DropDownContainer = styled.div`
  position: relative;
  min-width: max-content;
`;

export const DropDownHeader = styled.div`
  background: ${({ theme }) => theme.colors.white[400]};
  width: 100%;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.colors.gray[200]};
  min-width: 90px;
  padding: 8px 12px;
  text-align: center;
  line-height: 22px;
  font-weight: 700;
  border-radius: 20px;
  cursor: pointer;

  &::first-letter {
    text-transform: uppercase;
  }

  &.draft {
    border-color: ${({ theme }) => theme.colors.gray[50]};
    background-color: ${({ theme }) => theme.colors.gray[50]};
    color: ${({ theme }) => theme.colors.gray[600]};
  }
  &.ready {
    border-color: ${({ theme }) => theme.colors.yellow[200]};
    background-color: ${({ theme }) => theme.colors.yellow[200]};
    color: ${({ theme }) => theme.colors.yellow[400]};
  }
  &.sent-to-approve {
    border-color: ${({ theme }) => theme.colors.blue[200]};
    background-color: ${({ theme }) => theme.colors.blue[200]};
    color: ${({ theme }) => theme.colors.blue[400]};
  }
  &.accepted {
    border-color: ${({ theme }) => theme.colors.green[200]};
    background-color: ${({ theme }) => theme.colors.green[200]};
    color: ${({ theme }) => theme.colors.green[400]};
  }
  &.rejected {
    border-color: ${({ theme }) => theme.colors.red[200]};
    background-color: ${({ theme }) => theme.colors.red[200]};
    color: ${({ theme }) => theme.colors.red[400]};
  }
`;

export const DropDownList = styled.ul`
  position: absolute;
  margin: 0;
  padding: 0;
  width: 190px;
  background-color: ${({ theme }) => theme.colors.white[400]};
  box-sizing: border-box;
  z-index: 5;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.15));
  border: 1px solid ${({ theme }) => theme.colors.gray[200]};
  border-radius: 4px;

  &.dropdown-enter {
    opacity: 0;
    transform: translateY(10px);
  }

  &.dropdown-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms, transform 300ms;
  }

  &.dropdown-exit {
    opacity: 1;
    transform: translateY(0);
  }

  &.dropdown-exit-active {
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 400ms, transform 400ms;
  }
`;

export const ListStatus = styled.li`
  padding: 15px 22px;
  list-style: none;
  background-color: ${({ theme }) => theme.colors.white[400]};
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray[550]};

  &:first-of-type {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  &:last-of-type {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 21px;
    display: inline-block;
    order: 1;
  }

  & .draft {
    background-color: ${({ theme }) => theme.colors.gray[600]};
  }

  & .ready {
    background-color: ${({ theme }) => theme.colors.yellow[400]};
  }

  & .sent-to-approve {
    background-color: ${({ theme }) => theme.colors.blue[400]};
  }

  & .accepted {
    background-color: ${({ theme }) => theme.colors.green[400]};
  }

  & .rejected {
    background-color: ${({ theme }) => theme.colors.red[400]};
  }

  & .value::first-letter {
    text-transform: uppercase;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[450]};
  }
`;
