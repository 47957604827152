import { FC, useState, useEffect, useCallback } from 'react';

import { Technology } from 'services/types/EstimationData';
import useGetColumns from './columns';
import { Container, Table } from './styled/Table.style';
import TableHeader from './TableHeader';
import TableFooter from './TableFooter';
import Body from './Body';
import ContextMenu from './ContextMenu';
import TableContextProvider from './context';
import { useGetTechnologiesList, useFormattedData } from './utils';
import { EstimationData, Row } from './types';

type Props = {
  data: EstimationData;
  offset?: number;
  technologies: Technology[];
  onChange: (value: EstimationData) => void;
  changeDirectionData?: (timeSum: number) => void;
  canEdit?: boolean;
};

const EstimationTable: FC<Props> = ({
  data,
  offset,
  technologies,
  onChange,
  changeDirectionData,
  canEdit,
}) => {
  const [first] = data;
  const technologiesList = useGetTechnologiesList(first, technologies);
  const columns = useGetColumns<Row>(technologiesList);
  const formattedData = useFormattedData(data);
  const [xPos, setXPos] = useState('0px');
  const [yPos, setYPos] = useState('0px');
  const [showMenu, setShowMenu] = useState(false);

  const handleClick = useCallback(() => {
    if (showMenu) {
      setShowMenu(false);
    }
  }, [showMenu]);

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.addEventListener('click', handleClick);
    };
  }, [handleClick]);

  const handleContextMenu = useCallback(
    ({ clientX, clientY }: { clientX: number; clientY: number }) => {
      setXPos(`${clientX}px`);
      setYPos(`${clientY}px`);
      setShowMenu(true);
    },
    [],
  );

  return (
    <Container>
      <TableContextProvider
        data={formattedData}
        technologiesList={technologiesList}
        onChange={onChange}
        changeDirectionData={changeDirectionData}
      >
        <Table id="tablePDF">
          <TableHeader columns={columns} offset={offset} />
          <Body columns={columns} onContextMenuClick={handleContextMenu} canEdit={canEdit} />
          <TableFooter technologiesListLength={technologiesList.length} columns={columns} />
        </Table>
        <ContextMenu xPos={xPos} yPos={yPos} showMenu={showMenu} />
      </TableContextProvider>
    </Container>
  );
};

export default EstimationTable;
