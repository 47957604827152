import { FC } from 'react';
import { useFieldArray, Control } from 'react-hook-form';

import ContactItem from './ContactItem';

type Props = {
  control: Control;
};

const itemsList = [
  {
    iconName: 'email',
    value: 'email',
    label: 'Email',
  },
  {
    iconName: 'skype',
    value: 'skype',
    label: 'Skype',
  },
  {
    iconName: 'instagram',
    value: 'instagram',
    label: 'Instagram',
  },
  {
    iconName: 'linkedin',
    value: 'linkedin',
    label: 'LinkedIn',
  },
  {
    iconName: 'facebook',
    value: 'facebook',
    label: 'Facebook',
  },
  {
    iconName: 'zoom',
    value: 'zoom',
    label: 'Zoom',
  },
];

const ContactList: FC<Props> = ({ control }) => {
  const { fields, remove, insert } = useFieldArray({
    control,
    name: 'contacts',
  });

  const handleAddItem = (index: number) => {
    insert(index + 1, {
      type: '',
      value: '',
    });
  };

  const handleRemoveItem = (index: number) => {
    remove(index);
  };

  return (
    <>
      {fields.map((item, index) => {
        const newItemsList =
          fields.length > 0
            ? itemsList.filter(
                (elem) =>
                  fields.every((field) => field.type !== elem.value) || elem.value === item.type,
              )
            : itemsList;

        return (
          <ContactItem
            key={item.id}
            index={index}
            fields={fields}
            item={item}
            control={control}
            itemsList={newItemsList}
            onAddItem={handleAddItem}
            onDeleteItem={handleRemoveItem}
          />
        );
      })}
    </>
  );
};
export default ContactList;
