import { AppScaleEnum } from 'pages/EstimationDetailPage/Gantt/models/AppScaleEnum';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setAppStartPoint } from 'reducers/gantt';
import { useGanttScaleKey } from 'reducers/selectors';
import useHourPixels from '../../hooks/useHourPixels';
import { DatePeriod } from '../../types';
import { fromStart } from '../../utils';

const useGapFromStart = (weekStart: Date, monthStart: Date, datePeriod: DatePeriod): void => {
  const scaleKey = useGanttScaleKey();
  const pxInHour = useHourPixels(scaleKey);
  const dispatch = useDispatch();

  useEffect(() => {
    if (scaleKey === AppScaleEnum.Days) {
      dispatch(setAppStartPoint(0));
    }
    if (scaleKey === AppScaleEnum.Weeks) {
      // console.log(fromStart(weekStart, datePeriod.start, pxInHour));
      dispatch(setAppStartPoint(fromStart(weekStart, datePeriod.start, pxInHour)));
    }
    if (scaleKey === AppScaleEnum.Months) {
      // console.log(fromStart(monthStart, datePeriod.start, pxInHour));
      dispatch(setAppStartPoint(fromStart(monthStart, datePeriod.start, pxInHour)));
    }
  }, [dispatch, pxInHour, scaleKey, datePeriod, weekStart, monthStart]);
};

export default useGapFromStart;
