import { FC } from 'react';
import { StatusBoardContainer, StatusBoardLine, StatusTitle } from './StatusBoard.styled';

type Props = {
  name: string;
};

const StatusBoard: FC<Props> = ({ name }) => (
  <StatusBoardContainer>
    <StatusTitle>{name}</StatusTitle>
    <StatusBoardLine />
  </StatusBoardContainer>
);

export default StatusBoard;
