import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

type StyledProps = {
  withPersonPadding?: boolean;
  cardImage?: boolean;
  cardPersonEmail?: boolean;
};

export const HeaderContainer = styled.header`
  padding: 18px 48px;
  height: 72px;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.blue[600]};
  color: ${({ theme }) => theme.colors.white[400]};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderMenu = styled.div`
  margin-left: 68px;
  display: flex;
  align-items: center;
`;

export const HeaderMenuTitle = styled(Link)`
  text-decoration: none;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.gray[300]};
  margin-right: 25px;
  &:last-child {
    margin-right: 0px;
  }
`;

export const FlexContainer = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  position: relative;
  ${({ withPersonPadding }) =>
    withPersonPadding &&
    `
    padding: 16px;
  `}
`;

export const HeaderPersonImage = styled.img<StyledProps>`
  width: 34px;
  height: 34px;
  border-radius: 50%;
  margin-left: 24px;
  ${({ cardImage }) =>
    cardImage &&
    `
    width: 30px;
    height: 30px;
    margin-left: 0px;
    margin-right: 18px;
  `}
`;

export const PersonText = styled.div<StyledProps>`
  color: ${({ theme }) => theme.colors.white[400]};
  padding: 5px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;

export const CardPersonText = styled.div<StyledProps>`
  font-weight: 700;
  margin-bottom: 7px;
  ${({ cardPersonEmail }) =>
    cardPersonEmail &&
    `
    font-size: 12px;
    color: #8d9097;
  `}
`;

export const PersonContainer = styled.div`
  position: absolute;
  top: 46px;
  right: 4px;
  color: ${({ theme }) => theme.colors.black[400]};
  width: 276px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.white[400]};
  box-shadow: 0 5px 10px rgb(0 41 119 / 20%);
`;

export const PersonLogOut = styled.button`
  display: flex;
  align-items: center;
  padding: 13px 13px 13px 25px;
  color: ${({ theme }) => theme.colors.blue[700]};
  text-decoration: none;
  cursor: pointer;
  border: none;
  background-color: transparent;
`;
