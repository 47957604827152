import styled from '@emotion/styled';

export const Title = styled.h3`
  color: ${({ theme }) => theme.colors.gray[850]};
  font-weight: 500;
  font-size: 19px;
  margin-bottom: 25px;
`;

export const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray[200]};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.white[400]};
  padding: 24px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
