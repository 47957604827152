import { FC, useState, ChangeEvent } from 'react';

import Icon from 'components/Icon';
import {
  DropDownList,
  DropDownListContainer,
  SearchInputContainer,
  SearchInput,
  SearchIconContainer,
} from './Select.styled';
import Item, { Data } from './Item';

type Props = {
  value: string | number;
  withSearch: boolean;
  itemsList: Data[];
  onChange: (value: string | number) => void;
};

const ItemsList: FC<Props> = ({ itemsList, value, withSearch, onChange }) => {
  const [search, setSearch] = useState('');

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const filteredList = itemsList.filter(({ label }) =>
    label.toLowerCase().includes(search.toLowerCase()),
  );

  return (
    <DropDownListContainer>
      {withSearch && (
        <SearchInputContainer>
          <SearchInput autoComplete="off" type="search" onChange={handleSearch} />
          <SearchIconContainer>
            <Icon name="search" size={16} />
          </SearchIconContainer>
        </SearchInputContainer>
      )}
      <DropDownList>
        {filteredList.map((item) => (
          <Item key={item.value} data={item} value={value} onChange={onChange} />
        ))}
      </DropDownList>
    </DropDownListContainer>
  );
};

export default ItemsList;
